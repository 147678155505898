import Header from "../header/header"
import { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setSelectedBooth } from "../../redux/reducers/booths"
import { extendAuthTimer } from "../../redux/reducers/auth"
import { setSelectedClient } from "../../redux/reducers/clients"
import { DetailsForm, DeploymentHistory, Status, Preview, ImagesForm, ResourcesForm, VideosForm, OptionsForm, SlidesForm, ShowcaseForm } from "./components/index"
import { Link } from "react-router-dom"
import moment from "moment"
import Modal from "../modal/modal"
import md5 from "md5"
import AdminPanel from "../nav/adminPanel"
import ManagedUpload from "../../services/managedUpload"
import API from "../../services/api"
import { setHeaderClickEvent, setHeaderLeftButton, setHeaderRightButtons, setHeaderRightItems, setHeaderTitle } from "../../redux/reducers/ui"
import Constants from "../constants"
import RepresentativesForm from "./components/representatives"

const BoothEditor = (props) => {

  // Setup state
  const api = new API()
  const [selectedTab, setSelectedTab] = useState('details')
  const [boothStore, user, clients, headerEvent] = useSelector((state) => [state.booths, state.auth.user, state.clients, state.ui.header.clickEvent])
  const booth = boothStore.selectedBooth
  const availableBoothTypes = boothStore.availableTypes
  const dispatch = useDispatch()
  const [version, setVersion] = useState(false)
  const [pageContent, setPageContent] = useState(false)
  const [isPersisting, setIsPersisting] = useState(false)
  const [pendingChanges, setPendingChanges] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [showOverwriteModal, setShowOverwriteModal] = useState(false)
  const [overwriteName, setOverwriteName] = useState('')

  // Effect to check if we have changes, if so, we'll add an unload handler
  useEffect(() => {
    if (pendingChanges || isPersisting) {
      window.addEventListener("beforeunload", handleUnloadEvent)
    }
    else {
      window.removeEventListener("beforeunload", handleUnloadEvent)
    }

    return () => window.removeEventListener("beforeunload", handleUnloadEvent)

  }, [pendingChanges, isPersisting])

  useEffect(() => {
    let mounted = true
    console.log('booth editor: mounted')
    dispatch(extendAuthTimer(true))

    // Check to see if we're an admin, looking at a client booth w/o a client list or selected client
    if (user.isAdmin && clients && !clients.selectedClient) {
      // Redirect
      props.history.push('/clients')
      return
    }

    // Check to see if we need to fetch the booth, we might have it in our store already
    let defer = {}
    let p = new Promise((resolve, reject) => {
      defer.resolve = resolve
      defer.reject = reject
    })

    if (!booth) {
      props.loader.start()
      
      api.methods.get('booths/' + props.match.params.id).then((res) => {
        if (mounted) {
          props.loader.stop()

          if (res.data && res.data.booth) {
            // Set selected booth
            dispatch(setSelectedBooth(res.data.booth))
            defer.resolve(res.data.booth)
          }
          else {
            defer.reject()
          }
        }
      }, () => {
        console.log('error fetching booth')
        // todo: handle error
        if (mounted) {
          props.loader.stop()
        }
      })
    }
    else {
      defer.resolve(booth)
    }

    // After we have our booth, let's set the appropriate version
    p.then((resolvedBooth) => {

      // Set tab based on user permissions
      if (user && user.isShared && user.access === 'view') {
        setSelectedTab(null)
      }

      // Get version
      const foundVersion = resolvedBooth.versions.find((item) => item._id === props.match.params.version)
      if (!foundVersion) {
        console.log("Draft not found")
        return
      }

      // Set template
      let localTemplate = availableBoothTypes && availableBoothTypes.find((item) => item.key === resolvedBooth.type)

      // Check if we need to add data/media properties to our instance
      const instance = {
        ...foundVersion,
        data: foundVersion.data ? {...foundVersion.data} : {},
        media: foundVersion.media ? {...foundVersion.media} : {}
      }

      if (localTemplate) {

        // Check if we need to combine any templates
        if (localTemplate.parent) {
          const parent = availableBoothTypes && availableBoothTypes.find((item) => item.key === localTemplate.parent)
          if (parent) {
            localTemplate = {...parent, ...localTemplate}
          }
        }
      }

      // Check if we have a blueprint applied which will overwrite any instance changes
      if (resolvedBooth && resolvedBooth.blueprint && resolvedBooth.blueprint.selections) {

        Object.keys(resolvedBooth.blueprint.selections).forEach((selKey) => {
          const selection = resolvedBooth.blueprint.selections[selKey]
          instance[selection.instanceKey] = {...instance[selection.instanceKey], [selection.optionKey]: {[selection.optionValueKey]: selection.presetValue, locked: true}}

          // Add "hidden" options in the template if this blueprint provides the attr
          if (selection.unlocksAttr) {
            localTemplate = {...localTemplate, [selection.unlocksAttr.instanceKey]: {...localTemplate[selection.unlocksAttr.instanceKey], [selection.unlocksAttr.key]: selection.unlocksAttr}}
          }
        })
      }

      // Set header
      dispatch(setHeaderTitle(instance.name + (user.isShared && user.access === 'view' ? " (Read Only)" : "")))
      dispatch(setHeaderLeftButton({label: resolvedBooth.name, disabled: user.isShared, key: Constants.BOOTH_EDITOR_BACK_BTN}))
      dispatch(setHeaderRightItems([{label: "Last Updated: " + (instance.lastUpdated ? moment(instance.lastUpdated).format('MM/DD/YYYY hh:mm a') : moment(instance.created).format('MM/DD/YYYY hh:mm a'))}]))

      if (!user.isShared || (user.isShared && user.access === 'edit')) {
        dispatch(setHeaderRightButtons([{label: "Cancel", className: "cancel-btn", disabled: true, key: Constants.BOOTH_EDITOR_CANCEL_BTN}, {label: "Save", className: "primary-btn", disabled: true, key: Constants.BOOTH_EDITOR_SAVE_BTN}]))
      }
      else {
        dispatch(setHeaderRightButtons(null))
      }

      // Set version + template if it exists
      setVersion({
        instance: instance,
        pristine: JSON.parse(JSON.stringify(instance)),
        template: localTemplate ? localTemplate : null
      })

    }, () => {
      // todo: handle error
    })

    return () => mounted = false
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  // We need to observe pending changes & isPersisting to update our header buttons
  useEffect(() => {

    if (!user.isShared || (user.isShared && user.access === 'edit')) {
      dispatch(setHeaderRightButtons([{label: "Cancel", className: "cancel-btn", disabled: !pendingChanges || isPersisting, key: Constants.BOOTH_EDITOR_CANCEL_BTN}, {label: isPersisting ? "Saving..." : "Save", className: isPersisting ? "primary-btn active" : "primary-btn", disabled: !pendingChanges || isPersisting, key: Constants.BOOTH_EDITOR_SAVE_BTN}]))
    }

  }, [isPersisting, pendingChanges]) // eslint-disable-line react-hooks/exhaustive-deps
  

  // Handle header events
  useEffect(() => {

    if (!headerEvent || (user.isShared && user.access === "view")) { return }

    // Clear
    dispatch(setHeaderClickEvent(null))

    // Handle
    if (headerEvent.key === Constants.BOOTH_EDITOR_BACK_BTN) {
      handleBackButton()
    }
    else if (headerEvent.key === Constants.BOOTH_EDITOR_SAVE_BTN) {
      saveChanges()
    }
    else if (headerEvent.key === Constants.BOOTH_EDITOR_CANCEL_BTN) {
      cancelChanges()
    }

  }, [headerEvent]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleUnloadEvent = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  const handleChange = useCallback((instanceKey, key, val, subKey) => {
    // Check permissions
    if (user.isShared && user.access && user.access === 'view') { return }

    // Check if our instance key exists
    if (!version.instance[instanceKey]) {
      version.instance[instanceKey] = {}
    }

    // Check if we have any changes to apply
    const curVal = subKey && version.instance[instanceKey][key] && version.instance[instanceKey][key][subKey] ? version.instance[instanceKey][key][subKey] : version.instance[instanceKey][key]
    if (!curVal || curVal !== val) {
      setPendingChanges(true)
      let updated = {...version}

      if (typeof updated.instance[instanceKey] === 'object') {
        if (subKey) {
          updated.instance[instanceKey] = {...updated.instance[instanceKey], [key]: {...updated.instance[instanceKey][key], [subKey]: val}}
        }
        else {
          updated.instance[instanceKey] = {...updated.instance[instanceKey], [key]: val}
        }
      }
      else {
        updated.instance[instanceKey][key] = val
      }
      
      // If we're already published, we need to show a duplicate modal to avoid overriding what is published
      if (isDeployed()) {
        console.log('Check out new copy')
        setOverwriteName(version.instance.name + " - " + (booth.versions.length + 1))
        
        // Clear focus
        if (document.activeElement) {
          document.activeElement.blur()
        }

        // Show after half sec
        setTimeout(() => {
          setShowOverwriteModal(true)
        }, 500)

        return
      }

      // Update
      setVersion(updated)
    }
  }, [version, user.isShared, user.access]) // eslint-disable-line 

  // This method handles uploading media to AWS
  const persistMedia = (params) => {
    console.log('persist media')

    return new Promise((resolve, reject) => {

      let promises = []
      Object.keys(params).forEach((key) => {
        if (params[key].body) {
          promises.push(uploadMedia(params[key]))
        }
      })

      Promise.all(promises).then((values) => {
        resolve(values)
      }, () => reject())
    })
  }

  const uploadMedia = (params) => {
    return new Promise((resolve, reject) => {
      
      // Compile base asset
      let asset = {type: params.type, parentKey: params.parentKey}
      for (const key of params.body.keys()) {
        if (key !== "file") {
          asset[key] = params.body.get(key)
        }
      }

      // Check for delete
      if (asset.delete || !params.body.get("file")) {
        resolve(asset)
      }
      else {
        // Get file & start a managed upload
        console.log("Uploading: " + params.body.get("file").name)
        const file = params.body.get("file")
        const headers = {Authorization: `Bearer ${user.token}`}
        const managed = new ManagedUpload('booths/' + booth._id + "/" + version.instance._id + "/media/" + params.type, file, headers)
        managed.upload().then((res) => {

          // Append remote src to asset & resolve
          resolve({...asset, remoteSrc: res.url, etag: res.etag})
        }, (err) => {
          console.log(err)
          reject()
        })
      }
    })
  }

  const cancelChanges = () => {
    console.log("Cancel changes")
    setPendingChanges(false)
    setVersion({...version, instance: JSON.parse(JSON.stringify(version.pristine))})
    dispatch(extendAuthTimer(true))
  }

  const handleCancelOverwrite = () => {
    setShowOverwriteModal(false)
    cancelChanges()
  }

  const handleOverwriteDraft = () => {
    setShowOverwriteModal(false)
    console.log('save changes to new draft')

    dispatch(extendAuthTimer(true))
    const now = moment()

    // Create a copy of the current version
    const instanceCopy = JSON.parse(JSON.stringify(version.pristine))

    // Update id + name
    instanceCopy._id = md5(now.valueOf().toString() + instanceCopy._id)
    instanceCopy.name = overwriteName

    // Clear sharing, update dates
    delete instanceCopy.sharing
    instanceCopy.created = now.valueOf()
    instanceCopy.lastUpdated = now.valueOf()

    // Setup request
    const params = {
      versions: [...booth.versions, instanceCopy]
    }

    // Make booth request
    api.methods.patch('booths/' + booth._id, params).then((res) => {

      // Update store
      dispatch(setSelectedBooth({...booth, versions: params.versions}))

      // Redirect to new version
      const url = `/booths/${booth._id}/${instanceCopy._id}`
      props.history.push(url)

    }, () => {
      // todo: handle error
      console.log('error duplicating published version')
    })
  }

  const isDeployed = () => {
    return booth.deployments.filter((item) => item.versionId === version.instance._id && (item.status === "Published" || item.status === "Failed to Publish" || item.status === "Publishing")).length > 0
  }

  const saveChanges = () => {
    // Check permissions
    if (user.isShared && user.access === 'view') { return }

    dispatch(extendAuthTimer(true))
  
    // Trigger an expo hall update
    const expoClientId = clients.selectedClient ? clients.selectedClient._id : user.isShared && user.clientId ? user.clientId : false
    if (expoClientId) {
      const expoParams = {
        delay: 3,
        isAutoUpdate: true
      }

      api.methods.post(`clients/${expoClientId}/exhibitEndpoint`, expoParams).then((res) => {
        // Update client with data if we have a selected client
        if (clients.selectedClient) {
          let settings = clients.selectedClient.settings ? {...clients.selectedClient.settings} : {}
          settings.publicEndpoint = res.data
          dispatch(setSelectedClient({...clients.selectedClient, settings: settings}))
        }
      })
    }

    // Start saving
    setIsPersisting(true)
    let numUpdates = 0

    // Create diff obj
    let params = {data: {}, images: {}, videos: {}, resources: {}, representatives: {}, extras: {}, slides: {}, showcase: {}}
    for (const key in version.instance.data) {
      if (version.pristine.data[key] === undefined || JSON.stringify(version.instance.data[key]) !== JSON.stringify(version.pristine.data[key])) {
        params.data[key] = version.instance.data[key]
        numUpdates++
      }
    }

    // Check if we need to persist any images
    for (const key in version.instance.images) {
      
      // Check for deletion
      if (version.instance.images[key].delete) {
        const emptyBody = new FormData()
        emptyBody.append("key", key)
        emptyBody.append("delete", "true")
        params.images[key] = {key: key, type: 'images', body: emptyBody}
        numUpdates++
      }
      else if (version.instance.images[key].toBePersisted) {
        params.images[key] = {key: key, type: 'images', body: version.instance.images[key].toBePersisted}
        delete version.instance.images[key].toBePersisted
        numUpdates++
      }
    }

    // Check if we need to persist any videos
    for (const key in version.instance.videos) {
      // Check for deletion
      if (version.instance.videos[key].delete) {
        const emptyBody = new FormData()
        emptyBody.append("key", key)
        emptyBody.append("delete", "true")
        params.videos[key] = {key: key, type: 'videos', body: emptyBody}
        numUpdates++
      }
      else if (version.instance.videos[key].toBePersisted) {
        params.videos[key] = {key: key, type: 'videos', body: version.instance.videos[key].toBePersisted}
        delete version.instance.videos[key].toBePersisted
        numUpdates++
      }
    }

    // Check if we need to persist any resources
    for (const key in version.instance.resources) {
      
      // Check for deletion
      if (version.instance.resources[key].resource && version.instance.resources[key].resource.delete) {
        const emptyBody = new FormData()
        emptyBody.append("key", key)
        emptyBody.append("delete", "true")
        params.resources[key] = {key: key, type: 'resources', body: emptyBody}
        numUpdates++
      }
      else {
        if (version.instance.resources[key].resource && version.instance.resources[key].resource.toBePersisted) {
          params.resources[key + ".resource"] = {key: key + ".resource", type: 'resources', body: version.instance.resources[key].resource.toBePersisted}
          delete version.instance.resources[key].resource.toBePersisted
          numUpdates++
        }

        if (version.instance.resources[key].thumb && version.instance.resources[key].thumb.toBePersisted) {
          params.resources[key + ".thumb"] = {key: key + ".thumb", type: 'resources', body: version.instance.resources[key].thumb.toBePersisted}
          delete version.instance.resources[key].thumb.toBePersisted
          numUpdates++
        }
      }
    }

    // Check if we need to persist any slides
    for (const key in version.instance.slides) {
      
      // Check for deletion
      if (version.instance.slides[key] !== undefined) {
        params.slides[key] = {key: key, type: 'slides', value: version.instance.slides[key]}
        numUpdates++
      }
    }

    // Check if we need to persist any representatives
    for (const key in version.instance.representatives) {
      
      // Check for deletion
      if (version.instance.representatives[key].photo && version.instance.representatives[key].photo.delete) {
        const emptyBody = new FormData()
        emptyBody.append("key", key)
        emptyBody.append("delete", "true")
        params.representatives[key] = {key: key, type: 'representatives', body: emptyBody}
        numUpdates++
      }
      else {
        if (version.instance.representatives[key].photo && version.instance.representatives[key].photo.toBePersisted) {
          params.representatives[key + ".photo"] = {key: key + ".photo", type: 'representatives', body: version.instance.representatives[key].photo.toBePersisted}
          delete version.instance.representatives[key].photo.toBePersisted
          numUpdates++
        }

        // Persist any data fields
        if (Object.keys(version.instance.representatives[key]).length > 0) {
          Object.keys(version.instance.representatives[key]).filter((fieldKey) => fieldKey !== "photo" && fieldKey !== "key").forEach((fieldKey) => {
            if (!version.pristine.representatives || !version.pristine.representatives[key] || version.pristine.representatives[key][fieldKey] === undefined || JSON.stringify(version.instance.representatives[key][fieldKey]) !== JSON.stringify(version.pristine.representatives[key][fieldKey])) {

              if (!params.representatives[key]) {
                params.representatives[key] = {}
              }

              params.representatives[key][fieldKey] = version.instance.representatives[key][fieldKey]
            }
          })
        }
      }
    }

    // Check if we need to persist any showcase products
    for (const key in version.instance.showcase) {
      
      // Check for deletion
      if (version.instance.showcase[key].photo && version.instance.showcase[key].photo.delete) {
        const emptyBody = new FormData()
        emptyBody.append("key", key)
        emptyBody.append("delete", "true")
        params.showcase[key] = {key: key, type: 'showcase', body: emptyBody}
        numUpdates++
      }
      else {
        if (version.instance.showcase[key].photo && version.instance.showcase[key].photo.toBePersisted) {
          params.showcase[key + ".photo"] = {key: key + ".photo", type: 'showcase', body: version.instance.showcase[key].photo.toBePersisted}
          delete version.instance.showcase[key].photo.toBePersisted
          numUpdates++
        }

        if (version.instance.showcase[key].qrCodePhoto && version.instance.showcase[key].qrCodePhoto.toBePersisted) {
          params.showcase[key + ".qrCodePhoto"] = {key: key + ".qrCodePhoto", type: 'showcase', body: version.instance.showcase[key].qrCodePhoto.toBePersisted}
          delete version.instance.showcase[key].qrCodePhoto.toBePersisted
          numUpdates++
        }

        // Persist any data fields
        if (Object.keys(version.instance.showcase[key]).length > 0) {
          Object.keys(version.instance.showcase[key]).filter((fieldKey) => fieldKey !== "photo" && fieldKey !== "key" && fieldKey !== "qrCodePhoto").forEach((fieldKey) => {
            if (!version.pristine.showcase || !version.pristine.showcase[key] || version.pristine.showcase[key][fieldKey] === undefined || JSON.stringify(version.instance.showcase[key][fieldKey]) !== JSON.stringify(version.pristine.showcase[key][fieldKey])) {

              if (!params.showcase[key]) {
                params.showcase[key] = {}
              }

              params.showcase[key][fieldKey] = version.instance.showcase[key][fieldKey]
            }
          })
        }
      }
    }

    // Check if we need to persist any extras
    if (version.instance.data && Object.keys(version.instance.data).length > 0) {
      Object.keys(version.instance.data).forEach((key) => {
        if (version.instance.data[key].resource && version.instance.data[key].resource.toBePersisted) {
          params.extras[key + ".resource"] = {key: key + '.resource', type: 'data', body: version.instance.data[key].resource.toBePersisted}
          delete version.instance.data[key].resource.toBePersisted
          numUpdates++
        }
        else if (version.instance.data[key].resource && version.instance.data[key].resource.delete) {
          const emptyBody = new FormData()
          emptyBody.append("key", key)
          emptyBody.append("delete", "true")
          params.extras[key] = {key: key, type: 'data', body: emptyBody}
          numUpdates++
        }
      })
    }

    if (Object.keys(params.data).length < 1 && Object.keys(params.images).length < 1 && Object.keys(params.videos).length < 1 && Object.keys(params.resources).length < 1 && Object.keys(params.representatives).length < 1 && Object.keys(params.extras).length < 1 && Object.keys(params.slides).length < 1 && Object.keys(params.showcase).length < 1) {
      console.log('Nothing to persist')
      setPendingChanges(false)
      setIsPersisting(false)
      props.loader.stop()
      return
    }

    // Show loader
    const numUpdatesStr = numUpdates + (numUpdates === 1 ? " update " : " updates ")
    props.loader.start("We're saving your booth", "Please don't go anywhere, we'll be quick, promise. Just " + numUpdatesStr + "to make.")

    // Persist media
    persistMedia({...params.images, ...params.videos, ...params.resources, ...params.representatives, ...params.extras, ...params.slides, ...params.showcase}).then((values) => {

      // Now we'll translate our params to a patch that mongo will understand + update instance
      let patch = {
        $set: {},
        $unset: {}
      }

      let deletedReps = {}
      let deletedProducts = {}

      // Set instance image meta data if needed
      if (values.length > 0) {
        console.log('media persisted...')

        // Go through media
        values.forEach((val) => {

          if (val.type !== 'resources' && val.type !== 'data' && val.type !== 'representatives' && val.type !== 'showcase') {

            const patchKey = 'versions.$.' + val.type + '.' + val.key

            // Set remote src + original filename if included
            if (!val.delete) {
              for (const key in val) {
                patch.$set[patchKey + "." + key] = val[key] === "true" ? true : val[key] === "false" ? false : val[key]
              }

              if (val.remoteSrc) {
                version.instance[val.type][val.key].remoteSrc = val.remoteSrc
              }

              if (val.originalFilename) {
                version.instance[val.type][val.key].originalFilename = val.originalFilename
              }
            }
            else {
              patch.$unset[patchKey] = true
            }
          }
          // Representatives specific logic
          else if (val.type === 'representatives') {
            const patchKey = 'versions.$.' + val.type + '.' + val.key

            if (!val.delete) {

              for (const key in val) {
                patch.$set[patchKey + "." + key] = val[key] === "true" ? true : val[key] === "false" ? false : val[key]
              }

              if (val.remoteSrc && val.key.indexOf('.photo') > -1) {
                version.instance[val.type][val.parentKey].photo.remoteSrc = val.remoteSrc
              }
            }
            else {
              deletedReps[val.key] = true
              patch.$unset[patchKey] = true
            }
          }
          // Showcase specific logic
          else if (val.type === 'showcase') {
            const patchKey = 'versions.$.' + val.type + '.' + val.key

            if (!val.delete) {

              for (const key in val) {
                patch.$set[patchKey + "." + key] = val[key] === "true" ? true : val[key] === "false" ? false : val[key]
              }

              if (val.remoteSrc && val.key.indexOf('.photo') > -1) {
                version.instance[val.type][val.parentKey].photo.remoteSrc = val.remoteSrc
              }
              else if (val.remoteSrc && val.key.indexOf('.qrCodePhoto') > -1) {
                version.instance[val.type][val.parentKey].qrCodePhoto.remoteSrc = val.remoteSrc
              }
            }
            else {
              deletedProducts[val.key] = true
              patch.$unset[patchKey] = true
            }
          }
          // Resource specific logic
          else {

            const patchKey = 'versions.$.' + val.type + '.' + val.key

            if (!val.delete) {

              for (const key in val) {
                patch.$set[patchKey + "." + key] = val[key] === "true" ? true : val[key] === "false" ? false : val[key]
              }

              if (val.remoteSrc && val.key.indexOf('.resource') > -1) {
                version.instance[val.type][val.parentKey].resource.remoteSrc = val.remoteSrc
              }
              else if (val.remoteSrc && val.key.indexOf('.thumb') > -1) {
                version.instance[val.type][val.parentKey].thumb.remoteSrc = val.remoteSrc
              }
            }
            else {
              patch.$unset[patchKey] = true
            }
          }
        })
      }
      else {
        console.log('no media to persist')
      }

      // Go through data
      for (const key in params.data) {

        // Make sure we don't do anything that contains a resource (i.e contact resource)
        if (!params.data[key].resource) {
          /// Remove any local src / form data before it hits db
          let sanitized = sanitizeObject(JSON.parse(JSON.stringify(params.data[key])))
          patch.$set['versions.$.data.' + key] = sanitized
        }
        else {
          console.log("ignoring resource object: " + key)
        }
      }

      // Go through rep data
      if (params.representatives) {
        for (const key in params.representatives) {
          if (!deletedReps[key]) {
            if (key.indexOf(".photo") < 0) {
              Object.keys(params.representatives[key]).filter((fieldKey) => ['key', 'photo'].indexOf(fieldKey) < 0).forEach((fieldKey) => {
                patch.$set["versions.$.representatives." + key + "." + fieldKey] = params.representatives[key][fieldKey]
              })
            }
          }
        }
      }

      // Go through product data
      if (params.showcase) {
        for (const key in params.showcase) {
          if (!deletedProducts[key]) {
            if (key.indexOf(".photo") < 0 && key.indexOf(".qrCodePhoto") < 0) {
              Object.keys(params.showcase[key]).filter((fieldKey) => ['key', 'photo', 'qrCodePhoto'].indexOf(fieldKey) < 0).forEach((fieldKey) => {
                patch.$set["versions.$.showcase." + key + "." + fieldKey] = params.showcase[key][fieldKey]
              })
            }
          }
        }
      }

      // Go through slides
      if (params.slides) {
        for (const key in params.slides) {
          if (params.slides[key].value) {
            patch.$set["versions.$.slides." + key] = params.slides[key].value
          }
        }
      }

      // Add last updated
      const lastUpdated = moment().valueOf()
      patch.$set['versions.$.lastUpdated'] = lastUpdated

      // Remove unset if it's not needed
      if (Object.keys(patch.$unset).length < 1) {
        delete patch.$unset
      }

      console.log(patch)

      // Patch
      api.methods.patch('booths/' + booth._id + '/' + version.instance._id, patch).then(async () => {

        // Stop loader
        props.loader.stop()

        // Reset pending
        setPendingChanges(false)

        // Reset saving
        setIsPersisting(false)

        // Update pristine
        version.instance.lastUpdated = lastUpdated
        setVersion({...version, pristine: JSON.parse(JSON.stringify({...version.instance}))})

        // Update store
        let curVersions = [...booth.versions]
        const versionIndex = curVersions.findIndex((item) => item._id === version.instance._id)
        curVersions[versionIndex] = JSON.parse(JSON.stringify(version.instance))
        dispatch(setSelectedBooth({...booth, versions: curVersions}))

      }, () => {
      //   // todo: handle error
        console.log('error updating booth version')
        window.alert("We had some trouble updating your booth. Please try again") // change to a modal
        props.loader.stop()
        setIsPersisting(false)
      })

    }, () => {
      console.log('error persisting media')
      window.alert("We had some trouble updating your booth. Please try again") // change this to a modal
      props.loader.stop()
      setIsPersisting(false)
      // todo: handle
    })
  }

  const sanitizeObject = (obj) => {

    for (const paramKey in obj) {
      // Check for object
      if (typeof obj[paramKey] === "object") {
        for (const subKey in obj[paramKey]) {
          if (subKey === "src" || subKey === "toBePersisted") {
            delete obj[paramKey][subKey]
          }
        }
      }
      else if (paramKey === "src" || paramKey === "toBePersisted") {
        delete obj[paramKey]
      }
    }

    return obj
  }

  const handleSubmit = useCallback((params) => {

    // User is not allowed
    if (user.isShared && user.access === 'view') {
      return
    }

    // Set inferno creds
    // let creds = user.settings && user.settings.infernoAuth ? user.settings.infernoAuth  : {} 
    // if (clients && clients.selectedClient && clients.selectedClient.settings && clients.selectedClient.settings.infernoAuth) {
    //   console.log('set inferno creds from client')
    //   creds = clients.selectedClient.settings.infernoAuth
    // }

    // Compile base params
    let deployParams = {
      comments: params.comments,
      created: moment().valueOf(),
      status: "Submitted",
      type: "submission",
      // infernoParentCategoryId: booth.infernoParentCategory ? booth.infernoParentCategory.id : false,
      boothIdStr: booth._id,
      // boothName: booth.name,
      versionId: version.instance._id,
      versionName: version.instance.name,
      userIdStr: user._id,
      // infernoDeployment: booth.infernoDeployment ? booth.infernoDeployment : false
    }

    // Set auth object
    // deployParams.infernoAuth = creds

    // Post submission
    api.methods.post('booths/deploy/request', deployParams).then((res) => {

      // Add to booth
      if (res.data && res.data.success && res.data.deployment) {
        let deployments = booth.deployments ? [...booth.deployments] : []
        deployments.push(res.data.deployment)

        // Update store
        dispatch(setSelectedBooth({...booth, deployments: deployments}))
      }

      // todo: handle error
      
    }, () => {
    //   // todo: handle error
      console.log('error creating booth deployment')
    })

  }, [booth, user, version, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    let mounted = true
    const readOnly = user.isShared && user.access === 'view'
    const permission = !user.isShared ? 'publish' : user.isShared && user.access === 'edit' ? 'request' : false
    const parentCategory = booth && booth.infernoParentCategory ? booth.infernoParentCategory : false

    if (mounted && booth) {
      switch (selectedTab) {
        case 'options':
          setPageContent(<OptionsForm version={ version } change={ handleChange } readOnly={ readOnly } />)
          break
        case 'resources':
          setPageContent(<ResourcesForm version={ version } change={ handleChange } readOnly={ readOnly } />)
          break
        case 'representatives':
          setPageContent(<RepresentativesForm version={ version } change={ handleChange } readOnly={ readOnly } />)
          break
        case 'images': 
          setPageContent(<ImagesForm version={ version } change={ handleChange } readOnly={ readOnly } />)
          break
        case 'videos':
          setPageContent(<VideosForm version={ version } change={ handleChange } readOnly={ readOnly } />)
          break
        case 'details':
          setPageContent(<DetailsForm version={ version } change={ handleChange } readOnly={ readOnly }/>)
          break
        case 'slides':
          setPageContent(<SlidesForm version={ version } change={ handleChange } readOnly={ readOnly } />)
          break
        case 'showcase':
          setPageContent(<ShowcaseForm version={ version } change={ handleChange } readOnly={ readOnly } />)
          break
        case 'status':
          setPageContent(<Status persisting={ isPersisting } submit={ handleSubmit } permission={ permission } infernoParentCategory={ parentCategory } deployments={ booth.deployments } version={ version }/>)
          break
        default:
          setPageContent(null)
          break
      }
    }

    return () => mounted = false

  }, [selectedTab, handleChange, version, user, booth, handleSubmit, isPersisting])
  
  if (!booth || !version) { return <h3 className="red">Sorry, we could not locate the provided exhibit. Please try again.</h3> }

  // Setup header
  const handleBackButton = () => {
    // Do we need to show warning modal?
    if (isPersisting || pendingChanges) {
      setShowWarningModal(true)
      return
    }
    
    // Navigate to booth
    props.history.push('/booths/' + booth._id)
  }

  const warningModal = showWarningModal ? <Modal title="Just one second please!" children={ <div><h3>If you leave now, your changes will be lost.</h3><button className="primary-btn" onClick={ () => setShowWarningModal(false) }>Got it</button></div> } close={ () => setShowWarningModal(false) } /> : null
  const overwriteModal = showOverwriteModal ? <Modal title="Creating a new draft" children={ <div><h3>This item is currently published. We will duplicate what is published to a new draft. Sound good? Please name your new draft:</h3><br/><input type="text" value={ overwriteName } onChange={ (e) => setOverwriteName(e.target.value) } /><div className="bmt-button-group modal-group"><button className="cancel-btn" onClick={ handleCancelOverwrite }>Cancel Changes</button><button className="primary-btn" onClick={ handleOverwriteDraft }>Copy to new draft</button></div></div> } close={ handleCancelOverwrite } /> : null

  const view = booth.type ? (
    <div className="bmt-details">
      { warningModal }
      { overwriteModal }
      { (!user.isShared || (user.isShared && user.access === 'edit')) ? <ul className="bmt-details-top-bar">
        <li className={ selectedTab === "details" ? "selected" : "" } onClick={() => setSelectedTab('details')}>Details</li>
        { version.template.options !== undefined && <li className={ selectedTab === "options" ? "selected" : "" } onClick={() => setSelectedTab('options')}>Options</li> }
        { version.template.representatives !== undefined && <li className={ selectedTab === "representatives" ? "selected" : "" } onClick={() => setSelectedTab('representatives')}>Representatives</li> }
        { version.template.resources !== undefined && <li className={ selectedTab === "resources" ? "selected" : "" } onClick={() => setSelectedTab('resources')}>Resources</li> }
        { version.template.images !== undefined && <li className={ selectedTab === "images" ? "selected" : "" } onClick={() => setSelectedTab('images')}>Images</li> }
        { version.template.videos !== undefined && <li className={ selectedTab === "videos" ? "selected" : "" } onClick={() => setSelectedTab('videos')}>Videos</li> }
        { version.template.slides !== undefined && <li className={ selectedTab === "slides" ? "selected" : "" } onClick={() => setSelectedTab('slides')}>Slides</li> }
        { version.template.showcase !== undefined && <li className={ selectedTab === "showcase" ? "selected" : "" } onClick={() => setSelectedTab('showcase')}>AR Showcase</li> }
        <li className={ selectedTab === "status" ? "selected" : "" } onClick={() => setSelectedTab('status')}>Submit</li>
      </ul> : null }
      <div className="bmt-details-main flex">
        <div className={ pageContent ? "main-left" : "" }>
          { pageContent }
        </div>
        <div className={ pageContent ? "main-right" : "preview-only" }>
          { selectedTab === "status" ? <DeploymentHistory instance={ version.instance } deployments={ booth.deployments } /> : <Preview name={ booth.name } boothId={ booth._id } booth={ booth } version={ version } /> }
        </div>
      </div>
    </div>
  ) : user.isShared ? "This booth hasn't been fully configured yet, please come back later or contact us." :
  <Link to={ "/booths/" + booth._id }>Please set a booth type before editing this version.</Link>

  return (
    <div className="bmt-main-view">
      { <AdminPanel /> }
      <Header />
      { view }
    </div>
  )
}

export default BoothEditor