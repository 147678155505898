import { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import moment from "moment"
import { setHeaderRightButtons, setHeaderRightItems } from "../../redux/reducers/ui"
import Constants from "../constants"
import DebouncedInput from "../input-handlers/debouncedInput"
import Utils from "../utils"

const BoothListView = (props) => {

  const dispatch = useDispatch()
  const [enhanced, setEnhanced] = useState([])
  const [filtered, setFiltered] = useState([])
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState({type: 'lastUpdated', descending: true})
  const [exportArr, setExportArr] = useState([])
  const [showViewSettings, setShowViewSettings] = useState(false)
  const [viewSettings, setViewSettings] = useState(Constants.VIEW_SETTINGS)
  const searchInputRef = useRef()

  // Check to see if our user has any view settings
  useEffect(() => {

    let enabledKeys = {}
    if (props.user && props.user.settings && props.user.settings.viewSettings && props.user.settings.viewSettings.length > 0) {
      props.user.settings.viewSettings.forEach((item) => {
        if (viewSettings && viewSettings[item]) {
          enabledKeys[item] = true
        }
      })

      if (Object.keys(enabledKeys).length > 0) {
        let copy = JSON.parse(JSON.stringify(viewSettings))
        Object.keys(copy).forEach((key) => {
          copy[key].enabled = enabledKeys[key]
        })

        setViewSettings(copy)
      }
    }

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Enhance booths
  useEffect(() => {

    enhanceBooths()

  }, [props.booths]) // eslint-disable-line react-hooks/exhaustive-deps

  const enhanceBooths = () => {
    let handler = props.booths.map((item) => {
      const type = item.type && props.types.find((type) => type.key === item.type) ? props.types.find((type) => type.key === item.type) : false
      const typeName = type.name
      let hasExpoImage = false
      let hasChat = false
      let hasFishbowl = false
      let hasARoom = false
      const isShared = item.sharing && !item.sharing.disabled
      const shareObj = {
        edit: isShared && item.sharing.edit ? window.location.origin + '/share/' + item.sharing.edit : false,
        view: isShared && item.sharing.view ? window.location.origin + '/share/' + item.sharing.view : false
      }
      
      if (item.versions && item.versions.length > 0) {
        // Sort & pick latest
        const version = Utils.findLastDeployedIfAvailable([...item.versions].sort(Utils.sortByUpdatedOrCreated));

        // Check for expo hall image
        if (type && type.images && type.images.exhibitHall && version.images && version.images.exhibitHall) {
          hasExpoImage = true
        }

        // Check for fishbowl
        if (version.data && version.data.fishbowl) {
          hasFishbowl = true
        }

        // Check for chat
        if (version.data && version.data.chat) {
          hasChat = true
        }

        // Check for aroom
        if (version.data && version.data.aroom) {
          hasARoom = true
        }
      }

      const lastUpdatedTime = item.lastUpdated ? item.lastUpdated : item.created ? item.created : null
      return {...item, 
        hasChat: hasChat ? "Yes" : "No",
        hasARoom: hasARoom ? "Yes" : "No",
        hasFishbowl: hasFishbowl ? "Yes" : "No",
        hasExpoImage: hasExpoImage ? "Yes" : "No",
        category: item.infernoParentCategory && item.infernoParentCategory.name ? item.infernoParentCategory.name : "",
        isShared: isShared ? "Yes" : "No",
        sharedEditLink: shareObj.edit, 
        sharedViewLink: shareObj.view, 
        statusTag: item.status.toLowerCase().replace(" ", "-"), 
        type: typeName, 
        typeObj: type,
        name: item.name ? item.name : "Untitled",
        lastUpdatedTime: lastUpdatedTime,
        lastUpdatedStr: lastUpdatedTime ? moment(lastUpdatedTime).format('MM/DD/YYYY hh:mm a') : ""
      }
    })

    setEnhanced(handler)
  }

  useEffect(() => {
    if (enhanced.length > 0) {
      filterBooths()
    }
  }, [enhanced]) // eslint-disable-line react-hooks/exhaustive-deps

  const sortBooths = (arr, aSort) => {

    // Sorting
    arr.sort((itemA, itemB) => {
      let itemAval
      let itemBval
      if (aSort.type === 'lastUpdated') {
        itemAval = itemA.lastUpdatedTime
        itemBval = itemB.lastUpdatedTime
      }
      else if (aSort.type === 'type') {
        itemAval = itemA.type ? itemA.type.toLowerCase() : null
        itemBval = itemB.type ? itemB.type.toLowerCase() : null
      }
      else if (aSort.type === 'status') {
        itemAval = itemA.status ? itemA.status : null
        itemBval = itemB.status ? itemB.status : null
      }
      else if (aSort.type === 'shared') {
        itemAval = itemA.isShared 
        itemBval = itemB.isShared 
      }
      else if (aSort.type === 'expo') {
        itemAval = itemA.hasExpoImage 
        itemBval = itemB.hasExpoImage 
      }
      else if (aSort.type === "chat") {
        itemAval = itemA.hasChat
        itemBval = itemB.hasChat
      }
      else if (aSort.type === "aroom") {
        itemAval = itemA.hasARoom
        itemBval = itemB.hasARoom
      }
      else if (aSort.type === "fishbowl") {
        itemAval = itemA.hasFishbowl
        itemBval = itemB.hasFishbowl
      }
      else {
        itemAval = itemA.name ? itemA.name.toLowerCase() : null
        itemBval = itemB.name ? itemB.name.toLowerCase() : null
      }

      if (itemAval === null && itemBval === null) {
        return 0
      }
      else if (itemAval === null && itemBval !== null) {
        return aSort.descending ? -1 : 1
      }
      else if (itemAval !== null && itemBval === null) {
        return aSort.descending ? 1 : -1
      }

      return itemAval > itemBval ? 1 : itemAval < itemBval ? -1 : 0
    })

    if (aSort.descending) {
      arr.reverse()
    }

    return arr
  }

  const updateSort = (type) => {

    // Don't sort delete col
    if (type === "delete") { return }

    if (type === sort.type) {
      // Swap direction
      const newDirection = !sort.descending
      const sortObj = {...sort, descending: newDirection}
      setSort(sortObj)
      sortBooths(filtered, sortObj)
      return
    }

    // Set new sort type
    const sortObj = {...sort, type: type}
    setSort(sortObj)
    sortBooths(filtered, sortObj)
  }

  const filterBooths = (input) => {
    let handler = [...enhanced]

    if (input && input.length > 0) {
      const lowercased = input.toLowerCase()
      handler = handler.filter((item) => item.status.toLowerCase().includes(lowercased) || item.name.toLowerCase().includes(lowercased) || (item.type && item.type.toLowerCase().includes(lowercased)))
    }

    setFiltered(sortBooths(handler, sort))
  }

  const handleBoothClick = (boothId) => {
    props.onClick(boothId)
  }

  const handleBoothDelete = (e, booth) => {
    e.stopPropagation()
    props.onDelete(booth)
  }

  const handleSearch = (val) => {
    setSearch(val)
    filterBooths(val)
  }

  // Setup export data
  useEffect(() => {

    setExportArr(filtered.map((item) => {
        return {
          _id: item._id,
          Name: item.name, 
          Type: item.type,
          Status: item.status,
          HasExpoImage: item.hasExpoImage,
          HasChat: item.hasChat,
          HasARoom: item.hasARoom,
          HasContactShare: item.hasFishbowl,
          Shared: item.isShared,
          SharedEditLink: item.sharedEditLink,
          SharedViewLink: item.sharedViewLink,
          LastUpdated: item.lastUpdatedStr
        }
      })
    )

  }, [filtered, sort])

  const boothList = filtered.map((item) => {
    const thisShareBtnClass = "share-btn not-actionable " + (item.isShared === "Yes" ? "shared" : "")
    const thisExpoBtnClass = "share-btn not-actionable " + (item.hasExpoImage === "Yes" ? "shared" : "")
    const thisChatBtnClass = "share-btn not-actionable " + (item.hasChat === "Yes" ? "shared" : "")
    const thisARoomBtnClass = "share-btn not-actionable " + (item.hasARoom === "Yes" ? "shared" : "")
    const thisFishbowlBtnClass = "share-btn not-actionable " + (item.hasFishbowl === "Yes" ? "shared" : "")

    return (
      <li key={item._id} onClick={() => handleBoothClick(item._id)}>
        <div className="flex">{ item.livexId && <span className="live-x-tag">LiveX</span>} { item.name }</div>
        { viewSettings.type.enabled && <div className="flex small">{ item.type ? item.type : <i>Not Set</i> }</div> }
        { viewSettings.status.enabled &&<div className={ `flex small status-tag status-${item.statusTag}` }><span>{ item.status }</span></div> }
        { viewSettings.category.enabled &&<div className="flex small">{ item.category }</div> }
        { viewSettings.shared.enabled &&<div className="flex small"><span className={ thisShareBtnClass }>{ item.isShared }</span></div> }
        { viewSettings.expo.enabled &&<div className="flex small"><span className={ thisExpoBtnClass }>{ item.hasExpoImage }</span></div> }
        { viewSettings.chat.enabled &&<div className="flex small"><span className={ thisChatBtnClass }>{ item.hasChat }</span></div> }
        { viewSettings.aroom.enabled &&<div className="flex small"><span className={ thisARoomBtnClass }>{ item.hasARoom }</span></div> }
        { viewSettings.fishbowl.enabled &&<div className="flex small"><span className={ thisFishbowlBtnClass }>{ item.hasFishbowl }</span></div> }
        { viewSettings.lastUpdated.enabled &&<div className="flex small">{ item.lastUpdatedStr }</div> }
        { viewSettings.delete.enabled &&<div className="flex small"><button onClick={ (e) => handleBoothDelete(e, item) } className="more-btn red"><span className="icon-trash-2"></span> Delete</button></div> }
      </li>
    )
  })

  // Setup export button
  useEffect(() => {

    let arr = []

    // Check for livex
    if (!props.client || !props.client.settings || !props.client.settings.liveX || !props.client.settings.liveX.apiKey) {
      arr.push({
        label: "Create New Exhibit", 
        disabled: props.client && props.booths.length >= props.client.maxBooths && parseInt(props.client.maxBooths) !== 0, 
        key: Constants.CLIENT_DETAIL_NEW_BTN,
        className: 'primary-btn', 
        icon: 'icon-grid'
      })
    }

    arr.push({
      label: "Export",
      className: "export-btn",
      csv: {
        filename: `Exhibit_List_${ moment().format("MM/DD/YYYY hh:mm a") }.csv`,
        data: exportArr
      }
    })

    dispatch(setHeaderRightButtons(arr))
    dispatch(setHeaderRightItems(null))

  }, [exportArr]) // eslint-disable-line

  const handleViewSettingsBlur = (e) => {
    if (e.relatedTarget && e.relatedTarget.classList.contains("toggle-btn")) { return }

    setShowViewSettings(false)
  }

  const handleViewSetting = (key) => {
    const updated = {...viewSettings, [key]: {...viewSettings[key], enabled: !viewSettings[key].enabled}}
    setViewSettings(updated)

    if (props.onSaveView) {

      // Get only enabled settings & pluck keys only
      props.onSaveView(Object.keys(updated).filter((key) => updated[key].enabled))
    }
  }
  
  return (
    props.booths.length > 0 ?
    <div>
      <div className="form-group bmt-wrapper non-flex half margin-bottom overflow">
        <h5>Search</h5>
        <div className="form-group-subheader" tabIndex="0" onBlur={ (e) => handleViewSettingsBlur(e) }>
          <DebouncedInput ref={ searchInputRef } placeholder="Search by name, status or type" ready={ handleSearch } includeClear={ true } />
          <button className="outline-btn" onClick={ () => setShowViewSettings(true) }><span className="icon-settings"></span> Customize</button>
          { showViewSettings && 
            <div className="form-group-modal">
              <ul>
                { 
                  Object.keys(viewSettings).map((key) => {
                    return <li className={ viewSettings[key].enabled ? "selected" : "" } key={ key }><button className="toggle-btn" disabled={ viewSettings[key].disabled } onClick={ () => handleViewSetting(key) }>{ viewSettings[key].label }</button></li>
                  })
                }
              </ul>
            </div>
          }
        </div>
      </div>
      <div className="bmt-wrapper">
        <ul className="bmt-list-view">
          <li>
            {
              Object.keys(viewSettings).map((key, index) => {
                if (viewSettings[key].enabled) {
                  return <div className={ index !== 0 ? "flex small" : "flex" } key={ key }><button className={ sort.type === key ? "active" : "" } onClick={ () => updateSort(key) }>{ viewSettings[key].label } { sort.type === key && <span className={ sort.descending ? "icon-chevron-down" : "icon-chevron-up" }></span> }</button></div>
                }

                return null
              })
            }
          </li>
          { boothList }
          { boothList.length < 1 && search.length > 0 && <li className="no-action"><h5 className="no-items">No results</h5></li> }
        </ul>
      </div>
    </div> : 
    "Looks like there aren't any exhibits yet." 
  )
}

export default BoothListView