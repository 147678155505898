import "./miniLoader.scss"

const MiniLoader = (props) => {
  const miniClass = "bmt-mini-loader " + (props.inline ? "inline" : "")
  return (
    <div className={ miniClass }>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      <h3>{ props.copy }</h3>
    </div>
  )
}

export default MiniLoader