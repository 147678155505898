import { useState } from "react"
import Modal from "../../modal/modal"
import moment from "moment"

const EncodeResult = (props) => {

  const [showDetails, setShowDetails] = useState(false) 
  const deploy = props.deployments.find((item) => item.versionId === props.versionId)
  const postTasks = deploy && deploy.postPublishTasks ? Object.keys(deploy.postPublishTasks).filter((key) => deploy.postPublishTasks[key].type === "VERIFY_EVENT_VIDEO") : []
  let allSucceeded = true
  let allCompleted = true

  postTasks.forEach((key) => {
    if (!deploy.postPublishTasks[key].encodeCompleted) {
      allCompleted = false
    }

    if (!deploy.postPublishTasks[key].encodeSuccessful) {
      allSucceeded = false
    }
  })

  const status = allSucceeded && allCompleted ? "Completed" : !allSucceeded && allCompleted ? "Failed" : "Encoding"

  const handleViewDetails = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowDetails(true)
  }

  const handleRetry = (key) => {
    setShowDetails(false)

    if (key && !deploy.postPublishTasks[key].encodeSuccessful) {
      props.retryEncode(key)
    }
  }

  const list = (
    <ul className="bmt-list-view">
      <li>
        <div className="flex">Video</div>
        <div className="flex">Completed</div>
        <div className="flex">Status</div>
        <div className="flex">Last Updated</div>
        <div className="flex">Retry</div>
      </li>
      { 
        postTasks.map((key, index) => {
          let status = deploy.postPublishTasks[key].encodeCompleted && deploy.postPublishTasks[key].encodeSuccessful ? "Completed" : deploy.postPublishTasks[key].encodeCompleted && !deploy.postPublishTasks[key].encodeSuccessful ? "Failed" : "Encoding"
          const span = <span className={ status === "Completed" ? "icon-check" : status === "Failed" ? "icon-frown" : "icon-info" }></span>
          const date = deploy.postPublishTasks[key].lastCheck ? deploy.postPublishTasks[key].lastCheck : deploy.publishEnded
          const lastChecked = moment(date).format("MM/DD/YYYY hh:mm a")

          if (status === "Encoding") {
            status += " (" + deploy.postPublishTasks[key].encodePercent + "%)"
          }

          return (
            <li key={ key} className="no-action">
              <div className="flex">Video #{index+1}</div>
              <div className="flex">{ deploy.postPublishTasks[key].encodeCompleted ? "Yes" : "No" }</div>
              <div className="flex">{ span } { status }</div>
              <div className="flex">{ lastChecked }</div>
              <div className="flex">{ status === "Failed" && <button onClick={ () => handleRetry(key) } className="text-btn red"><span className="icon-rotate-cw"></span> Retry</button> }</div>
            </li>
          )
        })
      }
    </ul>
  )

  const details = showDetails ? <Modal z="2" title="Video Status" children={ list } close={ () => setShowDetails(false) } /> : null

  return (
    deploy && postTasks.length > 0 ? 
    <div onClick={ (e) => handleViewDetails(e) }>
      { details }
      <span className={ status === "Completed" ? "icon-check" : status === "Failed" ? "icon-frown" : "icon-info" }></span> { status }
    </div>
    : "n/a"
  )
}

export default EncodeResult