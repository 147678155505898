import { useState } from 'react'
import { AreaChart, Area, Legend, Tooltip, ResponsiveContainer, XAxis, YAxis, Line, LineChart, CartesianGrid, BarChart, Bar } from 'recharts'
import ReportDetailSearchModal from "./reportDetailSearchModal"
import ReportDetailPagesModal from "./reportDetailPagesModal"
import ReportDetailUserModal from "./reportDetailUserModal"

const ReportPlatformDetailView = (props) => {

  const [showUserSearch, setShowUserSearch] = useState(false)
  const [showAllPages, setShowAllPages] = useState(false)
  const [selectedUser, setSelectedUser] = useState(false)
  const percentileBars = [{key: "ten", val: 1}, {key: "twenty", val: 2}, {key: "thirty", val: 3}, {key: "forty", val: 4}, {key: "fifty", val: 5}, {key: "sixty", val: 6}, {key: "seventy", val: 7}, {key: "eighty", val: 8}, {key: "ninety", val: 9}, {key: "hundred", val: 10}]
  
  const handleSelectSearchResult = (item) => {
    setShowUserSearch(false)
    setSelectedUser(item)
  }

  return (
    <>
      { selectedUser && <ReportDetailUserModal user={ selectedUser} close={ () => setSelectedUser(false) } /> }
      { showUserSearch && <ReportDetailSearchModal users={ props.report.data.userInsights } selectItem={ handleSelectSearchResult } close={ () => setShowUserSearch(false) } /> }
      { showAllPages && <ReportDetailPagesModal pages={ props.report.data.pageInsights } close={ () => setShowAllPages(false) } /> }
      <div className="reporting-flex-row">
        <div className="reporting-col reporting-flex-col">
          <div className="data-icon data-turnout"><span className="icon-users"></span><span className="data-turnout-fill" style={ {height: props.report.data.turnout + "%"} }></span></div>
          <div className="data-row">
            <h2>{props.report.data.turnout}%</h2>
            <h3>Show Rate<br/><strong>{props.report.data.turnoutUserCount}</strong> active users / <strong>{props.report.data.registeredUsersCount}</strong> registered users</h3>
          </div>
        </div>
        <div className="reporting-col reporting-flex-col">
          <div className="data-icon data-turnout invert"><span className="icon-users invert"></span><span className="data-turnout-fill invert" style={ {height: (100 - props.report.data.turnout) + "%"} }></span></div>
          <div className="data-row">
            <h2>{100 - props.report.data.turnout}%</h2>
            <h3>No Show Rate<br/><strong>{(props.report.data.registeredUsersCount - props.report.data.turnoutUserCount)}</strong> inactive users / <strong>{props.report.data.registeredUsersCount}</strong> registered users</h3>
          </div>
        </div>
        <div className="reporting-col reporting-flex-col">
          <span className="icon-check green data-icon"></span>
          <div className="data-row">
            <h2>{props.report.data.activeSessions}</h2>
            <h3>Active Sessions<br/><strong>~{Math.round(props.report.data.averageDuration)}</strong> min/active user on platform</h3>
          </div>
        </div>
      </div>
      <div className="reporting-flex-row">
        <div className="reporting-col reporting-flex-col col two">
          <h4>Platform Engagement (%) per day</h4>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={ props.report.data.activity } barSize={ 10 } margin={{right: 20}}>
              <XAxis dataKey="date" scale="point"/>
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="rate" fill="#ee4b8a" background={ {fill: '#eee'} }/>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="reporting-col reporting-flex-col col two">
          <h4># Sessions per day</h4>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={props.report.data.activity} margin={{right: 20}}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="sessions" stroke="#8884d8" activeDot={ {r: 8} } />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="reporting-full-row">
        <div className="reporting-col col">
          <h4>Page Views per day</h4>
          <div className="full-chart">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={props.report.data.activity} margin={{right: 20}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="views" stroke="#6a81f2" strokeWidth="5" fill="#4e4bd7" strokeLinecap="round" fillOpacity="100" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="reporting-flex-row">
        <div className="reporting-col reporting-flex-col col two">
          <div className="reporting-col-header">
            <h4>Most Active Pages</h4>
            <button className="text-btn" onClick={ () => setShowAllPages(true) } disabled={ showAllPages }>Show all</button>
          </div>
          <div className="user-list-view">
            <div className="user-list-item header-row">
              <div className="page-item-percentile no-display"></div>
              <div className="user-item-meta">Name</div>
              <div className="user-item-count"># Views</div>
              <div className="user-item-count"># Interactions</div>
            </div>
            {
              props.report.data.mostActivePages.map((item) => {
                return (
                  <div className="user-list-item no-action" key={ item.key }>
                    <div className="page-item-percentile">
                      {
                        percentileBars.map((bar) => {
                          return (
                            <span className={ item.percentileBar === bar.val ? "page-item-percentile-bar filled" : "page-item-percentile-bar" } key={ bar.key }></span>
                          )
                        })
                      }
                    </div>
                    <div className="user-item-meta">{ item.name }<span className="page-item-percentile-label">{ item.percentile }<sup>th</sup> percentile</span></div>
                    <div className="user-item-count">{ item.viewItemsCount }</div>
                    <div className="user-item-count">{ item.contentItemsCount }</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="reporting-col reporting-flex-col col two">
          <div className="reporting-col-header">
            <h4>Most Active Users</h4>
            { props.report.data.mostActiveUsers.length > 0 && <button className="text-btn" onClick={ () => setShowUserSearch(true) } disabled={ showUserSearch }>Search for user</button> }
          </div>
          <div className="user-list-view">
            { props.report.data.mostActiveUsers.length > 0 &&
              <div className="user-list-item header-row">
                <div className="user-item-avatar"></div>
                <div className="user-item-meta">Name</div>
                <div className="user-item-count"># Views</div>
                <div className="user-item-count"># Interactions</div>
              </div>
            }
            {
              props.report.data.mostActiveUsers.map((item) => {
                return (
                  <div className="user-list-item more-padding" key={ item.userId } onClick={ () => setSelectedUser(item) }>
                    <div className="user-item-avatar"><span style={ {backgroundColor: item.meta.color} }>{ item.meta.initials }</span></div>
                    <div className="user-item-meta">{ item.meta.name }</div>
                    <div className="user-item-count">{ item.viewItemsCount }</div>
                    <div className="user-item-count">{ item.contentItemsCount }</div>
                  </div>
                )
              })
            }
            { props.report.data.mostActiveUsers.length < 1 && <h5 className="no-items center">No active users found...</h5> }
          </div>
        </div>
      </div>
      <div className="reporting-full-row">
        <div className="reporting-col col">
          <h4>Users by location</h4>
          <div className="funnel-diagram">
            <div className="funnel-middle"></div>
            {
              props.report.data.location.map((item, index) => {
                const opacity = Math.max(1 - (index/props.report.data.location.length), 0.2);
                return (
                  <div className="funnel-item" key={ item.city + item.country }>
                    <div className="funnel-item-fill" style={ {width: item.percentage + '%', opacity: opacity} }/>
                    <h5><span>{ item.percentage.toFixed(2) }%</span> { item.city }, { item.country }</h5>
                    <div className="funnel-item-line" style={ {width: ((100 - item.percentage)/2) + '%'} }/>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )
} 

export default ReportPlatformDetailView