import { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import API from "../../services/api"
import Modal from "../modal/modal"
import MiniLoader from "../mini-loader/miniLoader"
import moment from "moment"
import { useHistory } from "react-router-dom"
import DebouncedInput from "../input-handlers/debouncedInput"
import ReportDetailModal from "./reportDetailModal"
import { setHeaderClickEvent, setHeaderRightButtons, setHeaderRightItems, setHeaderTitle } from "../../redux/reducers/ui"
import Constants  from "../constants"
import Header from "../header/header"

const ReportsListView = (props) => {

  const dispatch = useDispatch()
  const api = new API()
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [reports, setReports] = useState([])
  const [error, setError] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [newReport, setNewReport] = useState({name: '', dateRange: ''})
  const [isCreating, setIsCreating] = useState(false)
  const [createError, setCreateError] = useState(false)
  const [showRefreshButton, setShowRefreshButton] = useState(false)
  const [filtered, setFiltered] = useState([])
  const [lastRefreshed, setLastRefreshed] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [user, headerEvent] = useSelector((state) => [state.auth.user, state.ui.header.clickEvent])
  const searchInputRef = useRef()

  // On load, we'll fetch this client's reports
  useEffect(() => {
    let mounted = true
    fetchReports().then((sorted) => {
      if (!mounted) { return }

      handleReportSuccessResponse(sorted)
      
      // Set header props
      if (!user.isAdmin) {
        dispatch(setHeaderTitle("Reports"))
      }

      dispatch(setHeaderRightItems(null))
      
      if (!user.isShared) {
        dispatch(setHeaderRightButtons([{label: "Create new report", className: "primary-btn", icon: "icon-plus", key: Constants.REPORTS_CREATE_BTN}]))
      }
      else {
        dispatch(setHeaderRightButtons(null))
      }

    }, () => {
      if (!mounted) { return }

      setError(true)
      setReports([])
      setFiltered([])
    })

    return () => mounted = false
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Listen for header event
  useEffect(() => {

    if (!headerEvent || user.isShared) { return }

    // Clear
    dispatch(setHeaderClickEvent(null))

    // Handle
    if (headerEvent.key === Constants.REPORTS_CREATE_BTN) {
      handleShowNewReport()
    }

  }, [headerEvent]) // eslint-disable-line

  const fetchReports = () => {
    return new Promise(async (resolve, reject) => {

      const urlPath = user.isShared ? "shared/" + user.clientId + "/" + user.livexId : !user.isAdmin ? user._id : props.client._id
      api.methods.get(`/reports/${urlPath}`).then((res) => {
        if (res.data && res.data.reports) {
          resolve(handleSort(res.data.reports))
        }
        else {
          reject()
        }
      }, () => {
        reject()
      })
    })
  }

  const handleReportSuccessResponse = (sorted) => {
    setError(false)
    setReports(JSON.parse(JSON.stringify(sorted)))
    setFiltered(JSON.parse(JSON.stringify(sorted)))

    // If we have any reports pending, let's show refresh button
    setShowRefreshButton(sorted.filter((item) => item.status.toLowerCase() === "pending").length > 0)
  }

  const handleSort = (arr) => {
    return arr.sort((a, b) => a.created > b.created ? -1 : 1)
  }

  const handleCancelNewReport = () => {
    setShowCreate(false)
    setNewReport({name: '', dateRange: ''})
    setIsCreating(false)
  }

  const handleCreateNewReport = () => {
    if (!newReport.name || !newReport.dateRange || user.isShared) { return }

    // Set params
    setIsCreating(true)
    setCreateError(false)

    // Compile start/end
    let startDate
    let endDate = moment()

    if (newReport.dateRange === "last7days") {
      startDate = moment().subtract(7, "days")
    }
    else if (newReport.dateRange === "last14days") {
      startDate = moment().subtract(14, "days")
    }
    else if (newReport.dateRange === "thisMonth") {
      startDate = moment().startOf("month")
    }
    else if (newReport.dateRange === "lastMonth") {
      startDate = moment().startOf("month").subtract(1, "month")
      endDate = moment().startOf("month").subtract(1, "month").endOf("month")
    }
    else {
      // Yesterday
      startDate = moment().subtract(1, "day")
      endDate = moment().subtract(1, "day")
    }

    const params = {
      name: newReport.name,
      dateRange: newReport.dateRange,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      client_id_str: user.isAdmin ? props.client._id : user._id
    }

    api.methods.post("/reports", params).then((res) => {
      setIsCreating(false)

      if (res.data && res.data.report) {
        setCreateError(false)
        setShowCreate(false)
        setNewReport({name: '', dateRange: ''})

        // Add
        const sorted = handleSort([res.data.report, ...reports])
        setReports(JSON.parse(JSON.stringify(sorted)))
        setFiltered(JSON.parse(JSON.stringify(sorted)))
        
        // Clear search input
        if (searchInputRef) {
          searchInputRef.current.clear()
        }

        // Show refresh btn
        setShowRefreshButton(true)
      }
      else {
        setCreateError(true)
      }

    }, () => {
      setIsCreating(false)
      setCreateError(true)
    })
  }

  const handleShowNewReport = () => {
    setCreateError(false)
    setShowCreate(true)
  }

  const filterReports = (input) => {
    let handler = [...reports]

    if (input && input.length > 0) {
      const lowercased = input.toLowerCase()
      handler = handler.filter((item) => item.status.toLowerCase().includes(lowercased) || item.name.toLowerCase().includes(lowercased))
    }

    setFiltered(handleSort(handler))
  }

  const createModalChildren = (
    <div className="bmt-form-page">
      <div className="form-group">
        <h5>Name</h5>
        <input type="text" placeholder="Name your report..." value={ newReport.name } onChange={ (e) => setNewReport({...newReport, name: e.target.value}) } />
      </div>
      <div className="form-group">
        <h5>Date Range</h5>
        <select name="date-range" value={ newReport.dateRange } onChange={ (e) => setNewReport({...newReport, dateRange: e.target.value} )}>
          <option value="">Please select a date range...</option>
          <option value="yesterday">Yesterday</option>
          <option value="last7days">Last 7 days</option>
          <option value="last14days">Last 14 days</option>
          <option value="thisMonth">This month</option>
          <option value="lastMonth">Last month</option>
        </select>
      </div>
      <div className="bmt-button-group modal-group">
        { isCreating && <MiniLoader inline={ true } copy="Creating..." /> }
        <button className="cancel-btn" onClick={ handleCancelNewReport }>Cancel</button>
        <button className="primary-btn" onClick={ handleCreateNewReport } disabled={ !newReport.name || !newReport.dateRange || isCreating }>Create</button>
      </div>
      { createError && <h3 className="error">Oops, there was an issue creating your report, please try again.</h3> }
    </div>
  )
  const createModal = <Modal title="Create new report" children={ createModalChildren } close={ handleCancelNewReport } />

  const handleReportClick = (report) => {
    if (report.status === "Available") {

      if (user.isShared) {
        // Directly show modal
        setViewModal(report.data.aggregation)
      }
      else {
        // Launch report detail
        const clientId = user.isAdmin ? props.client._id : user._id
        history.push(`/reports/${clientId}/${report._id}`)
      }
    }
  }

  const handleReportDelete = (e, reportId) => {
    if (user.isShared) { return }

    e.stopPropagation()

    if (!window.confirm("Are you sure you would like to delete this report? This action cannot be reversed.")) {
      return
    }

    const clientId = user.isAdmin ? props.client._id : user._id
    api.methods.delete(`/reports/${clientId}/${reportId}`).then((res) => {

      if (res.data && res.data.success) {
        // Remove from reports
        setReports([...reports].filter((item) => item._id !== reportId))
        setFiltered([...filtered].filter((item) => item._id !== reportId))
      }
      else {
        window.alert("Oops. There was an error deleting this report. Please try again")
      }

    }, () => {
      window.alert("Oops. There was an error deleting this report. Please try again")
    })
  }

  const handleSearch = (terms) => {
    setSearch(terms)

    if (terms && terms.length > 0) {
      filterReports(terms)
    }
    else {
      setFiltered([...reports])
    }
  }

  const handleRefreshList = () => {
    setLastRefreshed(moment().valueOf())

    fetchReports().then((sorted) => {
      handleReportSuccessResponse(sorted)
    }, () => {
      console.log("Error refreshing list...")
      // todo: handle better
    })
  }

  return (
    <div className="reporting-list">
      { viewModal && <ReportDetailModal item={ viewModal } close={ () => setViewModal(false) } /> }
      { showCreate && !user.isShared && createModal }
      { !user.isAdmin && <Header /> }
      <div className="form-group bmt-wrapper non-flex half margin-bottom">
        <h5>Reports</h5>
        <div className="form-group-subheader">
          <DebouncedInput ref={ searchInputRef } placeholder="Search by name or status" ready={ handleSearch } includeClear={ true } />
        </div> 
        { showRefreshButton && 
          <div className="form-group-subheader">
            <button className="refresh-btn" onClick={ handleRefreshList }><span className="icon-rotate-cw"></span> Refresh {lastRefreshed && <span className="last-checked">Last checked { moment(lastRefreshed).format("hh:mm a[,] ss[s]") }</span>}</button>
          </div>
        }
      </div>
      { error && <h3 className="error">Oops, there was an error fetching reports, please try again.</h3> }
      { !error && filtered.length > 0 &&
        <div className="bmt-wrapper">
          <ul className="bmt-list-view">
            <li>
              <div className="flex">Name</div>
              <div className="flex">Status</div>
              <div className="flex">Date Range</div>
              <div className="flex small">Created</div>
              { !user.isShared && <div className="flex red">Delete</div> }
            </li>
            { 
              filtered.map((item) => {
                return (
                  <li key={ item._id } onClick={ () => handleReportClick(item) } className={ item.status === "Available" ? "" : "no-data" }>
                    <div className="flex">{ item.name }</div>
                    <div className={ `flex status-tag status-${item.status.toLowerCase().replaceAll(" ", "-")}` }><span>{ item.status }</span></div>
                    <div className="flex">{ item.startDate && item.endDate && `${moment(item.startDate).format("MM/DD/YYYY")} - ${moment(item.endDate).format("MM/DD/YYYY")}` }</div>
                    <div className="flex small">{ moment(item.created).format("MM/DD/YYYY hh:mm a") }</div>
                    { !user.isShared && <div className="flex"><button onClick={ (e) => handleReportDelete(e, item._id) } className="more-btn red"><span className="icon-trash-2"></span> Delete</button></div> }
                  </li>
                )
              })
            }
          </ul>
        </div>
      } 
      { !error && filtered.length < 1 && <h5 className="no-items">{ search.length > 0 ? "Sorry, we couldn't find any reports matching your search" : "You haven't created any reports yet." }</h5> }
    </div>
  )
}

export default ReportsListView