const ReportExhibitModal = (props) => {
  console.log(props);
  return (
    <>
      <ul className="bmt-list-view">
        <li><div className="flex max-width-40">Activity</div><div className="flex max-width-40">Metadata</div><div className="flex small"># Count</div></li>
        <li className="no-action"><div className="flex max-width-40">Page View</div><div className="flex max-width-40"></div><div className="flex small">{props.booth.insights.viewItemsCount}</div></li>
        {
          props.booth.insights.contentItemsGrouped && Object.keys(props.booth.insights.contentItemsGrouped.types).map((itemKey) => {
            const item = props.booth.insights.contentItemsGrouped.types[itemKey]
            return (
              <li key={item.type} className="no-action">
                <div className="flex max-width-40">Interaction - {item.type}</div>
                <div className="flex max-width-40">
                  {item.items.map((subitem, index) => {
                    return (
                      <p key={index}>{subitem}</p>
                    )
                  })}
                </div>
                <div className="flex small">{item.count}</div>
              </li>
            )
          })
        }
      </ul>
      {
        props.booth.insights.shareItems && props.booth.insights.shareItems.length > 0 &&
        <div className="margin-top">
          <h3>Accepted Contact Shares</h3>
          <ul className="bmt-list-view margin-top">
            <li><div className="flex small">Type</div><div className="flex small">UserId</div><div className="flex max-width-40">User</div></li>
            {
              props.booth.insights.shareItems.map((item, index) => {
                return (
                  <li key={index} className="no-action">
                    <div className="flex small">{item.type}</div>
                    <div className="flex small">{item.userId}</div>
                    <div className="flex max-width-40">{item.user ? `${item.user.firstName} ${item.user.lastName} (${item.user.email})` : "(n/a)"}</div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      }
    </>
  )
}

export default ReportExhibitModal