import { createSlice } from "@reduxjs/toolkit"

const boothsSlice = createSlice({
  name: 'booths',
  initialState: {
    selectedBooth: null,
    availableTypes: null
  },
  reducers: {
    setSelectedBooth(state, action) {
      state.selectedBooth = action.payload
    },
    setAvailableTypes(state, action) {
      state.availableTypes = action.payload
    }
  }
})

export const { setSelectedBooth, setAvailableTypes } = boothsSlice.actions
export default boothsSlice.reducer