import API from "../../../services/api"
import MiniLoader from "../../mini-loader/miniLoader"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setSelectedClient } from "../../../redux/reducers/clients"
import { setHeaderRightButtons, setHeaderClickEvent } from "../../../redux/reducers/ui"
import moment from "moment"
import md5 from "md5"
import Constants from "../../constants"
import PeriodicModal from "./periodicModal"

const ClientDetailMain = (props) => {

  const dispatch = useDispatch()
  const api = new API()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [exhibitSettings, setExhibitSettings] = useState({maxBooths: 0, boothTypes: {}})
  const [apiSettings, setApiSettings] = useState({liveX: {environment: '', showId: '', showName: '', apiKey: '', expoHallSections: []}, google: {}, igniteX: {apiOverride: ''}, periodic: {}})
  const [meta, setMeta] = useState({firstName: '', lastName: '', projectName: '', state: ''})
  const availableBoothTypes = useSelector((state) => state.booths.availableTypes.filter((item) => item.visible))
  const [didChange, setDidChange] = useState(false)
  const [pristineExhibitSettings, setPristineExhibitSettings] = useState(false)
  const [pristineApiSettings, setPristineApiSettings] = useState(false)
  const [pristineMeta, setPristineMeta] = useState(false)
  const headerEvent = useSelector((state) => state.ui.header.clickEvent)
  const [dragEventId, setDragEventId] = useState('')
  const [showPeriodicSettings, setShowPeriodicSettings] = useState(false)

  // Run effect and catch our header events
  useEffect(() => {
    if (!headerEvent) { return } 
    
    if (headerEvent.key === Constants.CLIENT_DETAIL_SAVE_BTN) {
      // Save
      saveChanges()
      dispatch(setHeaderClickEvent(null))
    }
    else if (headerEvent.key === Constants.CLIENT_DETAIL_REVERT_BTN) {
      // Cancel
      revertChanges()
      dispatch(setHeaderClickEvent(null))
    }

  }, [headerEvent]) // eslint-disable-line

  useEffect(() => {
    console.log(props.client)
    let exSettings = {maxBooths: 0, boothTypes: {}}
    if (props.client.maxBooths) {
      exSettings.maxBooths = props.client.maxBooths
    }

    if (props.client.boothTypes) {
      exSettings.boothTypes = props.client.boothTypes
    }
    else {
      availableBoothTypes.forEach((item) => {
        exSettings.boothTypes[item.key] = true
      })
    }

    let apiSettings = {liveX: {environment: '', showId: '', showName: '', apiKey: ''}, igniteX: {apiOverride: ''}, periodic: {}, google: {}}
    if (props.client.settings) {
      if (props.client.settings.liveX && props.client.settings.liveX.apiKey && props.client.settings.liveX.showId && props.client.settings.liveX.showName) {
        apiSettings.liveX = {...props.client.settings.liveX}
      }

      if (props.client.settings.liveX && props.client.settings.liveX.expoHallSections) {
        apiSettings.liveX = {...apiSettings.liveX, expoHallSections: props.client.settings.liveX.expoHallSections}
      }

      if (props.client.settings.igniteX && props.client.settings.igniteX.apiOverride) {
        apiSettings.igniteX.apiOverride = props.client.settings.igniteX.apiOverride
      }

      if (props.client.settings.periodic) {
        apiSettings.periodic = props.client.settings.periodic
      }

      if (props.client.settings.google) {
        apiSettings.google = props.client.settings.google
      }
    } 

    const meta = {firstName: props.client.firstName, lastName: props.client.lastName, projectName: props.client.projectName, state: props.client.state ? props.client.state : 'active'}
    setMeta(meta)
    setPristineExhibitSettings(JSON.parse(JSON.stringify(exSettings)))
    setExhibitSettings(exSettings)
    setPristineApiSettings(JSON.parse(JSON.stringify(apiSettings)))
    setApiSettings(apiSettings)
    setPristineMeta(JSON.parse(JSON.stringify(meta)))

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleApiChange = (instanceKey, key, val) => {
    setDidChange(true)
    const instanceSettings = {...apiSettings[instanceKey], [key]: val}
    setApiSettings({...apiSettings, [instanceKey]: instanceSettings})
  }

  const handleMetaChange = (key, val) => {
    setDidChange(true)
    setMeta({...meta, [key]: val})
  }

  const handleChange = (e) => {
    const val = parseInt(e.target.value)
    setDidChange(true)
    setExhibitSettings({...exhibitSettings, [e.target.name]: val})
  }

  const toggleOption = (key) => {
    setDidChange(true)
    let types = {...exhibitSettings.boothTypes}
    if (types[key]) {
      delete types[key]
    }
    else {
      types[key] = true
    }

    setExhibitSettings({...exhibitSettings, boothTypes: types})
  }

  const revertChanges = () => {
    setExhibitSettings(JSON.parse(JSON.stringify(pristineExhibitSettings)))
    setApiSettings(JSON.parse(JSON.stringify(pristineApiSettings)))
    setMeta(JSON.parse(JSON.stringify(pristineMeta)))
    setDidChange(false)
  }

  const saveChanges = () => {
    const exhibitParams = {
      maxBooths: exhibitSettings.maxBooths,
      boothTypes: exhibitSettings.boothTypes
    }

    const settingParams = {
      settings: {...props.client.settings, ...apiSettings}
    }

    // Make request
    api.methods.patch(`clients/${props.client._id}`, {...exhibitParams, ...settingParams, ...meta}).then(() => {

      // Update client with data
      dispatch(setSelectedClient({...props.client, ...meta, maxBooths: exhibitParams.maxBooths, boothTypes: exhibitParams.boothTypes, settings: settingParams.settings}))
      setPristineExhibitSettings(JSON.parse(JSON.stringify(exhibitParams)))
      setPristineApiSettings(JSON.parse(JSON.stringify(apiSettings)))
      setPristineMeta(JSON.parse(JSON.stringify(meta)))
      setDidChange(false)

    }, () => {
      // todo: make this cleaner
      alert('There was an error updating this client - please try again.')
    })
  }

  const handleCreateEndpoint = () => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      api.methods.post(`clients/${props.client._id}/exhibitEndpoint`).then((res) => {
        setError(false)
        setLoading(false)

        // Update client with data
        let settings = props.client.settings ? {...props.client.settings} : {}
        settings.publicEndpoint = res.data
        dispatch(setSelectedClient({...props.client, settings: settings}))
        resolve()

      }, () => {
        setLoading(false) 
        setError("Sorry, there was an issue setting up this endpoint, please try again.")
        reject()
      })
    })
  }

  const handleCheckStatus = () => {
    setLoading(true)
    api.methods.get(`clients/${props.client._id}/exhibitEndpoint`).then((res) => {
      setLoading(false)

      // Check if status has changed
      if (res.data.status !== props.client.settings.publicEndpoint.status) {
        // Update client with data
        let settings = props.client.settings ? {...props.client.settings} : {}
        settings.publicEndpoint = res.data
        dispatch(setSelectedClient({...props.client, settings: settings}))
      }
     
    }, () => {
     setLoading(false) 

     // Not much to do here
    })
  }

  const handleUnlinkLiveX = () => {
    if (!window.confirm("Are you sure you would like to unlink LiveX? This action cannot be reversed")) { return }

    props.loader.start()

    api.methods.post(`user/revoke/livex`, {user_id_str: props.client._id}).then((res) => {

      // Refresh exhibit hall endpoint
      handleCreateEndpoint().then(() => {
        props.loader.stop()

        // Refresh page
        window.location.reload()
      }, () => {
        // Error
        window.alert("Oops, there was an error unlinking from LiveX. Please try again")
      })

    }, () => {
      props.loader.stop()
    })
  }

  const hasEndpoint = props.client && props.client.settings && props.client.settings.publicEndpoint
  const authHeader = btoa(props.client.projectName) + md5(btoa(props.client._id))

  // Update button state
  useEffect(() => {

    dispatch(setHeaderRightButtons([{
      label: "Cancel",
      key: Constants.CLIENT_DETAIL_REVERT_BTN,
      disabled: !didChange,
      className: "cancel-btn"
    }, {
      label: "Save",
      key: Constants.CLIENT_DETAIL_SAVE_BTN,
      disabled: !didChange,
      className: "primary-btn"
    }]))

  }, [didChange]) // eslint-disable-line

  const handleAddNewSection = () => {
    
    const arr = !apiSettings.liveX.expoHallSections ? [] : apiSettings.liveX.expoHallSections
    const newSection = {
      _id: md5("section_" + moment().valueOf().toString()),
      name: "",
      order: arr.length 
    }
    handleApiChange('liveX', 'expoHallSections', [...arr, newSection])
  }

  const handleSectionChange = (index, value) => {
    if (!apiSettings.liveX.expoHallSections[index]) { return }

    const arr = [...apiSettings.liveX.expoHallSections].map((item, innerIndex) => index === innerIndex ? {...item, name: value} : item)
    handleApiChange('liveX', 'expoHallSections', arr)
  }

  const handleDeleteSection = (itemId) => {
    const index = apiSettings.liveX.expoHallSections.find((item) => item._id === itemId)
    if (index < 0) { return }

    const arr = [...apiSettings.liveX.expoHallSections].sort((a, b) => a.order > b.order ? 1 : -1).filter((item) => item._id !== itemId).map((item, index) => { return {...item, order: index}})
    handleApiChange('liveX', 'expoHallSections', arr)
  }

  const handleDragStart = (e) => {
    setDragEventId(e.currentTarget.id)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.currentTarget.classList.add("dragover")
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.currentTarget.classList.remove("dragover")
  }

  const handleDrop = (e) => {
    // Reorder
    const cur = apiSettings.liveX.expoHallSections.find((item) => item._id === dragEventId)
    const target = apiSettings.liveX.expoHallSections.find((item) => item._id === e.currentTarget.id)

    if (!cur || !target) { return}

    const arr = [...apiSettings.liveX.expoHallSections].map((item) => { 
      if (item._id === dragEventId) {
        return {...item, order: target.order}
      }

      return item
    }).sort((a, b) => a.order > b.order ? 1 : -1).map((item, index) => { return {...item, order:index} })
    handleApiChange('liveX', 'expoHallSections', arr)

    // Reset classes
    document.querySelectorAll(".dragover").forEach((item) => item.classList.remove("dragover"))
  }

  const handleProviderSelection = (provider) => {
    console.log("handleProviderSelection")
    console.log(provider)
    handleApiChange('periodic', 'provider', provider)
    setShowPeriodicSettings(false)
  }

  return (
    <div>
      <div className="bmt-wrapper bmt-form-page">
        <h2>Client Information</h2>
        <div className="form-group">
          <h5>First Name</h5>
          <input type="text" value={ meta.firstName ? meta.firstName : "" } onChange={ (e) => handleMetaChange('firstName', e.target.value) } placeholder="First Name..." />
        </div>
        <div className="form-group">
          <h5>Last Name</h5>
          <input type="text" value={ meta.lastName ? meta.lastName : "" } onChange={ (e) => handleMetaChange('lastName', e.target.value) } placeholder="Last Name..." />
        </div>
        <div className="form-group">
          <h5>Project Name</h5>
          <input type="text" value={ meta.projectName ? meta.projectName : "" } onChange={ (e) => handleMetaChange('projectName', e.target.value) } placeholder="Project Name..." />
        </div>
        <div className="form-group">
          <h5>State</h5>
          <div onClick={ () => handleMetaChange("state", "active") } className={ meta.state === "active" ? "bmt-check-input selected" : "bmt-check-input" }>Active</div>
          <div onClick={ () => handleMetaChange("state", "archived") } className={ meta.state === "archived" ? "bmt-check-input selected" : "bmt-check-input" }>Archived</div>
        </div>
      </div>
      <div className="bmt-wrapper bmt-form-page">
        <div className="bmt-wrapper-subheader">
          <h2>Exhibit Settings</h2>
        </div>
        <div className="form-group">
          <h5>Max Exhibits (0 for unlimited)</h5>
          <input type="number" placeholder="Enter a number" name="maxBooths" value={ exhibitSettings.maxBooths ? exhibitSettings.maxBooths : 0 } onChange={ handleChange } />
        </div>
        <div className="form-group">
          <h5>Allowed Exhibit Types</h5>
          <div className="stacked-check-inputs">
            { availableBoothTypes.sort((a, b) => a.name > b.name ? 1 : -1).map((item) => {
                return (
                  <div key={ item.key } onClick={ () => toggleOption(item.key) } className={ exhibitSettings.boothTypes[item.key] ? "bmt-check-input selected" : "bmt-check-input" }>
                    { item.name }
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className="bmt-wrapper bmt-form-page">
        <h2>LiveX Settings</h2>
        { apiSettings.liveX && apiSettings.liveX.showId &&
          (
            <div className="form-group">
              <h5 className="red">Remove Integration</h5>
              <button className="negative-btn" onClick={ handleUnlinkLiveX }>Unlink from LiveX</button>
            </div>
          )
        }
        <div className="form-group">
          <h5>Environment</h5>
          <input type="text" value={ apiSettings.liveX.environment } onChange={ (e) => handleApiChange('liveX', 'environment', e.target.value) } placeholder="LiveX Environment" />
        </div>
        <div className="form-group">
          <h5>Show Id</h5>
          <input type="text" value={ apiSettings.liveX.showId } onChange={ (e) => handleApiChange('liveX', 'showId', e.target.value) } placeholder="LiveX Show Id" />
        </div>
        <div className="form-group">
          <h5>Show Name</h5>
          <input type="text" value={ apiSettings.liveX.showName } onChange={ (e) => handleApiChange('liveX', 'showName', e.target.value) } placeholder="LiveX Show Name" />
        </div>
        <div className="form-group">
          <h5>API Key</h5>
          <input type="text" value={ apiSettings.liveX.apiKey } onChange={ (e) => handleApiChange('liveX', 'apiKey', e.target.value) } placeholder="LiveX API Key" />
        </div>
        <div className="form-group">
          <h5>Expo Hall Sections</h5>
          <div>
            { (!apiSettings.liveX.expoHallSections || apiSettings.liveX.expoHallSections.length < 1) && <h5 className="no-items">You haven't created any sections yet.</h5> }
            { apiSettings.liveX.expoHallSections && apiSettings.liveX.expoHallSections.length > 0 &&
              apiSettings.liveX.expoHallSections.map((item, index) => {
                return (
                  <div key={ item._id } className="multi-text-list" id={ item._id } draggable={ true } onDragOver={ handleDragOver } onDragLeave={ handleDragLeave } onDragStart={ handleDragStart } onDrop={ handleDrop }>
                    <span className="icon-more-vertical"></span>
                    <input type="text" value={ item.name ? item.name : "" } onChange={ (e) => handleSectionChange(index, e.target.value) } placeholder="Section name..." />
                    <button onClick={ () => handleDeleteSection(item._id) } className="delete-btn">Remove</button>
                  </div>
                )
              })
            }
            <button className="text-btn" onClick={ handleAddNewSection }>Add New</button>
          </div>
        </div>
      </div>
      <div className="bmt-wrapper bmt-form-page set-group">
        { apiSettings.liveX.apiKey && <div className="disabled-overlay"></div> }
        <h2>IgniteX Settings</h2>
        <div className="form-group">
          <h5>API Override</h5>
          <input type="text" value={ apiSettings.igniteX.apiOverride } onChange={ (e) => handleApiChange('igniteX', 'apiOverride', e.target.value) } placeholder="Override the default IgniteX API Base URL" />
        </div>
      </div>
      <div className="bmt-wrapper bmt-form-page set-group">
        <h2>Periodic Settings</h2>
        <div className="form-group">
          <h5>Provider</h5>
          <div>
            { showPeriodicSettings && <PeriodicModal onClose={ () => setShowPeriodicSettings(false) } onSelect={ handleProviderSelection } /> }
            { apiSettings.periodic.provider && <h3>{ apiSettings.periodic.provider.name }</h3> } 
            <button className="text-btn" onClick={ () => setShowPeriodicSettings(true)}>{ apiSettings.periodic.provider ? "Change" : "Select" }</button>
          </div>
        </div>
        <div className="form-group">
          <h5>Booking Duration (in minutes)</h5>
          <input type="number" value={ apiSettings.periodic.duration ? apiSettings.periodic.duration : "" } onChange={ (e) => handleApiChange('periodic', 'duration', e.target.value) } placeholder="Set your booking duration, i.e for 30 min" />
        </div>
        <div className="form-group">
          <h5>Booking Frequency (in minutes)</h5>
          <input type="number" value={ apiSettings.periodic.frequency ? apiSettings.periodic.frequency : "" } onChange={ (e) => handleApiChange('periodic', 'frequency', e.target.value) } placeholder="Set your booking frequency, i.e every 30 min" />
        </div>
        <div className="form-group">
          <h5>Booking Start Date</h5>
          <input type="date" value={ apiSettings.periodic.startDate ? apiSettings.periodic.startDate : "" } onChange={ (e) => handleApiChange('periodic', 'startDate', e.target.value) } placeholder="Set your booking start date" />
        </div>
        <div className="form-group">
          <h5>Booking End Date</h5>
          <input type="date" value={ apiSettings.periodic.endDate ? apiSettings.periodic.endDate : "" } onChange={ (e) => handleApiChange('periodic', 'endDate', e.target.value) } placeholder="Set your booking end date" />
        </div>
      </div>
      <div className="bmt-wrapper bmt-form-page set-group">
        <h2>Google Analytics Settings</h2>
        <div className="form-group">
          <h5>Property Id</h5>
          <input type="text" value={ apiSettings.google.propertyId ? apiSettings.google.propertyId : "" } onChange={ (e) => handleApiChange('google', 'propertyId', e.target.value) } placeholder="Set your Google Analytics Property Id" />
        </div>
      </div>
      <div className="bmt-wrapper bmt-form-page">
        <h2>Developer Tools</h2>
        <div className="form-group">
          <h5>Exhibit List JSON endpoint</h5>
          <div>
            {   
              loading ? <MiniLoader copy="Updating..." /> :
              <div>
                { hasEndpoint && <h6>Status: <strong>{ props.client.settings.publicEndpoint.status }</strong></h6> }
                { hasEndpoint && props.client.settings.publicEndpoint.url && <h6>URL: <a className="text-btn" target="_blank" rel="noreferrer" href={ props.client.settings.publicEndpoint.url }><span className="icon-external-link"></span> View</a></h6> }
                { hasEndpoint && props.client.settings.publicEndpoint.lastUpdated && <h6>Updated: <strong>{ moment(props.client.settings.publicEndpoint.lastUpdated).format("MM/DD/YYYY hh:mm a") }</strong></h6> }
                { error && <h5 className="error">{ error } </h5> }
                { hasEndpoint && <h6>Triggered: <strong>{ props.client.settings.publicEndpoint.isAutoUpdate ? "Automatically" : "Manually"  }</strong></h6> }
                { hasEndpoint && props.client.settings.publicEndpoint.queuedFor && <h6>ETA: <strong>{ moment(props.client.settings.publicEndpoint.queuedFor).format("MM/DD/YYYY hh:mm a") }</strong></h6> }
                <br />
                { hasEndpoint && props.client.settings.publicEndpoint.status !== "Available" && <button className="text-btn" onClick={ handleCheckStatus }><span className="icon-rotate-cw"></span> Refresh Status</button> }
                { (!hasEndpoint || (hasEndpoint && props.client.settings.publicEndpoint.status === "Available")) && <button className="text-btn" onClick={ handleCreateEndpoint }>{ hasEndpoint ? <span><i className="icon-bar-chart-2"></i> Update Data</span> : "Create" }</button> }
              </div>
            }
          </div>
        </div>
        <div className="form-group">
          <h5>Screenshot API endpoint</h5>
          <div>
            <h6>Base URL: <strong>https://api.bmt.nextechar.com/api</strong></h6>
            <h6>Path: <strong>POST /clients/{ props.client._id }/<span className="red">:exhibit_id</span>/screenshot</strong></h6>
            <h6>"x-curator-auth" Header: <strong>{ authHeader }</strong></h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientDetailMain