import InputHandler from "../../input-handlers/inputHandler"
import { useState, useEffect } from "react"
import moment from "moment"
import md5 from "md5"

const ResourcesForm = (props) => {

  const [items, setItems] = useState(false)

  useEffect(() => {
    if (props.version.instance.resources || props.version.template.resources) {
      let required = {}
      let curItems = props.version.instance.resources && Object.keys(props.version.instance.resources).length > 0 ? props.version.instance.resources : {}
      if (props.version.template.resources && Object.keys(props.version.template.resources).length > 0) {
        Object.keys(props.version.template.resources).forEach((resourceKey) => {
          if (props.version.template.resources[resourceKey].required) {
            required[resourceKey] = props.version.template.resources[resourceKey]
          }
        })
      }

      let extendedItems = {...curItems}
      Object.keys(curItems).forEach((curKey) => {
        if (props.version.template.resources && props.version.template.resources[curKey]) {
          let curItemCopy = {...curItems[curKey]}
          curItemCopy.required = props.version.template.resources[curKey].required
          curItemCopy.label = props.version.template.resources[curKey].label
          extendedItems[curKey] = curItemCopy
        }
        else {
          extendedItems[curKey] = curItems[curKey]
        }
      })

      setItems({...required, ...extendedItems})
    }
    else {
      setItems({})
    }

  }, [props.version])

  const handleAddResource = () => {
    // Create a new item & add it to the list
    const newKey = md5(moment().valueOf().toString())
    setItems({...items, [newKey]: {key: newKey, resource: {}, thumb: {}}})
  }

  const allowMoreResources = !(props.version.template.overrides && props.version.template.overrides.disableMoreResources)  

  return (  
    <div className="bmt-form-page bmt-wrapper">
      <h2>Resources</h2>
      { Object.keys(items).length < 1 && <h5 className="no-items">You have not added any resources yet.</h5> }
      { Object.keys(items).map((itemKey, index) => {
        const label = "Resource #" + (index+1)
        const fullItem = {...items[itemKey], label: label, key: itemKey, instanceKey: 'resources', enableRename: true, enableThumb: true, type: 'file', restrictions: {filetype: ['.mov', '.mp4', '.pdf', '.jpg', '.png', '.ppt', '.pptx']}}

        return (
          <InputHandler overrides={ props.version.template.overrides } readOnly={ props.readOnly } key={ itemKey } input={ fullItem } value={ props.version.instance && props.version.instance.resources && props.version.instance.resources[itemKey] ? props.version.instance.resources[itemKey] : null } change={ props.change } />
        )
      }) }
      { allowMoreResources && <button className="text-btn" onClick={ handleAddResource }>Add Resource</button> }
    </div>
  )
}

export default ResourcesForm