import { useState } from "react"
import md5 from "md5"
import API from "../../services/api"
import MiniLoader from "../mini-loader/miniLoader"

const ForgotFlow = (props) => {

  const api = new API()
  const [input, setInput] = useState({
    email: '',
    code: '',
    password: '',
    confirmPassword: ''
  })

  const [page, setPage] = useState('initial')
  const [loading, setLoading] = useState(false)
  const [resetError, setResetError] = useState(false)
  const [errors, setErrors] = useState({})

  const handleInitialReset = (e) => {
    e.preventDefault()
    setResetError(false)
    const curErrors = validate()
    setErrors(curErrors)

    if (Object.keys(curErrors).length === 0) {
      setLoading(true)

      const params = {
        email: input.email.toLowerCase().trim()
      }

      api.methods.post('auth/forgot', params).then((res) => {

        setLoading(false)
        setPage('code')

      }, (e) => {
        setLoading(false)
        setResetError("Oops! There was an error resetting your account. Please try again")
      })
    }
  }

  const handleFinalReset = (e) => {
    e.preventDefault()
    setResetError(false)
    const curErrors = validate()
    setErrors(curErrors)

    if (Object.keys(curErrors).length === 0) {
      setLoading(true)

      const params = {
        code: input.code.trim(),
        email: input.email.toLowerCase().trim(),
        password: md5(input.password)
      }

      api.methods.post('auth/reset', params).then((res) => {

        setLoading(false)

        // Log user in
        props.success(res.data)
        
      }, (e) => {
        setLoading(false)
        setResetError("Oops! There was an error resetting your account. It's possible that your reset code is incorrect or has expired. Please try again")
      })
    }
  }

  const startOver = () => {
    setPage('initial')
    setResetError(false)
    setInput({...input, code: '', password: '', confirmPassword: ''})
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInput({...input, [name]: value})
  }

  const validate = () => {
    let errors = {}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i

    if (!input.email) {
      errors.email = 'Please enter an email'
    }
    else if (!regex.test(input.email)) {
      errors.email = 'Please enter a valid email'
    }

    if (page === "code") {

      if (!input.code || input.code.length < 3) {
        errors.code = 'Please enter a reset code'
      }

      if (!input.password || input.password.length < 6) {
        errors.password = 'Please enter a password of at least 6 characters'
      }

      if (input.confirmPassword !== input.password) {
        errors.confirmPassword = 'Passwords do not match'
      }
    }

    return errors
  }

  return (
    <div>
      { page === "initial" ?
        <form onSubmit={(e) => handleInitialReset(e)}>
          <h3>Reset your password</h3>
          <h4>Email Address</h4>
          <input type="email" name="email" placeholder="Email" value={ input.email } onChange={ handleChange } className={ errors.email ? 'error': '' } />
          { errors.email && <div className="error">{ errors.email }</div> }
          { resetError && <div className="error">{ resetError }</div> }
          { loading && <MiniLoader copy="Resetting your account..." /> }
          <button className="primary-btn" disabled={ loading || !input.email }><span className="icon-rotate-cw"></span> Reset</button>
        </form>
        :
        <form onSubmit={(e) => handleFinalReset(e)}>
          <h3>Enter your reset code & new password</h3>
          <div className="margin-top"><h5><button onClick={ startOver } className="text-btn"><span className="icon-arrow-left"></span> Back to reset</button></h5></div>
          <h4>Reset Code</h4>
          <input type="text" name="code" placeholder="Reset Code" value={ input.code } onChange={ handleChange } className={ errors.code ? 'error': ''} />
          { errors.code && <div className="error">{ errors.code }</div> }
          <h4>New Password</h4>
          <input type="password" name="password" placeholder="New Password" value={ input.password } onChange={ handleChange } className={ errors.password ? 'error': ''} />
          { errors.password && <div className="error">{ errors.password }</div> }
          <h4>Confirm New Password</h4>
          <input type="password" name="confirmPassword" placeholder="Confirm New Password" value={ input.confirmPassword } onChange={ handleChange } className={ errors.confirmPassword ? 'error': ''} />
          { errors.confirmPassword && <div className="error">{ errors.confirmPassword }</div> }
          { resetError && <div className="error">{ resetError }</div> }
          { loading && <MiniLoader copy="Updating account..." /> }
          <button className="primary-btn" disabled={ loading || !input.code || !input.password || !input.confirmPassword }>Update</button>
        </form>
      }
      <br /><div className="margin-top"><h5><button onClick={ props.switchToLogin } className="text-btn">Back to login</button></h5></div>
    </div>
  )
}

export default ForgotFlow