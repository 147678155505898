import Header from "../header/header"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setSelectedClient } from "../../redux/reducers/clients"
import AdminPanel from "../nav/adminPanel"
import BoothListView from "../booths-list/boothListView"
import API from "../../services/api"
import ClientDetailMain from "./components/clientDetailMain"
import NewBoothForm from "../booths-list/newBoothForm"
import ReportsListView from "../reporting/reportsListView"
import Constants from "../constants"
import { setHeaderClickEvent, setHeaderLeftButton, setHeaderTitle } from "../../redux/reducers/ui"
import { setUserSetting } from "../../redux/reducers/auth"

const ClientDetail = (props) => {
  const dispatch = useDispatch()
  const clientId = props.match.params.id
  const [user, client, boothTypes] = useSelector((state) => [state.auth.user, state.clients.selectedClient, state.booths.availableTypes])
  const [showNewBooth, setShowNewBooth] = useState(false)
  const [tab, setTab] = useState('booths')
  const api = new API()
  const headerEvent = useSelector((state) => state.ui.header.clickEvent)

  useEffect(() => {
    let mounted = true

    // Set loading
    props.loader.start()

    // Fetch client detail
    api.methods.get(`clients/${clientId}`).then((res) => {

      console.log('fetched client')

      if (mounted) {
        // Set client
        const client = res.data.client
        dispatch(setSelectedClient(client))

        // Set header title
        const archivedCopy = client.state && client.state === "archived" ? "(archived)" : ""
        const title = `${client.projectName} ${archivedCopy}`
        dispatch(setHeaderTitle(title))

        // Set header left button
        dispatch(setHeaderLeftButton({
          label: "Clients",
          key: Constants.CLIENT_DETAIL_BACK_BTN
        }))

        // Kill loader
        props.loader.stop()
      }

    }, () => {
      console.log('failed to get client')

      // Kill loader
      if (mounted) {
        props.loader.stop()
      }
    })

    return () => {
      mounted = false
    }

  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  // Subscribe to create new exhibit / back button event
  useEffect(() => {
    if (!headerEvent) { return } 

    // Clear
    dispatch(setHeaderClickEvent(null))

    if (headerEvent.key === Constants.CLIENT_DETAIL_NEW_BTN) {
      setShowNewBooth(true)
    }
    else if (headerEvent.key === Constants.CLIENT_DETAIL_BACK_BTN) {
      props.history.push('/clients')
    }

  }, [headerEvent])//eslint-disable-line react-hooks/exhaustive-deps

  const modal = showNewBooth && <NewBoothForm close={ () => setShowNewBooth(false) } complete={ (newBoothId) => props.history.push(`/booths/${newBoothId}`) } />

  const clientNotFound = (
    <div className="bmt-main">
      <h2>Sorry, we could not locate that client. Please try again</h2><br />
      <Link to="/clients">Back to clients</Link>
    </div>
  )
  
  const handleBoothClick = (boothId) => {
    props.history.push(`/booths/${boothId}`)
  }

  const handleDeleteBooth = (booth) => {

    if (!window.confirm('Are you sure you would like to delete this exhibit? This action cannot be reversed')) { return }

    // Override user_id
    const params = {
      user_id_str: clientId
    }

    // Setup promise
    const defer = {}
    const promise = new Promise((resolve, reject) => {
      defer.resolve = resolve
      defer.reject = reject
    })

    // First, we need to invalidate the LiveX hash if needed
    if (booth.livexId) {
      api.methods.post('user/invalidate/livex', params).then(() => {
        defer.resolve()
      }, () => {
        defer.reject()
      })
    }
    else {
      defer.resolve()
    }

    Promise.all([promise.promise]).then(() => {

      // Make API call
      api.methods.delete('booths/' + booth._id, params).then((res) => {
        if (res.data && res.data.success) {
          // Filter out deleted booth
          const boothsCopy = JSON.parse(JSON.stringify(client.booths)).filter((item) => item._id !== booth._id)
          dispatch(setSelectedClient({...client, booths: boothsCopy}))
        }
        else{
          // todo: handle error
        }
      }, () => {
        console.log('error deleting booth')
      })
    }, () => {
      window.alert("Oops, there was an error deleting this exhibit, please try again.")
    })
  }

  const handleSaveViewSettings = (settings) => {

    // Make API call
    api.methods.post("user/settings", {"settings.viewSettings": settings}).then((res) => {
      // Dispatch
      dispatch(setUserSetting({key: "viewSettings", val: settings}))
    }, () => {
      console.log('error saving view settings')
    })
  }

  const handleToggleBookmark = () => {
    let bookmarks = user.settings && user.settings.bookmarks ? {...user.settings.bookmarks} : {}

    // Toggle
    if (bookmarks[client._id]) {
      delete bookmarks[client._id]
    }
    else {
      bookmarks[client._id] = true
    }

    // Make API call
    api.methods.post("user/settings", {"settings.bookmarks": bookmarks}).then((res) => {
      // Dispatch
      dispatch(setUserSetting({key: "bookmarks", val: bookmarks}))
    }, () => {
      console.log('error toggling bookmark')
    })
  }

  return (
    !client ? clientNotFound :
    <div className="bmt-main-view">
      { <AdminPanel /> }
      { modal }
      <Header />
      <div className="bmt-details">
        <ul className="bmt-details-top-bar">
          <li className={tab === "booths" ? "selected" : ""} onClick={() => setTab('booths')}>Exhibits</li>
          <li className={tab === "details" ? "selected" : ""} onClick={() => setTab('details')}>Details</li>
          <li className={tab === "reporting" ? "selected" : ""} onClick={() => setTab('reporting')}>Reporting</li>
          <li className="right-align"><button onClick={ handleToggleBookmark } className={ user.settings && user.settings.bookmarks && user.settings.bookmarks[client._id] ? "outline-btn less-padding active" : "outline-btn less-padding" }><span className="icon-bookmark"></span></button></li>
        </ul>
        { tab === 'details' ? 
          <ClientDetailMain client={ client } loader={ props.loader } /> 
          : tab === "booths" ?
          <BoothListView client={ client } booths={ client.booths } user={ user } types={ boothTypes } onSaveView={ handleSaveViewSettings }  onClick={ handleBoothClick } onDelete={ handleDeleteBooth } /> 
          :
          <ReportsListView client={ client } />
        }
      </div>
    </div>
  )
}

export default ClientDetail