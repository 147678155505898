import VideoHandler from "./videoHandler"
import ResourceHandler from "./resourceHandler"
import GridHandler from "./gridHandler"
import RepresentativeHandler from "./representativeHandler"
import { useState, useEffect } from "react"
import Validator from "./validator"
import { Editor } from "@tinymce/tinymce-react"
import ProductHandler from "./productHandler"

const InputHandler = (props) => {
  const [invalid, setInvalid] = useState('')

  useEffect(() => {
    // Run validation on input as necessary on load
    if ((props.input.type === "text" || props.input.type === "textarea") && props.input.restrictions && props.input.restrictions.pattern && props.value) {
      setInvalid(!Validator.validate(props.value, props.input.restrictions.pattern))
    }
    else if ((props.input.type === "text" || props.input.type === "textarea") && props.input.restrictions && props.input.restrictions.chars && props.value) {
      setInvalid(!Validator.validate(props.value, "length", {limit: props.input.restrictions.chars}))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleHexInput = (key, val) => {
    if (val.length === 6 && !isNaN(Number('0x' + val))) {
      document.querySelector(`.hex-${key}`).style.background = '#' + val
    }
    else {
      document.querySelector(`.hex-${key}`).style.background = '#ffffff'
    }
  }

  const getBoolClass = () => {
    let classList = ["bool-picker"]
    if (props.value) {
      classList.push("selected")
    }

    if (props.readOnly) {
      classList.push("disabled")
    }

    return classList.join(" ")
  }

  const handleFileChange = (file) => {
    props.change(props.input.instanceKey, props.input.key, file)
  }

  const handleTextInput = (text) => {

    // Validate
    if (props.input.restrictions && props.input.restrictions.pattern) {
      setInvalid(!Validator.validate(text, props.input.restrictions.pattern))
    }
    else if (props.input.restrictions && props.input.restrictions.chars) {
      setInvalid(!Validator.validate(text, "length", {limit: props.input.restrictions.chars}))
    }

    // Emit change
    props.change(props.input.instanceKey, props.input.key, text, props.input.subKey)
  }

  const createInput = () => {
    switch (props.input.type) {
      case 'textarea':
        // Conditionally show TinyMCE
        if (!props.overrides || !props.overrides.disableTinyEditor) {
          return  <Editor init={ {width: "100%", height: 300, menubar: 'edit format'} } toolbar='undo redo formatselect | bold italic alignleft aligncenter alignright alignjustify' tinymceScriptSrc={ process.env.PUBLIC_URL + '/tinymce/tinymce.min.js' } disabled={ props.readOnly } value={ props.value ? props.value : "" } onEditorChange={ (newValue, editor) => handleTextInput(newValue)} />
        }

        return <textarea className={ invalid ? "invalid" : "" } readOnly={ props.readOnly } placeholder={ props.input.placeholder ? props.input.placeholder : props.input.label } onChange={ (e) => handleTextInput(e.target.value) } value={ props.value ? props.value : ""} />

      case 'file':
        if (props.input.instanceKey === 'videos') {
          return <VideoHandler inputKey={ props.input.key } change={ handleFileChange } disabled={ props.readOnly } value={ props.value } accept={ props.input.restrictions.filetype.join() } />
        }
        else if (props.input.instanceKey === 'representatives') {
          return <RepresentativeHandler label={ props.input.label } inputKey={ props.input.key } change={ handleFileChange } disabled={ props.readOnly } hasBookingProvider={ props.input.hasBookingProvider } disableBooking={ props.input.disableBooking } disableEmail={ props.input.disableEmail } value={ props.value } />
        }
        else if (props.input.instanceKey === 'showcase') {
          return <ProductHandler label={ props.input.label } inputKey={ props.input.key } change={ handleFileChange } disabled={ props.readOnly } value={ props.value } />
        }

        return <ResourceHandler label={ props.input.label } inputKey={ props.input.key } enableRename={ props.input.enableRename } enableThumb={ props.input.enableThumb } change={ handleFileChange } disabled={ props.readOnly } value={ props.value } accept={ props.input.restrictions.filetype.join() } />

      case 'text':
        const containerClass = props.input.specialClass ? props.input.specialClass : ""
        const prefixEl = props.input.restrictions && props.input.restrictions.pattern && props.input.restrictions.pattern === "url" && <span className="prefix-url-hint">https://</span>

        return (
          <div className={ containerClass }>
            { prefixEl }
            <input className={ invalid ? "invalid" : "" } readOnly={ props.readOnly } type="text" placeholder={ props.input.label } onChange={ (e) => handleTextInput(e.target.value) } value={ props.value ? props.value : ""} />
          </div>
        )
      
      case 'iframe':

        return (
          <div className="iframe-container">
            <textarea readOnly={ props.readOnly } placeholder={ props.input.label } onChange={ (e) => handleTextInput(e.target.value) } value={ props.value ? props.value : ""} />
          </div>
        )

      case 'grid': 

        return <GridHandler change={ props.change } instanceKey={ props.input.instanceKey } inputKey={ props.input.key } value={ props.value ? props.value : []} fields={ props.input.templateObjectFields ? props.input.templateObjectFields : ["item"] } />

      case 'bool':
        return (
          <div className={ getBoolClass() } onClick={ () => props.change(props.input.instanceKey, props.input.key, !props.value) }>
            { props.value ? "Yes" : "No" }
          </div>
        )
      case 'select':
        return (
          <select disabled={ props.readOnly } value={ props.value ? props.value : "" } onChange={ (e) => props.change(props.input.instanceKey, props.input.key, e.target.value) }>
            <option value="">Please select</option>
            { Object.keys(props.input.options).map((key) => <option key={ key} value={ key }>{ props.input.options[key] }</option>)}
          </select>
        )
      case 'hex':
        
        if (props.value) {
          setTimeout(() => handleHexInput(props.input.key, props.value))
        }

        return (
          <div className="hex-picker">
            <input readOnly={ props.readOnly } type="text" placeholder="000000" minLength="6" maxLength="6" onChange={ (e) => {
              handleHexInput(props.input.key, e.target.value)
              props.change(props.input.instanceKey, props.input.key, e.target.value)
            } } value={ props.value ? props.value : ""} /> 
            <div className={ `hex-preview hex-${props.input.key} `}></div>
          </div>
        )
      default: {
        return null
      }
    }
  }

  return (
    <div className={ props.input.type !== "textarea" ? "form-group non-flex" : "form-textarea" }>
      { !props.hideLabel && <h3>{ props.input.label }</h3> }
      <div>{ createInput() }</div>
    </div>
  )
}

export default InputHandler