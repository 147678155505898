import md5 from "md5"
import moment from "moment"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import API from "../../../services/api"
import Validator from "../../input-handlers/validator"
import MiniLoader from "../../mini-loader/miniLoader"
import Modal from "../../modal/modal"

const BookingModal = (props) => {

  const [currentState, setCurrentState] = useState(false)
  const [didChange, setDidChange] = useState(false)
  const [showNewResourceRow, setShowNewResourceRow] = useState(false)
  const [newResource, setNewResource] = useState({name: "", email: ""})
  const [newResourceError, setNewResourceError] = useState("")
  const [bookingError, setBookingError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [booth, client] = useSelector((state) => [state.booths.selectedBooth, state.clients.selectedClient])
  const api = new API()

  useEffect(() => {
    // Set current state & pristine
    if (booth.periodic) {
      console.log(booth.periodic)
      setCurrentState(JSON.parse(JSON.stringify(booth.periodic)))
    }
  }, []) // eslint-disable-line

  const handleToggle = () => {
    setDidChange(true)

    if (!currentState) {
      setCurrentState({
        resources: {}
      })
    }
    else {
      setCurrentState(false)
    }
  }

  const handleAddNewResource = () => {
    // Reset error
    setNewResourceError("")

    // Validate email
    if (!Validator.validate(newResource.email, "email")) {
      setNewResourceError("Please enter a valid email address")
      return
    }

    // Make sure email doesn't exist
    let isExisting = false
    Object.keys(currentState.resources).forEach((key) => {
      if (currentState.resources[key].email.toLowerCase().trim() === newResource.email.toLowerCase().trim()) {
        isExisting = true
      }
    })

    if (isExisting) {
      setNewResourceError("Uh oh. You cannot use that email address again")
      return
    }

    setDidChange(true)
    const resourceId = md5(newResource.email + moment().valueOf().toString())
    setCurrentState({...currentState, resources: {...currentState.resources, [resourceId]: {...newResource, _id: resourceId}}})
    setShowNewResourceRow(false)
  }

  const handleShowAddResource = () => {
    setNewResourceError("")
    setNewResource({name: "", email: ""})
    setShowNewResourceRow(true)
  }

  const handleNewResourceChange = (e) => {
    setNewResource({...newResource, [e.target.name]: e.target.value})
  }

  const handleRemoveUser = (userId) => {
    const updated = {...currentState.resources}
    if (updated[userId]) {
      delete updated[userId]
    }

    setDidChange(true)
    setCurrentState({...currentState, resources: updated})
  }

  const handleSave = () => {
    setBookingError(false)
    setLoading(true)
    handleToggleBooking(currentState)
  }


  // Toggle periodic booking
  const handleToggleBooking = (params) => {

    console.log("Toggle booking: ", params)

    // Disable it
    if (!params) {
      api.methods.delete('user/bookings/bookable', {
        boothId: booth._id,
        clientId: client._id
      }).then((res) => {

        console.log(res)

        // Update state
        setCurrentState(false)
        setLoading(false)
        setDidChange(false)

        // Update booth
        props.callback(false)

      }, () => {
        setLoading(false)
        console.log('error updating booth bookable')
        setBookingError(true)
      })
    }
    else {
      // Enable it
      api.methods.post('user/bookings/bookable', {...params,
        boothId: booth._id,
        clientId: client._id
      }).then((res) => {

        console.log(res)

        // Update state
        setCurrentState(JSON.parse(JSON.stringify(res.data.bookableParams)))
        setLoading(false)
        setDidChange(false)

        // Update booth
        props.callback(res.data.bookableParams)

      }, () => {
        console.log('error updating booth bookable')
        setBookingError(true)
        setLoading(false)
      })
    }
  }

  const handleCopyMagicLink = (e, link) => {

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(link)
    }
    else {
      // Fallback
      let textArea = document.createElement("textarea")
      textArea.value = link
      textArea.style.position = "fixed"
      textArea.style.left = "-999999px"
      textArea.style.top = "-999999px"
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      document.execCommand('copy')
      textArea.remove()
    }

    e.target.innerHTML = "<span class='icon-copy'></span> Copied"

    setTimeout(() => {
      e.target.style.color = ''
      e.target.innerHTML = "<span class='icon-copy'></span> Copy"
    }, 1500)
  }

  const children = (
    <>
      <div className="bmt-form-page">
        <div onClick={ handleToggle } className={ currentState ? "bmt-check-input selected" : "bmt-check-input" }>Enabled</div>
      </div>
      { currentState && 
        <div className="bmt-form-page">
          <h4>Bookable Users</h4>
          <ul className="bmt-list-view margin-top">
            { (showNewResourceRow || (currentState.resources && Object.keys(currentState.resources).length > 0)) &&
              <li>
                <div className="flex">Name</div>
                <div className="flex">Email</div>
                <div className="flex">Manage Availability</div>
                <div className="flex small">Actions</div>
              </li>
            }
            { 
              currentState.resources &&  Object.keys(currentState.resources).length > 0 && Object.keys(currentState.resources).map((key) => {
                const user = currentState.resources[key]
                return (
                  <li key={ user._id } className="no-header">
                    <div className="flex">{ user.name }</div>
                    <div className="flex">{ user.email }</div>
                    <div className="flex">
                      { user.magicLink ? 
                        <>
                          <a className="primary-btn" href={user.magicLink} target="_blank" rel="noreferrer">Set Availability</a>
                          <button className="text-btn margin-left" onClick={ (e) => handleCopyMagicLink(e, user.magicLink) }><span className="icon-copy"></span> Copy</button>
                        </>
                        : "Pending..." 
                      }</div>
                    <div className="flex small"><button className="text-btn red" disabled={loading} onClick={ () => handleRemoveUser(user._id) }>Remove</button></div>
                  </li>
                )
              })
            }
            { showNewResourceRow && 
              <li className="adder-row">
                <div className="flex"><input type="text" name="name" placeholder="Name..." value={ newResource.name } onChange={ handleNewResourceChange } /></div>
                <div className="flex"><input type="email" name="email" placeholder="Email..." value={ newResource.email } onChange={ handleNewResourceChange } /></div>
                <div className="flex small">&nbsp;</div>
                <div className="flex">
                  <button className="text-btn gray" onClick={ () => setShowNewResourceRow(false) }>Cancel</button>
                  <button className="text-btn margin-left" onClick={ handleAddNewResource } disabled={ !newResource.name || !newResource.email }>Add</button>
                </div>
              </li> 
            }
          </ul> 
          { newResourceError && <div className="error">{ newResourceError }</div> }
          { !showNewResourceRow && (!currentState.resources || Object.keys(currentState.resources).length < 1) && <h5 className="no-items">You haven't added any users yet...</h5> }
          <button className="text-btn margin-top" disabled={ showNewResourceRow } onClick={ handleShowAddResource }>Add a user</button>
        </div>
      }
      { bookingError && <h5 className="error">Uh oh. There was an error saving your calendar booking settings. Please try again</h5> }
      <div className="bmt-button-group modal-group">
        { loading && <MiniLoader inline={true} copy="Updating..." /> }
        <button className="cancel-btn" disabled={loading} onClick={ props.close }>Cancel</button>
        <button className="primary-btn" onClick={ handleSave } disabled={loading || !didChange || (currentState && Object.keys(currentState.resources).length < 1)}>Save</button>
      </div>
    </>
  )

  return (
    <Modal title="Calendar Booking Settings" children={ children } close={ props.close } />
  )
}

export default BookingModal