import moment from "moment"
import md5 from "md5"
import base64 from "base-64"
import { useState, useEffect, useCallback, useRef } from "react"
import Modal from "../../modal/modal"
import { useDispatch, useSelector } from "react-redux"
import { setSharedUi } from "../../../redux/reducers/auth"
import TemplateForm from "./templateForm"
import EncodeResult from "./encodeResult"
import Utils from "../../utils"
import PresentationContent from "./presentationContent"
import BookingModal from "./bookingModal"

const GeneralForm = (props) => {

  const dispatch = useDispatch()
  const [pendingChanges, setPendingChanges] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showInfernoModal, setShowInfernoModal] = useState(false)
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [selectedVersion, setSelectedVersion] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [infernoSearch, setInfernoSearch] = useState('')
  const [selectedTab, setSelectedTab] = useState('drafts')
  const [showNoCategoryWarning, setShowNoCategoryWarning] = useState(false)
  const [requestedRefresh, setRequestedRefresh] = useState('')
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const [showPresentation, setShowPresentation] = useState(false)
  const [categoriesData, clients] = useSelector((state) => [state.auth.categories, state.clients])
  const [presentation, setPresentation] = useState({})
  const [showBookingSettings, setShowBookingSettings] = useState(false)
  const hasPeriodic = useRef(false)

  const limitedTypes = clients && clients.selectedClient && clients.selectedClient.boothTypes ? clients.selectedClient.boothTypes : false
  const options = props.types.filter((type) => type.visible && (!limitedTypes || limitedTypes[type.key])).map((type) => {
    return (
      <option key={ type._id } value={ type.key }>{type.name}</option>
    )
  })

  // Setup expo hall sections if available
  let expoHallOptions = []
  if (props.booth.livexId && clients.selectedClient && clients.selectedClient.settings && clients.selectedClient.settings.liveX && clients.selectedClient.settings.liveX.expoHallSections) {
    expoHallOptions = clients.selectedClient.settings.liveX.expoHallSections.map((item) => {
      return <option key={ item._id } value={ item._id }>{ item.name }</option>
    })
  }

  // Check for periodic settings
  if (clients.selectedClient && clients.selectedClient.settings && clients.selectedClient.settings.periodic) {
    hasPeriodic.current = true
  }

  // Setup version grouped statuses
  const orderedDeployments = props.booth.deployments ? [...props.booth.deployments].sort((a, b) => a.created > b.created ? 1 : a.created < b.created ? -1: 0 ) : []
  const versionData = {
    drafts: [],
    published: [],
    pending: [],
    failed: [],
    publishing: []
  }

  // Go through versions and get most recent status
  if (props.booth.versions && props.booth.versions.length > 0) {
    props.booth.versions.forEach((version) => {
      const latestDeps = orderedDeployments.filter((item) => item.versionId === version._id).sort((a, b) => a.created > b.created ? 1 : a.created < b.created ? -1 : 0)
      const latest = latestDeps.length > 0 ? latestDeps[0] : false

      if (!latest) {
        versionData.drafts.push(version)
        return
      }

      if (latest.status === "Published") {
        versionData.published.push({...version, publishEnded: latest.publishEnded, deployed: latest.created, parentCategory: latest.infernoParentCategory ? latest.infernoParentCategory.name : null})
      }
      else if (latest.status === "Pending Submission" || latest.status === "Submitted") {
        versionData.pending.push({...version, deployed: latest.created, deploymentComments: latest.comments ? latest.comments : ""})
      }
      else if (latest.status === "Publishing") {
        versionData.publishing.push({...version, deployed: latest.created, parentCategory: latest.infernoParentCategory ? latest.infernoParentCategory.name : null})
      }
      else if (latest.status === "Failed to Publish") {
        versionData.failed.push({...version, deployed: latest.created, parentCategory: latest.infernoParentCategory ? latest.infernoParentCategory.name : null})
      }
    })
  }

  const handlePublish = (e, version) => {
    e.stopPropagation()

    // Check to make sure we have an inferno parent category - if it's a livex booth let it go
    if (!props.booth.infernoParentCategory && !props.booth.livexId) {
      setShowNoCategoryWarning(true)
      return
    }

    if (!selectedVersion || selectedVersion._id !== version._id) {
      setSelectedVersion(version)
    }

    setShowPublishModal(true)
  }

  const deleteVersion = (e, version) => {
    e.stopPropagation()

    if (!window.confirm('Are you sure you would like to delete this item? This action cannot be reversed')) { return }

    // Delete it
    let curVersions = props.booth.versions.filter((item) => item._id !== version._id)
    props.change({name: 'versions', value: curVersions, autosave: true})
  }

  const handleShareBooth = () => {
    setShowShareModal(true)   
  }

  const handleHideBooth = () => {
    const newVal = props.booth.hidden ? false : true
    props.change({name: 'hidden', value: newVal, autosave: false})
    setPendingChanges(true)
  }

  const generateShareCodes = useCallback((version) => {
    const sharing = {generated: moment().valueOf()}

    // Set read/write codes
    sharing.view = md5(base64.encode(props.booth._id)) + md5(moment().valueOf().toString() + "read")
    sharing.edit = md5(base64.encode(props.booth._id)) + md5(moment().valueOf().toString() + "edit")
    return sharing
  }, [props.booth._id])

  const persistSharing = (sharing) => {
    props.change({name: 'sharing', value: sharing, autosave: true})
  }

  const handleToggleSharing = () => {
    let newValue = !props.booth.sharing || props.booth.sharing.disabled ? true : false
    if (newValue) {
      newValue = generateShareCodes()
    }
    else {
      newValue = {disabled: true}
    }

    // Persist
    persistSharing(newValue)
  }

  // We'll observe the selected version & check to see if we should enable sharing on it
  useEffect(() => {

    // If sharing isn't enabled, let's enable it by default
    if (!props.booth.sharing && showShareModal) {
      handleToggleSharing()
    }
     
  }, [showShareModal]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateParam = (name, val) => {
    props.change({name: name, value: val})
    setPendingChanges(true)
  }

  const cancelChanges = () => {
    setPendingChanges(false)
    props.cancel()
  }

  const saveChanges = () => {
    setPendingChanges(false)
    props.save()
  }

  const copyShareLink = (e, code) => {
    const url = window.location.origin + '/share/' + code

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(url)
    }
    else {
      // Fallback
      let textArea = document.createElement("textarea")
      textArea.value = url;
      textArea.style.position = "fixed"
      textArea.style.left = "-999999px"
      textArea.style.top = "-999999px"
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      document.execCommand('copy')
      textArea.remove()
    }

    e.target.style.color = '#314CD4'
    e.target.innerHTML = "<span class='icon-copy'></span> Copied"

    setTimeout(() => {
      e.target.style.color = ''
      e.target.innerHTML = "<span class='icon-copy'></span> Copy"
    }, 1500)
  }

  const handleRequestRefresh = () => {
    // Set request time
    setRequestedRefresh(moment().format("hh:mm a, ss") + "s")

    // Make request
    props.refreshStatus()
  }

  const handleGenerateNewLinks = () => {
    if (!window.confirm('Are you sure you would like to regenerate sharing links? This action cannot be reversed')) { return }

    // Reset & persist
    const newValue = generateShareCodes()
    props.change({name: 'sharing', value: newValue, autosave: true})
  }

  const setupButtons = (
    <div className="bmt-button-group">
      { props.booth.status === "Publishing" && <button className="primary-btn refresh-btn" onClick={ handleRequestRefresh }><span className="icon-rotate-cw"></span> { requestedRefresh ? `Refreshed at ${requestedRefresh}` : "Refresh Status" }</button> }
      <button className="cancel-btn" disabled={ !pendingChanges } onClick={ cancelChanges }>Cancel</button>
      <button className="primary-btn" disabled={ !pendingChanges } onClick={ saveChanges }>Save</button>
    </div>
  )
  
  const existingShareContent = (
    props.booth.sharing && !props.booth.sharing.disabled &&
    <ul className="bmt-list-view">
      <li><div className="flex">Access</div><div className="flex">Generated</div><div className="flex">Link</div></li>
      <li className="no-action">
        <div className="flex capitalize">Edit</div>
        <div className="flex nowrap">{ moment(props.booth.sharing.generated).format("MM/DD/YYYY hh:mm a") }</div> 
        <div className="flex"><button className="action-btn" onClick={ (e) => copyShareLink(e, props.booth.sharing.edit) }><span className="icon-copy"></span> Copy</button></div>
      </li>
      <li className="no-action">
        <div className="flex capitalize">View</div>
        <div className="flex nowrap">{ moment(props.booth.sharing.generated).format("MM/DD/YYYY hh:mm a") }</div> 
        <div className="flex"><button className="action-btn" onClick={ (e) => copyShareLink(e, props.booth.sharing.view) }><span className="icon-copy"></span> Copy</button></div>
      </li>
    </ul>
  )

  const shareModalInput = (
    <div>
      <div className="bmt-form-page">
        <div className={ props.booth.sharing && !props.booth.sharing.disabled ? "bmt-check-input selected" : "bmt-check-input" } onClick={ handleToggleSharing }>
          Enabled
        </div>
        { props.booth.sharing && !props.booth.sharing.disabled && 
          <div className="margin-top">
            <button className="text-btn" onClick={ handleGenerateNewLinks }><span className="icon-rotate-cw"></span> Generate new share links</button> 
          </div>
        }
      </div>
      <div className="bmt-form-page">
        { existingShareContent }
      </div>
    </div>
  )

  const shareModal = showShareModal ? <Modal title={ `Share: ${props.booth.name}` } children={ shareModalInput } close={ () => setShowShareModal(false) } /> : null
  const noCatModal = showNoCategoryWarning ? <Modal title="Oops!" children={ <div>Please select an IgniteX Parent Category first <br/><div className="bmt-button-group modal-group"><button className="primary-btn" onClick={ () => { setShowNoCategoryWarning(false); showAndMaybeSetInfernoCategories() } }>OK, take me there!</button></div></div> } close={ () => setShowNoCategoryWarning(false) } /> : null

  const publishContent = (
    <div>
      <h2>Booth: { props.booth.name }</h2>
      <h3>Draft: { selectedVersion.name }</h3>
      { !props.booth.livexId && <h3>IgniteX Parent Category: { props.booth.infernoParentCategory && props.booth.infernoParentCategory.name }</h3> }
      <br/>
      { selectedVersion.deploymentComments && <h5><span className="icon-message-square"></span> { selectedVersion.deploymentComments }</h5> }
      <p>Are you sure you want to publish to { !props.booth.livexId ? "IgniteX" : "LiveX" }?</p>
      <div className="bmt-button-group modal-group">
        <button className="cancel-btn" onClick={ () => setShowPublishModal(false) }>Cancel</button>
        <button className="primary-btn" onClick={ () => { setShowPublishModal(false); props.publish(selectedVersion._id)} }><span className="icon-external-link"></span> Publish</button>
      </div>
    </div>
  )
  const publishModal = showPublishModal ? <Modal title="Publish" children={ publishContent } close={ () => setShowPublishModal(false) } /> : null

  const duplicateContent = showDuplicateModal && (
    <div>
      <h3>It looks like you have published to a different IgniteX Parent Category previously. Do you want to duplicate the last published draft?</h3>
      <div className="bmt-button-group modal-group">
        <button className="cancel-btn" onClick={  () => setShowDuplicateModal(false) }>No thanks</button>
        <button className="primary-btn" onClick={ () => { setShowDuplicateModal(false); props.handleDuplicatePublished() } }>Yes</button>
      </div>
    </div>
  )
  const duplicateModal = showDuplicateModal && <Modal title="Duplicate" children={ duplicateContent } close={ () => setShowDuplicateModal(false) } />

  const setInfernoCategory = (cat) => {
    const otherCatPublishedDeployments = props.booth.deployments.filter((item) => item.status === "Published" && item.infernoParentCategory && item.infernoParentCategory.categoryId !== cat.id)

    if (otherCatPublishedDeployments.length > 0) {
      setShowDuplicateModal(true)
    }

    const infernoCatParams = {id: cat.id, name: cat.name}
    setSelectedVersion({...selectedVersion, infernoParentCategory: infernoCatParams})
    setShowInfernoModal(false)
    props.change({name: 'infernoParentCategory', value: infernoCatParams})
    setPendingChanges(true)
  }

  const showAndMaybeSetInfernoCategories = () => {

    if (!props.hasInfernoCreds) {
      configureInferno()
      return
    }

    // First, we'll check to see if we have categories & if they've been fetched within the last 2m
    if (!categoriesData.lastFetched || categoriesData.items.length < 1 || moment().subtract(2, 'm').isAfter(moment(categoriesData.lastFetched))) {
      props.fetchCategories()
    }

    setShowInfernoModal(true)
  }

  const filteredCategories = categoriesData.items.filter(item => infernoSearch === '' ? true : item.name.toLowerCase().includes(infernoSearch))

  const infernoContent = (
    <div className="bmt-form-page">
      <div className="form-group">
        <input type="text" placeholder="Search categories" value={ infernoSearch } onChange={ (e) => setInfernoSearch(e.target.value.toLowerCase().trim()) }/>
      </div>
      <ul className="bmt-list-view">
        <li>Category</li>
        <li onClick={ () => setInfernoCategory({name: "Root", id: "root"}) }>Root (No Parent Category)</li>
      { filteredCategories.length > 0 ? filteredCategories.map((item) => <li key={ item.id } onClick={ () => setInfernoCategory(item) }>{ item.name }</li>) : <li className="no-action">No results matching your search</li> }
      </ul>
    </div>
  )

  const infernoModal = showInfernoModal && <Modal title="Set IgniteX Parent Category" className="static-height" children={ infernoContent } close={ () => setShowInfernoModal(false) } />
  
  const configureInferno = () => {
    dispatch(setSharedUi({key: 'showInfernoSettings', val: true}))
  }

  const handleStatusClick = () => {
    if (props.booth.status === "Pending Submission" || props.booth.status === "Submitted") {
      setSelectedTab("pending")
    }
    else if (props.booth.status === "Publishing") {
      setSelectedTab("publishing")
    }
    else if (props.booth.status === "Failed to Publish") {
      setSelectedTab("failed")
    }
    else if (props.booth.status === "Published") {
      setSelectedTab("published")
    }
  }

  const sortItems = (a, b) => {
    if (a.deployed > b.deployed) {
      return -1
    }
    else if (a.deployed < b.deployed) {
      return 1
    }

    return 0
  }

  const handleRetryEncode = (versionId, videoKey) => {
    props.retryEncode(versionId, videoKey)
  }

  const handleBlueprintSelection = (blueprint) => {
    updateParam(['blueprint_id', 'type', 'blueprint'], [blueprint._id, blueprint.boothType, blueprint])
    setShowTemplateModal(false)
  }

  const handleStartPresentation = () => {
    // Get slides from last version
    const version = [...props.booth.versions].sort(Utils.sortByUpdatedOrCreated)[0]
    const activeSlide = props.booth.presentation && props.booth.presentation.activeSlide ? props.booth.presentation.activeSlide : version.slides ? Object.keys(version.slides)[0] : false
    const val = {
      enabled: true,
      name: version.name,
      slides: version.slides,
      activeSlide: activeSlide
    }

    setPresentation(val)

    // Update booth
    props.change({name: 'presentation', value: val, autosave: true})
    setShowPresentation(true)
  }

  const handlePresentationSlideChange = (slideId) => {
    // Update booth
    setPresentation({...presentation, activeSlide: slideId})
    props.change({name: 'presentation', value: {...props.booth.presentation, activeSlide: slideId}, autosave: true})
  }

  const handleEndPresentation = () => {
    // Update booth
    props.change({name: 'presentation', value: {}, autosave: true})
    setShowPresentation(false)
    setPresentation({})
  }

  const templateForm = showTemplateModal && <TemplateForm types={ props.types } handleBlueprintSelection={ handleBlueprintSelection } templates={ props.blueprints } />
  const templateModal = showTemplateModal && <Modal title="Select a Blueprint" children={ templateForm } close={ () => setShowTemplateModal(false) } />
  const presentationContent = showPresentation && <PresentationContent presentation={ presentation } onEnd={ handleEndPresentation } onSlideChange={ handlePresentationSlideChange } />
  const presentationModal = showPresentation && <Modal className="presentation-modal" title={ `Presenting: ${props.booth.name} ` } children={ presentationContent } close={ handleEndPresentation } />
  const bookingModal = showBookingSettings && <BookingModal callback={ props.updateBooking } close={ () => setShowBookingSettings(false) } />

  return (
    <div className="bmt-form-page smaller">
      { duplicateModal }
      { templateModal }
      { noCatModal }
      { shareModal }
      { infernoModal }
      { publishModal }
      { presentationModal }
      { bookingModal }
      <div className="bmt-wrapper">
        <div className="bmt-wrapper-subheader">
          <h2>Details</h2>
          { setupButtons }
        </div>
        <div className="form-group">
          <h5>Name { props.booth.livexId && <span className="live-x-set-tag">Set by LiveX</span> }</h5>
          <input type="text" name="name" disabled={ props.booth.livexId } value={ props.booth.name ? props.booth.name : '' }  onChange={ (e) => updateParam('name', e.target.value) }/>
        </div>
        { props.booth.livexId && 
          <div className="form-group">
            <h5>UUID <span className="live-x-set-tag">Set by LiveX</span></h5>
            <h6 className="live-x-set-tag">{ props.booth.livexId } </h6>
          </div>
        }
        { props.booth.livexId && expoHallOptions.length > 0 &&
          <div className="form-group">
            <h5>Expo Hall Section</h5>
            <select value={ props.booth.expoHallSection ? props.booth.expoHallSection : "" } onChange={ (e) => updateParam('expoHallSection', e.target.value) } name="expoHallSection">
              <option value="">Please select a section</option> 
              { expoHallOptions }
            </select>
          </div>
        }
        { props.blueprints.length > 0 &&
          <div className="form-group">
            <h5>Blueprint</h5>
            <h6>{ props.booth.blueprint && <div><h6>{ props.booth.blueprint.name } - { props.booth.blueprint.description }</h6></div> }<button onClick={ () => setShowTemplateModal(true) } className="text-btn">{ props.booth.blueprint ? "Change" : "Select blueprint" }</button></h6>
          </div>
        }
        <div className="form-group">
          <h5>Type</h5>
          <select value={ props.booth.type ? props.booth.type : "" } onChange={ (e) => updateParam('type', e.target.value) } name="type">
            <option value="">Please select a type</option> 
            { options }
          </select>
        </div>
        <div className="form-group">
          <h5>Status</h5>
          <h6 onClick={ handleStatusClick } className={ `status-tag active status-${props.booth.status ? props.booth.status.toLowerCase().replace(" ", "-") : ""}` }><span>{ props.booth.status }</span></h6>
        </div>
        { !props.booth.livexId && 
          <div className="form-group">
            <h5>IgniteX Parent Category</h5>
            <div>{ !props.hasInfernoCreds ? <div><h6>IgniteX Settings need to be configured</h6><button className="text-btn" onClick={ configureInferno }>Configure</button></div> : props.booth.infernoParentCategory && props.booth.infernoParentCategory.name ? <div><h6>{ props.booth.infernoParentCategory.name }</h6><button className="text-btn" onClick={ showAndMaybeSetInfernoCategories }>Change</button></div> : <button className="text-btn" onClick={ showAndMaybeSetInfernoCategories }>Set</button> }</div>
          </div>
        }
        <div className="form-group">
          <h5>Created</h5>
          <h6>{ moment(props.booth.created).format('MM/DD/YYYY hh:mm a') }</h6>
        </div>
        <div className="form-group">
          <h5>Last Updated</h5>
          <h6>{ moment((props.booth.lastUpdated ? props.booth.lastUpdated : props.booth.created)).format('MM/DD/YYYY hh:mm a') }</h6>
        </div>
        { props.booth.infernoDeployment &&
          <div className="form-group">
            <h5>Last Published</h5>
            <h6>{ moment(props.booth.infernoDeployment.publishEnded).format('MM/DD/YYYY hh:mm a') }</h6>
          </div>
        }
        <div className="form-group">
          <h5>Shared</h5>
          <h6><button onClick={ handleShareBooth } className={ props.booth.sharing && !props.booth.sharing.disabled ? "share-btn shared" : "share-btn" }>{ props.booth.sharing && !props.booth.sharing.disabled ? "Yes" : "No" }</button></h6>
        </div>
        { props.booth.livexId && props.booth.livexS3Location && 
          <div className="form-group">
            <h5>LiveX Static JSON <span className="live-x-set-tag">Dev</span></h5>
            <h6><a className="primary text-btn" href={ props.booth.livexS3Location } target="_blank" rel="noreferrer"><span className="icon-external-link"></span> View</a></h6>
          </div>
        }
        <div className="form-group">
          <h5>Hide in Exhibit List</h5>
          <h6><button onClick={ handleHideBooth } className={ props.booth.hidden ? "share-btn shared" : "share-btn" }>{ props.booth.hidden ? "Yes" : "No" }</button></h6>
        </div>
        { props.booth.type === "keynote" && props.booth.versions.length > 0 && props.booth.status.toLowerCase() === "published" && 
          <div className="form-group">
          <h5>Presentation Mode</h5>
          <h6><button onClick={ handleStartPresentation } className="text-btn">{ props.booth.presentation && props.booth.presentation.enabled ? "Resume" : "Start" }</button></h6>
        </div>
        }
        { hasPeriodic.current && 
          <div className="form-group">
            <h5>Enable Calendar Booking</h5>
            <h6><button onClick={ () => setShowBookingSettings(true) } className={ props.booth.periodic ? "share-btn shared" : "share-btn" }>{ props.booth.periodic ? "Yes" : "No" }</button>
            { props.booth.periodic && props.booth.periodic.bookingLink && <div className="margin-top"><a className="primary text-btn" href={props.booth.periodic.bookingLink} target="_blank" rel="noreferrer">View Bookable</a></div> }
            { props.bookingError && <div className="error margin-top">Uh oh. There was an error updating the booking status for this exhibit. Please try again</div> }
            </h6>
          </div>
        }
      </div>
      <div className="bmt-details">
        <ul className="bmt-details-top-bar">
          <li className={ selectedTab === "drafts" ? "selected" : "" } onClick={ () => setSelectedTab('drafts') }>Drafts { versionData.drafts.length > 0 && <span className="version-count">{ versionData.drafts.length }</span> }</li>
          <li className={ selectedTab === "published" ? "selected" : "" } onClick={ () => setSelectedTab('published') }>Published { versionData.published.length > 0 && <span className="version-count green">{ versionData.published.length }</span> }</li>
          <li className={ selectedTab === "pending" ? "selected" : "" } onClick={ () => setSelectedTab('pending') }>Submitted { versionData.pending.length > 0 && <span className="version-count red">{ versionData.pending.length }</span> }</li>
          <li className={ selectedTab === "publishing" ? "selected" : "" } onClick={ () => setSelectedTab('publishing') }>Publishing { versionData.publishing.length > 0 && <span className="version-count blue">{ versionData.publishing.length }</span> }</li>
          <li className={ selectedTab === "failed" ? "selected" : "" } onClick={ () => setSelectedTab('failed') }>Failed to Publish { versionData.failed.length > 0 && <span className="version-count dashed-red">{ versionData.failed.length }</span> }</li>
        </ul>
        <div className="bmt-wrapper">
          <ul className="bmt-list-view">
            { versionData[selectedTab].length > 0 && 
              <li>
                <div className="flex bigger">Name</div>
                <div className="flex">Last Updated</div>
                { selectedTab === "pending" && <div className="flex">Publish</div> }
                { selectedTab === "published" && <div className="flex">Published</div> }
                { selectedTab === "publishing" && <div className="flex">Publish Started</div> }
                { selectedTab === "failed" && <div className="flex red">Retry</div> }
                { selectedTab !== "pending" && selectedTab !== "drafts" && <div className="flex">Parent Category</div> }
                { selectedTab === "published" && <div className="flex">Video Status</div> }
                { selectedTab === "published" && <div className="flex">Refresh</div> }
                <div className="flex">Delete</div>
              </li>
            }
            { versionData[selectedTab].length > 0 ?
              versionData[selectedTab].sort(sortItems).map((item) => {
                const deploy = props.booth.deployments && props.booth.deployments.find((deploy) => deploy.versionId === item._id)

                return (
                  <li key={ item._id } onClick={ () => props.edit(props.booth._id, item._id) }>
                    <div className="flex bigger">{ item.name }</div>
                    <div className="flex">{ item.lastUpdated ? moment(item.lastUpdated).format('MM/DD/YYYY hh:mm a') : moment(item.created).format('MM/DD/YYYY hh:mm a') }</div>
                    { selectedTab === "pending" && <div className="flex"><button onClick={ (e) => handlePublish(e, item)} className="outline-btn"><span className="icon-external-link"></span> Publish</button></div> }
                    { selectedTab === "published" && <div className="flex">{ moment(item.publishEnded).format('MM/DD/YYYY hh:mm a') }</div> }
                    { selectedTab === "publishing" && <div className="flex">{  moment(deploy.publishStarted).format('MM/DD/YYYY hh:mm a') }</div> }
                    { selectedTab === "failed" && <div className="flex red"><button className="text-btn red" onClick={ (e) => handlePublish(e, item)}><span className="icon-rotate-cw"></span> Retry</button></div> }
                    { selectedTab !== "pending" && selectedTab !== "drafts" && <div className="flex">{ item.parentCategory }</div> }
                    { selectedTab === "published" && <div className="flex"><EncodeResult versionId={ item._id } deployments={ props.booth.deployments } retryEncode={ (key) => handleRetryEncode(item._id, key) } /></div> }
                    { selectedTab === "published" && <div className="flex"><button className="text-btn" onClick={ (e) => handlePublish(e, item)}><span className="icon-rotate-cw"></span> Refresh</button></div> }
                    <div className="flex"><button onClick={ (e) => deleteVersion(e, item)} className="red"><span className="icon-trash-2"></span> Delete</button></div>
                  </li>
                )
              })
              : <h5 className="no-items">No items just yet</h5>
            }
          </ul>
        </div>
      </div>
    </div>
  )
}

export default GeneralForm