import { Route, Switch } from "react-router-dom"
import Login from "./components/auth/login"
import Dashboard from "./components/dashboard/dashboard"
import Blueprints from "./components/blueprints/blueprints"
import BlueprintDetail from "./components/blueprint-detail/blueprintDetail"
import Clients from "./components/clients/clients"
import ClientDetail from "./components/client-detail/clientDetail"
import BoothDetail from "./components/booth-detail/boothDetail"
import BoothEditor from "./components/booth-editor/boothEditor"
import BoothsList from "./components/booths-list/boothsList"
import ReportDetail from "./components/reporting/reportDetail"
import ReportsListView from "./components/reporting/reportsListView"
import protector from "./services/protector"

const Router = () => {

  return (
    <Switch>
      <Route exact path="/login" component={ Login } />
      <Route exact path="/dashboard" component={ protector(Dashboard) } />
      <Route exact path="/clients" component={ protector(Clients) } />
      <Route exact path="/clients/:id" component={ protector(ClientDetail) } />
      <Route exact path="/booths" component={ protector(BoothsList) } />
      <Route exact path="/booths/:id" component={ protector(BoothDetail) } />
      <Route exact path="/booths/:id/:version" component={ protector(BoothEditor) } />
      <Route exact path="/blueprints" component={ protector(Blueprints) } />
      <Route exact path="/blueprints/:id" component={ protector(BlueprintDetail) } />
      <Route exact path="/reports/:clientId/:reportId" component={ protector(ReportDetail) } />
      <Route exact path="/reports" component={ protector(ReportsListView) } />
      <Route exact path="/shared/reports" component={ protector(ReportsListView) } />
      <Route exact path="/share/:code" component={ Login } />
      <Route component={ Login } />
    </Switch>
  )
}

export default Router