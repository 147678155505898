import ImageHandler from "../../input-handlers/imageHandler"

const ImagesForm = (props) => {

  if (!props.version.template || !props.version.instance) { return null }

  let templateClone = {...props.version.template}

  // Order the keys
  const orderedKeys = Object.keys(templateClone.images).filter((key) => templateClone.images[key] !== false).sort((a, b) => {
    if (templateClone.images[a].order > templateClone.images[b].order) {
      return 1
    }
    else if (templateClone.images[a].order < templateClone.images[b].order) {
      return -1
    }

    return 0
  })

  return (
    <div className="bmt-form-page bmt-wrapper">
      <h2>Images</h2>
      {
        orderedKeys.map((key) => {
          let item = {...templateClone.images[key], key: key, instanceKey: 'images'}
          let val = null

          if (templateClone.images[key].defaultValue) {
            val = templateClone.images[key].defaultValue
          }

          if (props.version.instance && props.version.instance.images && props.version.instance.images[key]) {
            val = props.version.instance.images[key]
          }

          return (
            <ImageHandler presets={ item.presets } readOnly={ props.readOnly } key={ key } input={ item } value={ val } change={ props.change } />
          )
        })
      }
    </div>
  )
}

export default ImagesForm