import { useEffect, useState } from 'react'
import { Legend, ComposedChart, Bar, Line, Area, Tooltip, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts'
import Modal from '../modal/modal'
import ReportExhibitModal from './reportExhibitModal'

const ReportExhibitDetailView = (props) => {

  const [interactionsSummary, setInteractionsSummary] = useState(false)
  const [selectedBooth, setSelectedBooth] = useState(false)

  useEffect(() => {
    let summary = []
    props.report.boothInsights.forEach((item) => {
      let itemObj = {
        name: item.name
      }

      if (item.insights) {
        itemObj.interactions = item.insights.contentItemsCount
        itemObj.views = item.insights.viewItemsCount
        itemObj.total = item.insights.totalCount
        summary.push(itemObj)
      }
    })

    setInteractionsSummary(summary)

  }, []) // eslint-disable-line

  const handleBoothClick = (_id) => {
    const booth = props.report.boothInsights.find((item) => item._id === _id)
    if (!booth || !booth.insights) {
      return
    }

    setSelectedBooth(booth)
  }

  return (
    <>
      { selectedBooth &&
        <Modal title={selectedBooth.name} children={<ReportExhibitModal booth={selectedBooth} />} close={() => setSelectedBooth(false)} />
      }
      {
        interactionsSummary &&
        <div className="reporting-full-row">
          <div className="reporting-col col">
            <h4>Exhibit Summary</h4>
            <div className="full-chart" style={{height: (interactionsSummary.length * 7) + "vh"}}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={interactionsSummary} layout="vertical" margin={{
                  top: 0,
                  right: 0,
                  left: 30,
                  bottom: 0,
                }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" scale="band" />
                  <Tooltip />
                  <Area dataKey="views" fill="#8884d8" stroke="#8884d8" />
                  <Bar dataKey="interactions" barSize={20} fill="#612EFF" />
                  <Line dataKey="total" stroke="#ff7300" />
                  <Legend />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      } 
      <div className="bmt-wrapper">
        <ul className="bmt-list-view">
          <li>
            <div className="flex">Name</div>
            <div className="flex"># Views</div>
            <div className="flex"># Interactions</div>
          </li>
          {
            props.report.boothInsights.map((booth) => {
              return (
                <li key={ booth._id } className={!booth.insights ? "no-action" : ""} onClick={() => handleBoothClick(booth._id)}>
                  <div className="flex">{ booth.name }</div>
                  <div className="flex">{ booth.insights ? booth.insights.viewItemsCount : <span className="no-data-found">n/a</span> }</div>
                  <div className="flex">{ booth.insights ? booth.insights.contentItemsCount : <span className="no-data-found">n/a</span> }</div>
                </li>
              )
            })
          }
        </ul>
      </div>
    </>
  )
}

export default ReportExhibitDetailView