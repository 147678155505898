import "./header.scss"
import { CSVLink } from "react-csv"
import { useSelector, useDispatch } from "react-redux"
import { setHeaderClickEvent } from "../../redux/reducers/ui"

const Header = () => {

  const dispatch = useDispatch()
  const params = useSelector((state) => state.ui.header)

  const dispatchButtonEvent = (buttonKey) => {
    dispatch(setHeaderClickEvent({key: buttonKey}))
  }

  const topLeftButton = params && params.leftButton ? <button className="top-left-btn" onClick={ () => dispatchButtonEvent(params.leftButton.key) } disabled={ params.leftButton.disabled ? true : false }>{ !params.leftButton.disabled && <span className="icon-arrow-left"></span> } { params.leftButton.label}</button> : ""

  // Check for top right button(s)
  let topRightButtonArr = null
  if (params && params.rightButtons && Array.isArray(params.rightButtons)) {
    topRightButtonArr = params.rightButtons.map((item) => {
      if (item.csv) {
        return <CSVLink key={ item.label } data={ item.csv.data } className={ `top-right-btn ${item.className}` } filename={ item.csv.filename }><span className="icon-download"></span> { item.label }</CSVLink>
      }
      
      return <button key={item.key} disabled={item.disabled} className={`top-right-btn ${item.className}`} onClick={ () => dispatchButtonEvent(item.key) }>{ item.icon && <span className={item.icon}></span> } {item.label}</button>
    })
  }

  // Check for array of top left buttons
  // let topLeftButtonArr = null
  // if (props.params && props.params.topLeftButtons && Array.isArray(props.params.topLeftButtons)) {
  //   topLeftButtonArr = props.params.topLeftButtons.map((item, index) => {
  //     return <button key={item.label} className="top-left-btn" onClick={item.handler}>{ index === 0 && <span className="icon-arrow-left"></span> } {item.label}</button>
  //   })
  // }

  // Check for an array of top right items
  let topRightItemsArr = null
  if (params && params.rightItems && Array.isArray(params.rightItems)) {
    topRightItemsArr = params.rightItems.map((item) => {
      return <div className="header-item" key={ item.label }>{ item.label }</div>
    })
  }

  return (
    <div className="bmt-header">
      { topLeftButton }
      <h1>{ params.title }</h1>
      { topRightItemsArr }
      { topRightButtonArr }
    </div>
  )
}

export default Header