import Header from "../header/header"
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { setSelectedClient } from "../../redux/reducers/clients"
import { extendAuthTimer, setUserSetting } from "../../redux/reducers/auth"
import "../inputs.scss"
import BoothListView from "../booths-list/boothListView"
import API from "../../services/api"
import NewBoothForm from "./newBoothForm"
import { setHeaderClickEvent, setHeaderLeftButton, setHeaderRightItems, setHeaderTitle } from "../../redux/reducers/ui"
import Constants from "../constants"

const BoothsList = (props) => {

  const [client, user, availableTypes, headerEvent] = useSelector((state) => [state.clients.selectedClient, state.auth.user, state.booths.availableTypes, state.ui.header.clickEvent])
  const dispatch = useDispatch()
  const [showNewBooth, setShowNewBooth] = useState(false)
  const api = new API()

  useEffect(() => {
    let mounted = true
    props.loader.start()
    dispatch(extendAuthTimer(true))

    // Set header
    dispatch(setHeaderTitle("Exhibits"))
    dispatch(setHeaderLeftButton(null))
    dispatch(setHeaderRightItems(null))

    // For shared users, we need to block this page
    if (user.isShared) {
      // Redirect to the url this user is allowed to see, if we can't find it, redirect to login
      const url = user.boothId && user.versionId ? `/booths/${user.boothId}/${user.versionId}`: false
      if (url) {
        props.history.push(url)
      }
      else {
        props.history.push('/login')
      }

      return
    }

    // For admin users, we need to redirect to clients page
    if (user.isAdmin) {
      props.history.push('/clients')
      return
    }

    // Fetch client
    api.methods.get(`clients/${user._id}`).then((res) => {

    if (mounted) {
      // Set client
      dispatch(setSelectedClient(res.data.client))
      props.loader.stop()
    }
    }, () => {
      console.log('error fetching client')
      // todo: handle error
    })

    return () => mounted = false
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // We'll need to respond to header events
  useEffect(() => {
    if (!headerEvent) { return }

    // Clear
    dispatch(setHeaderClickEvent(null))

    if (headerEvent.key === Constants.CLIENT_DETAIL_NEW_BTN) {
      setShowNewBooth(true)
    }

  }, [headerEvent]) // eslint-disable-line

  const handleBoothClick = (boothId) => {
    props.history.push(`/booths/${boothId}`)
  }

  const handleDeleteBooth = (booth) => {

    if (!window.confirm('Are you sure you would like to delete this exhibit? This action cannot be reversed')) { return }

    const defer = {}
    const promise = new Promise((resolve, reject) => {
      defer.resolve = resolve
      defer.reject = reject
    })

    // First, we need to invalidate the LiveX hash if needed
    if (booth.livexId) {
      api.methods.post('user/invalidate/livex').then(() => {
        defer.resolve()
      }, () => {
        defer.reject()
      })
    }
    else {
      defer.resolve()
    }

    Promise.all([promise.promise]).then(() => {
      // Make API call
      api.methods.delete('booths/' + booth._id).then((res) => {
        if (res.data && res.data.success) {
          // Filter out deleted booth
          const boothsCopy = JSON.parse(JSON.stringify(client.booths)).filter((item) => item._id !== booth._id)
          dispatch(setSelectedClient({...client, booths: boothsCopy}))
        }
        else{
          // todo: handle error
        }
      }, () => {
        console.log('error deleting booth')
      })
    }, () => {
      window.alert("Oops, there was an error deleting this exhibit, please try again.")
    })
  }

  const handleSaveViewSettings = (settings) => {

    // Make API call
    api.methods.post("user/settings", {"settings.viewSettings": settings}).then((res) => {
      // Dispatch
      dispatch(setUserSetting({key: "viewSettings", val: settings}))
    }, () => {
      console.log('error saving view settings')
    })
  }

  const modal = showNewBooth && <NewBoothForm close={ () => setShowNewBooth(false) } complete={ (newBoothId) => props.history.push(`/booths/${newBoothId}`) } />

  return (
    !client ? "" :
    <div className="bmt-main-view">
      <Header />
      <BoothListView booths={ client.booths } client={ client } user={ user } types={ availableTypes } onSaveView={ handleSaveViewSettings } onClick={ handleBoothClick } onDelete={ handleDeleteBooth } />
      { modal }
    </div>
  )
}

export default BoothsList