import { combineReducers } from "redux"
import authReducer from "./auth"
import clientsReducer from "./clients"
import boothsReducer from "./booths"
import uiReducer from "./ui"

export default combineReducers({
  auth: authReducer,
  clients: clientsReducer,
  booths: boothsReducer,
  ui: uiReducer
})