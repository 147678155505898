const Utils = {
  maybePrefixHTTPs(input) {
    if (input.indexOf("http://") > -1 || input.indexOf("https://") > -1) {
      return input
    }

    return "https://" + input
  },
  sortByUpdatedOrCreated(a, b) {
    let aTime = a.lastUpdated ? a.lastUpdated : a.created ? a.created : -Infinity
    let bTime = b.lastUpdated ? b.lastUpdated : b.created ? b.created : -Infinity
    return aTime > bTime ? -1 : 1
  },
  findLastDeployedIfAvailable(arr) {

    for (var i = 0; i < arr.length; i++) {
      if (arr[i].deploymentId) {
        return arr[i];
      }
    }

    // Default to first
    return arr[0];
  }
}

export default Utils