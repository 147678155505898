import { useState, useEffect } from "react"

const BlueprintForm = (props) => {

  const [newBlueprint, setNewBlueprint] = useState({name: '', description: '', boothType: false, selections: {}})
  const [boothOptions, setBoothOptions] = useState([])

  // Onload handler (prefill new blueprint state with props)
  useEffect(() => {
    if (props.blueprint && !props.pending) {
      setNewBlueprint({...props.blueprint})
      setBoothOptions(buildOptions(props.blueprint.boothType))
    }
  }, [props.blueprint, props.pending]) //eslint-disable-line react-hooks/exhaustive-deps

  const setBoothType = (type) => {
    const updated = {...newBlueprint, boothType: type}
    setNewBlueprint(updated)

    if (!type) {
      setBoothOptions([])
      return
    }

    setBoothOptions(buildOptions(type))

    // Trigger change if it exists
    if (props.onChange) {
      props.onChange(updated)
    }
  }

  const buildOptions = (type) => {
    // Loop through template & find anything with a preset
    let options = []
    let booth = props.boothTypes.find((a) => a.key === type)

    // Check if we need to merge a parent booth
    if (booth && booth.parent) {
      const parent = props.boothTypes.find((a) => a.key === booth.parent)
      if (parent) {
        booth = {...parent, ...booth}
      }
    }

    Object.keys(booth).forEach((key) => {
      if (typeof booth[key] === "object") {
        const valueKey = key === "images" ? "remoteSrc" : "src"
        Object.keys(booth[key]).forEach((subKey) => {
          if (booth[key][subKey].presets) {
            options.push({instanceKey: key, key: subKey, valueKey: valueKey, label: booth[key][subKey].label, presets: booth[key][subKey].presets})
          }
        })
      }
    })

    return options
  }

  const setBlueprintValue = (key, val) => {
    const updated = {...newBlueprint, [key]: val}
    setNewBlueprint(updated)

    // Trigger change if it exists
    if (props.onChange) {
      props.onChange(updated)
    }
  }

  const toggleSelection = (option, preset) => {
    let updated = {...newBlueprint.selections}

    if (updated[option.instanceKey + option.key] && updated[option.instanceKey + option.key].presetValueKey === preset.key) {
      delete updated[option.instanceKey + option.key]
    }
    else {
      updated[option.instanceKey + option.key] = {instanceKey: option.instanceKey, optionKey: option.key, optionValueKey: option.valueKey, presetValueKey: preset.key, presetValue: preset.src, unlocksAttr: preset.unlocksAttr}
    }

    const updatedBp = {...newBlueprint, selections: updated}
    setNewBlueprint(updatedBp)

    // Trigger change if it exists
    if (props.onChange) {
      props.onChange(updatedBp)
    }
  }

  const limitedBoothTypes = props.boothTypes.filter((b) => {
    return b.visible && (!props.limitedTypes || props.limitedTypes[b.key])
  }).sort((a, b) => a.order > b.order ? 1 : -1)

  return (
    <div className="margin-top">
      <div className="form-group">
        <h5>Name</h5>
        <input type="text" placeholder="Name" value={ newBlueprint.name } onChange={ (e) => setBlueprintValue('name', e.target.value) }/>
      </div>
      <div className="form-group">
        <h5>Description</h5>
        <input type="text" placeholder="Describe this blueprint" value={ newBlueprint.description } onChange={ (e) => setBlueprintValue('description', e.target.value) }/>
      </div>
      <div className="form-group">
        <h5>Select an exhibit type</h5>
        <select value={ newBlueprint.boothType } onChange={ (e) => setBoothType(e.target.value) }>
          <option value="">Please select</option>
          { limitedBoothTypes.map((b) => {
              return <option key={ b.key } value={ b.key }>{ b.name }</option>
          })}
        </select>
      </div>
      { 
        boothOptions.map((option) => {
          return (
            <div className="form-group" key={ option.instanceKey + option.key }>
              <h5>{ option.label }</h5>
              <div className="stacked-check-inputs">
                { 
                  option.presets.map((preset) => {
                    return (
                      <div key={ preset.key } onClick={ () => toggleSelection(option, preset) } className={ newBlueprint.selections[option.instanceKey + option.key] && newBlueprint.selections[option.instanceKey + option.key].presetValueKey === preset.key ? "bmt-check-input selected" : "bmt-check-input" }>{ preset.label }</div>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
      { props.error && <div className="error">Oops! There was an error creating your blueprint. Please try again.</div> }
      { props.modal && 
        <div className="bmt-button-group modal-group">
          <button className="cancel-btn" onClick={ () => props.done(true) }>Cancel</button>
          <button className="primary-btn" disabled={ !newBlueprint.name || !newBlueprint.description || Object.keys(newBlueprint.selections).length < 1 } onClick={ () => props.done(false, newBlueprint) }>Save</button>
        </div>
      }
    </div>
  )
}

export default BlueprintForm