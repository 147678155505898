import DebouncedInput from "../input-handlers/debouncedInput"
import Modal from "../modal/modal"
import { useRef, useState } from "react"

const ReportDetailSearchModal = (props) => {

  const [didSearch, setDidSearch] = useState(false)
  const searchInputRef = useRef()
  const pristine = JSON.parse(JSON.stringify(props.users))
  const [results, setResults] = useState([])

  const handleSearch = (term) => {
    if (term.length > 2) {
      setDidSearch(true)
      const sani = term.toLowerCase().trim()
      const filtered = [...pristine].filter((item) => {
        return item.meta.name.toLowerCase().trim().indexOf(sani) > -1 || item.firstName.toLowerCase().trim().indexOf(sani) > -1 || item.lastName.toLowerCase().trim().indexOf(sani) > -1
      })

      setResults(filtered)
    }
  }

  const children = (
    <div>
      <div className="form-group">
        <div className="form-group-subheader">
          <DebouncedInput ref={ searchInputRef } placeholder="Search by first or last name..." ready={ handleSearch } includeClear={ true } />
        </div>
      </div>
      <ul className="bmt-list-view">
        { results.length > 0 && <li>Name</li> }
        {
          results.map((item) => {
            return (
              <li key={ item.userId } onClick={ () => props.selectItem(item) }>{ item.meta.name }</li>
            )
          })
        }
      </ul>
      { !didSearch && <h4 className="no-items">No results to show just yet...</h4> }
      { didSearch && results.length < 1 && <h4 className="no-items">Uh oh! We couldn't find any users matching your search. Please try again.</h4> }
    </div>
  )

  return (
    <Modal title="Search users" className="static-height" children={ children } close={ props.close } />
  )
}

export default ReportDetailSearchModal