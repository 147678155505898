import Modal from "../modal/modal"
import API from "../../services/api"
import moment from "moment"
import MiniLoader from "../mini-loader/miniLoader"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

const NewBoothForm = (props) => {

  const api = new API()
  const [newBooth, setNewBooth] = useState({name: '', blueprint_id: ''})
  const [blueprints, setBlueprints] = useState([])
  const [loading, setLoading] = useState(true)
  const selectedClient = useSelector((state) => state.clients.selectedClient)

  // Fetch blueprints
  useEffect(() => {
    let mounted = true

    // Fetch blueprints
    api.methods.get('blueprints').then((res) => {
      if (mounted) {
        setBlueprints(res.data.blueprints)
        setLoading(false)
      }
    }, () => {
      console.log('error fetching blueprints')
      setLoading(false)
    })

    return () => mounted = false
  })

  // Handle new booth
  const handleCreateNewBooth = () => {
    
    // Set blueprint if it exists
    let type = false
    if (newBooth.blueprint_id) {
      const blueprint = blueprints.find((item) => item._id === newBooth.blueprint_id)
      if (blueprint) {
        type = blueprint.boothType
      }
    }

    let newBoothParams = {...newBooth, created: moment().valueOf(), type: type, blueprint_id_str: newBooth.blueprint_id}

    // Set client id if populated
    if (selectedClient) {
      newBoothParams.user_id_str = selectedClient._id
    }

    // Make API call & then navigate to new page
    api.methods.post('booths', newBoothParams).then((res) => {
      if (res.data && res.data.success && res.data._id) {
        props.complete(res.data._id)
      }
      else{
        // todo: handle error
      }
    }, () => {
      console.log('error creating new booth')
    })
  } 

  const modalContent = (
    <div className="bmt-form-page">
      <div className="form-group">
        <h5>Name</h5>
        <input type="text" placeholder="Name" value={ newBooth.name } onChange={ (e) => setNewBooth({...newBooth, name: e.target.value}) } />
      </div>
      { blueprints.length > 0 &&
        <div className="form-group">
          <h5>Blueprint</h5>
          <select value={ newBooth.blueprint_id } onChange={ (e) => setNewBooth({...newBooth, blueprint_id: e.target.value}) }>
            <option value="">Please select</option>
            {
              blueprints.map((item) => {
                return <option value={ item._id } key={ item._id }>{ item.name } - { item.description }</option>
              })
            }
          </select>
        </div>
      }
      <div className="bmt-button-group modal-group">
        <button className="cancel-btn" onClick={ props.close }>Cancel</button>
        <button className="primary-btn" onClick={ handleCreateNewBooth } disabled={ !newBooth.name }>Create</button>
      </div>
    </div>
  )

  return <Modal title="Create New Exhibit" children={ !loading ? modalContent : <MiniLoader copy="Fetching blueprints..."/> } close={ props.close } />
}

export default NewBoothForm