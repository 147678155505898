import InputHandler from "../../input-handlers/inputHandler"

const OptionsForm = (props) => {

  if (!props.version.template || !props.version.instance) { return null }

  const orderedKeys = Object.keys(props.version.template.options).sort((item) => item.order)

  return (
    <div className="bmt-form-page bmt-wrapper">
      <h2>Options</h2>
      {
        orderedKeys.map((key) => {
          let item = {...props.version.template.options[key], key: key, instanceKey: 'data'}
          let shouldInclude = true

          if (item.dependency) {
            const dependencyKey = item.dependency.instanceKey ? item.dependency.instanceKey : "data"
            const valKey = item.dependency.valueKey ? item.dependency.valueKey : false
            if (props.version.instance && props.version.instance[dependencyKey] && props.version.instance[dependencyKey][item.dependency.key]) {
              const val = valKey && props.version.instance[dependencyKey][item.dependency.key].src && props.version.instance[dependencyKey][item.dependency.key].src[valKey] ? props.version.instance[dependencyKey][item.dependency.key].src[valKey] : props.version.instance[dependencyKey][item.dependency.key]
              shouldInclude = val === item.dependency.value
            }
            else {
              shouldInclude = false
            }
          }

          return (
            shouldInclude ?
            <InputHandler overrides={ props.version.template.overrides } dependency={ item.childOption } readOnly={ props.readOnly } key={ key } input={ item } value={ props.version.instance && props.version.instance.data && props.version.instance.data[key] !== undefined ? props.version.instance.data[key] : props.version.template.options[key].default } change={ props.change } />
            : null
          )
        })
      }
    </div>
  )
}

export default OptionsForm