import axios from "axios"
  
class ManagedUpload {
  constructor(path, file, headers) {
    const host = window.location.hostname
    const apiUrl = host.indexOf('localhost') >= 0 ? "http://localhost:8080/api/" : host.indexOf("curator.nextechar.com") > -1 ? "https://api.bmt.nextechar.com/api/" : "https://test.api.bmt.nextechar.com/api/"
    this.httpApi = axios.create({baseURL: apiUrl})
    this.s3Api = axios.create()
    this.path = path
    this.file = file
    this.uploadId = null
    this.assetUrl = null
    this.headers = headers
    this.awsKey = null
    this.mapping = {
      Parts: []
    }
  }

  upload() {

    return new Promise((resolve, reject) => {
      
      this.httpApi.put(this.path + "/start", {filename: this.file.name, filetype: this.file.type}, {headers: this.headers}).then((res) => {

        if (res.data && res.data.uploadId && res.data.assetUrl) {
          
          this.uploadId = res.data.uploadId
          this.assetUrl = res.data.assetUrl
          this.awsKey = res.data.awsKey

          // Continue to upload file
          // Let's split the file into chunks of 5mb
          const filesize = this.file.size;
          const sliceSize = 5 * 1024 * 1024;
          const numChunks = Math.ceil(filesize/sliceSize);
          let promises = []

          console.log('Uploading asset of size: ' + filesize + ' - Num chunks: ' + numChunks);

          for (let i = 0; i < numChunks; i++) {
            const start = i * sliceSize
            const end = (start + sliceSize > filesize) ? filesize : start + sliceSize
            const slice = this.file.slice(start, end)
            promises.push(this.processChunk(i+1, slice))
          }

          Promise.all(promises).then((data) => {

            // Finalize upload
            this.httpApi.put(this.path + "/complete", {key: this.awsKey, uploadId: this.uploadId, mapping: this.mapping}, {headers: this.headers}).then((res) => {

              console.log("Managed Upload Completed!")
              resolve({url: this.assetUrl, etag: res.data.data.ETag.replace(/"/g,"")})

            }, (err) => {
              console.log("error completing", err)
            })

          }, (err) => {
            console.log('one or more chunks failed')
            console.log(err)
          })
        }
        else {
          reject()
        }

      }, (err) => {
        console.log(err)
        reject(err)
      })
    })
  }

  processChunk(iteration, chunk) {
    // First, get a signed url
    return new Promise((resolve, reject) => {
      this.httpApi.put(this.path + "/chunk/" + iteration, {key: this.awsKey, uploadId: this.uploadId}, {headers: this.headers}).then((res) => {

        if (res.data && res.data.signedUrl) {

          // Make S3 request
          console.log("Processing chunk #" + iteration)
          this.s3Api.put(res.data.signedUrl, chunk, {headers: {'ContentType': this.file.type}}).then((response) => {

            this.mapping.Parts[iteration - 1] = {
              ETag: response.headers.etag,
              PartNumber: iteration
            }

            console.log("Chunk #" + iteration + " completed")

            resolve()
          }, (err) => {
            reject(err)
          })
        }
        else {
          reject()
        }

      }, (err) => {
        reject(err)
      })
    })
  }
}

export default ManagedUpload