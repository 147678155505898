import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { useState } from "react"

const protector = (WrappedComponent, hideWrapper) => {

  const WithProtector = (props) => {

    // const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.user)
    const [loading, setLoading] = useState(false)
    const [header, setHeader] = useState("Loading")
    const [text, setText] = useState("")

    // If we're on a protected page w/o user auth, we need to redirect
    if (!user) {
      return <Redirect to="/login" />
    }

    // Loader methods
    let startTime = null
    let endTime = null
    const loaderMethods = {
      start: (propHeader, propText) => {
        setHeader(propHeader ? propHeader : "Loading")
        setText(propText ? propText : "")
        startTime = performance.now()
        setLoading(true)
      },
      stop: () => {
        endTime = performance.now()

        // Prevent flickering from too short of a load time
        let extra = 0
        if (endTime - startTime < 400) {
          extra = 400 - (endTime - startTime)
        }

        setTimeout(() => {
          setLoading(false)
        }, extra)
      }
    }

    const loader = (
      <div className="bmt-loader">
        <h1>{ header }</h1>
        <h3>{ text }</h3>
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
    )

    const wrappedEl = <WrappedComponent {...props} loader={loaderMethods} />

    if (!hideWrapper) {
      return (
        <div className="bmt-main">
          {loading ? loader : null}
          {wrappedEl}
        </div>
      )
    }

    return wrappedEl
  }

  return WithProtector  
}

export default protector