import NavSidebar from "./nav/sidebar"
import Routes from "../routes"
import AuthTimer from "./auth-timer/authTimer"
import { useLocation } from "react-router"

const Main = () => {

  const location = useLocation()
  console.log('curator: main render')

  return (
    <div className="bmt-app-container flex">
      { <AuthTimer /> }
      { location.pathname !== "/" && location.pathname !== "/login" && <NavSidebar /> }
      <Routes />
    </div>
  )
}

export default Main