import { useState, useEffect, useRef } from "react"
import moment from "moment"

const VideoHandler = (props) => {

  const [file, setFile] = useState(false)
  const debounce = useRef(null)

  useEffect(() => {

    // Set file based on value
    if (props.value) {

      if (props.value.remoteSrc || props.value.src) { 
        console.log(props.inputKey, '-> fetch remote details & set local video') 

        const newForm = props.value.toBePersisted ? props.value.toBePersisted : new FormData()
        newForm.set('key', props.inputKey)
        newForm.set('autoplay', props.value.autoplay)

        let newFileObject = {
          name: props.value.originalFilename ? props.value.originalFilename : props.value.name,
          autoplay: props.value.autoplay,
          src: props.value.remoteSrc ? props.value.remoteSrc + "?v=" + moment().valueOf() : props.value.src,
          toBePersisted: newForm,
          loaded: moment().valueOf()
        }

        setFile(newFileObject)
      }
      // else if (props.value.src && !file.src) {
      //   console.log(props.inputKey, '-> reset local video') 
      //   // const name = props.value.originalFilename ? props.value.originalFilename : props.value.name
      //   // const ext = name.split('.').pop()
      //   const newForm = props.value.toBePersisted ? props.value.toBePersisted : new FormData()
      //   // newForm.set('file', new File([props.value.src], props.inputKey + "." + ext, {type: 'video/' + ext}))
      //   newForm.set('key', props.inputKey)
      //   newForm.set('src', props.value.remoteSrc ? props.valueprops.value.src)
      //   newForm.set('autoplay', props.value.autoplay)

      //   let newFileObject = {
      //     name: props.value.originalFilename ? props.value.originalFilename : props.value.name,
      //     autoplay: props.value.autoplay,
      //     src: props.value.src,
      //     toBePersisted: newForm,
      //     loaded: moment().valueOf()
      //   }

      //   setFile(newFileObject)
      // }
      else if (!props.value.src && !props.value.remoteSrc && !props.value.toBePersisted && file) {
        console.log(props.inputKey, '-> unset local video')
        setFile(false)
        // setPristine(false)
      }
    }
    else if (file.src ) {
      console.log(props.inputKey, '-> unset local video')
      setFile(false)
      // setPristine(false)
    }

    // Kill debounce timer if it is still running
    return () => {
      if (debounce.current) {
        window.clearTimeout(debounce.current)
        debounce.current = null
      }
    }

  }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFileChange = (file) => {

    if (!file) { return}

    // Lock down to 500mb
    if (file.size > (1024*1024*500)) { 
      window.alert("Please select a file smaller than 500mb in size.")
      return
    }

    let newFileObject = {
      name: file.name,
      src: window.URL.createObjectURL(file),
      autoplay: true
    }

    const formData = new FormData()
    formData.set('file', new File([file], props.inputKey + "." + file.name.split('.').pop(), {type: file.type}))
    formData.set('originalFilename', file.name)
    formData.set('key', props.inputKey)
    formData.set('autoplay', "true")
    newFileObject.toBePersisted = formData

    // Set file
    setFile(newFileObject)

    // Propagate change after debounce
    debounceChange(newFileObject)
  }

  const handleToggleAutoplay = () => {
    const formData = file.toBePersisted ? file.toBePersisted : new FormData()
    const newAutoplay = !file.autoplay
    let updatedFileObject = {...file, autoplay: newAutoplay, loaded: moment().valueOf()}

    // Update form
    formData.set('autoplay', newAutoplay ? "true" : "false")
    formData.set('originalFilename', file.name)
    formData.set('key', props.inputKey)

    // Set file
    updatedFileObject.toBePersisted = formData
    setFile(updatedFileObject)

    // Propagate change after debounce
    debounceChange(updatedFileObject)
  }

  const debounceChange = (updatedFileObject) => {
    if (debounce.current) {
      window.clearTimeout(debounce.current)
      debounce.current = null
    }

    debounce.current = window.setTimeout(() => {
      props.change(updatedFileObject)
    }, 500)   
  }

  const handleRemoveVideo = () => {
    setFile(false)
    props.change({delete: true})
  }

  return (
    file ? 
    <div>
      <div className="bmt-media-input bmt-picker-input active">
        <div className="bmt-picker-input-top">
          <div><i className="icon-video"></i> { file.name }</div>
        </div>
        <div className={ !file.autoplay ? "bmt-check-input" : "bmt-check-input selected" } onClick={ handleToggleAutoplay }>Autoplay</div>
      </div>
      <button className="text-btn red" onClick={ handleRemoveVideo }>Remove</button>
    </div> 
    : 
    <div>
      <div className="bmt-media-input bmt-picker-input">
        <div className="bmt-file-picker">
          <label htmlFor={ `video-${props.inputKey}` }><span><i className="icon-video"></i> Select a video</span></label>
          <input type="file" id={ `video-${props.inputKey}` } name={ `video-${props.inputKey}` } disabled={ props.disabled } accept={ props.accept } onChange={ (e) => handleFileChange(e.target.files[0]) } />
        </div>
      </div>
    </div>
  )
}

export default VideoHandler