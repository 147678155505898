const TemplateForm = (props) => {

  return (
    <div className="bmt-wrapper no-pad">
      <div className="margin-top">
        <ul className="bmt-list-view">
          { props.templates.length > 0 && <li><div className="flex">Name</div><div className="flex">Description</div><div className="flex">Exhibit Type</div></li> }
          { props.templates.map((item) => {
            const boothType = props.types.find((a) => a.key === item.boothType)

            return <li key={ item._id } onClick={ () => props.handleBlueprintSelection(item) }><div className="flex">{ item.name }</div><div className="flex">{ item.description }</div><div className="flex">{ boothType && boothType.name }</div></li>
          })}
        </ul>
        { props.templates.length < 1 && <h5 className="no-items">You have not created any templates yet</h5> }
      </div>
  </div>
  )
}

export default TemplateForm