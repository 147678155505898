import axios from "axios"
import { unauthorized, refreshToken } from "../redux/reducers/auth"
import store from "../store"

class API {

  constructor() {
    const user = store.getState().auth.user
    const host = window.location.hostname
    let baseUrl = "http://localhost:8080/api/"

    if (host.indexOf("curator.nextechar.com") > -1 || host.indexOf('booth-management-tool-app.s3-website-us-west-2.amazonaws.com') > -1) {
      baseUrl = "https://api.bmt.nextechar.com/api/"
    }
    else if (host.indexOf("booth-management-tool-app-test.s3-website-us-west-2.amazonaws.com") > -1) {
      baseUrl = "https://test.api.bmt.nextechar.com/api/"
    }

    this.refreshPath = 'auth/refresh'
    this.http = axios.create({baseURL: baseUrl})
    this.methods = {
      get: (path) => {
        return this.http.get(path)
      },
      post: (path, data) => {
        return this.http.post(path, data)
      },
      put: (path, data, config) => {
        return this.http.put(path, data, config ? config : false)
      },
      patch: (path, data) => {
        return this.http.patch(path, data)
      },
      delete: (path, data) => {
        const dataObj = {data: data}
        return this.http.delete(path, dataObj)
      }
    }

    // Response interceptor
    this.http.interceptors.response.use((response) => response, (e) => {
      if (e && e.response && e.response.status === 401) {
        console.log('-- intercept unauthorized user --')
        store.dispatch(unauthorized())
      }

      return Promise.reject(e)
    })

    // Request interceptor
    this.http.interceptors.request.use((config) => {

      if (user && user.token) {
        config.headers = config.headers || {}
        config.headers.Authorization = `Bearer ${user.token}`
      }

      return config
    })
  }

  refreshAuthToken() {
    return new Promise((resolve, reject) => {
      this.methods.get(this.refreshPath).then((res) => {
        store.dispatch(refreshToken(res.data.refreshToken))
        resolve()
      }, (err) => {
        reject(err)
      })
    })
  }
}

export default API