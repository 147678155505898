import { createSlice } from "@reduxjs/toolkit"

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    header: {
      title: '',
      leftButton: null,
      rightItems: [],
      rightButtons: [],
      clickEvent: null
    }
  },
  reducers: {
    setHeaderTitle(state, action) {
      state.header.title = action.payload
    },
    setHeaderLeftButton(state, action) {
      state.header.leftButton = action.payload
    },
    setHeaderRightItems(state, action) {
      state.header.rightItems = action.payload
    },
    setHeaderRightButtons(state, action) {
      state.header.rightButtons = action.payload
    },
    setHeaderClickEvent(state, action) {
      state.header.clickEvent = action.payload
    },
    clearHeaderButtons(state, action) {
      state.header.leftButton = null
      state.header.rightButtons = []
    }
  }
})

export const { setHeaderTitle, setHeaderLeftButton, setHeaderRightItems, setHeaderRightButtons, setHeaderClickEvent, clearHeaderButtons } = uiSlice.actions
export default uiSlice.reducer