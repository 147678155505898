import { useState, useEffect, useRef } from "react"
import "./preview.scss"
import OutputHandler from "../../output-handler/outputHandler"
import moment from "moment"
import MiniLoader from "../../mini-loader/miniLoader"

const Preview = (props) => {

  const outputHandler = useRef()
  const [fullscreen, setFullscreen] = useState(false)
  const [ignite, setIgnite] = useState(false)
  const [stylesLoaded, setStylesLoaded] = useState(false)
  const [scriptsLoaded, setScriptsLoaded] = useState(false)
  const [templateHTML, setTemplateHTML] = useState({__html: null})
  const [hidePreview, setHidePreview] = useState(true)

  const toggleFullscreen = () => {
    setFullscreen(!fullscreen)
  }

  const toggleIgnite = () => {
    setIgnite(!ignite)
  }

  // Setup output handler
  useEffect(() => {
    outputHandler.current = new OutputHandler()
  }, [])

  // Setup template scripts/styles
  useEffect(() => {

    // Load template script
    const script = document.createElement("script")
    script.onload = () => { setScriptsLoaded(true) }
    script.src = props.version.template.script + "?v=" + moment().valueOf().toString()
    script.async = true
    document.body.appendChild(script)

    // Load template styles
    if (!document.getElementById("style-" + props.version.template.key)) {
      const styles = document.createElement("link")
      styles.id = "style-" + props.version.template.key
      styles.rel = "stylesheet"
      styles.type = "text/css"
      styles.onload = () => { setStylesLoaded(true) }
      styles.href = props.version.template.styles + "?v=" + moment().valueOf().toString()
      document.head.appendChild(styles)
    }
    else {
      setStylesLoaded(true)
    }

    // Remove script tag on unload
    return () => {
      document.body.removeChild(script)
    }

  }, [props.version.template])

  // Set template html when our scripts/styles are ready
  useEffect(() => {
    if (stylesLoaded && scriptsLoaded) {
      setTemplateHTML({__html: props.version.template.template})
      window.setTimeout(() => setHidePreview(false), 500)
    }
  }, [stylesLoaded, scriptsLoaded, props.version.template])

  // Re-render every time our instance changes
  useEffect(() => {

    if (!props.version.template || !templateHTML.__html) { return }

    // Go through meta
    if (props.version.template.meta) {
      Object.keys(props.version.template.meta).forEach((key) => {

        // Add to handler
        if (props.version.template.meta[key].templateAction !== undefined) {
          outputHandler.current.add('data', key, props.version.template.meta[key])
        }
      })
    }

    // Go through options
    if (props.version.template.options !== undefined) {
      Object.keys(props.version.template.options).forEach((key) => {

        // Add to handler
        if (props.version.template.options[key].type === "bool" || props.version.template.options[key].type === "hex") {
          outputHandler.current.add('data', key, props.version.template.options[key])
        }
      })
    }

    // Go through images
    if (props.version.template.images) {
      Object.keys(props.version.template.images).forEach((key) => {

        // Add to handler
        outputHandler.current.add('images', key, props.version.template.images[key])      
      })
    }

    // Check if we have additional template supplied image requests
    if (props.version.instance && props.version.instance.hiddenOptions) {
      Object.keys(props.version.instance.hiddenOptions).forEach((optionKey) => {
        const option = props.version.instance.hiddenOptions[optionKey]
        const valKey = Object.keys(option)[0]
        const valObj = option[valKey]

        if (valObj.unlocksAttr && valObj.unlocksAttr.instanceKey === "images") {

          // Add to handler
          outputHandler.current.add('images', valObj.unlocksAttr.key, valObj.unlocksAttr)
        }
      })
    }

    // Go through videos
    if (props.version.template.videos) {
      Object.keys(props.version.template.videos).forEach((key) => {

        // Add to handler
        outputHandler.current.add('videos', key, props.version.template.videos[key])
      })
    }

    // Add booth menu
    if (!props.version.template.menu.disabled) {
      const menuTemplateClass = props.version.template.menu.templateClass ? props.version.template.menu.templateClass : ".previewer-output"
      const menuTemplateColorClass = props.version.template.menu.colorTemplateClass ? props.version.template.menu.colorTemplateClass : false
      outputHandler.current.add('extras', 'menu', {templateClass: menuTemplateClass, colorTemplateClass: menuTemplateColorClass, templateAction: "insert", templateObject: "ul", templateObjectClasses: ["inferno-generated-booth-menu", "booth-" + props.version.template.key]})

      // Add booth overlays
      outputHandler.current.add('extras', 'overlays', {templateClass: ".previewer-output", templateAction: "insert", templateObject: "div", templateObjectClasses: ["inferno-generated-booth-overlay-holder"]})
    }

    if (props.version.template.resources) {
      // Magazine rack resources
      outputHandler.current.add('resources', 'resource-list', {templateClass: ".inferno-generated-booth-resources", templateTarget: "overlay", templateAction: "insert", templateObject: "ul", templateObjectClasses: ["inferno-booth-udf-resources"]})      

      // Overlay resources
      outputHandler.current.add('resources', 'resource-overlay', {alwaysUpdate: true, templateClass: ".inferno-generated-booth-resources-overlay-list", templateTarget: "external", templateAction: "insert", templateObject: "ul", templateObjectClasses: ["inferno-booth-udf-resources"]})      
    }

    // Overlay representatives
    if (props.version.template.representatives) {
      outputHandler.current.add('representatives', 'representatives-overlay', {alwaysUpdate: true, templateClass: ".inferno-generated-booth-representatives-overlay-list", templateAction: "insert", templateObject: "ul", templateObjectClasses: ["inferno-booth-udf-representatives"]})      
    }

    // Showcase
    if (props.version.template.showcase) {
      outputHandler.current.add('showcase', 'showcase', {alwaysUpdate: true, templateClass: ".previewer-output", templateAction: "insert", templateObject: "div", templateObjectClass: "showcase"})      
    }

    // Slides
    if (props.version.template.slides) {
      outputHandler.current.add('slides', 'slides', {templateClass: ".ignite-keynote-slide-content", templateAction: "insert", templateObject: "div", templateObjectClass: "ignite-keynote-slide"})      
    }

    // Check if we have any display toggles
    if (props.version.template.displayToggles) {
      Object.keys(props.version.template.displayToggles).forEach((key) => {
        outputHandler.current.add('displayToggles', key, props.version.template.displayToggles[key])
      })
    }

    // Process
    outputHandler.current.process(props.boothId, props.name, props.booth, props.version.instance, props.version.template, false) // Last param specifies log output

  }, [templateHTML, JSON.stringify(props.version.instance)]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.version.template) { return null }

  return (
    <div className={ fullscreen || ignite ? ignite ? "bmt-previewer fullscreen ignite" : "bmt-previewer fullscreen" : "bmt-previewer"}>
      <div className="previewer-header">
        <h2>Preview</h2>
        <div className="previewer-header-options">
          { !fullscreen && <button onClick={ toggleIgnite }>{ !ignite ? <img className="ignite-icon" src="/ignite.png" alt="igniteX" /> : <span className="icon-x"></span> }</button> }
          { !ignite && <button onClick={ toggleFullscreen }><span className={ !fullscreen ? "icon-maximize" : "icon-minimize-2" } /></button> }
        </div>
      </div>
      <div className="previewer-container">
        { hidePreview && <div className="previewer-loading"><MiniLoader copy="Loading..." /></div> }

        { /* Preview container */ }
        <div className={ !hidePreview && ignite ? "ignite-preview" : "ignite-disabled" }>
          <header className="ignite-header"><h4>IgniteX Header</h4></header>
          <div className="ignite-container">
            <div className="ignite-sidebar"></div>
            <div className="ignite-landing">
              <div className={ hidePreview ? "previewer-output hidden" : "previewer-output" } dangerouslySetInnerHTML={ templateHTML }></div>
              <footer className="ignite-footer">IgniteX Footer</footer>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Preview