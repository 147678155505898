import { useSelector } from "react-redux"
import { useState } from "react"
import MiniLoader from "../mini-loader/miniLoader"
import API from "../../services/api"

const NewClientForm = (props) => {

  const api = new API()
  const types = useSelector((state) => state.booths.availableTypes).filter((item) => item.visible)
  const [newClient, setNewClient] = useState({
    projectName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    maxBooths: '',
    boothTypes: {}
  })

  const [infernoAuth, setInfernoAuth] = useState({email: '', password: '', domain: '', tested: false})
  const [liveX, setLiveX] = useState({enabled: false, showId: false, showName: '', apiKey: '', environment: ''})
  const [liveXShows, setLiveXShows] = useState({loading: false, shows: [], fetched: false})
  const [showError, setShowError] = useState(false)
  
  const handleDone = () => {

    // Reset error state
    setShowError(false)

    if (!validate()) {
      setShowError(true)
      return
    }

    props.completion(newClient, infernoAuth, liveX)
  }

  const validate = () => {
    if (Object.keys(newClient.boothTypes).length < 1 || !newClient.projectName || !newClient.firstName  || !newClient.lastName || !newClient.email || !newClient.password || !newClient.maxBooths) {
      return false
    }

    // Check inferno
    if (!liveX.enabled && ((!infernoAuth.tested && (infernoAuth.email || infernoAuth.password || infernoAuth.domain)) || (infernoAuth.tested && props.infernoError))) {
      return false
    }

    // Check livex
    if (liveX.enabled && ((!liveX.showId || !liveX.showName || !liveX.apiKey))) {
      return false
    }

    // All good
    return true
  }

  const toggleBooth = (type) => {
    let cur = {...newClient.boothTypes}
    if (cur[type]) {
      delete cur[type]
    }
    else {
      cur[type] = true
    }

    setNewClient({...newClient, boothTypes: cur})
  }

  const testInferno = (e) => {
    e.preventDefault()
    setInfernoAuth({...infernoAuth, tested: true})
    props.testInferno(infernoAuth)
  }

  const toggleLiveX = () => {
    const enabled = !liveX.enabled
    setLiveX({...liveX, enabled: enabled, showId: false, showName: '', apiKey: '', environment: ''})

    if (enabled) {
      // Reset inferno auth
      setInfernoAuth({email: '', password: '', domain: '', tested: false})
    }
  }

  const handleSelectEnv = (e) => {
    const env = e.target.value
    setLiveX({...liveX, environment: env})

    // Maybe fetch shows for env
    if (!liveXShows.fetched || env !== liveX.environment) {
      setLiveXShows({...liveXShows, loading: true})
      api.methods.get(`user/livex/shows/${env}`).then((res) => {
        setLiveXShows({...liveXShows, loading: false, fetched: true, shows: res.data.shows})
      }, () => {
        setLiveXShows({...liveXShows, loading: false, fetched: true, shows: []})
      })
    }
  }

  const handleSelectShow = (e) => {
    const showId = parseInt(e.target.value)
    const show = liveXShows.shows.find((item) => item.show_id === showId)

    if (show) {
      setLiveX({...liveX, showId: showId, showName: show.Title, apiKey: show.API_Key})
    }
  }

  return (
    <form onSubmit={(e) => e.preventDefault() }>
      <h3>curator settings</h3>
      <div className="form-group">
        <h5><span className="red">*</span> Project Name</h5>
        <input type="text" placeholder="Project Name" value={ newClient.projectName } onChange={ (e) => setNewClient({...newClient, projectName: e.target.value}) } required />
      </div>
      <div className="form-set">
        <div className="form-group">
          <h5><span className="red">*</span> First Name</h5>
          <input type="text" placeholder="First Name" value={ newClient.firstName } onChange={ (e) => setNewClient({...newClient, firstName: e.target.value}) } required />
        </div>
        <div className="form-group">
          <h5><span className="red">*</span> Last Name</h5>
          <input type="text" placeholder="Last Name" value={ newClient.lastName } onChange={ (e) => setNewClient({...newClient, lastName: e.target.value}) } required />
        </div>
      </div>
      <div className="form-set divider">
        <div className="form-group">
          <h5><span className="red">*</span> Email</h5>
          <input type="email" placeholder="Email" value={ newClient.email } onChange={ (e) => setNewClient({...newClient, email: e.target.value}) } required />
        </div>
        <div className="form-group">
          <h5><span className="red">*</span> Password</h5>
          <input type="password" placeholder="Password" value={ newClient.password } onChange={ (e) => setNewClient({...newClient, password: e.target.value}) } required />
        </div>
      </div>
      <h3>Booth settings</h3>
      <div className="form-set divider">  
        <div className="form-group">
          <h5><span className="red">*</span> Max Number of Booths (0 for unlimited)</h5>
          <input type="number" placeholder="# Booths" value={ newClient.maxBooths } onChange={ (e) => setNewClient({...newClient, maxBooths: e.target.value}) } required />
        </div>
        <div className="form-group">
          <h5><span className="red">*</span> Allowed Booth Types</h5>
          <div className="stacked-check-inputs">
            { types.map((item) => {
              return <div onClick={ () => toggleBooth(item.key) } key={ item.key } className={ `bmt-check-input ${newClient.boothTypes[item.key] ? 'selected' : ''}` }>{ item.name }</div>
            })}
          </div>
        </div>
      </div>
      <h3>LiveX settings</h3>
      <div className="form-set-list divider">
        <div className="form-group">
          <h5>Enable LiveX integration?</h5>
          <div className={ liveX.enabled ? "bmt-check-input selected" : "bmt-check-input" } onClick={ toggleLiveX }>Enable</div>
        </div>
      { liveX.enabled && 
        <div>
          <div className="form-group">
            <h5>Environment</h5>
            <select value={ liveX.environment } onChange={ handleSelectEnv }>
              <option value={ null }>Please select an option</option>
              <option value="staging">Staging</option>
              <option value="production">Production</option>
              <option value="demo">Demo</option>
            </select>
          </div>
          { liveX.environment && 
            <div className="form-group">
              <h5>Show</h5>
              { 
                liveXShows.loading && !liveXShows.fetched ? <MiniLoader copy="Fetching shows..." /> 
                :
                liveXShows.fetched && liveXShows.shows.length < 1 ? <h6>Sorry, there are no available LiveX shows - please try again.</h6>
                :
                <select value={ liveX.showId } onChange={ handleSelectShow }>
                  <option value={ null }>Please select a show</option>
                  { 
                    liveXShows.shows.map((show) => {
                      return <option key={ show.show_id } value={ show.show_id }>{ show.Title }</option>
                    })
                  }
                </select>
              }
            </div>
          }
        </div>
      }
      </div>
      <div className="set-group">
        { liveX.enabled && <div className="disabled-overlay"></div> }
        <h3>IgniteX settings</h3>
        <div className="form-set">  
          <div className="form-group">
            <h5>IgniteX Domain</h5>
            <input type="text" placeholder="IgniteX Domain" value={ infernoAuth.domain } onChange={ (e) => setInfernoAuth({...infernoAuth, domain: e.target.value}) } />
          </div>
          <div className="form-group">
            <h5>IgniteX Email</h5>
            <input type="email" placeholder="IgniteX Email" value={ infernoAuth.email } onChange={ (e) => setInfernoAuth({...infernoAuth, email: e.target.value}) } />
          </div>
        </div>
        <div className="form-set">
          <div className="form-group">
            <h5>IgniteX Password</h5>
            <input type="password" placeholder="IgniteX Password" value={ infernoAuth.password } onChange={ (e) => setInfernoAuth({...infernoAuth, password: e.target.value}) } />
          </div>
          <div className="form-group half">
            <button onClick={ (e) => testInferno(e) } disabled={ !infernoAuth.domain || !infernoAuth.email || !infernoAuth.password } className={ infernoAuth.tested && props.infernoError ? "outline-btn negative-btn" : infernoAuth.tested && !props.infernoError ? "outline-btn save-btn" : "outline-btn" }><span className={ infernoAuth.tested && props.infernoError ? "icon-frown" : infernoAuth.tested && !props.infernoError ? "icon-check" : "icon-log-in" }></span> { infernoAuth.tested && !props.infernoError ? "Verified" : "Verify" }</button>
          </div>
        </div> 
      </div>
      { showError && <div className="error">Please fill out all of the required fields</div> }
      { props.postError && <div className="error">Oops. The email you entered has already been associated with another client.</div> }
      { props.infernoError && <div className="error">Oops. The email and/or password you provided were incorrect.</div> }
      <div className="bmt-button-group modal-group">
        <button className="cancel-btn" onClick={ props.cancel }>Cancel</button>
        <button className="primary-btn" onClick={ handleDone } disabled={ !validate() }>Create</button>
      </div>
    </form>
  )
}

export default NewClientForm