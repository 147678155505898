import { useState, useEffect, useRef } from "react"
import moment from "moment"
import md5 from "md5"
import ImageHandler from "./imageHandler"

const GridHandler = (props) => {

  const debounce = useRef(null)
  const [curItems, setCurItems] = useState({})
  const [isObject, setIsObject] = useState(false)

  useEffect(() => {

    // Check field for object
    setIsObject(typeof props.fields[0] === "object")
    let val = {}
    if (!Array.isArray(props.value)) {
      val = props.value
    }
    else {
      props.value.forEach((item) => {
        val[item._id] = item
      })
    }

    if (val) {
      if (Object.keys(val).length > 0 && md5(JSON.stringify(val)) !== md5(JSON.stringify(curItems))) {
        setCurItems(val)
      }
      else if (Object.keys(val).length < 1 && Object.keys(curItems).length > 0) {
        setCurItems({})
      }
    }

    // Kill debounce timer if it is still running
    return () => {
      if (debounce.current) {
        window.clearTimeout(debounce.current)
        debounce.current = null
      }
    }
  }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleNewItem = () => {
    const newItem = {
      _id: moment().valueOf()
    }

    props.fields.forEach((field) => {
      const key = isObject ? md5(field.key) : md5(field)
      newItem[key] = ""
    })

    setCurItems({...curItems, [newItem._id]: newItem})
  }

  const handleItemUpdate = (itemId, fieldId, value) => {
    const updated = {...curItems, [itemId]: {...curItems[itemId], [fieldId]: value}}
    setCurItems(updated)

    // Propagate change after debounce
    debounceChange(updated)
  }

  const handleRemoveItem = (itemId) => {
    let updated = {...curItems}
    delete updated[itemId]
    setCurItems(updated)

    // Propagate change after debounce
    debounceChange(updated)
  }

  const debounceChange = (newItems) => {
    if (debounce.current) {
      window.clearTimeout(debounce.current)
      debounce.current = null
    }

    debounce.current = window.setTimeout(() => {
      props.change(props.instanceKey, props.inputKey, newItems)
    }, 500)   
  }

  const handleImageChange = (instanceKey, key, params) => {
    handleItemUpdate(instanceKey, key, params)
  }

  return (
    <div className="grid-input">
      <div className="grid-input-header"><h4>{ Object.keys(curItems).length } { Object.keys(curItems).length === 1 ? "item" : "items" }</h4><button className="add-btn" onClick={ handleNewItem }>Add Item</button></div>
      <div className="grid-items">
        { 
          Object.keys(curItems).map((itemKey) => {
            const item = curItems[itemKey]

            return (
              <div key={ item._id } className="grid-item">
                { 
                  props.fields.map((field) => {
                    const key = isObject ? md5(field.key) : field.toLowerCase().trim()
                    const label = isObject ? field.label : field
                    const type = isObject ? field.type : "text"
                    const imageInput = type === "image" ? {
                      key: item._id + key,
                      instanceKey: item._id,
                      width: 500,
                      height: 500
                    } : null

                    return (
                      <div key={ key } className="form-group">
                        <h5>{ label }</h5>
                        { type === "text" ?
                          <input type="text" placeholder={ label } onChange={ (e) => handleItemUpdate(item._id, key, e.target.value) } value={ item[key] } /> :
                          type === "image" ?
                          <ImageHandler key={ key } value={ item[item._id + key] } input={ imageInput } hideLabel={ true } change={ handleImageChange } />
                          : null
                        }
                      </div>
                    )
                  })
                }
                <button onClick={ () => handleRemoveItem(item._id) } className="text-btn red">Remove</button>
              </div>
            )
          }) 
        }
      </div>
    </div>
  )
}

export default GridHandler