import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState, useRef } from "react"
import { startAuthTimer, stopAuthTimer, extendAuthTimer, logout } from "../../redux/reducers/auth"
import Modal from "../modal/modal"
import API from "../../services/api"

const AuthTimer = (props) => {

  const mainTimerValue = 1000*60*40 // 40min
  const dispatch = useDispatch()
  const {user, timer} = useSelector((state) => state.auth)
  const [timeoutWarning, setTimeoutWarning] = useState(null)
  const countdown = useRef()
  const countdownEl = useRef()
  const mainTimer = useRef()
  const auxTimer = useRef()
  const tokenTimer = useRef()
  const api = useRef()

  useEffect(() => {

      console.log('Timer: user state change')

    if (!timer.running && user) {
      // Kick off timer
      console.log('Timer: begin')
      mainTimer.current = window.setTimeout(handleTimeout, mainTimerValue)
      dispatch(startAuthTimer())
      api.current = new API()
      tokenTimer.current = window.setTimeout(handleTokenTimer, mainTimerValue)
    }
    else if (!user) {
      // Clear timer
      clearTimers()
      if (mainTimer.current) {
        window.clearTimeout(mainTimer.current)
        mainTimer.current = null
      }
    }

  }, [user]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (timer.running && timer.extend) {
      console.log('Timer: extend session via component')
      window.clearTimeout(mainTimer.current)
      mainTimer.current = window.setTimeout(handleTimeout, mainTimerValue)
      dispatch(extendAuthTimer(false))
    }

  }, [timer.extend]) //eslint-disable-line react-hooks/exhaustive-deps


  const handleCountdown = () => {

    if (countdown.current > 0) {
      countdown.current = countdown.current-1
      if (countdownEl && countdownEl.current) {
        countdownEl.current.style.transform = "scaleX(" + (countdown.current-1) / 60 + ")"
      }
    }
    else {
      // Logout
      handleLogout()
    }
  }

  const clearTimers = () => {
    console.log('Timer: clear timers')

    if (auxTimer.current) {
      window.clearInterval(auxTimer.current)
      auxTimer.current = null
    }

    if (tokenTimer && tokenTimer.current) {
      window.clearInterval(tokenTimer.current)
      tokenTimer.current = null
    }
  }

  const handleTokenTimer = () => {
    console.log('Timer: refresh auth token')
    if (api.current) {
      api.current.refreshAuthToken().then(() => {
        console.log('Timer: refresh token stored')
      }, (err) => {
        console.log('Timer: Error fetching refresh token: ', err)
      })
    }

    // Create new timer
    tokenTimer.current = window.setTimeout(handleTokenTimer, mainTimerValue)
  }

  const handleLogout = () => {
    console.log('Timer: end session')
    setTimeoutWarning(null)
    dispatch(logout())
    dispatch(stopAuthTimer())
    clearTimers()
  }

  const handleExtendSession = () => {
    console.log('Timer: extend session via modal')
    setTimeoutWarning(null)
    mainTimer.current = window.setTimeout(handleTimeout, mainTimerValue)
    window.clearInterval(auxTimer.current)
    auxTimer.current = null
  }

  const handleTimeout = () => {
    console.log('Timer: session expired')
    countdown.current = 60
    const content = (
      <div>
        <div className="bmt-countdown">
          <div className="bmt-countdown-fill" ref={ countdownEl }></div>
        </div>
        <h3>In one minute, we will automatically log you out for your security.</h3>
        <div className="bmt-button-group modal-group">
          <button className="cancel-btn" onClick={ handleLogout }>Log out now</button>
          <button className="primary-btn" onClick={ handleExtendSession }>Continue working</button>
        </div>
      </div>
    )
    const warning = <Modal z="9999" title="Your session is about to expire" children= { content } close={ handleExtendSession } /> 
    setTimeoutWarning(warning)
    auxTimer.current = setInterval(handleCountdown, 1000)
  }

  return timeoutWarning
}

export default AuthTimer