import InputHandler from "../../input-handlers/inputHandler"
import { useState, useEffect } from "react"
import moment from "moment"
import md5 from "md5"

const ShowcaseForm = (props) => {

  const [items, setItems] = useState(false)

  useEffect(() => {
    if (props.version.instance.showcase || props.version.template.showcase) {
      let required = {}
      let curItems = props.version.instance.showcase && Object.keys(props.version.instance.showcase).length > 0 ? props.version.instance.showcase : {}
      if (props.version.template.showcase && Object.keys(props.version.template.showcase).length > 0) {
        Object.keys(props.version.template.showcase).forEach((resourceKey) => {
          if (props.version.template.showcase[resourceKey].required) {
            required[resourceKey] = props.version.template.showcase[resourceKey]
          }
        })
      }

      let extendedItems = {...curItems}
      Object.keys(curItems).forEach((curKey) => {
        if (props.version.template.showcase && props.version.template.showcase[curKey]) {
          let curItemCopy = {...curItems[curKey]}
          curItemCopy.required = props.version.template.showcase[curKey].required
          curItemCopy.label = props.version.template.showcase[curKey].label
          extendedItems[curKey] = curItemCopy
        }
        else {
          extendedItems[curKey] = curItems[curKey]
        }
      })

      setItems({...required, ...extendedItems})
    }
    else {
      setItems({})
    }

  }, [props.version])

  const handleAddProduct = () => {
    // Create a new item & add it to the list
    const newKey = md5(moment().valueOf().toString())
    setItems({...items, [newKey]: {key: newKey, value: false}})
  }

  const allowMoreProducts = !props.version.template.showcase.max || props.version.template.showcase.max > Object.keys(items).length   

  return (  
    <div className="bmt-form-page bmt-wrapper">
      <h2>AR Showcase</h2>
      { Object.keys(items).length < 1 && <h5 className="no-items">You have not added any products yet.</h5> }
      { Object.keys(items).map((itemKey, index) => {
        const label = "Product #" + (index+1)
        const fullItem = {...items[itemKey], label: label, key: itemKey, instanceKey: 'showcase', type: 'file'}

        return (
          <InputHandler overrides={ props.version.template.overrides } readOnly={ props.readOnly } key={ itemKey } input={ fullItem } value={ props.version.instance && props.version.instance.showcase && props.version.instance.showcase[itemKey] ? props.version.instance.showcase[itemKey] : null } change={ props.change } />
        )
      }) }
      { allowMoreProducts && <button className="text-btn" onClick={ handleAddProduct }>Add Product</button> }
    </div>
  )
}

export default ShowcaseForm