import { useState, useEffect, useCallback } from "react"
import { useDispatch } from "react-redux"
import { loginSuccess, logout } from "../../redux/reducers/auth"
import md5 from "md5"
import "./login.scss"
import API from "../../services/api"
import RegisterFlow from "./registerFlow"
import ForgotFlow from "./forgotFlow"

const Login = (props) => {

  const dispatch = useDispatch()
  const [authError, setAuthError] = useState(false)
  const [input, setInput] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: ''
  })
  const [shareLink, setShareLink] = useState('')
  const [loadingCode, setLoadingCode] = useState(true)
  const [errors, setErrors] = useState({})
  const api = new API()
  const [loginFlow, setLoginFlow] = useState('standard')
  const [registerFlow, setRegisterFlow] = useState('initial')

  const handleShareCode = useCallback((code) => {
    api.methods.post('auth/code', {code: code}).then((res) => {
      dispatch(loginSuccess(res.data))

      // Redirect
      props.history.push('/booths/' + res.data.boothId + '/' + res.data.versionId)
    }, () => {
      setAuthError("Sorry, we could not verify that share link, please try again.")
    })
  }, [api.methods, dispatch, props.history])

  // Inspect initial state for a share code
  useEffect(() => {

    // Invalidate login
    dispatch(logout())

    if (props.match.params && props.match.params.code) {
      console.log('validate sharing code')
      // We'll send over the code & see if it's good
      handleShareCode(props.match.params.code)
    }
    else {
      setLoadingCode(false)
    }

  }, [props.match, handleShareCode]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = (e) => {
    e.preventDefault()
    let curErrors = validate()
    setErrors(curErrors)

    if (Object.keys(curErrors).length === 0) {
      const params = {
        email: input.email.toLowerCase().trim(),
        password: md5(input.password)
      }

      api.methods.post('auth', params).then((res) => {

        const user = res.data
        handleNewLogin(user)

      }, () => {
        setAuthError(true)
      })
    }
  }

  const handleNewLogin = (user) => {
    // Fetch settings
    api.methods.get('user/settings?token=Bearer ' + user.token).then((res) => {

      dispatch(loginSuccess({...user, settings: res.data.settings}))

      // Redirect
      props.history.push('/dashboard')
    })
  }

  const errorDiv = authError ? <div className="error">{ authError.length > 0 ? authError : "We could not validate your credentials, please try again." }</div> : ""
  
  const handleChange = (e) => {
    const { name, value } = e.target
    setInput({...input, [name]: value})
  }

  const validate = () => {
    let errors = {}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i

    if (!input.email) {
      errors.email = 'Please enter an email'
    }
    else if (!regex.test(input.email)) {
      errors.email = 'Please enter a valid email'
    }

    if (!input.password) {
      errors.password = 'Please enter a password'
    }

    return errors
  }

  const handleLinkLogin = (e) => {
    e.preventDefault()
    setLoginFlow('shareLink')
    setShareLink('')
    setAuthError(false)
  }

  const handleStandardLogin = (e) => {
    e.preventDefault()
    setLoginFlow('standard')
    setShareLink('')
    setAuthError(false)
  }

  const handleShareLink = (e) => {
    e.preventDefault()

    // Sanitize as needed
    let link = shareLink
    if (link.indexOf("/share/") > -1) {
      const arr = link.split("/share/")
      if (arr.length > 1) {
        link = arr[1]
      }
    }

    handleShareCode(link)
  }

  const handleSignUpFlow = (flow) => {
    setRegisterFlow(flow)
    setLoginFlow('register')
  }

  const handleForgotFlow = () => {
    setLoginFlow('forgot')
  }

  return (
    loadingCode ? "" :
    <div className="bmt-login">
      <div className="bmt-login-main">
        <div className="bmt-branding">
          <img src="/icon.png" alt="logo" />
          <h3>curator</h3>
        </div>
        <h2>Welcome back</h2>
        { loginFlow === "standard" ?
          <div>
            <form onSubmit={(e) => handleLogin(e)}>
              <h3>Login</h3>
              <h4>Email Address</h4>
              <input type="email" name="email" placeholder="Email" value={input.email} onChange={handleChange} className={errors.email ? 'error': ''} />
              <h4>Password</h4>
              <input type="password" name="password" placeholder="Password" value={input.password} onChange={handleChange} className={errors.password ? 'error': ''} />
              {errorDiv}
              <button className="primary-btn" disabled={!input.email || !input.password}><span className="icon-log-in"></span> Login</button>
              <button className="text-btn margin-top" onClick={ (e) => handleLinkLogin(e) }>Use a share link instead</button>
            </form>
            <div className="margin-top">
              <h5>Need an admin account? <button onClick={ () => handleSignUpFlow('initial') } className="text-btn">Sign up</button> or <button onClick={ () => handleSignUpFlow('code') } className="text-btn">Activate existing</button></h5>
            </div>
            <div className="margin-top">
              <button onClick={ handleForgotFlow } className="text-btn">Forgot your password?</button>
            </div>
          </div>
          :
          loginFlow === "forgot" ? <ForgotFlow switchToLogin={ handleStandardLogin } success={ handleNewLogin } />
          :
          loginFlow === "register" ?  <RegisterFlow flow={ registerFlow } switchToLogin={ handleStandardLogin } success={ handleNewLogin } />
          : 
          <div>
            <form onSubmit={(e) => handleShareLink(e)}>
              <h4>Share Link</h4>
              <br /><h5>Paste your <strong>https://curator.nextechar.com/share/</strong> link below</h5>
              <input type="text" name="shareLink" placeholder="Share Link" value={ shareLink } onChange={ (e) => setShareLink(e.target.value) } />
              {errorDiv}
              <button className="primary-btn" disabled={ !shareLink }><span className="icon-log-in"></span> Go</button>
              <button className="text-btn margin-top" onClick={ (e) => handleStandardLogin(e) }>Use an email/password instead</button>
            </form>
          </div>
        }
      </div>
      <div className="bmt-login-right"></div>
    </div>
  )
}

export default Login