class Validator {

  static _PATTERNS = Object.freeze({
    url: new RegExp(/^(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,'i'),
    hasNonDeleteKeysInResources: "method",
    hasNonDeleteKeysInPhoto: "method",
    hasNonDeleteResource: "method",
    hasResource: "method",
    hasItems: "method",
    length: "length",
    hasKeys: "method",
    email: new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
  })

  static validate(input, type, optionalParams) {
    if (!this._PATTERNS[type]) {
      throw new Error('Must specify a validation type')
    }

    if (this._PATTERNS[type] instanceof RegExp) {
      return this._PATTERNS[type].test(input)
    }

    return this.handleMethodValidation(type, input, optionalParams)
  }

  static handleMethodValidation(type, input, optionalParams) {
    if (type === "hasNonDeleteKeysInResources") {
      if (!input || Object.keys(input).length < 1) {
        return false
      }

      const deleted = Object.keys(input).filter((key) => !input[key].resource || input[key].resource.delete)
      return deleted.length !== Object.keys(input).length
    }
    else if (type === "hasNonDeleteKeysInPhoto") {
      if (Object.keys(input).length < 1) {
        return false
      }

      const deleted = Object.keys(input).filter((key) => !input[key].photo || input[key].photo.delete)
      return deleted.length !== Object.keys(input).length
    }
    else if (type === "hasResource") {
      return input && (input.src || input.remoteSrc)
    }
    else if (type === "hasNonDeleteResource") {
      return input && input.resource && !input.resource.delete && (input.resource.src || input.resource.remoteSrc)
    }
    else if (type === "hasItems") {
      return input && input.length > 0
    }
    else if (type === "hasKeys") {
      return input && Object.keys(input) && Object.keys(input).length > 0
    }
    else if (type === "length") {
      var limit = optionalParams && optionalParams.limit ? optionalParams.limit : Infinity
      return input && input.length <= limit
    }

    return false
  }
}

export default Validator