import moment from "moment"

const DeploymentHistory = (props) => {

  // Fetch only deployments for this version
  const deployments = props.deployments ? [...props.deployments].sort((a, b) => a.created > b.created ? -1 : a.created < b.created ? 1 : 0).filter((item) => item.versionId === props.instance._id) : []
  const map = deployments.map((item, index) => {
    return (
      <li key={ item._id } className="no-action">
        <div className="flex">
          Draft Submitted
        </div>
        <div className={ `flex status-tag status-${item.status.toLowerCase().replace(" ", "-")}` }><span>{ item.status }</span></div>
        <div className="flex bigger">
          <span className="list-tag">Submitted: { moment(item.created).format("MM/DD/YYYY hh:mm a") }</span>
          { item.publishStarted && <span className="list-tag">Started to Publish: { moment(item.publishedStarted).format("MM/DD/YYYY hh:mm a") }</span> }
          { item.publishEnded && <span className="list-tag green-bg">Published: { moment(item.publishEnded).format("MM/DD/YYYY hh:mm a") }</span> }
          { !item.publishEnded && item.publishFailed && <span className="list-tag red-bg">Failed to Publish: { moment(item.publishFailed).format("MM/DD/YYYY hh:mm a") }</span> }
        </div>
      </li>
    )
  })
 
  return (
    <div className="bmt-wrapper">
      <h2>Activity</h2>
      <ul className="bmt-list-view">
        <li><div className="flex">Item</div><div className="flex">Status</div><div className="flex bigger">Info</div></li>
        { map }
        <li className="no-action">
          <div className="flex">
            Draft Created
          </div>
          <div className="flex status-tag status-created"><span>Created</span></div>
          <div className="flex bigger">
            <span className="list-tag">Created: { moment(props.instance.created).format("MM/DD/YYYY hh:mm a") }</span>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default DeploymentHistory