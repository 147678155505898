import { useState, useEffect, useRef } from "react"
import moment from "moment"
import InputHandler from "./inputHandler"

const ProductHandler = (props) => {

  const [product, setProduct] = useState({name: '', ctaText: '', ctaLink: '', arProductLink: '', photo: {}, qrCodePhoto: {}})
  const debounce = useRef(null)
  const [isChangingPhoto, setIsChangingPhoto] = useState(false)
  const [isChangingQrCode, setIsChangingQrCode] = useState(false)

  useEffect(() => {

    // Set file based on value
    if (props.value) {

      // Set photos // handle delete
      if (props.value.photo && !props.value.photo.delete) {
        let aProd = {...props.value}

        // Handle main photo
        if (props.value.photo.remoteSrc && (!product.photo || !product.photo.src || product.photo.loaded !== props.value.photo.loaded)) { 
          console.log(props.value.photo.name, '-> fetch remote details & set local product photo') 
          const resourceForm = new FormData()
          resourceForm.set('key', props.inputKey + ".photo")
          resourceForm.set('parentKey', props.inputKey)
          resourceForm.set('name', props.value.photo.name)
          aProd.photo = {...props.value.photo, toBePersisted: resourceForm, name: props.value.photo.name, src: props.value.photo.remoteSrc, loaded: moment().valueOf()}
        }
        else if (props.value.photo.src && (!product.photo.src || product.photo.loaded !== props.value.photo.loaded)) {
          console.log(props.inputKey, '-> reset local product photo') 
          const resourceForm = new FormData()
          resourceForm.append('key', props.inputKey + ".photo")
          resourceForm.append('parentKey', props.inputKey)
          resourceForm.append('name', props.value.photo.name)
  
          aProd.photo = {...props.value.photo, toBePersisted: resourceForm, src: props.value.photo.src, loaded: moment().valueOf()}
        }

        // Handle qr code
        if (props.value.qrCodePhoto) {
          if (props.value.qrCodePhoto.remoteSrc && (!product.qrCodePhoto || !product.qrCodePhoto.src || product.qrCodePhoto.loaded !== props.value.qrCodePhoto.loaded)) { 
            console.log(props.value.qrCodePhoto.name, '-> fetch remote details & set local qr code photo') 

            const resourceForm = new FormData()
            resourceForm.set('key', props.inputKey + ".qrCodePhoto")
            resourceForm.set('parentKey', props.inputKey)
            resourceForm.set('name', props.value.qrCodePhoto.name)

            aProd.qrCodePhoto = {...props.value.qrCodePhoto, toBePersisted: resourceForm, name: props.value.qrCodePhoto.name, src: props.value.qrCodePhoto.remoteSrc, loaded: moment().valueOf()}
          }
          else if (props.value.qrCodePhoto.src && (!product.qrCodePhoto.src || product.qrCodePhoto.loaded !== props.value.qrCodePhoto.loaded)) {
            console.log(props.inputKey, '-> reset local product qr code photo') 
            const resourceForm = new FormData()
            resourceForm.append('key', props.inputKey + ".qrCodePhoto")
            resourceForm.append('parentKey', props.inputKey)
            resourceForm.append('name', props.value.qrCodePhoto.name)

            aProd.qrCodePhoto = {...props.value.qrCodePhoto, toBePersisted: resourceForm, src: props.value.qrCodePhoto.src, loaded: moment().valueOf()}
          }
        }

        // Set
        setProduct(aProd)
      }
      else if (props.value.photo && props.value.photo.delete) {
        console.log(props.inputKey, '-> unset local product')
        setProduct(false)
      }
    }
    else if (product && product.photo.src) {
      console.log(props.inputKey, '-> unset local product')
      setProduct(false)
    }

    // Kill debounce timer if it is still running
    return () => {
      if (debounce.current) {
        window.clearTimeout(debounce.current)
        debounce.current = null
      }
    }

  }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleRemoveProduct = () => {
    setProduct({name: '', ctaText: '', ctaLink: '', arProductLink: '', photo: {}, qrCodePhoto: {}})
    props.change({key: props.inputKey, photo: {delete: true}})
  }

  const handlePhotoUpdate = (file) => {
    setIsChangingPhoto(false)
    handleFileChange(file, 'photo')
  }

  const handleQrUpdate = (file) => {
    setIsChangingQrCode(false)
    handleFileChange(file, 'qrCodePhoto')
  }

  const handleFileChange = (file, key) => {

    if (!file || !key) { return }

    // Lock down to 5mb
    if (file.size > (1024*1024*5)) { 
      window.alert("Please select a file smaller than 5mb in size.")
      return
    }

    const newProduct = {...product, key: props.inputKey}

    const formData = new FormData()
    formData.set('file', file)
    formData.set('key', props.inputKey + '.' + key)
    formData.set('name', file.name)
    formData.set('filename', file.name)
    formData.set('parentKey', props.inputKey)

    const photoFile = {
      filename: file.name,
      src: window.URL.createObjectURL(file),
      toBePersisted: formData,
      name: file.name,
      loaded: moment().valueOf()
    }

    newProduct[key] = photoFile

    // Set
    setProduct(newProduct)

    // Propagate change after debounce
    debounceChange(newProduct)
  }

  const debounceChange = (newRep) => {
    if (debounce.current) {
      window.clearTimeout(debounce.current)
      debounce.current = null
    }

    debounce.current = window.setTimeout(() => {
      props.change(newRep)
    }, 500)   
  }

  const handleFieldChange = (field, value) => {
    const newProduct = {...product, [field]: value}
    setProduct(newProduct)

    // Propagate change after debounce
    debounceChange(newProduct) 
  }

  return (
    product.photo && product.photo.src ? 
    <div className="bmt-media-input bmt-picker-input active">
      <div className="bmt-picker-input-top">
        <div className="top-label"><i className="icon-image"></i> Photo: <strong>{ product.photo.filename }</strong> { !isChangingPhoto && <div><button className="text-btn" onClick={ () => setIsChangingPhoto(true) }>Change</button></div> }</div> 
      </div>
      { isChangingPhoto && 
        <div>
          <div className="bmt-media-input bmt-picker-input margin-top">
            <div className="bmt-file-picker">
              <label htmlFor={ `photo-${props.inputKey}` }><span><i className="icon-image"></i> Select an image (.png)</span></label>
              <input type="file" id={ `photo-${props.inputKey}` } name={ `photo-${props.inputKey}` } disabled={ props.disabled } accept=".png" onChange={ (e) => handlePhotoUpdate(e.target.files[0]) } />
            </div>
          </div>
          <button class="text-btn red margin-top" onClick={ () => setIsChangingPhoto(false) }>Cancel</button>
        </div>
      }
      <div className="bmt-picker-input-top margin-top">
        <h5>Name</h5>
        <input type="text" placeholder="Name..." value={ product.name } onChange={ (e) => handleFieldChange("name", e.target.value) } /> 
      </div>
      <div className="bmt-picker-input-top margin-top">
        <h5>Call to Action Label</h5>
        <input type="text" placeholder="Find out more..." value={ product.ctaText } onChange={ (e) => handleFieldChange("ctaText", e.target.value) } /> 
      </div>
      <div className="bmt-picker-input-top">
        <h5>Call to Action URL</h5>
        <InputHandler hideLabel={ true } value={ product.ctaLink } change={ (instanceKey, key, val) => handleFieldChange("ctaLink", val) } input={ {instanceKey: "showcase", key: "ctaLink", specialClass: "prefix-url", type: "text", restrictions: {pattern: "url"}} } />
      </div>  
      <div className="bmt-picker-input-top">
        <h5>threedyAI URL</h5>
        <InputHandler hideLabel={ true } value={ product.arProductLink } change={ (instanceKey, key, val) => handleFieldChange("arProductLink", val) } input={ {instanceKey: "showcase", key: "arProductLink", specialClass: "prefix-url", type: "text", restrictions: {pattern: "url"}} } />
      </div> 
      { product.qrCodePhoto && product.qrCodePhoto.src ?
        <>
          <div className="bmt-picker-input-top">
            <div className="top-label"><i className="icon-image"></i> QR Code: <strong>{ product.qrCodePhoto.filename }</strong> { !isChangingQrCode && <div><button className="text-btn" onClick={ () => setIsChangingQrCode(true) }>Change</button></div> }</div> 
          </div>
          { isChangingQrCode && 
            <div>
              <div className="bmt-media-input bmt-picker-input margin-top">
                <div className="bmt-file-picker">
                  <label htmlFor={ `qr-${props.inputKey}` }><span><i className="icon-image"></i> Select an image</span></label>
                  <input type="file" id={ `qr-${props.inputKey}` } name={ `qr-${props.inputKey}` } disabled={ props.disabled } accept=".jpg,.jpeg,.png" onChange={ (e) => handleQrUpdate(e.target.files[0]) } />
                </div>
              </div>
              <button class="text-btn red margin-top" onClick={ () => setIsChangingQrCode(false) }>Cancel</button>
            </div>
          }
        </>
        :
        <div className="bmt-picker-input-top">
          <h5>QR Code</h5>
          <div className="bmt-media-input bmt-picker-input">
            <div className="bmt-file-picker">
              <label htmlFor={ `qr-${props.inputKey}` }><span><i className="icon-image"></i> Select an image</span></label>
              <input type="file" id={ `qr-${props.inputKey}` } name={ `qr-${props.inputKey}` } disabled={ props.disabled } accept=".jpg,.jpeg,.png" onChange={ (e) => handleFileChange(e.target.files[0], 'qrCodePhoto') } />
            </div>
          </div>
        </div>
      }
      <button className="text-btn red margin-top" onClick={ handleRemoveProduct }>Remove</button>
    </div> : 
    <div>
      <div className="bmt-media-input bmt-picker-input">
        <div className="bmt-file-picker">
          <label htmlFor={ `photo-${props.inputKey}` }><span><i className="icon-image"></i> Select an image (.png)</span></label>
          <input type="file" id={ `photo-${props.inputKey}` } name={ `photo-${props.inputKey}` } disabled={ props.disabled } accept=".png" onChange={ (e) => handleFileChange(e.target.files[0], 'photo') } />
        </div>
      </div>
    </div>
  )
}

export default ProductHandler