import Header from "../header/header"
import { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setSelectedBooth } from "../../redux/reducers/booths"
import { setCategories, extendAuthTimer } from "../../redux/reducers/auth"
import { setSelectedClient } from "../../redux/reducers/clients"
import Modal from "../modal/modal"
import GeneralForm from "./components/general"
import moment from "moment"
import md5 from "md5"
import $ from "jquery"
import OutputHandler from "../output-handler/outputHandler"
import AdminPanel from "../nav/adminPanel"
import API from "../../services/api"
import { clearHeaderButtons, setHeaderClickEvent, setHeaderLeftButton, setHeaderRightButtons, setHeaderTitle } from "../../redux/reducers/ui"
import Constants from "../constants"
import Utils from "../utils"

const BoothDetail = (props) => {

  // Set state
  const dispatch = useDispatch()
  const api = new API()
  const [boothStore, user, clients, headerEvent] = useSelector((state) => [state.booths, state.auth.user, state.clients, state.ui.header.clickEvent])
  const pristineBooth = boothStore.selectedBooth
  const availableBoothTypes = boothStore.availableTypes

  // Setup a booth to work off of
  const [booth, setBooth] = useState({})
  const [autosave, setAutosave] = useState(false)
  const [blueprints, setBlueprints] = useState([])

  // New draft 
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [newVersion, setNewVersion] = useState({name: ''})

  useEffect(() => {
    let mounted = true
    console.log('booth detail: mounted')
    props.loader.start()
    dispatch(extendAuthTimer(true))

    // Check if we're loading for a shared user who should not see this booth in its entirety 
    if (user.isShared) {
      // Redirect to the version allocated to them
      editVersion(user.boothId, user.versionId)
      return
    }

    // Check to see if we're an admin, looking at a client booth w/o a client list or selected client
    if (user.isAdmin && clients && !clients.selectedClient) {
      // Redirect
      props.history.push('/clients')
      return
    }

    // Set selected client if it hasn't been set yet
    if (!user.isAdmin && clients && !clients.selectedClient) {
      
      api.methods.get(`clients/${user._id}`).then((res) => {

        if (mounted) {
          // Set client
          dispatch(setSelectedClient(res.data.client))
        }

      }, () => {
        console.log('error setting client')
        // todo: handle error
      })
    }


    // Fetch booth
    api.methods.get('booths/' + props.match.params.id).then((res) => {

      if (mounted) {
        props.loader.stop()

        if (res.data && res.data.booth) {
          // Set selected booth
          dispatch(setSelectedBooth(res.data.booth))
          setBooth(res.data.booth)
          setBlueprints(res.data.blueprints)

          // Set header
          dispatch(setHeaderTitle(res.data.booth.name ? res.data.booth.name : "Untitled booth"))

          if (!user.isShared) {
            dispatch(setHeaderLeftButton({label: user.isAdmin && clients.selectedClient ? `${clients.selectedClient.firstName} ${clients.selectedClient.lastName} Exhibits` : "Exhibits", key: Constants.BOOTH_DETAIL_BACK_BTN}))
            dispatch(setHeaderRightButtons([
              {label: "Delete", className: "negative-btn", key: Constants.BOOTH_DETAIL_DELETE_BTN}, 
              {label: "Export", icon: "icon-download", className: "save-btn", key: Constants.BOOTH_DETAIL_EXPORT_BTN},
              {label: "New Draft", icon: "icon-plus", className: "primary-btn", key: Constants.BOOTH_DETAIL_NEW_BTN}
            ]))
          }
          else {
            dispatch(clearHeaderButtons())
          }
        }
      }

    }, () => {
      console.log('error fetching booth')
      // todo: handle error
      if (mounted) {
        props.loader.stop()
      }
    })

    return () => mounted = false

  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  // Header events
  useEffect(() => {
    if (!headerEvent) { return}

    // Clear
    dispatch(setHeaderClickEvent(null))

    // Handle
    if (headerEvent.key === Constants.BOOTH_DETAIL_BACK_BTN) {
      handleBackButton()
    }
    else if (headerEvent.key === Constants.BOOTH_DETAIL_NEW_BTN) {
      setShowCreateModal(true)
    }
    else if (headerEvent.key === Constants.BOOTH_DETAIL_DELETE_BTN) {
      handleDeleteBooth()
    }
    else if (headerEvent.key === Constants.BOOTH_DETAIL_EXPORT_BTN) {
      handleExportBooth();
    }

  }, [headerEvent]) // eslint-disable-line

  const handleRefreshStatus = () => {
    dispatch(extendAuthTimer(true))

    api.methods.get('booths/' + props.match.params.id).then((res) => {
      setBooth({...booth, status: res.data.booth.status, deployments: res.data.booth.deployments})
      dispatch(setSelectedBooth({...booth, status: res.data.booth.status, deployments: res.data.booth.deployments}))
    })
  }

  const cancelChanges = () => {
    setBooth({...pristineBooth})
  }

  const saveChanges = useCallback(() => {
    console.log('save changes')
    dispatch(extendAuthTimer(true))

    // Trigger an expo hall update
    const expoClientId = clients.selectedClient ? clients.selectedClient._id : false
    if (expoClientId) {
      const expoParams = {
        delay: 2,
        isAutoUpdate: true
      }

      api.methods.post(`clients/${expoClientId}/exhibitEndpoint`, expoParams).then((res) => {
        // Update client
        let settings = clients.selectedClient.settings ? {...clients.selectedClient.settings} : {}
        settings.publicEndpoint = res.data
        dispatch(setSelectedClient({...clients.selectedClient, settings: settings}))
      })
    }

    // Create diff obj
    let params = {}
    for (const key in booth) {
      if (!pristineBooth[key] || pristineBooth[key] !== booth[key]) {
        params[key] = booth[key]
      }
    }

    // Delete blueprint if it exists (we don't want this saved in the db)
    let dbParams = {...params}
    if (dbParams.blueprint) {
      delete dbParams.blueprint
    }

    if (Object.keys(dbParams).length < 1) {
      return
    }

    api.methods.patch('booths/' + booth._id, dbParams).then((res) => {

      // Update pristine / pending
      dispatch(setSelectedBooth({...pristineBooth, ...params, lastUpdated: res.data.lastUpdated, lastUpdatedBy: res.data.lastUpdatedBy}))

    }, () => {
      // todo: handle error
      console.log('error updating booth')
    })
  }, [booth, pristineBooth, dispatch]) //eslint-disable-line react-hooks/exhaustive-deps


  const renderInvisibleBooth = (inputVersion, isExport) => {
    return new Promise((resolve, reject) => {

      const version = {...inputVersion}
      
      // Get booth template
      let template = availableBoothTypes && availableBoothTypes.find((item) => item.key === booth.type)

      if (template) {

        // Check if we need to combine any templates
        if (template.parent) {
          const parent = availableBoothTypes && availableBoothTypes.find((item) => item.key === template.parent)
          if (parent) {
            template = {...parent, ...template}
          }
        }
      }
      else {
        return false
      }

      // Check if we have a blueprint applied which will overwrite any instance changes
      if (booth.blueprint && booth.blueprint.selections) {

        Object.keys(booth.blueprint.selections).forEach((selKey) => {
          const selection = booth.blueprint.selections[selKey]
          version[selection.instanceKey] = {...version[selection.instanceKey], [selection.optionKey]: {[selection.optionValueKey]: selection.presetValue, locked: true}}

          // Add "hidden" options in the template if this blueprint provides the attr
          if (selection.unlocksAttr) {
            template = {...template, [selection.unlocksAttr.instanceKey]: {...template[selection.unlocksAttr.instanceKey], [selection.unlocksAttr.key]: selection.unlocksAttr}}
          }
        })
      }

      console.log("TEMPLATE:")
      console.log(template)

      // Fetch JS
      fetch(template.script + "?v=" + moment().valueOf()).then((res) => res.text()).then((scriptBody) => {

        // Fetch CSS
        fetch(template.styles + "?v=" + moment().valueOf()).then((res) => res.text()).then((stylesBody) => {

          // Create invisible holding div
          const hiddenDiv = $("<div class='bmt-hidden-output'></div>")
            .css({display: "none"})
            .html("<style>" + stylesBody + "</style><script>" + scriptBody + "</script>" + template.template)

          // Append
          $("html body").append(hiddenDiv)

          // Setup our output handler
          const outputHandler = new OutputHandler()

          // Build our booth
          Object.keys(template.meta).forEach((key) => {
            if (template.meta[key].templateAction !== undefined) {
              outputHandler.add('data', key, template.meta[key])
            }
          })

          if (template.options !== undefined) {
            Object.keys(template.options).forEach((key) => {
              if (template.options[key].type === "bool" || template.options[key].type === "hex") {
                outputHandler.add('data', key, template.options[key])
              }
            })
          }

          Object.keys(template.images).forEach((key) => {
            outputHandler.add('images', key, template.images[key])      
          })

          // Check if we have additional template supplied image requests
          if (version.hiddenOptions) {
            Object.keys(version.hiddenOptions).forEach((optionKey) => {
              const option = version.hiddenOptions[optionKey]
              const valKey = Object.keys(option)[0]
              const valObj = option[valKey]

              if (valObj.unlocksAttr && valObj.unlocksAttr.instanceKey === "images") {

                // Add to handler
                outputHandler.add('images', valObj.unlocksAttr.key, valObj.unlocksAttr)
              }
            })
          }

          // Videos
          if (template.videos) {
            Object.keys(template.videos).forEach((key) => {
              outputHandler.add('videos', key, template.videos[key])
            })
          }

          // Menu
          if (!template.menu.disabled) {
            const menuTemplateClass = template.menu.templateClass ? template.menu.templateClass : ".bmt-hidden-output"
            const menuTemplateColorClass = template.menu.colorTemplateClass ? template.menu.colorTemplateClass : false
            outputHandler.add('extras', 'menu', {templateClass: menuTemplateClass, colorTemplateClass: menuTemplateColorClass, templateAction: "insert", templateObject: "ul", templateObjectClasses: ["inferno-generated-booth-menu", "booth-" + template.key]})
            outputHandler.add('extras', 'overlays', {templateClass: ".bmt-hidden-output", templateAction: "insert", templateObject: "div", templateObjectClasses: ["inferno-generated-booth-overlay-holder"]})
          }
          
          // Add booth resources
          const resourceContainerClasses = ["inferno-booth-udf-resources"]
          if (version.data && version.data.resourceDisplay !== "grid") {
            resourceContainerClasses.push("list-view")
          }

          if (template.resources) {
            // Magazine rack resources
            outputHandler.add('resources', 'resource-list', {templateClass: ".inferno-generated-booth-resources", templateTarget: "overlay", templateAction: "insert", templateObject: "ul", templateObjectClasses: resourceContainerClasses})      

            // Overlay resources
            outputHandler.add('resources', 'resource-overlay', {templateClass: ".inferno-generated-booth-resources-overlay-list", templateTarget: "external", templateAction: "insert", templateObject: "ul", templateObjectClasses: resourceContainerClasses})      
          }

          // Overlay representatives
          if (template.representatives) {
            outputHandler.add('representatives', 'representatives-overlay', {templateClass: ".inferno-generated-booth-representatives-overlay-list", templateAction: "insert", templateObject: "ul", templateObjectClasses: ["inferno-booth-udf-representatives"]})      
          }

          // Slides
          if (template.slides) {
            outputHandler.add('slides', 'slides', {templateClass: ".ignite-keynote-slide-content", templateAction: "insert", templateObject: "div", templateObjectClass: "ignite-keynote-slide"})      
          }

          // Showcase
          if (template.showcase) {
            outputHandler.add('showcase', 'showcase', {alwaysUpdate: true, templateClass: ".bmt-hidden-output", templateAction: "insert", templateObject: "div", templateObjectClass: "showcase"})      
          }
          
          // Check if we have any display toggles
          if (template.displayToggles) {
            Object.keys(template.displayToggles).forEach((key) => {
              outputHandler.add('displayToggles', key, template.displayToggles[key])
            })
          }

          // Process
          outputHandler.process(booth._id, booth.name, booth, version, template, false)

          // Create output & append booth_id to html
          let output = $(hiddenDiv).html() + "<div style='display:none' class='inferno_booth_id'>" + booth._id + "</div>"

          // Append Ignite override if needed
          let apiOverride = false
          if (user.settings && user.settings.igniteX && user.settings.igniteX.apiOverride) {
            apiOverride = user.settings.igniteX.apiOverride
          }

          if (clients && clients.selectedClient && clients.selectedClient.settings && clients.selectedClient.settings.igniteX && clients.selectedClient.settings.igniteX.apiOverride) {
            apiOverride = clients.selectedClient.settings.igniteX.apiOverride
          }

          if (apiOverride) {
            output += "<div style='display:none' class='inferno_api_override'>" + apiOverride + "</div>"
          }

          // Append export flag if needed
          if (isExport) {
            output += "<div style='display:none' class='inferno_is_export'></div>"
          }

          // Check if our template has a chat url specified
          if (template.overrides && template.overrides.chatUrl) {
            output += "<div style='display:none' class='inferno-chat-url'>" + template.overrides.chatUrl + "</div>"
          }

          // Remove from dom
          hiddenDiv.remove()

          // Return
          resolve(output)
        })
      })
    })
  }

  const handleExportBooth = () => {
    // Show loader
    dispatch(extendAuthTimer(true))
    props.loader.start()

    // Get latest version
    const sorted = [...booth.versions].sort(Utils.sortByUpdatedOrCreated)

    // Acquire HTML
    renderInvisibleBooth(sorted[0], true).then((html) => {

      // Make it real html
      let finalHtml = "<!DOCTYPE html><html lang='en'><head>" + html + "</body></html>"
      finalHtml = finalHtml.replace("</script>", "</script></head><body>")
      finalHtml = finalHtml.replace("loadDependencies();", "setTimeout(loadDependencies);")

      const blob = new Blob([finalHtml], {type: 'text/html'})
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = booth.name + ".html"

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
          a.remove()
        }, 150);
      };
      
      a.addEventListener('click', clickHandler, false)
      a.click()
      props.loader.stop()

    }, () => {
      window.alert("Uh oh, there was an error exporting this exhibit. Please try again")
    });
  }

  const handlePublish = (versionId) => {

    // User is not allowed
    if (user.isShared && user.access === 'view') {
      return
    }

    const isLivexBooth = booth.livexId

    // Make sure we have a category set
    if (!booth.infernoParentCategory && !isLivexBooth) {
      return
    }

    const versionDeployments = booth.deployments.filter((item) => item.versionId === versionId).sort((a, b) => a.created > b.created ? 1 : a.created < b.created ? -1 : 0)

    if (versionDeployments.length < 1) {
      return
    }

    dispatch(extendAuthTimer(true))

    // Set inferno creds
    let creds = false
    if (isLivexBooth) {
      creds = "LIVEX_DEFAULTS"
    }
    else {
      creds = user.settings && user.settings.infernoAuth ? {...user.settings.infernoAuth} : false
      if (user.settings && user.settings.igniteX && user.settings.igniteX.apiOverride) {
        creds.apiOverride = user.settings.igniteX.apiOverride
      }

      if (clients && clients.selectedClient && clients.selectedClient.settings && clients.selectedClient.settings.infernoAuth) {
        creds = {...clients.selectedClient.settings.infernoAuth}

        // Add override as well if needed
        if (clients.selectedClient.settings.igniteX && clients.selectedClient.settings.igniteX.apiOverride) {
          creds.apiOverride = clients.selectedClient.settings.igniteX.apiOverride
        }
      }
    }

    if (!creds) {
      window.alert("This deployment is missing IgniteX credentials. Please authenticate with IgniteX first.")
      return
    }

    // Trigger an expo hall update
    const expoClientId = clients.selectedClient ? clients.selectedClient._id : false
    if (expoClientId) {
      const expoParams = {
        delay: 2,
        isAutoUpdate: true
      }

      api.methods.post(`clients/${expoClientId}/exhibitEndpoint`, expoParams).then((res) => {
        // Update client
        let settings = clients.selectedClient.settings ? {...clients.selectedClient.settings} : {}
        settings.publicEndpoint = res.data
        dispatch(setSelectedClient({...clients.selectedClient, settings: settings}))
      })
    }

    // Check if we have a previous deployment targeting the same parent category
    const version = booth.versions.find((item) => item._id === versionId)
    const priorDeployments = booth.deployments.filter((item) => item.publishMeta && Object.keys(item.publishMeta).length > 0 && (isLivexBooth || (item.infernoParentCategory && item.infernoParentCategory.categoryId === booth.infernoParentCategory.id)))
    const priorDeployment = priorDeployments.length > 0 ? priorDeployments[0] : false

    // Compile rendered html
    renderInvisibleBooth(version).then((rendered) => {

      let tasks = {}
      if (!isLivexBooth) {
        tasks = {
          createSnippet: {
            type: "CREATE_SNIPPET",
            order: 0,
            meta: {
              name: "Snippet generated by curator for booth --> " + booth.name,
              snippetType: 0 // Landing page 
            },
            output: {
              prop: "id",
              outputKey: "codeSnippetId",
              targets: [
                {
                  key: "createRevision",
                  prop: "codeSnippetId"
                }, {
                  key: "createCategory",
                  prop: "landingPageSnippetId"
                }
              ]
            }
          },
          createRevision: {
            type: "CREATE_SNIPPET_REVISION",
            order: 10,
            meta: {
              comments: "Rendered on " + moment().format('MM/DD/YYYY hh:mm a') + " from draft --> " + version.name,
              snippet: rendered
            }
          }
        }
      }

      tasks.createCategory = {
        type: "CREATE_CATEGORY",
        order: 2,
        meta: {
          name: booth.name,
          parentId: isLivexBooth ? "$LIVEX_PARENT_CATEGORY" : (booth.infernoParentCategory.id === "root" ? null : booth.infernoParentCategory.id),
          landingPagePersistent: true
        },
        output: {
          prop: "id",
          outputKey: "categoryId",
          targets: [
            {
              key: "createRevision",
              pattern: "{categoryId}",
              propType: "replacement",
              propSearchIn: "snippet",
              propSearch: '<div style="display:none" class="inferno_categoryId">',
              propReplace: '<div style="display:none" class="inferno_categoryId">{categoryId}' 
            }
          ]
        }
      }

      // Compile request params + task list
      const params = {
        deploy: {
          status: "Publishing",
          boothName: booth.name,
          publishStarted: moment().valueOf(),
          infernoAuth: creds,
          infernoParentCategory: isLivexBooth ? false : {
            categoryId: booth.infernoParentCategory.id,
            name: booth.infernoParentCategory.name
          },
          publishMeta: {},
          postPublishTasks: {},
          tasks: tasks
        },
        isLivexBooth: isLivexBooth,
        boothId: booth._id
      } 

      // Create video tasks
      let eventKeys = []
      if (version.videos) {
        Object.keys(version.videos).forEach((key) => {
          const eventKey = "createEvent_" + key 
          const linkKey = "linkEvent_" + key
          const videoKey = "uploadVideoEvent_" + key
          
          // Set keys
          eventKeys.push({
            event: eventKey,
            link: linkKey,
            video: videoKey,
            original: key
          })

          // Event
          params.deploy.tasks[eventKey] = {
            type: "CREATE_EVENT",
            order: 3,
            meta: {
              name: booth.name + " - Video: " + key,
              clientId: user.infernoClientId,
              categories: booth.infernoParentCategory.id === "root" ? [] : [booth.infernoParentCategory],
              description: "",
              eventFeaturesCustomPlayerPageOverrideSnippetId: null,
              secondsToPreRoll: 0,
              secondsToStartTime: 0,
              disablePreroll: true,
              eventFeaturesVoting: true,
              video: {
                bandwidthsString: "",
                captions: [],
                defaultBandwidth: 0,
                duration: 0,
                filePath: "",
                thumbnail: "",
                widthsString: "",
                autoplay: version.videos[key].autoplay
              }
            },
            output: {
              prop: "id",
              outputKey: eventKey,
              targets: [{
                key: linkKey,
                propType: "url"
              }, {
                key: videoKey,
                propType: "url"
              }, {
                key: "createRevision",
                pattern: "{eventId}",
                propType: "replacement",
                propSearchIn: "snippet",
                propSearch: '<div style="display:none" class="inferno_' + key + '">',
                propReplace: '<div style="display:none" class="inferno_' + key + '">{eventId}' 
              }]
            }
          }

          // Event + Cat
          params.deploy.tasks[linkKey] = {
            type: "LINK_EVENT_TO_CATEGORY",
            order: 4,
            urlParams: {
              pattern: "{eventId}",
              value: "" // to be set by CREATE_EVENT task
            },
            meta: isLivexBooth ? "$LIVEX_PARENT_CATEGORY_ARR" : (booth.infernoParentCategory.id === "root" ? [] : [booth.infernoParentCategory.id])
          }

          // Event + Video
          params.deploy.tasks[videoKey] = {
            type: "UPLOAD_EVENT_VIDEO",
            order: 5,
            urlParams: {
              pattern: "{eventId}",
              value: "" // to be set by CREATE_EVENT task
            },
            meta: {
              name: version.videos[key].originalFilename // Filename
            },
            extras: {
              s3Key: version.videos[key].remoteSrc,
              outputKey: videoKey,
              etag: version.videos[key].etag
            },
            output: {
              prop: videoKey,
              outputKey: key
            }
          }

          // Verify encoding task
          params.deploy.postPublishTasks[eventKey] = {
            type: "VERIFY_EVENT_VIDEO",
            videoKey: eventKey,
            lastCheck: false,
            numTries: 0,
            encodeState: 'Queued',
            encodePercent: 0,
            willRetry: true,
            nextCheck: moment().add(2, 'minutes').valueOf()
          }
        })
      }
      else if (version.data && version.data.aroom) {
        // Setup event for aroom 
        // Event
        params.deploy.tasks.createEvent_aroom = {
          type: "CREATE_EVENT",
          order: 3,
          meta: {
            name: booth.name + " - ARoom Holder Event",
            clientId: user.infernoClientId,
            categories: booth.infernoParentCategory.id === "root" ? [] : [booth.infernoParentCategory],
            description: "",
            eventFeaturesCustomPlayerPageOverrideSnippetId: null,
            secondsToPreRoll: 0,
            secondsToStartTime: 0,
            disablePreroll: true,
            eventFeaturesVoting: true,
            video: {
              bandwidthsString: "",
              captions: [],
              defaultBandwidth: 0,
              duration: 0,
              filePath: "",
              thumbnail: "",
              widthsString: "",
              autoplay: false
            }
          },
          output: {
            prop: "id",
            outputKey: "createEvent_aroom",
            targets: [{
              key: "createRevision",
              pattern: "{eventId}",
              propType: "replacement",
              propSearchIn: "snippet",
              propSearch: '<div style="display:none" class="inferno_videoOne">',
              propReplace: '<div style="display:none" class="inferno_videoOne">{eventId}' 
            }]
          }
        }
      }

      // For LiveX booths, for redundancy purposes, we want a static snapshot of this booth
      if (isLivexBooth) {
        params.deploy.postPublishTasks.snapshot = {
          type: "CREATE_SNAPSHOT",
          booth_id: booth._id,
          livexId: booth.livexId
        }
      }

      // Have we already made a deployment? If so, delete some tasks
      if (priorDeployment && priorDeployment.publishMeta) {

        // Set publish meta in our params
        params.deploy.publishMeta = priorDeployment.publishMeta;

        // Category
        if (priorDeployment.publishMeta.categoryId) {
          delete params.deploy.tasks.createCategory
        }

        // Snippet
        if (priorDeployment.publishMeta.codeSnippetId) {
          params.deploy.tasks.createRevision.meta.codeSnippetId = priorDeployment.publishMeta.codeSnippetId
          delete params.deploy.tasks.createSnippet
        }

        // Set categoryId in revision
        if (params.deploy.tasks.createRevision) {
          params.deploy.tasks.createRevision.meta.snippet = params.deploy.tasks.createRevision.meta.snippet.replace('<div style="display:none" class="inferno_categoryId">', '<div style="display:none" class="inferno_categoryId">' + priorDeployment.publishMeta.categoryId)
        }

        // Events
        eventKeys.forEach((keys) => {
          if (priorDeployment.publishMeta[keys.event]) {
            // Delete the create event / link task no matter what
            delete params.deploy.tasks[keys.event]
            delete params.deploy.tasks[keys.link]

            // Set event key in revision
            if (params.deploy.tasks.createRevision) {
              params.deploy.tasks.createRevision.meta.snippet = params.deploy.tasks.createRevision.meta.snippet.replace('<div style="display:none" class="inferno_' + keys.original + '">', '<div style="display:none" class="inferno_' + keys.original + '">' + priorDeployment.publishMeta[keys.event])
            }
          }

          // Only delete the upload task IF the video has not changed
          if (priorDeployment.publishMeta[keys.original] && priorDeployment.publishMeta[keys.original] === version.videos[keys.original].etag) {
            console.log("SKIP video upload for: ", keys.original)
            delete params.deploy.tasks[keys.video] 
          }
          else {
            // Make sure to set eventId url param
            params.deploy.tasks[keys.video].urlParams.value = priorDeployment.publishMeta[keys.event]
          }
        })  

        // ARoom event
        if (priorDeployment.publishMeta.createEvent_aroom && params.deploy.tasks.createEvent_aroom) {
          delete params.deploy.tasks.createEvent_aroom

          // Set event key in revision
          if (params.deploy.tasks.createRevision) {
            params.deploy.tasks.createRevision.meta.snippet = params.deploy.tasks.createRevision.meta.snippet.replace('<div style="display:none" class="inferno_videoOne">', '<div style="display:none" class="inferno_videoOne">' + priorDeployment.publishMeta.createEvent_aroom)
          }
        }
      }

      console.log(params)
      
      // Post submission
      api.methods.patch('booths/deploy/' + versionDeployments[0]._id + '/approve', params).then((res) => {

        // Update booth deployment
        let deployments = [...booth.deployments].map((item) => {
          if (item._id === versionDeployments[0]._id) {
            return {...item, status: params.deploy.status}
          }

          return item
        })

        // Update booth status
        setBooth({...booth, status: params.deploy.status, deployments: deployments})
        dispatch(setSelectedBooth({...booth, status: params.deploy.status, deployments: deployments}))
        
      }, () => {
      //   // todo: handle error
        console.log('error updating booth deployment')
      })
    })
  }

  const handleDeleteBooth = () => {
    if (!window.confirm('Are you sure you would like to delete this booth? This action cannot be reversed')) { return }

    // Check if we need to override the user_id when making this request
    let params = {}
    if (clients && clients.selectedClient) {
      params.user_id_str = clients.selectedClient._id
    }

    const defer = {}
    const promise = new Promise((resolve, reject) => {
      defer.resolve = resolve
      defer.reject = reject
    })

    // First, we need to invalidate the LiveX hash if needed
    if (booth.livexId) {
      api.methods.post('user/invalidate/livex', params).then(() => {
        defer.resolve()
      }, () => {
        defer.reject()
      })
    }
    else {
      defer.resolve()
    }

    Promise.all([promise.promise]).then(() => {

      // Delete & then redirect to booths landing
      api.methods.delete('booths/' + booth._id, params).then((res) => {

        // Update 
        dispatch(setSelectedBooth(false))

        // Redirect
        props.history.push('/booths')

      }, () => {
        // todo: handle error
        console.log('error deleting booth')
      })
    })
  }

  const handleBackButton = () => {
    // For admins, we'll need to go back to the client list, assuming we have a selected client
    if (user.isAdmin && clients.selectedClient) {
      props.history.push('/clients/' + clients.selectedClient._id)
      return
    }

    // For clients, we'll go back to their booth listing
    props.history.push('/booths')
  }

  const createNewVersion = () => {

    dispatch(extendAuthTimer(true))
    
    if (newVersion.name.length > 0) {
      let params = {
        _id: md5(newVersion.name + moment().valueOf().toString()),
        created: moment().valueOf(),
        name: newVersion.name
      }

      // Trigger change
      let curVersions = booth.versions ? booth.versions : []
      handleChange({name: 'versions', value: [params, ...curVersions], autosave: true})
    }

    // Close modal
    closeModal()
  }

  const handleRetryEncode = (versionId, videoKey) => {
    const version = booth.versions.find((item) => item._id === versionId)
    const ogKey = videoKey.replace("createEvent_", "")
    const priorDeployments = booth.deployments.filter((item) => item.publishMeta && Object.keys(item.publishMeta).length > 0 && item.infernoParentCategory && item.infernoParentCategory.categoryId === booth.infernoParentCategory.id)
    const prior = priorDeployments.length > 0 ? priorDeployments[0] : false

    if (!version || !prior || !version.videos || !version.videos[ogKey] || !prior.publishMeta[videoKey]) { return }

    // Fetch video
    const vid = version.videos[ogKey]
    const params = {
      boothId: booth._id,
      deploy: {
        created: moment().valueOf(),
        publishStarted: moment().valueOf(),
        publishEnded: null,
        status: "Publishing",
        tasks: {},
        postPublishTasks: {}
      }
    }
    
    // Upload
    params.deploy.tasks[videoKey] = {
      type: "UPLOAD_EVENT_VIDEO",
      order: 0,
      urlParams: {
        pattern: "{eventId}",
        value: prior.publishMeta[videoKey]
      },
      meta: {
        name: vid.originalFilename 
      },
      extras: {
        s3Key: vid.remoteSrc,
        outputKey: videoKey,
        etag: vid.etag
      }
    }

    // Verify encoding task
    params.deploy.postPublishTasks[videoKey] = {
      type: "VERIFY_EVENT_VIDEO",
      videoKey: videoKey,
      lastCheck: false,
      numTries: 0,
      encodeState: 'Queued',
      encodePercent: 0,
      willRetry: true,
      nextCheck: moment().add(5, 'minutes').valueOf()
    }

    // Post submission
    api.methods.patch('booths/deploy/' + prior._id + '/approve', params).then((res) => {

      // Update booth deployment
      let deployments = [...booth.deployments].map((item) => {
        if (item._id === prior._id) {
          return {...item, status: params.deploy.status}
        }

        return item
      })

      // Update booth status
      setBooth({...booth, status: params.deploy.status, deployments: deployments})
      dispatch(setSelectedBooth({...booth, status: params.deploy.status, deployments: deployments}))
      
    }, () => {
    //   // todo: handle error
      console.log('error updating booth deployment')
    })
  }

  const closeModal = () => {
    setShowCreateModal(false)
    setNewVersion({name: ''})
  }

  const createModalInput = (
    <div className="bmt-form-page">
      <div className="form-group">
        <h5>Name</h5>
        <input placeholder="Name" name="name" value={ newVersion.name } onChange={ (e) => setNewVersion({...newVersion, name: e.target.value})} />
      </div>
      <br/>
      <div className="bmt-button-group modal-group">
        <button className="cancel-btn" onClick={ () => setShowCreateModal(false) }>Cancel</button>
        <button className="primary-btn" onClick={ createNewVersion } disabled={ !newVersion.name }>Create</button>
      </div>
    </div>
  )

  const createModal = showCreateModal ? <Modal title="New Draft" children={ createModalInput } close={ () => setShowCreateModal(false) } /> : null

  const handleDuplicatePublished = () => {
    
    const publishedDeployments = booth.deployments.filter((item) => item.status === "Published")
    const publishedDeployment = publishedDeployments.length > 0 ? publishedDeployments[0] : false

    if (!publishedDeployment) { return }

    const version = booth.versions.find((item) => item._id === publishedDeployment.versionId)
    const now = moment()

    // Create a copy of the current version
    const instanceCopy = JSON.parse(JSON.stringify(version))

    // Update id + name
    instanceCopy._id = md5(now.valueOf().toString() + instanceCopy._id)
    instanceCopy.name = instanceCopy.name += " (Copy)"

    // Clear sharing, update dates
    delete instanceCopy.sharing
    instanceCopy.created = now.valueOf()
    instanceCopy.lastUpdated = now.valueOf()

    // Set change
    handleChange({name: 'versions', value: [instanceCopy, ...booth.versions], autosave: true})
  }

  const handleChange = (params) => {
    const { name, value, autosave } = params

    if (Array.isArray(name) && Array.isArray(value)) {
      let updates = {}

      for (let i = 0; i < name.length; i++) {
        updates[name[i]] = value[i]
      }

      setBooth({...booth, ...updates})
    }
    else {
      setBooth({...booth, [name]: value})
    }

    // Should we autosave?
    setAutosave(autosave)
  }

  useEffect(() => {
    let mounted = true
    if (mounted && autosave) {
      setAutosave(false)
      saveChanges()
    }

    return () => mounted = false

  }, [booth, autosave, saveChanges])

  const editVersion = (boothId, versionId) => {
    // Update url
    const url = `/booths/${boothId}/${versionId}`
    props.history.push(url)
  }

  const noBooth = (
    <div className="bmt-main-view"><h2>Sorry, we could not locate that booth</h2></div>
  )

  if (!booth) { return noBooth }

  const fetchCategories = () => {
    let override = ""

    // Optionally pass in user_id if we're an admin
    if (user.isAdmin && clients && clients.selectedClient) {
      override = "/" + clients.selectedClient._id
    }

    // Make api call to fetch categories
    api.methods.get('user/categories' + override).then((res) => {

      // Set categories
      dispatch(setCategories({items: res.data.categories, lastFetched: moment().valueOf()}))

    }, () => {
      // todo: handle error
      console.log('error updating booth')
    })
  }

  // Set creds
  let hasCreds = user.settings && user.settings.infernoAuth && user.settings.infernoAuth.accessToken ? true : false 
  if (user.isAdmin && clients && clients.selectedClient) {
    hasCreds = clients.selectedClient.settings && clients.selectedClient.settings.infernoAuth && clients.selectedClient.settings.infernoAuth.accessToken
  }

  const handleBookingUpdate = (params) => {
    const updated = {...booth, periodic: params}
    setBooth(updated)
    dispatch(setSelectedBooth(updated))
  }

  const view = (
    <div className="bmt-details">
      <GeneralForm updateBooking={ handleBookingUpdate } blueprints={ blueprints } retryEncode={ handleRetryEncode } handleDuplicatePublished={ handleDuplicatePublished } refreshStatus={ handleRefreshStatus } types={ availableBoothTypes } fetchCategories={ fetchCategories } hasInfernoCreds={ hasCreds } booth={ booth } edit={ editVersion } publish={ handlePublish } change={ handleChange } cancel={ cancelChanges } save={ saveChanges } />
    </div>
  )
  
  return (
    <div className="bmt-main-view">
      { <AdminPanel /> }
      <Header />
      { createModal }
      { view }
    </div>
  )
}

export default BoothDetail