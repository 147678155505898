class SessionService {

  get() {
    if (window.localStorage.bmtUser) {
      return JSON.parse(window.localStorage.bmtUser)
    }

    return false
  }
  
  create(object) {
    window.localStorage.bmtUser = JSON.stringify(object)
  }

  updateSetting(key, val) {
    const user = this.get()
    if (user) {
      if (!user.settings) {
        user.settings = {}
      }

      user.settings[key] = val
      this.create(user)
    }
  }

  update(token) {
    const user = this.get()
    user.token = token
    this.create(user)
  }

  destroy() {
    delete window.localStorage.bmtUser
  }
}

export const session = new SessionService()
