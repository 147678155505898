import { useState } from "react"
import md5 from "md5"
import API from "../../services/api"
import MiniLoader from "../mini-loader/miniLoader"

const RegisterFlow = (props) => {

  const api = new API()
  const [input, setInput] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    code: ''
  })

  const [page, setPage] = useState(props.flow)
  const [loading, setLoading] = useState(false)
  const [registerError, setRegisterError] = useState(false)
  const [errors, setErrors] = useState({})

  const handleRegister = (e) => {
    e.preventDefault()
    setRegisterError(false)
    const curErrors = validate()
    setErrors(curErrors)

    if (Object.keys(curErrors).length === 0) {
      setLoading(true)

      const params = {
        firstName: input.firstName.trim(),
        lastName: input.lastName.trim(),
        email: input.email.toLowerCase().trim(),
        password: md5(input.password)
      }

      api.methods.post('auth/register', params).then((res) => {

        setLoading(false)
        setPage('code')

        
      }, (e) => {
        setLoading(false)
        setRegisterError((e.response.status === 409 ? "Oops! It looks like this email already exists. Did you mean to login?" : "Oops! There was an error creating your account. Please try again"))
      })
    }
  }

  const handleActivate = (e) => {
    e.preventDefault()
    setRegisterError(false)
    const curErrors = validate()
    setErrors(curErrors)

    if (Object.keys(curErrors).length === 0) {
      setLoading(true)

      const params = {
        code: input.code.trim(),
        email: input.email.toLowerCase().trim()
      }

      api.methods.post('auth/activate', params).then((res) => {

        setLoading(false)

        // Log user in
        props.success(res.data)
        
      }, (e) => {
        setLoading(false)
        setRegisterError("Oops! There was an error activating your account. Please try again")
      })
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInput({...input, [name]: value})
  }

  const validate = () => {
    let errors = {}
    const regex = /^[A-Za-z0-9]+(.|_)+[A-Za-z0-9]+@+nextechar.com$/i

    if (!input.email) {
      errors.email = 'Please enter an email'
    }
    else if (!regex.test(input.email)) {
      errors.email = 'Please enter a valid (@nextechar.com) email'
    }

    if (page === "initial") {

      if (!input.password || input.password.length < 6) {
        errors.password = 'Please enter a password of at least 6 characters'
      }

      if (!input.firstName || input.firstName.length < 3) {
        errors.firstName = 'Please enter a first name of at least 3 characters'
      }

      if (!input.lastName || input.lastName.length < 3) {
        errors.lastName = 'Please enter a last name of at least 3 characters'
      }
    }
    else {
      if (!input.code || input.code.length < 3) {
        errors.code = 'Please enter an activation code of at least 3 characters';
      }
    }

    return errors
  }

  return (
    <div>
      { page === "initial" ?
        <form onSubmit={(e) => handleRegister(e)}>
          <h3>Register for curator</h3>
          <h4>First Name</h4>
          <input type="text" name="firstName" placeholder="First Name" value={ input.firstName } onChange={ handleChange } className={ errors.firstName ? 'error': ''} />
          { errors.firstName && <div className="error">{ errors.firstName }</div> }
          <h4>Last Name</h4>
          <input type="text" name="lastName" placeholder="Last Name" value={ input.lastName } onChange={ handleChange } className={ errors.lastName ? 'error': ''} />
          { errors.lastName && <div className="error">{ errors.lastName }</div> }
          <h4>Email Address</h4>
          <input type="email" name="email" placeholder="Email" value={ input.email } onChange={ handleChange } className={ errors.email ? 'error': '' } />
          { errors.email && <div className="error">{ errors.email }</div> }
          <h4>Password</h4>
          <input type="password" name="password" placeholder="Password" value={ input.password } onChange={ handleChange } className={ errors.password ? 'error': '' } />
          { errors.password && <div className="error">{ errors.password }</div> }
          { registerError && <div className="error">{ registerError }</div> }
          { loading && <MiniLoader copy="Creating account..." /> }
          <button className="primary-btn" disabled={loading || !input.email || !input.password || !input.firstName || !input.lastName}><span className="icon-log-in"></span> Register</button>
        </form>
        :
        <form onSubmit={(e) => handleActivate(e)}>
          <h3>Activate your account</h3>
          <h4>Email Address</h4>
          <input type="email" name="email" placeholder="Email" value={ input.email } onChange={ handleChange } className={ errors.email ? 'error': ''} />
          { errors.email && <div className="error">{ errors.email }</div> }
          <h4>Activation Code</h4>
          <input type="text" name="code" placeholder="Code" value={ input.code } onChange={ handleChange } className={ errors.code ? 'error': ''} />
          { errors.lastName && <div className="error">{ errors.lastName }</div> }
          { registerError && <div className="error">{ registerError }</div> }
          { loading && <MiniLoader copy="Activating account..." /> }
          <button className="primary-btn" disabled={ loading || !input.code }>Activate</button>
        </form>
      }
      <br /><div className="margin-top"><h5>Already have an account? <button onClick={ props.switchToLogin } className="text-btn">Login</button></h5></div>
    </div>
  )
}

export default RegisterFlow