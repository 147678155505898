import InputHandler from "../../input-handlers/inputHandler"
import { useState, useEffect } from "react"
import moment from "moment"
import md5 from "md5"

const SlidesForm = (props) => {

  const [items, setItems] = useState(false)

  useEffect(() => {
    if (props.version.instance.slides || props.version.template.slides) {
      let required = {}
      let curItems = props.version.instance.slides && Object.keys(props.version.instance.slides).length > 0 ? props.version.instance.slides : {}
      if (props.version.template.slides && Object.keys(props.version.template.slides).length > 0) {
        Object.keys(props.version.template.slides).forEach((resourceKey) => {
          if (props.version.template.slides[resourceKey].required) {
            required[resourceKey] = props.version.template.slides[resourceKey]
          }
        })
      }

      let extendedItems = {...curItems}
      Object.keys(curItems).forEach((curKey) => {
        if (props.version.template.slides && props.version.template.slides[curKey]) {
          let curItemCopy = {...curItems[curKey]}
          curItemCopy.required = props.version.template.slides[curKey].required
          curItemCopy.label = props.version.template.slides[curKey].label
          extendedItems[curKey] = curItemCopy
        }
        else {
          extendedItems[curKey] = curItems[curKey]
        }
      })

      setItems({...required, ...extendedItems})
    }
    else {
      setItems({})
    }

  }, [props.version])

  const handleAddSlide = () => {
    // Create a new item & add it to the list
    const newKey = md5(moment().valueOf().toString())
    setItems({...items, [newKey]: {key: newKey, value: false}})
  }

  const allowMoreSlides = !(props.version.template.overrides && props.version.template.overrides.disableMoreSlides)  

  return (  
    <div className="bmt-form-page bmt-wrapper">
      <h2>Slides</h2>
      { Object.keys(items).length < 1 && <h5 className="no-items">You have not added any slides yet.</h5> }
      { Object.keys(items).map((itemKey, index) => {
        const label = "Slide #" + (index+1) + " - iframe content"
        const fullItem = {...items[itemKey], label: label, key: itemKey, instanceKey: 'slides', subKey: "value", type: 'iframe'}

        return (
          <InputHandler overrides={ props.version.template.overrides } readOnly={ props.readOnly } key={ itemKey } input={ fullItem } value={ props.version.instance && props.version.instance.slides && props.version.instance.slides[itemKey] ? props.version.instance.slides[itemKey].value : null } change={ props.change } />
        )
      }) }
      { allowMoreSlides && <button className="text-btn" onClick={ handleAddSlide }>Add Slide</button> }
    </div>
  )
}

export default SlidesForm