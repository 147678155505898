import Header from "../header/header"
import "./dashboard.scss"
import { useEffect, useState } from "react"
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts'
import { useDispatch } from "react-redux"
import { extendAuthTimer } from "../../redux/reducers/auth"
import AdminPanel from "../nav/adminPanel"
import API from "../../services/api"
import { setHeaderTitle, clearHeaderButtons } from "../../redux/reducers/ui"

const Dashboard = (props) => {
    
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const api = new API()

  useEffect(() => {
    let mounted = true
    props.loader.start()
    console.log('dashboard: mounted')

    // Set header
    dispatch(setHeaderTitle("Dashboard"))
    dispatch(clearHeaderButtons())

    api.methods.get('dashboard').then((res) => {

      if (mounted) {
        setData({pie: res.data.pie, activity: res.data.activity, clientActivity: res.data.clientActivity ? res.data.clientActivity : null })
        props.loader.stop()
        dispatch(extendAuthTimer(true))
      }

    })

    return () => mounted = false
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="bmt-main-view">
      { <AdminPanel /> }
      <Header />
      <div className="dashboard-container">
        <div className="dashboard-vis">
          <h4><span>Status of Exhibits</span></h4>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <defs>
                <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#612eff" />
                  <stop offset="95%" stopColor="#cab9ff" />
                </linearGradient>
                <linearGradient id="blue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FBD800" />
                  <stop offset="95%" stopColor="#fef6c6" />
                </linearGradient>
                <linearGradient id="yellow" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#46D4FF" />
                  <stop offset="95%" stopColor="#468aff" />
                </linearGradient>
                <linearGradient id="red" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F77600" />
                  <stop offset="95%" stopColor="#ff9337" />
                </linearGradient>
              </defs>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data.pie}
                cx="50%"
                cy="50%"
                innerRadius={40}
                outerRadius={80}
                fill="#8884d8"
                label></Pie>
              <Tooltip 
                contentStyle={ {background: "#222", border: "none", borderRadius: "8px", padding: "1vmin", boxShadow: "2px 4px 12px rgba(1,1,1,.2)"} } 
                itemStyle={ {color: "#fff", margin: "0", padding: "0", fontSize: "1.5vmin"} }>
              </Tooltip>
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="dashboard-vis">
          <h4><span>Exhibit Activity this week</span></h4>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data.activity}
              margin={{
                top: 30,
                right: 30,
                left: 0,
                bottom: 0,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="created" stroke="#2aa274" />
              <Line type="monotone" dataKey="updated" stroke="#3200c8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        { data.clientActivity && 
          <div className="dashboard-vis">
            <h4><span>Client Activity this week</span></h4>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={data.clientActivity}
                margin={{
                  top: 30,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="created" stroke="#f4ab32" />
                <Line type="monotone" dataKey="loggedIn" stroke="#ec7276" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        }
      </div>
    </div>
  )
}

export default Dashboard