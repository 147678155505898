import "./presentationContent.scss"

const PresentationContent = (props) => {

  if (!props.presentation || !props.presentation.name) {
    return null
  }

  const handleSlideClick = (slideId) => {
    props.onSlideChange(slideId)
  }

  return (
    <div className="presentation-content">
      <h3>{ props.presentation.name }</h3>
      <button className="negative-btn" onClick={ props.onEnd }>End Presentation</button>
      { props.presentation.slides &&
        (
          <div className="presentation-content-slides">
            { 
              Object.keys(props.presentation.slides).map((key) => {
                return (
                  <div onClick={ () => handleSlideClick(key)} key={ key } className={ props.presentation.activeSlide === key ? "presentation-content-slide active" : "presentation-content-slide" }>
                    <div className="slide-module" dangerouslySetInnerHTML={ {__html: props.presentation.slides[key].value} }></div>
                  </div>
                )
              })
            }
          </div>
        )
      }
    </div>
  )
}

export default PresentationContent