import { useState } from "react"
import Modal from "../modal/modal"

const ReportDetailUserModal = (props) => {

  const [selectedTab, setSelectedTab] = useState("views")
  const [displayItems, setDisplayItems] = useState(props.user.viewItems)

  const selectTab = (view) => {
    setSelectedTab(view)
    setDisplayItems(view === "views" ? props.user.viewItems : props.user.contentItems)
  }

  const content = (
    <div className="report-detail-user-view">
      <div className="bmt-details">
        <ul className="bmt-details-top-bar">
          <li onClick={ () => selectTab("views") } className={ selectedTab === "views" ? "selected" : "" }>Page Views</li>
          <li onClick={ () => selectTab("interactions") } className={ selectedTab === "interactions" ? "selected" : "" }>Interactions</li>
        </ul>
      </div>
      <ul className="bmt-list-view">
        { selectedTab === "views" && displayItems.length > 0 && <li><div className="flex">Page</div><div className="flex"># Views</div></li> }
        { selectedTab === "interactions" && displayItems.length > 0 && <li><div className="flex">Page</div><div className="flex">Type</div><div className="flex">Item</div><div className="flex"># Interactions</div></li> }
        { 
          displayItems.map((item, index) => {
            return (
              <li className="no-action" key={ index }>
                <div className="flex">{ item.page }</div>
                { selectedTab === "interactions" && <div className="flex">{ item.type }</div> }
                { selectedTab === "interactions" && <div className="flex">{ item.item }</div> }
                <div className="flex">{ item.count }</div>
              </li>
            )
          })
        }
        { displayItems.length > 0 &&
          (
            <li className="no-action sum-row">
              <div className="flex">Total</div>
              { selectedTab === "interactions" && <div className="flex"></div> }
              { selectedTab === "interactions" && <div className="flex"></div> }
              <div className="flex">{ selectedTab === "interactions" ? props.user.contentItemsCount : props.user.viewItemsCount }</div>
          </li>
          )
        }
      </ul>
      { displayItems.length < 1 && <h4 className="no-items">No { selectedTab} found for <strong>{ props.user.meta.name }</strong></h4> }
    </div>
  )

  return (
    <Modal className="static-height" title={ props.user.meta.name } close={ props.close } children={ content } />
  )
}

export default ReportDetailUserModal