import "./modal.scss"

const Modal = (props) => {
  const child = props.children ? props.children : null
  const externalClass = props.className ? props.className : ""

  return (
    <div className={ `bmt-overlay ${externalClass}` } onClick={ props.clickOutside && props.close } style={ {zIndex: props.z ? props.z : 8000} }>
      <div className={ props.className ? `bmt-overlay-content ${props.className}` : "bmt-overlay-content" } onClick={(e) => e.stopPropagation()}>
        <div className="bmt-overlay-header">
          <h1>{props.title}</h1>
          <button className="close-btn" onClick={props.close}><span className="icon-x"></span></button>
        </div>
        {child}
      </div>
    </div>
  )
}

export default Modal