import Cropper from "react-easy-crop"
import { useState, useEffect, useRef } from "react"
import moment from "moment"
import MiniLoader from "../mini-loader/miniLoader"
import Modal from "../modal/modal"
import Validator from "./validator"

const ImageHandler = (props) => {

  const [image, setImage] = useState({src: '', name: '', clickZoom: false, clickLink: false})
  // const [pristine, setPristine] = useState({src: '', name: ''})
  const [crop, setCrop] = useState({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [didChange, setDidChange] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showPresets, setShowPresets] = useState(false)
  const [workingImage, setWorkingImage] = useState(false)
  const [workingLink, setWorkingLink] = useState('')
  const debounce = useRef(null)
  const urlDebounce = useRef(null)
  
  // Use Effect used to kill debounce timer if it is still running
  useEffect(() => {

    // Check if we have a value
    if (props.value) {

      if (props.value.remoteSrc && (!image.src || image.loaded !== props.value.loaded)) {  
        // Reset zoom/crop
        setCrop({x: 0, y:0})
        setZoom(1)
        setLoading(true)

        console.log(props.input.key, '-> fetch remote src & set local image')

        // Convert to b64
        convertRemoteToBase64(props.value.remoteSrc + "?v=" + moment().valueOf()).then((base64) => {
          const anImage = {
            src: base64, 
            name: props.value.originalFilename, 
            loaded: moment().valueOf(), 
            link: props.value.link ? props.value.link : '', 
            clickLink: props.value.clickLink !== undefined ? props.value.clickLink : false, 
            clickZoom: props.value.clickZoom !== undefined ? props.value.clickZoom : false
          }

          setWorkingImage(base64)
          setImage(anImage)
          setWorkingLink(props.value.link ? props.value.link : '')
          // setPristine(JSON.parse(JSON.stringify(anImage)))
          setLoading(false)
        }, () => {
          console.log('error fetching remote image')
          // todo: handle this
        })
      }
      else if (props.value.delete || (!props.value.src && !props.value.remoteSrc)) {
        console.log(props.input.key, '-> remove local image')
        setCrop({x: 0, y:0})
        setZoom(1)
        setImage({})
        setLoading(false)
      }
      else if (props.value.src && !image.src) {
        console.log(props.input.key, '-> set local image from local src')
        const anImage = {
          src: props.value.src, 
          name: props.value.originalFilename, 
          loaded: moment().valueOf(), 
          link: props.value.link ? props.value.link : '', 
          clickLink: props.value.clickLink !== undefined ? props.value.clickLink : false, 
          clickZoom: props.value.clickZoom !== undefined ? props.value.clickZoom : false
        }

        setWorkingImage(props.value.src)
        setImage(anImage)
        setWorkingLink(props.value.link ? props.value.link : '')
        setCrop({x: 0, y:0})
        setZoom(1)
        setLoading(false)
      }
    }
    else {
      console.log(props.input.key, '-> unset local image')
      setCrop({x: 0, y:0})
      setZoom(1)
      setImage({})
      setLoading(false)
    }

    return () => {
      if (debounce.current) {
        window.clearTimeout(debounce.current)
        debounce.current = null
      }

      if (urlDebounce.current) {
        window.clearTimeout(urlDebounce.current)
        urlDebounce.current = null
      }
    }
  }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const convertRemoteToBase64= (src) => {
    return new Promise((resolve, reject) => {
      fetch(src).then((res) => res.blob()).then((blob) => {
        toBase64(blob).then((res) => resolve(res), () => reject())
      }, () => {
        reject()
      })
    })
  }

  const handleNewInput = (input) => {
    // Check size
    if (input.size > (1024*1024*2)) { 
      window.alert("Please select an image smaller than 2mb in size.")
      return
    }

    // Reset zoom/crop
    setCrop({x: 0, y:0})
    setZoom(1)

    // Set image
    toBase64(input).then((imageData) => {
      setImage({src: imageData, name: input.name, loaded: moment().valueOf()})
      setDidChange(true)
    }, () => {
      console.log('error setting image')
      // todo: handle
    })
  }

  const toBase64 = (input) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        resolve(e.target.result)
      }
      fileReader.readAsDataURL(input)
    })
  }

  const handleCrop = (newCrop) => {
    if (newCrop.x === crop.x && newCrop.y === crop.y) {
      return
    }

    setDidChange(true)
    setCrop(newCrop)
  }

  const handleZoom = (newZoom) => {
    if (newZoom === zoom) {
      return
    }

    setDidChange(true)
    setZoom(newZoom)
  }

  const handleComplete = (croppedArea, croppedAreaPixels) => {

    if (!didChange) {
      return
    }

    if (debounce.current) {
      window.clearTimeout(debounce.current)
      debounce.current = null
    }

    debounce.current = window.setTimeout(() => handleDebounce(croppedAreaPixels), 500)
  }

  const handleDebounce = (cropped) => {
    setDidChange(false)
    createCroppedImage(cropped).then((blob) => {
      persistMedia(blob)
    }).catch((e) => {
      console.log('error cropping image')
      console.log(e)
    })
  }

  const persistMedia = (blob) => {
    const params = {
      link: image.link ? image.link : '',
      clickLink: image.clickLink !== undefined ? image.clickLink : false,
      clickZoom: image.clickZoom !== undefined ? image.clickZoom : false
    }

    const formData = new FormData()
    const extension = props.input.requiresPNG ? ".png" : ".jpg"
    const type = props.input.requiresPNG ? "image/png" : "image/jpeg"

    formData.set('file', new File([blob], props.input.key + extension, {type: type}))
    formData.set('key', props.input.key)
    formData.set('originalFilename', image.name)
    formData.set("link", params.link)
    formData.set("clickLink", params.clickLink)
    formData.set("clickZoom", params.clickZoom)
    setImage({...image, toBePersisted: formData, params})

    toBase64(blob).then((base64) => {
      setWorkingImage(base64)
      props.change(props.input.instanceKey, props.input.key, {...params, src: base64, toBePersisted: formData, loaded: moment().valueOf()})
    })
  }

  const createNativeImage = (url) => {
    return new Promise((resolve, reject) => {
      const anImage = new Image()
      anImage.addEventListener('load', () => resolve(anImage))
      anImage.addEventListener('error', (err) => reject(err))
      anImage.setAttribute('crossOrigin', 'anonymous')
      anImage.src = url
    })
  }

  const createCroppedImage = (cropped) => {

    let defer = {}
    let promise = new Promise((resolve, reject) => {
      defer.resolve = resolve
      defer.reject = reject
    })

    createNativeImage(image.src).then((nativeImage) => {

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d');

      // Create some breathing room on the canvas
      const maxAttr = Math.max(nativeImage.width, nativeImage.height)
      const breather = 2 * ((maxAttr / 2) * Math.sqrt(2))
      
      // Set
      canvas.width = breather
      canvas.height = breather

      // Draw white bg
      if (!props.input.requiresPNG) {
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, 0, canvas.width, canvas.height)
      }
      
      // Draw initial image
      ctx.drawImage(nativeImage, (breather * 0.5) - (nativeImage.width * 0.5), (breather * 0.5) - (nativeImage.height * 0.5))

      // Get data & redraw cropped output
      const data = ctx.getImageData(0, 0, breather, breather)
      canvas.width = cropped.width
      canvas.height = cropped.height

      // Draw white bg
      if (!props.input.requiresPNG) {
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, 0, canvas.width, canvas.height)
      }

      // Put image
      ctx.putImageData(data, Math.round(0 - breather / 2 + nativeImage.width * 0.5 - cropped.x), Math.round(0 - breather / 2 + nativeImage.height * 0.5 - cropped.y))

      // Output to blob
      const type = props.input.requiresPNG ? "image/png" : "image/jpeg"
      canvas.toBlob(blob => {
        defer.resolve(blob)
      }, type, 0.65)

    }).catch((err) => {
      defer.reject(err)
    })

    return promise
  }

  const setPreset = (preset) => {
    convertRemoteToBase64(preset.src).then((base64) => {
      setImage({src: base64, name: preset.label, loaded: moment().valueOf()})
      // setPristine({src: base64, name: preset.label, loaded: moment().valueOf()})
      setDidChange(true)
    }, () => {
      console.log('error fetching preset image')
    })

    setShowPresets(false)
  }

  const toggleClickLink = () => {
    setWorkingLink('')
    const newVal = !image.clickLink
    setImage({...image, clickLink: newVal, clickZoom: false})
    const formData = image.toBePersisted ? image.toBePersisted : new FormData()
    formData.set("link", '')
    formData.set("clickLink", newVal)
    formData.set("clickZoom", false)
    formData.set("key", props.input.key)
    props.change(props.input.instanceKey, props.input.key, {...image, toBePersisted: formData, clickZoom: false, clickLink: newVal, link: '', loaded: moment().valueOf(), src: workingImage})
  }

  const toggleClickZoom = () => {
    setWorkingLink('')
    const newVal = !image.clickZoom
    setImage({...image, clickLink: false, link: '', clickZoom: newVal})

    const formData = image.toBePersisted ? image.toBePersisted : new FormData()
    formData.set("link", '')
    formData.set("clickLink", false)
    formData.set("clickZoom", newVal)
    formData.set("key", props.input.key)
    props.change(props.input.instanceKey, props.input.key, {...image, toBePersisted: formData, clickZoom: newVal, clickLink: false, link: '', loaded: moment().valueOf(), src: workingImage})
  }

  const handleLinkChange = (val) => {

    setWorkingLink(val)

    // Once the link is valid, we'll propagate a change
    if (Validator.validate(val, "url")) {
      if (urlDebounce.current) {
        window.clearTimeout(urlDebounce.current)
        urlDebounce.current = null
      }

      urlDebounce.current = window.setTimeout(() => {

        setImage({...image, clickLink: true, link: val, clickZoom: false})
        const formData = image.toBePersisted ? image.toBePersisted : new FormData()
        formData.set("clickZoom", false)
        formData.set("link", val)
        formData.set("clickLink", true)
        formData.set("key", props.input.key)
        props.change(props.input.instanceKey, props.input.key, {...image, toBePersisted: formData, clickZoom: false, link: val, clickLink: true, loaded: moment().valueOf(), src: workingImage})
      
      }, 750)
    }
  }

  const handleRemoveImage = () => {
    setImage({name: '', src: '', clickZoom: false, clickLink: false})
    props.change(props.input.instanceKey, props.input.key, {delete: true})
  }

  const presetContent = showPresets && props.presets ? props.presets.map((item) => <div key={ item.key } className="bmt-preset-picker"><button className="outline-btn" onClick={ () => setPreset(item) }>{ item.label }</button> <a href={ item.src } target="_blank" rel="noreferrer"><span className="icon-external-link"></span> View</a></div>) : null
  const presetModal = showPresets && props.presets ? <Modal title="Set preset image" children={ presetContent } close={ () => setShowPresets(false) } /> : null
  const loader = loading ? <div className="bmt-image-input disabled"><MiniLoader copy="Loading..." /></div> : null

  return (
    <div className="form-group non-flex">
      { presetModal }
      { !props.hideLabel && <h3>{ props.input.label }</h3> }
      { !props.hideLabel && props.input.sublabel && <h6>{ props.input.sublabel }</h6> }
      { props.value && props.value.locked && <h6 className="primary-color"><span className="icon-layers"></span> Automatically set by blueprint</h6> }
      { props.presets && !image.src && <button className="secondary-btn" onClick={ () => setShowPresets(true) }>Select from preset images</button> }
      { loading ? loader : 
      <div className={ props.value && props.value.locked ? "bmt-media-input locked" : image.src ? "bmt-media-input active" : "bmt-media-input" }>
        {
          image.src && props.input && props.input.width && props.input.height
            ? 
            <Cropper image={ image.src } aspect={ props.input.width / props.input.height } crop={ crop } maxZoom="6" minZoom="0.5" restrictPosition={ false } onCropChange={ handleCrop } zoom={ zoom } zoomWithScroll={ false } onCropComplete={ handleComplete } /> 
            :
            <div className="bmt-file-picker">
              <label htmlFor={ `image-${props.input.key}` }><span><i className="icon-image"></i> Select an image</span></label>
              <input type="file" id={ `image-${props.input.key}` } name={ `image-${props.input.key}` } onChange={ (e) => handleNewInput(e.target.files[0]) } accept=".jpg,.jpeg,.png" />
            </div>
        }
      </div>
      }
      <input style={{visibility: !image.src || (props.value && props.value.locked) ? 'hidden' : 'visible'}} className="bmt-slider" type="range" min="0.5" max="6" step="0.25" value={ zoom } onChange={ (e) => handleZoom(e.target.value) } />
      { image.src && props.input.enableLinks && 
        <div className="form-group non-flex">
          <div className={ image.clickLink ? "bmt-check-input selected" : "bmt-check-input" } onClick={ toggleClickLink }>On click, link to url</div><br/>
          <div className={ image.clickZoom ? "bmt-check-input selected" : "bmt-check-input" } onClick={ toggleClickZoom }>On click, zoom in</div>
          { image.clickLink && <div className="prefix-url"><span className="prefix-url-hint">https://</span><input type="text" placeholder="URL to link to" value={ workingLink } onChange={ (e) => handleLinkChange(e.target.value) } /></div> }
        </div> 
      }
      { image.src && !(props.value && props.value.locked) && <div className="form-group non-flex"><button onClick={ handleRemoveImage } className="text-btn red">Remove</button></div> }
    </div>
  )
}

export default ImageHandler