import Modal from "../modal/modal"
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts'
import { useEffect, useState } from "react"
import moment from "moment"
import { CSVLink } from "react-csv"
import { toPng } from "html-to-image"

const ReportDetailModal = (props) => {
  
  const [events, setEvents] = useState(null)
  const [resourceArr, setResourceArr] = useState(null)
  const [contactArr, setContactArr] = useState(null)
  const [allEventArr, setAllEventArr] = useState(null)
  const [usePrintView, setUsePrintView] = useState(false)

  // Update name key for events & filter empty ones
  useEffect(() => {
    const arr = [...props.item.events].filter((item) => item.value > 0).map((item) => {
      return {...item, name: item.eventType, fill: item.heatFill}
    })

    setEvents(arr)

    // Set all events arr
    if (props.item.events && props.item.events.length > 0) {
      let allEventsArr = []
      props.item.events.sort((a, b) => a.eventType > b.eventType ? 1 : -1).forEach((item) => {
        allEventsArr.push({
          event: item.eventType,
          numClicks: item.value
        })
      })

      setAllEventArr(allEventsArr)
    }

    // Set resource arr
    if (props.item.resourceDownloads && Object.keys(props.item.resourceDownloads).length > 0) {
      let resourceArr = []
      Object.keys(props.item.resourceDownloads).forEach((key) => {
        resourceArr.push({
          resource: props.item.resourceDownloads[key].resourceName ? props.item.resourceDownloads[key].resourceName : key,
          views: props.item.resourceDownloads[key].count
        })
      })

      setResourceArr(resourceArr)
    }

    // Set contact arr
    if (props.item.contactShares && props.item.contactShares.length > 0) {
      let contactArr = []
      props.item.contactShares.forEach((item) => {
        if (item.User) {
          const share = item.User
          contactArr.push({
            name: share.First_Name ? `${share.First_Name} ${share.Last_Name}`: "n/a",
            email: share.Email ? share.Email : "n/a",
            organization: share.Organization ? share.Organization : "n/a",
            title: share.Title ? share.Title : "n/a"
          })
        }
      })

      setContactArr(contactArr)
    }

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const exportToImage = () => {
    setUsePrintView(true)
    setTimeout(() => {
      const el = document.getElementById("report-detail")
      toPng(el, {quality: 0.95}).then((dataUrl) => {
        var a = document.createElement("a")
        a.href = dataUrl
        a.setAttribute("download", `${(props.item.name ? props.item.name : "Unnamed").replace(/[\W_]+/g,"-")}_Export_${ moment().format("MM/DD/YYYY hh:mm a") }.png`)
        a.click()
        setUsePrintView(false)
      })
    })
  }

  const content = (
    events ? 
      <>
        { usePrintView && <div className="dashboard-container-capture-loader"><h2>Capturing...</h2></div> }
        <div className={ usePrintView ? "dashboard-container printable" : "dashboard-container no-padding" } id="report-detail">
          <div className="dashboard-vis full">
            <button onClick={ exportToImage } className="export-btn primary-color"><span className="icon-image"></span> <strong>Export as Image</strong></button>
            { usePrintView && <h2>{ props.item.name ? props.item.name : "Unnamed" }</h2> }
            { usePrintView && props.reportName && <h3>{ props.reportName }</h3> }
            { usePrintView && <h3>{ moment().format("MM/DD/YYYY hh:mm a") }</h3> }
            <h4 className="margin-top"><span># Views: <strong>{ props.item.viewCount }</strong></span> <span># Clicks: <strong>{ props.item.eventCount }</strong></span></h4>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  dataKey="value"
                  isAnimationActive={ false }
                  data={ events }                
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  outerRadius={80}
                  paddingAngle={5}
                  fill="#8884d8"
                  label />
                <Tooltip 
                  contentStyle={ {background: "#222", border: "none", borderRadius: "8px", padding: "1vmin", boxShadow: "2px 4px 12px rgba(1,1,1,.2)"} } 
                  itemStyle={ {color: "#fff", margin: "0", padding: "0", fontSize: "1.5vmin"} }>
                </Tooltip>
                <Legend layout="vertical" align="right" />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <p>&nbsp;</p>
          <div className="dashboard-vis list">
            <div className="dashboard-vis-header">
              <h4><span>All Clicks: <strong>{ props.item.eventCount }</strong></span></h4>
              { allEventArr && <CSVLink data={ allEventArr } className="export-btn" filename={ `${(props.item.name ? props.item.name : "Unnamed").replace(/[\W_]+/g,"-")}_All_Clicks_List_${ moment().format("MM/DD/YYYY hh:mm a") }.csv` }><span className="icon-download"></span> Download</CSVLink> }
            </div>
            <ul className="bmt-list-view margin-top">
              <li>
                <div className="flex">Event</div>
                <div className="flex small"># Clicks</div>
              </li>
              { props.item.events.sort((a, b) => a.eventType > b.eventType ? 1 : -1).map((item) => {
                  return (
                    <li key={ item.eventType } className="no-action">
                      <div className="flex">{ item.eventType }</div>
                      <div className="flex small"><span className="vis-heat" style={ {backgroundColor: item.heatFill} }></span> { item.value }</div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          { props.isAdmin && props.item.contactShareCount > 0 &&
            <div className="dashboard-vis list">
              <div className="dashboard-vis-header">
                <h4><span>Contact Shares: <strong>{ props.item.contactShareCount}</strong></span></h4>
                { contactArr && <CSVLink data={ contactArr } className="export-btn" filename={ `${(props.item.name ? props.item.name : "Unnamed").replace(/[\W_]+/g,"-")}_Contact_Share_List_${ moment().format("MM/DD/YYYY hh:mm a") }.csv` }><span className="icon-download"></span> Download</CSVLink> }
              </div>
              <ul className="bmt-list-view margin-top">
                <li>
                  <div className="flex">Name</div>
                  <div className="flex">Email</div>
                  <div className="flex small">Organization</div>
                  <div className="flex small">Title</div>
                </li>
                { props.item.contactShares.map((item) => {
                    return (
                      <li key={ item.Date_Time } className="no-action">
                        <div className="flex">{ item.User && item.User.First_Name ? `${item.User.First_Name} ${item.User.Last_Name}`  : item.User_ID }</div>
                        <div className="flex">{ item.User && item.User.Email ? item.User.Email : 'n/a' }</div>
                        <div className="flex small">{ item.User && item.User.Organization ? item.User.Organization : 'n/a' }</div>
                        <div className="flex small">{ item.User && item.User.Title ? item.User.Title : 'n/a' }</div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          }
          { props.item.resourceDownloadsCount > 0 && props.item.resourceDownloads && Object.keys(props.item.resourceDownloads).length > 0 && 
            <div className="dashboard-vis list">
              <div className="dashboard-vis-header">
                <h4><span>Resources Viewed: <strong>{ props.item.resourceDownloadsCount}</strong></span></h4>
                { resourceArr && <CSVLink data={ resourceArr } className="export-btn" filename={ `${(props.item.name ? props.item.name : "Unnamed").replace(/[\W_]+/g,"-")}_Resource_View_List_${ moment().format("MM/DD/YYYY hh:mm a") }.csv` }><span className="icon-download"></span> Download</CSVLink> }
              </div>
              <ul className="bmt-list-view margin-top">
                <li>
                  <div className="flex">Name</div>
                  <div className="flex"># Views</div>
                </li>
                { Object.keys(props.item.resourceDownloads).map((resourceId) => {
                    const item = props.item.resourceDownloads[resourceId]
                    return (
                      <li key={ resourceId } className="no-action">
                        <div className="flex">{ item.resourceName ? item.resourceName : resourceId }</div>
                        <div className="flex">{ item.count }</div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>  
          }
        </div>
      </>
      :
      null
  )

  return (
    <Modal title={ props.item.name ? props.item.name : props.item.boothId } children={ content } close={ props.close } className="bigger" />
  )
}
export default ReportDetailModal