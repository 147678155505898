import Modal from "../../modal/modal"
import { useState } from "react"

const Status = (props) => {
  
  const [showDeployModal, setShowDeployModal] = useState(false)
  const [deployComments, setDeployComments] = useState('')

  const handleCloseModal = () => {
    setShowDeployModal(false)
    setDeployComments('')
  }

  const handleDeploy = () => {
    props.submit({
      comments: deployComments
    })

    handleCloseModal()
  }

  if (!props.version) { return null }

  const versionDeployments = props.deployments ? props.deployments.filter((item) => item.versionId === props.version.instance._id).sort((a, b) => a.created > b.created ? -1 : a.created < b.created ? 1 : 0) : []
  const submittedPreviously = versionDeployments.length > 0
  const publishedPreviously = versionDeployments.length > 0 && versionDeployments.filter((item) => item.status === "Published").length > 0
  
  const status = publishedPreviously ? "Published" : submittedPreviously ? versionDeployments[0].status : "Created"
  const statusTag = status.toLowerCase().replace("(","").replace(")","").replace(" ", "-")

  const deploymentModalContent = (
    <div className="bmt-form-page">
      { submittedPreviously && <span className="red">* This submission will overwrite prior drafts.</span> }
      <div className="form-group">
        <h5>Comments</h5>
        <textarea placeholder="Comments about this submission..." value={ deployComments } onChange={ (e) => setDeployComments(e.target.value) } />
      </div>
      <div className="bmt-button-group modal-group">
        <button className="cancel-btn" onClick={ handleCloseModal }>Cancel</button>
        <button className="primary-btn" onClick={ handleDeploy }>Submit</button>
      </div>
    </div>
  )

  const deploymentModal = showDeployModal ? <Modal title="Submit" children={ deploymentModalContent } close={ handleCloseModal } /> : null

  return (
    <div className="bmt-form-page bmt-wrapper">
      { deploymentModal }
      <h2>Submit</h2>
      <div className="form-group">
        <h5>Current Status</h5>
        <h6 className={ `status-tag status-${statusTag}` }><span>{ status }</span></h6>
      </div>
      { !publishedPreviously && status !== "Publishing" && status !== "Failed to Publish" && <button className="primary-btn" disabled={ props.persisting } onClick={ () => setShowDeployModal(true) }>Submit</button> }
    </div>
  )
}

export default Status