import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { logout, setUserSetting, setSharedUi } from "../../redux/reducers/auth"
import { setSelectedClient } from "../../redux/reducers/clients"
import Modal from "../modal/modal"
import MiniLoader from "../mini-loader/miniLoader"
import "./sidebar.scss"
import moment from "moment"
import { useLocation } from "react-router-dom"
import API from "../../services/api"
 
const NavSidebar = (props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [showMenu, setShowMenu] = useState(false)
  const { user, sharedUi } = useSelector((state) => state.auth)
  const clients = useSelector((state) => state.clients)
  const [infernoAuth, setInfernoAuth] = useState({email: '', password: '', error: false, loading: false})
  const [currentLocation, setCurrentLocation] = useState("/")
  const api = new API()
  
  useEffect(() => {
    setCurrentLocation(location.pathname)
  }, [location])

  if (!user) { return ""}

  let settings = user.settings
  const handleLogout = () => {
    dispatch(logout())
    setShowMenu(false)
  }

  const targetUser = user.isAdmin && clients.selectedClient ? clients.selectedClient : user
  const host = window.location.hostname
  const isProd = host.indexOf('curator.nextechar') > -1 || host.indexOf('booth-management-tool-app.s3-website-us-west-2.amazonaws.com') > -1
  const sidebarClass =  isProd ? "bmt-sidebar" : "bmt-sidebar dark"

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowMenu(false)
    }
  }

  const setSelectedClass = (page) => {
    return currentLocation.toLowerCase().indexOf(page) > -1 ? "selected" : ""
  }

  const showInfernoSettings = () => {
    dispatch(setSharedUi({key: 'showInfernoSettings', val: true}))
    setShowMenu(false)
  }

  const hideInfernoSettings = () => {
    dispatch(setSharedUi({key: 'showInfernoSettings', val: false}))
    setInfernoAuth({email: '', password: '', error: false, loading: false})
  }

  const userMenu = <div className="bmt-popup-menu">
    { !user.isShared && !user.isAdmin && !(user.settings && user.settings.liveX && user.settings.liveX.apiKey) ? <button onClick={ showInfernoSettings }>IgniteX Settings</button> : null }
    <button onClick={handleLogout}><span className="icon-log-out"></span> Logout</button>
  </div>

  const onToggleSidebar = () => {
    if (!settings) {
      settings = {
        showSidebar: true
      }
    }

    const newVal = !settings.showSidebar
    
    // Dispatch event for auth users
    dispatch(setUserSetting({key: 'showSidebar', val: newVal}))

    // Persist for normal users
    if (!user.isShared) {

      api.methods.post('user/settings', {'settings.showSidebar': newVal}).then(() => {
      
      }, () => {
        console.log('error setting sidebar')
      })
    }
  }

  // Inferno auth
  const saveInfernoAuth = () => {
    setInfernoAuth({...infernoAuth, loading: true, error: false})

    // Setup base params
    let params = {
      email: infernoAuth.email, 
      password: infernoAuth.password
    }

    // Append domain and userId if we're an admin
    if (user.isAdmin && clients && clients.selectedClient) {
      params.userId = clients.selectedClient._id
    }

    // Make request
    api.methods.post('user/authorize/inferno', params).then((res) => {
      setInfernoAuth({...infernoAuth, loading: false, error: !res.data.success})

      // Save inferno response settings w/ a valid login
      if (res.data.success && res.data.params) {

        // Update current client OR current user
        if (user.isAdmin && clients && clients.selectedClient) {
          const revisedSettings = {...clients.selectedClient.settings, infernoAuth: res.data.params}
          dispatch(setSelectedClient({...clients.selectedClient, settings: revisedSettings}))
          hideInfernoSettings()
        }
        else {
          dispatch(setUserSetting({key: 'infernoAuth', val: res.data.params}))
        }
      }

    }, () => {
      setInfernoAuth({...infernoAuth, loading: false, error: true})
    })
  }

  const revokeInfernoAuth = () => {
    if (!window.confirm('Are you sure you would like to revoke this connection?')) { return }

    // Make request
    api.methods.post('user/revoke/inferno').then((res) => {
      setInfernoAuth({...infernoAuth, loading: false, error: false})

      // Remove inferno auth from settings
      dispatch(setUserSetting({key: 'infernoAuth', val: null}))

    }, () => {
      setInfernoAuth({...infernoAuth, loading: false, error: true})
    })
  }

  // Inferno modal
  const modalForm = targetUser.settings && targetUser.settings.infernoAuth && targetUser.settings.infernoAuth.issued ? (
      <div>
        <h4 className="primary-color">Authenticated</h4>
        <div className="form-group">
          <h5>Email</h5>
          <h6><strong>{ targetUser.settings.infernoAuth.email }</strong></h6>
        </div>
        <div className="form-group">
          <h5>Issued</h5>
          <h6><strong>{ moment(targetUser.settings.infernoAuth.issued).format('MM/DD/YYYY hh:mm a') }</strong></h6>
        </div>
        <button className="negative-btn" onClick={ revokeInfernoAuth }>Revoke Access</button>
      </div>
    ) : (
    <div>
      <h4>You are currently not authenticated with IgniteX. Please login below</h4>
      <div className="form-group">
        <h5>Email</h5>
        <input type="email" value={ infernoAuth.email } onChange={ (e) => setInfernoAuth({...infernoAuth, email: e.target.value}) } placeholder="Your IgniteX Email..." />
      </div>
      <div className="form-group">
        <h5>Password</h5>
        <input type="password" value={ infernoAuth.password } onChange={ (e) => setInfernoAuth({...infernoAuth, password: e.target.value}) } placeholder="Your IgniteX Password..." />
      </div>
      { infernoAuth.loading ? <MiniLoader copy="Authenticating..." /> : null }
      { infernoAuth.error ? <h5 className="error">There was an error authenticating with IgniteX, please try again.</h5> : null }
      <div className="bmt-button-group modal-group">
        <button className="cancel-btn" onClick={ hideInfernoSettings }>Cancel</button>
        <button className="primary-btn" disabled={ infernoAuth.loading || !infernoAuth.email || !infernoAuth.password } onClick={ saveInfernoAuth }>Save</button>
      </div>
    </div>
  )

  const modalContent = (
    <div className="bmt-form-page">
      { modalForm }
    </div>
  )
  
  const infernoModal = sharedUi.showInfernoSettings ? <Modal title="IgniteX Settings" children={ modalContent } close={ hideInfernoSettings } /> : null

  // Depending on the user type, we'll need to present different menu options
  return (
    <div className={settings && !settings.showSidebar ? sidebarClass + " collapsed" : sidebarClass }>
      { infernoModal }
      <div className="bmt-branding">
        <img className="bmt-icon" src="/icon.png" alt="logo" />
        <h2>curator</h2>
        <button className="sidebar-toggle-btn" onClick={onToggleSidebar}><span>☰</span></button>
      </div>
      <div className="bmt-user-container">
        <div className="bmt-user" tabIndex="0" onBlur={(e) => handleBlur(e)}>
          { !user.isShared && <h3><span className="icon-user"></span> <span className={ user.isAdmin ? "user-label save-bg" : "user-label" }>{ user.isAdmin ? "Admin" : "Client" }</span> { user.firstName } { user.lastName }</h3> }
          { user.isShared && <h3><span className="icon-users"></span> <span className="user-label orange-bg">Shared - { user.access }</span></h3> }
          <button className="user-menu-btn" onClick={() => setShowMenu(true)}><span className="icon-more-horizontal"></span></button>
          { showMenu && userMenu }
        </div>
      </div>
      { !user.isShared ?
        <ul>
          <li className={setSelectedClass('dashboard')}><Link to="/dashboard"><span className="icon-trello"></span> {settings && !settings.showSidebar ? "" : "Dashboard"}</Link></li>
          {user.isAdmin ? <li className={setSelectedClass('clients')}><Link to="/clients"><span className="icon-users"></span> {settings && !settings.showSidebar ? "" : "Clients"}</Link></li> : null}
          {!user.isAdmin ? <li className={setSelectedClass('booths')}><Link to="/booths"><span className="icon-grid"></span> {settings && !settings.showSidebar ? "" : "Exhibits"}</Link></li> : null}
          {!user.isAdmin && <li className={setSelectedClass('reports')}><Link to="/reports"><span className="icon-bar-chart-2"></span> {settings && !settings.showSidebar ? "" : "Reports"}</Link></li> }
          <li className={setSelectedClass('blueprints')}><Link to="/blueprints"><span className="icon-layers"></span> {settings && !settings.showSidebar ? "" : "Blueprints"}</Link></li>
        </ul> :
        <ul>
          <li className={setSelectedClass('booths')}><Link to={`/booths/${user.boothId}`}><span className="icon-grid"></span> {settings && !settings.showSidebar ? "" : "My Exhibit"}</Link></li>
          <li className={setSelectedClass('reports')}><Link to="/shared/reports"><span className="icon-bar-chart-2"></span> {settings && !settings.showSidebar ? "" : "Reports"}</Link></li>
        </ul>
      }
      { !isProd && <div className="bmt-test"><i>TEST</i><span>Testing Environment</span></div> }
      <div className="bmt-version">version 2.2.2</div>
      <div className="bmt-version program-updates"><span className="icon-alert-circle"></span> Program Updates <div className="version-hidden bmt-popup-menu">Routine program updates will occur every MONDAY 8:30am - 9:30am EST. Users may experience lapse of service if working during this period.</div></div>
    </div>
  )
}

export default NavSidebar