import { BrowserRouter as Router } from "react-router-dom"
import Main from "./components/main"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setAvailableTypes } from "./redux/reducers/booths"
import "./main.scss"
import "./fonts.css"
import API from "./services/api"

const App = (props) => {

  const placeholder = (
    <div className="bmt-placeholder">
      <h1>loading your curator experience...</h1>
      <img src="/icon.png" alt="curator logo" />
    </div>
  )

  const dispatch = useDispatch()
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    let mounted = true

    // We'll use this area to fetch any app details, i.e booth types
    const api = new API()
    api.methods.get('booths/types').then((res) => {
      if (mounted && res.data && res.data.types) {
        dispatch(setAvailableTypes(res.data.types))
      }

      setFetched(true)

    }, () => {
      console.log('error fetching booth types')
      // todo: handle error
    }) 

    return (() => mounted = false)
      
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return fetched ? <Router><Main /></Router> : placeholder
}

export default App
