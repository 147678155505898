import Modal from "../../modal/modal"
import { useState, useEffect } from "react"
import MiniLoader from "../../mini-loader/miniLoader"
import API from "../../../services/api"

const PeriodicModal = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [didError, setDidError] = useState(false)
  const [providers, setProviders] = useState([])
  const [selection, setSelection] = useState(false)
  const api = new API()

  // Fetch providers on load
  useEffect(() => {
    let isMounted = true

    // Make request
    api.methods.get(`user/bookings/providers`).then((res) => {

      if (isMounted) {
        setProviders(res.data.providers)
        setIsLoading(false)
      }

    }, () => {
      if (isMounted) {
        setIsLoading(false)
        setDidError(true)
      }
    })

    return () => isMounted = false

  }, []) // eslint-disable-line

  const handleSelectProvider = (val) => {
    const provider = providers.find((item) => item.id === val)
    setSelection({name: provider.name, id: provider.id, subdomain: provider.subdomain})
  } 

  const handleSave = () => {
    props.onSelect(selection)
  }
                          
  const content = (
    <div>
      { 
        isLoading ?
          <MiniLoader copy="Fetching info from Periodic..." /> 
        : 
          didError ? <div className="error">Oops. There was an error communicating with Periodic. Please try again.</div>
        : providers && providers.length > 0 ?
          (
            <>
              <div>
                <select name="provider" onChange={ (e) => handleSelectProvider(e.target.value) }>
                    <option value={ null }>Please select a provider</option>
                    { 
                      providers.map((item) => {
                        return <option key={ item.id } value={ item.id }>{ item.name }</option>
                      })
                    }
                </select>
              </div>
              <div className="bmt-button-group modal-group">
                <button className="cancel-btn" disabled={ !selection } onClick={ props.onClose }>Cancel</button>
                <button className="primary-btn" disabled={ !selection } onClick={ handleSave }>Select</button>
              </div>
            </>
          )
          :
          <div className="error">Oops. We couldn't locate any providers. Please try again.</div>
      }
    </div>
  )

  return <Modal title="Select a Periodic Provider" children={ content } close={ props.onClose } />
}

export default PeriodicModal