import { useState, useEffect } from "react"
import "./dropdown.scss"

const Dropdown = (props) => {
  const [expanded, setExpanded] = useState(false)
  const [selections, setSelections] = useState({})

  // Handle defaults
  useEffect(() => {
    if (props.defaults) {
      setSelections(props.defaults)
    }
  }, []) // eslint-disable-line

  const handleBlur = (e) => {
    setExpanded(false)
  }

  const toggleOption = (option) => {
    let aCopy = JSON.parse(JSON.stringify(selections))

    if (aCopy[option.value]) {
      // Remove
      delete aCopy[option.value]
    }
    else {
      aCopy[option.value] = option
    }

    setSelections(aCopy)

    // Trigger callback
    if (props.onChange) {
      props.onChange(aCopy)
    }
  }

  const getDisplayLabel = () => {
    if (Object.keys(selections).length < 1 || Object.keys(selections).length === props.options.length) {
      return `All ${props.label}s`
    } 
    else if (Object.keys(selections).length === 1) {
      return selections[Object.keys(selections)[0]].label
    }
    else {
      return `${Object.keys(selections).length} ${props.label}s`
    }
  }

  const expandedView = (
    <div className="dropdown-expanded">
      <ul>
        {
          props.options && props.options.map((option) => {
            return <li className={ selections[option.value] ? "selected" : "" } key={ option.value } onClick={ () => toggleOption(option) }>{ option.label }</li>
          })
        }
      </ul>
    </div>
  )

  return (
    <div className={ expanded || (Object.keys(selections).length > 0 && Object.keys(selections).length !== Object.keys(props.options).length) ? "dropdown expanded" : "dropdown" } tabIndex="0" onBlur={ (e) => handleBlur(e) }>
      <div className="dropdown-main" onClick={ () => setExpanded(!expanded) }>
        <h4>{ getDisplayLabel() }</h4><span className="icon-chevron-down"></span>
      </div>
      { expanded && expandedView }
    </div>
  )
}

export default Dropdown