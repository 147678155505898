import { useState, useEffect, useRef } from "react"
import moment from "moment"

const ResourceHandler = (props) => {

  const [resource, setResource] = useState({resource: {name: ''}, thumb: {}})
  // const [pristine, setPristine] = useState({})
  const debounce = useRef(null)
  useEffect(() => {

    // Set file based on value
    if (props.value) {

      if (props.value.resource && props.value.resource.remoteSrc && (!resource.resource || !resource.resource.src || resource.resource.loaded !== props.value.resource.loaded)) { 
        console.log(props.value.resource.name, '-> fetch remote details & set local resource') 

        const resourceForm = new FormData()
        resourceForm.set('key', props.inputKey + ".resource")
        resourceForm.set('parentKey', props.inputKey)
        resourceForm.set('name', props.value.resource.name)

        const aResource = {...props.value, resource: {...props.value.resource, toBePersisted: resourceForm, name: props.value.resource.name, src: props.value.resource.remoteSrc, loaded: moment().valueOf()}}
        setResource(aResource)
        // setPristine(aResource)
      }
      else if (props.value.resource && props.value.resource.delete) {
        console.log(props.inputKey, '-> clear local resource')
        setResource(false)
      }
      else if (props.value.resource && props.value.resource.src && (!resource.resource.src || resource.resource.loaded !== props.value.resource.loaded)) {
        console.log(props.inputKey, '-> reset local resource') 
        const resourceForm = new FormData()
        resourceForm.append('key', props.inputKey + ".resource")
        resourceForm.append('parentKey', props.inputKey)
        resourceForm.append('name', props.value.resource.name)

        const aResource = {...props.value, resource: {...props.value.resource, toBePersisted: resourceForm, src: props.value.resource.src, loaded: moment().valueOf()}}
        setResource(aResource)
      }
    }
    else if (resource && resource.resource.src) {
      console.log(props.inputKey, '-> unset local resource')
      setResource(false)
      // setPristine(false)
    }

    // Kill debounce timer if it is still running
    return () => {
      if (debounce.current) {
        window.clearTimeout(debounce.current)
        debounce.current = null
      }
    }

  }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleRemoveResource = () => {
    setResource({resource: {name: ''}, thumb: {}})
    // setPristine({})
    props.change({key: props.inputKey, resource: {delete: true}})
  }

  const handleFileChange = (file) => {

    if (!file) { return }

    // Lock down to 500mb
    if (file.size > (1024*1024*500)) { 
      window.alert("Please select a file smaller than 500mb in size.")
      return
    }

    const newResource = {...resource, key: props.inputKey}

    const formData = new FormData()
    formData.set('file', file)
    formData.set('key', props.inputKey + '.resource')
    formData.set('name', file.name)
    formData.set('filename', file.name)
    formData.set('parentKey', props.inputKey)

    const resourceFile = {
      filename: file.name,
      src: window.URL.createObjectURL(file),
      toBePersisted: formData,
      name: file.name,
      loaded: moment().valueOf()
    }

    newResource.resource = resourceFile

    // Set resource
    setResource(newResource)

    // Propagate change after debounce
    debounceChange(newResource)
  }

  const debounceChange = (newResource) => {
    if (debounce.current) {
      window.clearTimeout(debounce.current)
      debounce.current = null
    }

    debounce.current = window.setTimeout(() => {
      props.change(newResource)
    }, 500)   
  }

  const handleThumbFileChange = (file) => {

    if (!file) { return }
      
    // Lock down to 500mb
    if (file.size > (1024*1024*500)) { 
      window.alert("Please select a file smaller than 500mb in size.")
      return
    }
    
    const formData = new FormData()
    formData.set('file', file)
    formData.set('key', props.inputKey + '.thumb')
    formData.set('filename', file.name)
    formData.set('parentKey', props.inputKey)

    const thumb = {
      filename: file.name,
      src: window.URL.createObjectURL(file),
      toBePersisted: formData
    }

    const newResource = {...resource, thumb: thumb, loaded: moment().valueOf()}
    
    // Set resource
    setResource(newResource)

    // Propagate change after debounce
    debounceChange(newResource)
  }

  const handleNameChange = (name) => {
    const formData = resource.resource.toBePersisted ? resource.resource.toBePersisted : new FormData()
    formData.set('name', name)
    formData.set('parentKey', props.inputKey)
    formData.set('key', props.inputKey + '.resource')

    const newResource = {...resource, loaded: moment().valueOf(), resource: {...resource.resource, name: name, toBePersisted: formData}}
    setResource(newResource)

    // Propagate change after debounce
    debounceChange(newResource) 
  }

  return (
    resource.resource && resource.resource.src ? 
    <div className="bmt-media-input bmt-picker-input active">
      <div className="bmt-picker-input-top">
        <div className="top-label"><i className="icon-file-text"></i> Resource: <strong>{ resource.resource.filename }</strong></div> 
      </div>
      { props.enableRename &&
        <div className="bmt-picker-input-top margin-top">
          <h5>Name</h5>
          <input type="text" placeholder="Resource name..." value={ resource.resource.name } onChange={ (e) => handleNameChange(e.target.value) } /> 
        </div>
      }
      { props.enableThumb &&
        <div className="bmt-picker-input-top margin-top">
          <h5>Thumbnail</h5>
          <div className="bmt-media-input bmt-flex-picker">
            <div className="bmt-file-picker">
              <label htmlFor={ `resource-thumb-${props.inputKey}` }><span><i className="icon-image"></i> { resource.thumb && resource.thumb.filename ? resource.thumb.filename : "Select an image" }</span></label>
              <input type="file" id={ `resource-thumb-${props.inputKey}` } name={ `resource-thumb-${props.inputKey}` } disabled={ props.disabled } accept=".jpg,.png" onChange={ (e) => handleThumbFileChange(e.target.files[0]) } />
            </div>
          </div>
        </div>
      }
      { resource.resource.remoteSrc && 
        <div>
          <div className="bmt-picker-input-top margin-top">
            <h5>Location</h5>
            <div><a rel="noreferrer" className="outline-btn" href={ resource.resource.remoteSrc } target="_blank"><i className="icon-external-link"></i> View</a></div>
          </div>
          <br/>
        </div>
      }
      <button className="text-btn red" onClick={ handleRemoveResource }>Remove</button>
    </div> : 
    <div>
      <div className="bmt-media-input bmt-picker-input">
        <div className="bmt-file-picker">
          <label htmlFor={ `resource-${props.inputKey}` }><span><i className="icon-file-text"></i> Select a file</span></label>
          <input type="file" id={ `resource-${props.inputKey}` } name={ `resource-${props.inputKey}` } disabled={ props.disabled } accept={ props.accept } onChange={ (e) => handleFileChange(e.target.files[0]) } />
        </div>
      </div>
    </div>
  )
}

export default ResourceHandler