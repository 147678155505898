import Modal from "../modal/modal"

const ReportDetailPagesModal = (props) => {

  const percentileBars = [{key: "ten", val: 1}, {key: "twenty", val: 2}, {key: "thirty", val: 3}, {key: "forty", val: 4}, {key: "fifty", val: 5}, {key: "sixty", val: 6}, {key: "seventy", val: 7}, {key: "eighty", val: 8}, {key: "ninety", val: 9}, {key: "hundred", val: 10}]

  const children = (
    <div className="user-list-view">
      <div className="user-list-item header-row">
        <div className="page-item-percentile no-display"></div>
        <div className="user-item-meta">Name</div>
        <div className="user-item-count"># Views</div>
        <div className="user-item-count"># Interactions</div>
      </div>
      {
        props.pages.sort((a, b) => a.totalCount > b.totalCount ? -1 : 1).map((item) => {
          return (
            <div className="user-list-item no-action" key={ item.key }>
              <div className="page-item-percentile">
                {
                  percentileBars.map((bar) => {
                    return (
                      <span className={ item.percentileBar === bar.val ? "page-item-percentile-bar filled" : "page-item-percentile-bar" } key={ bar.key }></span>
                    )
                  })
                }
              </div>
              <div className="user-item-meta">{ item.name }<span className="page-item-percentile-label">{ item.percentile }<sup>th</sup> percentile</span></div>
              <div className="user-item-count">{ item.viewItemsCount }</div>
              <div className="user-item-count">{ item.contentItemsCount }</div>
            </div>
          )
        })
      }
    </div>
  )

  return (
    <Modal title="All Pages" close={ props.close } children={ children } />
  )
}

export default ReportDetailPagesModal