import Header from "../header/header"
import Modal from "../modal/modal"
import "./clients.scss"
import { showNewClient, addNewClient, setClients } from "../../redux/reducers/clients"
import { extendAuthTimer, setUserSetting } from "../../redux/reducers/auth"
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import NewClientForm from "./newClientForm"
import moment from "moment"
import md5 from "md5"
import AdminPanel from "../nav/adminPanel"
import API from "../../services/api"
import Dropdown from "../dropdown/dropdown"
import { setHeaderClickEvent, setHeaderLeftButton, setHeaderRightButtons, setHeaderTitle } from "../../redux/reducers/ui"
import Constants from "../constants"

const Clients = (props) => {

  const api = new API()
  const dispatch = useDispatch()
  const state = useSelector((state) => state.clients)
  const clients = state.clients
  const ui = state.ui
  const handleShowNewClient = () => {
    setTestInfernoError(false)
    dispatch(showNewClient({show: true}))
  }
  const [addClientError, setAddClientError] = useState(false)
  const [testInfernoError, setTestInfernoError] = useState(false)
  const [clientSearch, setClientSearch] = useState('')
  const [types, setTypes] = useState({})
  const [states, setStates] = useState({active: {label: "Active", value: "active"}})
  const headerEvent = useSelector((state) => state.ui.header.clickEvent)
  const user = useSelector((state) => state.auth.user)

  const closeModal = () => {
    dispatch(showNewClient({show: false}))
  }

  const handleAddNewClient = (newClient, infernoAuth, liveX) => {

    // Reset error
    setAddClientError(false)

    // Hash password + setup params
    const params = {client: {...newClient, password: md5(newClient.password)}, infernoAuth: infernoAuth, liveX: liveX}

    // Make api request
    api.methods.post('clients', params).then((res) => {
      
      // Handle success
      if (res.data.success && res.data.user) {
        // Close modal
        closeModal()

        // Dispatch
        dispatch(addNewClient(res.data.user))
      }
      else {
        // todo: handle error
        setAddClientError(true)
      }

    }, () => {
      // todo: handle error
      setAddClientError(true)
    })
  }

  const handleTestNewClientInferno = (creds) => {

    // Reset error
    setTestInfernoError(false)

    // Set test mode
    creds.testOnly = true

    // Make api request
    api.methods.post('user/authorize/inferno', creds).then((res) => {
      
      // Handle success
      if (res.data.success) {

        // Success
        setTestInfernoError(false)
      }
      else {
        // todo: handle error
        setTestInfernoError(true)
      }

    }, () => {
      // todo: handle error
      setTestInfernoError(true)
    })
  }

  useEffect(() => {
    let mounted = true

    // Set header title
    dispatch(setHeaderTitle("Clients"))
    dispatch(setHeaderLeftButton(null))

    // Set loading
    props.loader.start()

    // Fetch & store clients
    api.methods.get('clients').then((res) => {

      if (mounted) {
        dispatch(setClients(res.data.clients))
        dispatch(extendAuthTimer(true))

        // Stop loader
        props.loader.stop()
      }
    }, () => {
      console.log('error fetching clients')

      // Stop loader
      if (mounted) {
        props.loader.stop()
      }
    })

    return () => {
      mounted = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const goToClient = (clientId) => {
    props.history.push('/clients/' + clientId)
  }

  const sortClients = (a, b) => {
    if (a.projectName.toLowerCase() < b.projectName.toLowerCase()) {
      return -1
    }
    else if (a.projectName.toLowerCase() > b.projectName.toLowerCase()) {
      return 1
    }

    return 0
  }

  let filteredClients = clients.filter((item) => {
    const hasLiveX = item.settings && item.settings.liveX && item.settings.liveX.apiKey
    const state = item.state ? item.state : "active"
    const typeFilter = Object.keys(types).length === 0 || Object.keys(types).length === 2 ? true : types.livex ? hasLiveX : !hasLiveX
    const stateFilter = Object.keys(states).length === 0 || Object.keys(states).length === 2 ? true : states.archived ? state === "archived" : state === "active"
    const bookmarkFilter = !user.settings || !user.settings.showBookmarksOnly || (user.settings && user.settings.showBookmarksOnly && user.settings.bookmarks && user.settings.bookmarks[item._id])
    return (bookmarkFilter && stateFilter && typeFilter && (item.projectName.toLowerCase().includes(clientSearch) || item.firstName.toLowerCase().includes(clientSearch) || item.lastName.toLowerCase().includes(clientSearch)))
  }).sort(sortClients)

  const exportData = filteredClients.map((item) => {
    return {
      ProjectName: item.projectName,
      FirstName: item.firstName, 
      LastName: item.lastName,
      Domain: item.settings && item.settings.infernoAuth && item.settings.infernoAuth.domain ? item.settings.infernoAuth.domain : "",
      HasAuthenticated: item.settings && item.settings.infernoAuth && item.settings.infernoAuth.accessToken ? "Yes" : "No",
      Active: item.isActive ? "Yes" : "No",
      Created: item.created ? moment(item.created).format("MM/DD/YYYY hh:mm a") : "",
      LastLogin: item.lastLogin ? moment(item.lastLogin).format("MM/DD/YYYY hh:mm a") : ""
    }
  })

  const handleTypeChange = (selections) => {
    setTypes(selections)
  }

  const handleStateChange = (selections) => {
    setStates(selections)
  }

  // Set header buttons, hook into export data
  useEffect(() => {
    const headerButtons = [{
      label: "Create New Client",
      icon: "icon-plus",
      className: 'primary-btn',
      key: Constants.CLIENTS_NEW_BTN
    },
    {
      label: "Export",
      className: "export-btn",
      csv: {
        filename: `Client_List_${ moment().format("MM/DD/YYYY hh:mm a") }.csv`,
        data: exportData
      }
    }]

    dispatch(setHeaderRightButtons(headerButtons))

  }, [exportData]) // eslint-disable-line

  // Listen for header click event
  useEffect(() => {

    if (headerEvent && headerEvent.key === Constants.CLIENTS_NEW_BTN) {
      handleShowNewClient()
      dispatch(setHeaderClickEvent(null))
    }

  }, [headerEvent]) // eslint-disable-line

  const handleToggleBookmarks = () => {

    const settings = user.settings ? user.settings : {}
    const showBookmarksOnly = !settings.showBookmarksOnly

    // Make API call
    api.methods.post("user/settings", {"settings.showBookmarksOnly": showBookmarksOnly}).then((res) => {
      // Dispatch
      dispatch(setUserSetting({key: "showBookmarksOnly", val: showBookmarksOnly}))
    }, () => {
      console.log('error saving bookmark settings')
    })
  }

  return (
    <div className="bmt-main-view">
      { <AdminPanel /> }
      <Header />
      <div className="bmt-form-page">
        <div className="form-group bmt-wrapper non-flex half margin-bottom overflow">
          <h5>Search</h5>
          <div className="form-group-subheader">
            <div>
              <input type="text" placeholder="Search by first name, last name, or project" value={ clientSearch } onChange={ (e) => setClientSearch(e.target.value.toLowerCase()) } />
              { clientSearch.length > 0 && <button className="cancel-btn" onClick={ () => setClientSearch('') }>Clear</button> }
            </div>
          </div>
          <div className="form-group-row">
            <div className="form-group-row-item">
              <h5>Type</h5>
              <Dropdown label="Type" options={ [{label: "LiveX", value: "livex"}, {label: "IgniteX", value: "ignitex"}] } onChange={ handleTypeChange } />
            </div>
            <div className="form-group-row-item">
              <h5>State</h5>
              <Dropdown label="State" options={ [{label: "Active", value: "active"}, {label: "Archived", value: "archived"}] } defaults={ {active: {label: "Active", value: "active"}} } onChange={ handleStateChange } />
            </div>
            <button className={ user.settings && user.settings.showBookmarksOnly ? "outline-btn less-padding form-group-btn active" : "outline-btn less-padding form-group-btn" } onClick={ handleToggleBookmarks }>
              <span className="icon-bookmark"></span>
              { user.settings && user.settings.bookmarks && <i>{ Object.keys(user.settings.bookmarks).length }</i> }
            </button>
          </div>
        </div>
        <div className="client-cards">
          {filteredClients.map((client) => {
            return (
              <div key={ client._id } className={ client.state && client.state === "archived" ? "client-card archived" : "client-card" } onClick={ () => goToClient(client._id) }>
                <h3 className="primary-color">
                  { user.settings && user.settings.bookmarks && user.settings.bookmarks[client._id] && <span className="bookmark-tag icon-bookmark"></span> } 
                  { client.settings && client.settings.liveX && client.settings.liveX.apiKey && <span className="live-x-tag">LiveX</span> } 
                  { client.projectName }
                </h3>
                <h4>{ client.firstName } { client.lastName }</h4>
                <h5>{ client.settings && client.settings.infernoAuth && client.settings.infernoAuth.domain ? client.settings.infernoAuth.domain : "" }</h5>
                {  client.numBooths ? <h5><strong>{ client.numBooths } { client.numBooths === 1 ? "booth" : "booths" }</strong></h5> : <h5>0 booths</h5>  }
                <h5>Created { moment(client.created).format('MM/DD/YYYY') }</h5>
              </div>
            )
          })}
        </div>
        { filteredClients.length < 1 && <h5 className="no-items">No clients found matching your filters...</h5> }
        {ui.showNewClient && <Modal title="Create a new client" close={ closeModal }><NewClientForm infernoError={ testInfernoError } testInferno={ handleTestNewClientInferno } postError={ addClientError } completion={ handleAddNewClient } cancel={ closeModal } /></Modal>}
      </div>
    </div>
  )
}

export default Clients