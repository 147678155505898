import { useState, useEffect, useRef } from "react"
import moment from "moment"
import InputHandler from "./inputHandler"

const RepresentativeHandler = (props) => {

  const [rep, setRep] = useState({name: '', bio: '', email: '', websiteLink: '', facebookLink: '', twitterLink: '', linkedinLink: '', instagramLink: '', youtubeLink: '', pinterestLink: '', photo: {}})
  const debounce = useRef(null)
  const [isChangingPhoto, setIsChangingPhoto] = useState(false)

  useEffect(() => {

    // Set file based on value
    if (props.value) {

      if (props.value.photo && props.value.photo.remoteSrc && (!rep.photo || !rep.photo.src || rep.photo.loaded !== props.value.photo.loaded)) { 
        console.log(props.value.photo.name, '-> fetch remote details & set local rep') 

        const resourceForm = new FormData()
        resourceForm.set('key', props.inputKey + ".photo")
        resourceForm.set('parentKey', props.inputKey)
        resourceForm.set('name', props.value.photo.name)

        const aRep = {...props.value, photo: {...props.value.photo, toBePersisted: resourceForm, name: props.value.photo.name, src: props.value.photo.remoteSrc, loaded: moment().valueOf()}}
        setRep(aRep)
      }
      else if (props.value.photo && props.value.photo.delete) {
        console.log(props.inputKey, '-> clear local rep')
        setRep(false)
      }
      else if (props.value.photo && props.value.photo.src && (!rep.photo.src || rep.photo.loaded !== props.value.photo.loaded)) {
        console.log(props.inputKey, '-> reset local rep') 
        const resourceForm = new FormData()
        resourceForm.append('key', props.inputKey + ".photo")
        resourceForm.append('parentKey', props.inputKey)
        resourceForm.append('name', props.value.photo.name)

        const aRep = {...props.value, photo: {...props.value.photo, toBePersisted: resourceForm, src: props.value.photo.src, loaded: moment().valueOf()}}
        setRep(aRep)
      }
    }
    else if (rep && rep.photo.src) {
      console.log(props.inputKey, '-> unset local rep')
      setRep(false)
    }

    // Kill debounce timer if it is still running
    return () => {
      if (debounce.current) {
        window.clearTimeout(debounce.current)
        debounce.current = null
      }
    }

  }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleRemoveRep = () => {
    setRep({name: '', title: '', photo: {}})
    props.change({key: props.inputKey, photo: {delete: true}})
  }

  const handleFileUpdate= (file) => {
    setIsChangingPhoto(false)
    handleFileChange(file)
  }

  const handleFileChange = (file) => {

    if (!file) { return }

    // Lock down to 5mb
    if (file.size > (1024*1024*5)) { 
      window.alert("Please select a file smaller than 5mb in size.")
      return
    }

    const newRep = {...rep, key: props.inputKey}

    const formData = new FormData()
    formData.set('file', file)
    formData.set('key', props.inputKey + '.photo')
    formData.set('name', file.name)
    formData.set('filename', file.name)
    formData.set('parentKey', props.inputKey)

    const repFile = {
      filename: file.name,
      src: window.URL.createObjectURL(file),
      toBePersisted: formData,
      name: file.name,
      loaded: moment().valueOf()
    }

    newRep.photo = repFile

    // Set
    setRep(newRep)

    // Propagate change after debounce
    debounceChange(newRep)
  }

  const debounceChange = (newRep) => {
    if (debounce.current) {
      window.clearTimeout(debounce.current)
      debounce.current = null
    }

    debounce.current = window.setTimeout(() => {
      props.change(newRep)
    }, 500)   
  }

  const handleFieldChange = (field, value) => {
    const newResource = {...rep, [field]: value}
    setRep(newResource)

    // Propagate change after debounce
    debounceChange(newResource) 
  }

  return (
    rep.photo && rep.photo.src ? 
    <div className="bmt-media-input bmt-picker-input active">
      <div className="bmt-picker-input-top">
        <div className="top-label"><i className="icon-image"></i> Photo: <strong>{ rep.photo.filename }</strong> { !isChangingPhoto && <button className="text-btn" onClick={ () => setIsChangingPhoto(true) }>(Change)</button> }</div> 
      </div>
      { isChangingPhoto && 
        <div>
          <div className="bmt-media-input bmt-picker-input margin-top">
            <div className="bmt-file-picker">
              <label htmlFor={ `photo-${props.inputKey}` }><span><i className="icon-image"></i> Select an image</span></label>
              <input type="file" id={ `photo-${props.inputKey}` } name={ `photo-${props.inputKey}` } disabled={ props.disabled } accept=".jpg,.jpeg,.png" onChange={ (e) => handleFileUpdate(e.target.files[0]) } />
            </div>
          </div>
          <button class="text-btn red margin-top" onClick={ () => setIsChangingPhoto(false) }>Cancel</button>
        </div>
      }
      <div className="bmt-picker-input-top margin-top">
        <h5>Name</h5>
        <input type="text" placeholder="Name..." value={ rep.name } onChange={ (e) => handleFieldChange("name", e.target.value) } /> 
      </div>
      <div className="bmt-picker-input-top margin-top">
        <h5>Short Bio</h5>
        <textarea placeholder="Short Bio..." value={ rep.bio } onChange={ (e) => handleFieldChange("bio", e.target.value) } /> 
      </div>
      { !props.disableEmail &&
        <div className="bmt-picker-input-top">
          <h5>Email</h5>
          <InputHandler hideLabel={ true } value={ rep.email } change={ (instanceKey, key, val) => handleFieldChange("email", val) } input={ {instanceKey: "representatives", key: "email", type: "text", restrictions: {pattern: "email"}} } />
        </div>  
      }
      <div className="bmt-picker-input-top">
        <h5>Website Link</h5>
        <InputHandler hideLabel={ true } value={ rep.websiteLink } change={ (instanceKey, key, val) => handleFieldChange("websiteLink", val) } input={ {instanceKey: "representatives", key: "websiteLink", specialClass: "prefix-url", type: "text", restrictions: {pattern: "url"}} } />
      </div>
      <div className="bmt-picker-input-top">
        <h5>Facebook Link</h5>
        <InputHandler hideLabel={ true } value={ rep.facebookLink } change={ (instanceKey, key, val) => handleFieldChange("facebookLink", val) } input={ {instanceKey: "representatives", key: "facebookLink", specialClass: "prefix-url", type: "text", restrictions: {pattern: "url"}} } />
      </div>
      <div className="bmt-picker-input-top">
        <h5>Twitter Link</h5>
        <InputHandler hideLabel={ true } value={ rep.twitterLink } change={ (instanceKey, key, val) => handleFieldChange("twitterLink", val) } input={ {instanceKey: "representatives", key: "twitterLink", specialClass: "prefix-url", type: "text", restrictions: {pattern: "url"}} } />
      </div>
      <div className="bmt-picker-input-top">
        <h5>LinkedIn Link</h5>
        <InputHandler hideLabel={ true } value={ rep.linkedinLink } change={ (instanceKey, key, val) => handleFieldChange("linkedinLink", val) } input={ {instanceKey: "representatives", key: "linkedinLink", specialClass: "prefix-url", type: "text", restrictions: {pattern: "url"}} } />
      </div>
      <div className="bmt-picker-input-top">
        <h5>Instagram Link</h5>
        <InputHandler hideLabel={ true } value={ rep.instagramLink } change={ (instanceKey, key, val) => handleFieldChange("instagramLink", val) } input={ {instanceKey: "representatives", key: "instagramLink", specialClass: "prefix-url", type: "text", restrictions: {pattern: "url"}} } />
      </div>
      <div className="bmt-picker-input-top">
        <h5>YouTube Link</h5>
        <InputHandler hideLabel={ true } value={ rep.youtubeLink } change={ (instanceKey, key, val) => handleFieldChange("youtubeLink", val) } input={ {instanceKey: "representatives", key: "youtubeLink", specialClass: "prefix-url", type: "text", restrictions: {pattern: "url"}} } />
      </div>
      <div className="bmt-picker-input-top">
        <h5>Pinterest Link</h5>
        <InputHandler hideLabel={ true } value={ rep.pinterestLink } change={ (instanceKey, key, val) => handleFieldChange("pinterestLink", val) } input={ {instanceKey: "representatives", key: "pinterestLink", specialClass: "prefix-url", type: "text", restrictions: {pattern: "url"}} } />
      </div>
      <button className="text-btn red" onClick={ handleRemoveRep }>Remove</button>
    </div> : 
    <div>
      <div className="bmt-media-input bmt-picker-input">
        <div className="bmt-file-picker">
          <label htmlFor={ `photo-${props.inputKey}` }><span><i className="icon-image"></i> Select an image</span></label>
          <input type="file" id={ `photo-${props.inputKey}` } name={ `photo-${props.inputKey}` } disabled={ props.disabled } accept=".jpg,.jpeg,.png" onChange={ (e) => handleFileChange(e.target.files[0]) } />
        </div>
      </div>
    </div>
  )
}

export default RepresentativeHandler