import md5 from "md5"
import $ from "jquery"
import Validator from "../input-handlers/validator"
import moment from "moment"
import Utils from "../utils"

class OutputHandler {

  constructor() {
    this.outputs = {}
    this._exhibitId = false
    this._id = false
    // this.trackingBase = "xtrackx|3|exhibit|click|" // V3 - migrating to V3b to attempt fix of Ignite tracking
    this.trackingBase = "xtrackx|3b|{booth_id}|click|"
    this.debounce = null
    this.defaultAttributes = Object.freeze({
      videos: {
        muted: true,
        loop: true
      }
    }) // Static object of props we can set defaults to for outputs depending on type
    this.propsThatCauseRender = Object.freeze({
      videos: {
        src: true,
        autoplay: true
      },
      slides: {
        value: true,
        delete: true
      },
      images: {
        src: true,
        link: true,
        clickZoom: true
      },
      representatives: {
        name: true,
        photo: true,
        bio: true,
        websiteLink: true,
        calendarLink: true,
        liveMeetingLink: true,
        external: {
          data: {
            representativesDisplay: true
          }
        }
      },
      showcase: {
        delete: true
      },
      resources: {
        name: true,
        thumb: true,
        resource: true,
        external: {
          data: {
            resourceDisplay: true
          }
        }
      },
      extras: {
        menu: {
          menuColor: true,
          information: true,
          contact: true,
          meeting: true,
          calendar: true,
          fishbowl: true,
          aroom: true,
          showcase: true
        },
        overlays: {
          information: true,
          fishbowlCTA: true,
          youtube: true,
          facebook: true,
          website: true,
          instagram: true,
          twitter: true,
          pinterest: true,
          linkedin: true
        },
        resources: {
          delete: true
        },
        representatives: {
          delete: true
        },
        videos: {
          src: true,
          delete: true,
          remoteSrc: true
        }
      }
    }) // Static object of props we expect that will cause a re-render depending on type
  }

  add(instanceKey, key, item) {
    if (!this.outputs[instanceKey + "." + key]) {
      this.outputs[instanceKey + "." + key] = {instanceKey: instanceKey, key: key, item: item, src: null, etag: null, propHash: null, props: []}
    }
  }

  getExtension(filename) {
    if (filename && filename !== undefined && typeof filename === "string" && filename.indexOf(".") > -1) {
      return filename.split('.').pop()
    }

    return ""
  }

  sanitize(input) {
    return input && input.length > 0 ? input.toLowerCase().replace(/" "/g, "_").replace(/[\W]+/g,"") : ""
  }

  runAfterRender(methods) {
    methods.forEach((method) => {
      if (window.$ && window[method] && typeof window[method] === "function") {
        window[method]()
      }
    })
  }

  createTrackingCode(item, targetInput, friendlyInput) {
    let tracking = JSON.parse(JSON.stringify(this.trackingBase))

    // Replace {booth_id} with exhibit id
    if (this._exhibitId && tracking.indexOf("{booth_id}") > -1) {
      tracking = tracking.replace("{booth_id}", this._exhibitId)
    }

    const target = this.sanitize(targetInput)
    const friendly = this.sanitize(friendlyInput)
    const endSlot = friendly === "" ? "" : "|" + friendly

    // Slot 5: Result, Slot 6: Target, Slot 7: Friendly name
    if (item === "image") {
      tracking += "view_resource|" + target + endSlot
    }
    else if (item === "external") {
      tracking += "link_external|" + target + endSlot
    }
    else if (item === "resource") {
      tracking += "download_resource|" + target + endSlot
    }
    else if (item === "overlay") {
      tracking += "view_detail|" + target + endSlot
    }
    else {
      tracking += item + "|" + target + endSlot
    }

    return tracking
  }

  createSpecialItem(item, optionalParams) {
    if (item === "chat" && optionalParams && optionalParams.name && optionalParams._id) {
      const chatFrame = $(".inferno-chat-container")
      const chatContainer = $(".inferno-generated-booth-chat")
      if (chatFrame.length < 1 || chatContainer.length < 1) { return }

      if (optionalParams.src) {
        chatContainer.removeClass("hide")
      }
      else {
        chatContainer.addClass("hide")
      }

      // Get iframe url
      const iframeSrc = chatFrame[0].src
      if (chatContainer.hasClass("updated-chat") && chatContainer.find(".inferno-chat-name").length > 0) {
        chatContainer.find(".inferno-chat-name").text(optionalParams.name)
      }
      else {
        chatFrame[0].src = iframeSrc.replace("{chatId}", optionalParams._id).replace("{chatName}", optionalParams.name)
      }
    }
    else if (item === "badge" && optionalParams && optionalParams.src !== undefined) {
      const badgeContainer = $(".inferno-generated-booth-qr")
      const badgeImage = $(".inferno-booth-qr-img")
      
      if (badgeContainer.length < 1 || badgeImage.length < 1) { return }

      if (optionalParams.src) {
        badgeContainer.removeClass('hide')
        badgeImage[0].src = optionalParams.src
      }
      else {
        badgeContainer.addClass('hide')
      }
    }
    else if (item === "grid") {
      const gridEl = $(optionalParams.templateClass)
      const listEl = $("." + optionalParams.templateObjectClass)

      if (gridEl.length < 1 || listEl.length < 1) { return }

      // Reset
      listEl.empty()
      const listItems = typeof optionalParams.src === "object" ? optionalParams.src : Object.assign({}, optionalParams.src)
      const listItemKeys = Object.keys(listItems)

      if (optionalParams.src && listItemKeys.length > 0) {
        // Populate elements
        let itemStr = ""

        listItemKeys.forEach((listItemKey) => {
          const listItem = listItems[listItemKey]
          itemStr += "<li>"
          let i = 0

          Object.keys(listItem).filter((key) => key !== "_id" && listItem[key] !== "").forEach((itemKey) => {
            const tags = i === 0 ? ["<h3>", "</h3>"] : ["<h4>", "</h4>"]
            itemStr += tags[0] + listItem[itemKey] + tags[1]
            i++
          })

          itemStr += "</li>"
        })  

        listEl.append(itemStr)
        gridEl.removeClass('hide')
      }
      else {
        gridEl.addClass('hide')
      }
    }
    else if ((item === "social-link" || item === "social-icon") && optionalParams.templateClass && optionalParams.templateObjectClass) {
      const linkContainer = $(optionalParams.templateClass)
      const linkEl = linkContainer.find("." + optionalParams.templateObjectClass)


      if (optionalParams.extras && optionalParams.extras.prefixObject && optionalParams.extras.prefixClass) {
        const prefixEl = linkEl.find(optionalParams.extras.prefixClass)
        if (prefixEl.length < 1) {
          linkEl.append(optionalParams.extras.prefixObject)
        }
      }
 
      if (optionalParams.src) {
        linkEl.attr("href", "https://" + optionalParams.src)
        linkEl.attr("target", "_blank")

        if (item === "social-link") {
          const linkBody = linkEl.find(".link-body")
          if (linkBody.length < 1) {
            linkEl.append("<span class='link-body'>" + optionalParams.src + "</span>")
          }
          else {
            linkBody.text(optionalParams.src)
          }
        }
      }
      else {
        linkEl.remove()
      }
    }
  }

  createResourceList(resources, target) {
    let resourceListEls = ""
    if (resources) {

      Object.keys(resources).forEach((key) => {

        if (resources[key].resource && !resources[key].resource.delete) {

          let thumbSrc = "https://nextech-booths.s3-us-west-2.amazonaws.com/_assets/file-icons/files-empty.png"
          let href = null
          let anchorStart = ""
          let anchorEnd = ""
          let liTag = null

          if (resources[key].thumb && resources[key].thumb.remoteSrc) {
            thumbSrc = resources[key].thumb.remoteSrc
          }
          else if (resources[key].thumb && resources[key].thumb.src) {
            thumbSrc = resources[key].thumb.src
          }

          if (resources[key].resource && resources[key].resource.remoteSrc) {
            href = resources[key].resource.remoteSrc
          }
          else if (resources[key].resource && resources[key].resource.src) {
            href = resources[key].resource.src
          }

          let trackingId
          if (target === "external") {
            trackingId = this.createTrackingCode("resource", this.getExtension(href), resources[key].resource.name)
          }
          else {
            trackingId = this.createTrackingCode("overlay", "resources_overlay")
          }

          if (target === "external") {
            liTag = "<li>"
            anchorStart = "<a id='" + trackingId + "' href='" + href + "' target='_blank'>"
            anchorEnd = "</a>"
          }
          else {
            liTag = "<li id='" + trackingId + "' onclick='toggleOverlay(true, \"inferno-generated-booth-resources-overlay\")'>"
          }

          resourceListEls += liTag + anchorStart 
          resourceListEls += "<img src='" + thumbSrc + "' alt=' resource thumbnail' /><h4>" + resources[key].resource.name + "</h4>" + anchorEnd + "</li>"
        }
      })
    }

    return resourceListEls
  }

  createRepresentativesList(reps, menuColor, socialColor) {
    let repsListEls = ""
    if (reps) {

      Object.keys(reps).forEach((key) => {

        if (reps[key].photo && !reps[key].photo.delete) {

          let thumbSrc = "https://nextech-booths.s3-us-west-2.amazonaws.com/_assets/file-icons/files-empty.png"
          if (reps[key].photo && reps[key].photo.remoteSrc) {
            thumbSrc = reps[key].photo.remoteSrc
          }
          else if (reps[key].photo && reps[key].photo.src) {
            thumbSrc = reps[key].photo.src
          }

          repsListEls += "<li class='not-actionable representative'><div class='rep-image-container'><img class='rep-image' src='" + thumbSrc + "' alt='representative thumbnail' /></div>"
          
          repsListEls += "<div class='rep-bio'><h3 style='color: " + menuColor + "'>" + reps[key].name + "</h3>"

          if (reps[key].bio) {
            repsListEls += "<p style='color: " + menuColor + "'>" + reps[key].bio + "</p>"
          }

          repsListEls += "</div>"
          
          repsListEls += "<div class='rep-social-media'>"
          
          let hasLinks = false
          if (reps[key].liveMeetingLink || reps[key].calendarLink || reps[key].email) {
            repsListEls += "<h4 style='color: " + menuColor + "'>Let's Connect!</h4>"
            repsListEls += "<div class='rep-social-media-links'>"
            hasLinks = true
          }

          if (reps[key].liveMeetingLink)  {
            repsListEls += "<a class='live-meeting-btn' style='background: " + menuColor + "' href='https://" + reps[key].liveMeetingLink.replace("https://", "").replace("http://", "")  + "' target='_blank'><img src='https://nextech-booths.s3-us-west-2.amazonaws.com/_assets/icons/monitor_white.png'> Join My Meeting Room</a>"
          }

          if (reps[key].calendarLink && !reps[key].bookable) {
            repsListEls += "<a class='calendar-btn' style='color: " + menuColor + "; border-color: " + menuColor + "' href='https://" + reps[key].calendarLink.replace("https://", "").replace("http://", "")  + "' target='_blank'><img src='https://nextech-booths.s3-us-west-2.amazonaws.com/_assets/icons/calendar.png'> Book a Meeting</a>"
          }

          if (reps[key].email) {
            repsListEls += "<a class='calendar-btn' style='color: " + menuColor + "; border-color: " + menuColor + "' href='mailto:" + reps[key].email.trim()  + "'><img src='https://nextech-booths.s3-us-west-2.amazonaws.com/_assets/icons/mail.png'> Email me</a>"
          }

          if (hasLinks) {
            repsListEls += "</div>"
          }

          let hasSocial = false
          if (reps[key].websiteLink || reps[key].facebookLink || reps[key].twitterLink || reps[key].linkedinLink || reps[key].instagramLink || reps[key].youtubeLink || reps[key].pinterestLink) {
            repsListEls += "<h4 style='color: " + menuColor + "'>Social Media</h4><div class='rep-social-media-outlets'>"
            hasSocial = true
          }

          if (reps[key].websiteLink) {
            repsListEls += "<a style='background: " + menuColor + "' href='https://" + reps[key].websiteLink.replace("https://", "").replace("http://", "")  + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/globe.png'></a>"
          }

          if (reps[key].facebookLink) {
            repsListEls += "<a style='background: " + menuColor + "' href='https://" + reps[key].facebookLink.replace("https://", "").replace("http://", "")  + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/facebook.png'></a>"
          }

          if (reps[key].twitterLink) {
            repsListEls += "<a style='background: " + menuColor + "' href='https://" + reps[key].twitterLink.replace("https://", "").replace("http://", "")  + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/twitter.png'></a>"
          }

          if (reps[key].linkedinLink) {
            repsListEls += "<a style='background: " + menuColor + "' href='https://" + reps[key].linkedinLink.replace("https://", "").replace("http://", "")  + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/linkedin.png'></a>"
          }

          if (reps[key].instagramLink) {
            repsListEls += "<a style='background: " + menuColor + "' href='https://" + reps[key].instagramLink.replace("https://", "").replace("http://", "")  + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/instagram.png'></a>"
          }

          if (reps[key].youtubeLink) {
            repsListEls += "<a style='background: " + menuColor + "' href='https://" + reps[key].youtubeLink.replace("https://", "").replace("http://", "")  + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/youtube.png'></a>"
          }

          if (reps[key].pinterestLink) {
            repsListEls += "<a style='background: " + menuColor + "' href='https://" + reps[key].pinterestLink.replace("https://", "").replace("http://", "")  + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/pinterest.png'></a>"
          }

          if (hasSocial) {
            repsListEls += "</div>"
          }
          
          repsListEls += "</div></li>"
        }
      })
    }

    return repsListEls
  }

  createShowcaseMenuAndSlides(products) {
    let menu = ""
    let slides = ""
    let i = 0

    if (products) {

      // Find middle point
      let middleIndex = 0
      if (Object.keys(products).length > 1) {
        middleIndex = [Math.round((Object.keys(products).length - 1) / 2)]
      }

      Object.keys(products).forEach((key) => {

        if (products[key].photo && !products[key].photo.delete) {

          let prodSrc
          if (products[key].photo && products[key].photo.remoteSrc) {
            prodSrc = products[key].photo.remoteSrc
          }
          else if (products[key].photo && products[key].photo.src) {
            prodSrc = products[key].photo.src
          }

          let qrSrc
          if (products[key].qrCodePhoto && products[key].qrCodePhoto.remoteSrc) {
            qrSrc = products[key].qrCodePhoto.remoteSrc
          }
          else if (products[key].qrCodePhoto && products[key].qrCodePhoto.src) {
            qrSrc = products[key].qrCodePhoto.src
          }

          const className = i === middleIndex ? " active" : ""
          let content = '<div class="showcase-slide glide__slide slide_' + i + className + '">'
          content += '<h2>' + products[key].name + '</h2>'

          if (prodSrc) {
            content += '<img src="' + prodSrc + '" />'
          }

          if (products[key].arProductLink) {
            content += '<img onclick="selectShowcaseAr(\'' + products[key].arProductLink + '\')" src="https://inferno-event-assets.s3.us-west-2.amazonaws.com/ar-showcase/360.png" alt="360"/>'
          }

          if (qrSrc) {
            content += '<img src="' + qrSrc + '" alt="qr code"/>'
          }

          if (products[key].ctaLink && products[key].ctaText) {
            content += '<a class="more-btn" href="https://' + products[key].ctaLink.replace("https://", "").replace("http://", "") + '" target="_blank">' + products[key].ctaText +'</a>'
          }

          content += '</div>'

          let menuContent = '<li onclick="selectShowcaseSlide(' + i + ')" class="showcase-slide-menu-item showcase-slide-menu-item-' + i + className + '">' + products[key].name + '</li>'
          slides += content
          menu += menuContent
          i++
        }
      })
    }

    return {menu: menu, slides: slides}
  }

  process(exhibitId, exhibitName, exhibit, instance, template, log) {

    // Set id
    this._exhibitId = exhibitId
    this._id = instance._id

    if (log) {
      console.log('Processing ' + Object.keys(this.outputs).length + ' instance outputs')
    }

    // Go through our outputs
    Object.keys(this.outputs).forEach((outputKey) => {
      const output = this.outputs[outputKey]
      let templateClassArr = null

      if (output.instanceKey !== "extras" && output.instanceKey !== "resources" && output.instanceKey !== "representatives" && output.instanceKey !== "slides" && output.instanceKey !== "showcase" && output.item.templateClass) {
        // Setup class arr
        templateClassArr = !Array.isArray(output.item.templateClass) ? [output.item.templateClass] : output.item.templateClass
          
        let currentSrc = null
        let currentEtag = null
        let currentOptionsHash = ""

        if (output.item.type === "bool") {
          currentSrc = instance[output.instanceKey] && instance[output.instanceKey][output.key] !== undefined ? instance[output.instanceKey][output.key] : output.item.default
          currentOptionsHash = md5(output.instanceKey + output.key + currentSrc.toString())
        }
        else if (output.item.type === "textarea" || output.item.type === "text" || output.item.type === "hex") {
          currentSrc = instance[output.instanceKey] && instance[output.instanceKey][output.key] !== undefined ? instance[output.instanceKey][output.key] : ""
          currentOptionsHash = md5(output.instanceKey + output.key + currentSrc.toString())
        }
        else if (output.item.type === "grid") {
          currentSrc = instance[output.instanceKey] && instance[output.instanceKey][output.key] !== undefined ? instance[output.instanceKey][output.key] : []
          currentOptionsHash = md5(output.instanceKey + output.key + JSON.stringify(currentSrc))
        }
        else if (output.instanceKey === "displayToggles") {
          let val = ''

          // Locate dependent attr
          if (output.item.dependsOn && output.item.dependsOn.instanceKey && output.item.dependsOn.validator) {

            // Fetch val & also validate
            const preVal = output.item.dependsOn.key && instance[output.item.dependsOn.instanceKey] && instance[output.item.dependsOn.instanceKey][output.item.dependsOn.key] ? instance[output.item.dependsOn.instanceKey][output.item.dependsOn.key] : instance[output.item.dependsOn.instanceKey] ? instance[output.item.dependsOn.instanceKey] : null
            if (preVal && Validator.validate(preVal, output.item.dependsOn.validator)) {
              val = preVal
            }
          }

          if (val !== "") {
            if (val.resource) {
              currentSrc = val.resource.remoteSrc || val.resource.src || ""
            }
            else {
              currentSrc = val.remoteSrc || val.src || JSON.stringify(val)
            }
          }
          else {
            currentSrc = ""
          }

          currentOptionsHash = md5(currentSrc)
        }
        else if (instance[output.instanceKey] && instance[output.instanceKey][output.key] !== undefined) {
          // Get instance value
          let val = ''
          if (instance[output.instanceKey][output.key].src) {
            val = instance[output.instanceKey][output.key].src
          }
          else if (instance[output.instanceKey][output.key].remoteSrc) {
            val = instance[output.instanceKey][output.key].remoteSrc
          }

          currentSrc =  val
          currentEtag = instance[output.instanceKey][output.key].etag ? instance[output.instanceKey][output.key].etag :  md5(JSON.stringify(val))
        }
        else if (output.item.defaultValue && output.item.defaultValue.remoteSrc) {
          currentSrc =  output.item.defaultValue.remoteSrc
          currentEtag = md5(output.item.defaultValue.remoteSrc)
        }
        
        // Compile any other options that might require a re-render
        let currentOptions = {}
        if (instance[output.instanceKey] && instance[output.instanceKey][output.key]) {
          Object.keys(instance[output.instanceKey][output.key]).forEach((srcProp) => {
            if (this.propsThatCauseRender[output.instanceKey] && this.propsThatCauseRender[output.instanceKey][srcProp]) {
              currentOptions[srcProp] = instance[output.instanceKey][output.key][srcProp]
              currentOptionsHash += srcProp + instance[output.instanceKey][output.key][srcProp]
            }
          })

          // Set hash
          currentOptionsHash = md5(currentOptionsHash)
        }

        // Re-render
        if (output.etag !== currentEtag || currentOptionsHash !== output.propHash) {

          if (log) {
            console.log('Set: ', output.key)
          }

          // Update output
          output.src = currentSrc
          output.etag = currentEtag
          output.propHash = currentOptionsHash

          // Set in template
          templateClassArr.forEach((className) => {

            if (!className || $(className).length < 1) { return}

            if (output.item.templateAction === 'toggle' && output.item.templateToggleClass) {
              let isToggled
              if (!currentSrc) {
                $(className).addClass(output.item.templateToggleClass)
                isToggled = true
              }
              else {
                $(className).removeClass(output.item.templateToggleClass)
                isToggled = false
              }

              // Check if we need to add an attr to our item
              if (output.item.templateToggleAttr && output.item.templateToggleAttrVal) {
                if (isToggled) {
                  $(className).attr(output.item.templateToggleAttr, null)
                }
                else {
                  $(className).attr(output.item.templateToggleAttr, output.item.templateToggleAttrVal.replace("$src", currentSrc))
                }
              }
            }
            else if (output.item.templateAction === 'replace' && output.item.templateRule) {
              if (currentSrc !== null && currentSrc !== "") {
                $(className).css({[output.item.templateRule]: output.item.templateValue.replace("$value", currentSrc)})
              }
              else {
                $(className).css({[output.item.templateRule]: ""})
              }

            }
            else if (output.item.templateAction === 'replace' && output.item.templateAttribute) {
              if (currentSrc !== null && currentSrc !== "") {
                $(className).attr(output.item.templateAttribute, output.item.templateValue.replace("$value", currentSrc))  
                $(className).attr("style", "display:inherit")     
              }
              else {
                $(className).attr("style", "display:none")   
              }
            }
            else if (output.item.templateAction === 'insert' && output.item.templateObject) {

              const objectTargetClass = output.item.templateObjectClasses ? output.item.templateObjectClasses : output.item.templateObjectClass ? [output.item.templateObjectClass] : false
              const objectTarget = objectTargetClass ? "." + objectTargetClass[0] : output.item.templateObject

              let anObjectEl = $(className + " " + objectTarget)
              if (anObjectEl.length < 1) {
                const newEl = document.createElement(output.item.templateObject)
                newEl.setAttribute("class", objectTargetClass ? objectTargetClass.join(" ") : "")
                $(className).append(newEl)
                anObjectEl = $(className + " " + objectTarget)
              }

              if (output.item.templateObjectSpecialItem) {
                this.createSpecialItem(output.item.templateObjectSpecialItem, {name: exhibitName, templateClass: output.item.templateClass, templateObjectClass: output.item.templateObjectClass, src: currentSrc, _id: instance._id, extras: output.item.templateObjectSpecialItemExtras})
              }
              else if (output.item.type === "textarea" || output.item.type === "text") {
                // Set inner text (using html to support line breaks)
                if (currentSrc && currentSrc !== "") {
                  anObjectEl.html(currentSrc)
                }
                // Remove
                else {
                  anObjectEl.remove()
                }
              }
              else {
                // Set attribute
                if (currentSrc && currentSrc !== "") {

                  const fullSrc = currentSrc + (currentSrc.indexOf('data:image/') > -1 || currentSrc.indexOf('blob') > -1 ? "" : "?v=" + moment().valueOf())
                  anObjectEl.attr("src", fullSrc)

                  // Need to handle special case for video... b/c react can't mute it for some reason
                  if (output.item.templateObject === "video") {
                    anObjectEl[0].muted = true
                  }

                  // Handle image click events
                  if (output.item.templateObject === "img") {

                    if (instance[output.instanceKey][output.key].clickLink && instance[output.instanceKey][output.key].link) {
                      anObjectEl.attr("onclick", "openURL(\"https://" + instance[output.instanceKey][output.key].link + "\")")
                      anObjectEl.addClass("actionable")

                      // Add tracking
                      this.createTrackingCode("external")
                      const trackingId = this.createTrackingCode("external", instance[output.instanceKey][output.key].link)
                      anObjectEl.attr("id", trackingId)
                    }
                    else if (instance[output.instanceKey][output.key].clickZoom || output.item.clickZoom) {
                      anObjectEl.attr("onclick", "toggleOverlay(true, \"inferno-generated-booth-external-overlay\", \"" + currentSrc + "\", true)")
                      anObjectEl.addClass("actionable")

                      // Add tracking
                      const trackingId = this.createTrackingCode("image", this.getExtension(currentSrc), output.key)
                      anObjectEl.attr("id", trackingId)
                    }
                    else {
                      anObjectEl.removeAttr("onclick")
                      anObjectEl.removeClass("actionable")

                      // Remove tracking
                      anObjectEl.removeAttr("id")
                    }
                  }
                }
                // Remove
                else {
                  anObjectEl.remove()
                }
              }

              // Set default attributes
              if (this.defaultAttributes[output.instanceKey]) {
                Object.keys(this.defaultAttributes[output.instanceKey]).forEach((propKey) => {
                  anObjectEl.attr(propKey, this.defaultAttributes[output.instanceKey][propKey])
                })
              }

              // Set custom attributes
              if (output.item.templateObjectAttributes) {
                Object.keys(output.item.templateObjectAttributes).forEach((attrKey) => {
                  anObjectEl.attr(attrKey, output.item.templateObjectAttributes[attrKey])
                })
              }

              // Set options
              Object.keys(currentOptions).forEach((optionKey) => {
                if (currentOptions[optionKey]) {
                  anObjectEl.attr(optionKey, currentOptions[optionKey])
                }
                else {
                  anObjectEl.removeAttr(optionKey)
                }
              })
            }
          })
        }
        else {
          if (log) {
            console.log('Skip: ', output.key)
          }
        }
      }
      else if (output.instanceKey === "resources") {

        let resourceOptionsHash = ""

        if (instance.resources) {
          Object.keys(instance.resources).forEach((resourceKey) => {
            Object.keys(this.propsThatCauseRender.resources).forEach((propKey) => {
              let resourceVal = instance.resources[resourceKey][propKey] ? JSON.stringify(instance.resources[resourceKey][propKey]) : ""
              resourceOptionsHash += propKey + md5(resourceVal)
            })
          })

          Object.keys(this.propsThatCauseRender.resources.external).forEach((propKey) => {
            Object.keys(this.propsThatCauseRender.resources.external[propKey]).forEach((subpropKey) => {
              if (instance[propKey] && instance[propKey][subpropKey]) {
                resourceOptionsHash += propKey + subpropKey + md5(JSON.stringify(instance[propKey][subpropKey]))
              }
            })
          })
        }

        if (resourceOptionsHash === output.propHash && !output.item.alwaysUpdate) {
          if (log) {
            console.log('Skip: ', output.key)
          }
          return
        }

        // Set hash
        if (log) {
          console.log('Set: ', output.key)
        }


        output.propHash = resourceOptionsHash

        // Create list
        let anObjectEl = $(output.item.templateClass + " ." + output.item.templateObjectClasses[0])
        let appendClasses = [output.item.templateObjectClasses[0]]
        if (instance.data && instance.data.resourceDisplay && instance.data.resourceDisplay === "list") {
          appendClasses.push("list-view")
        }

        if (anObjectEl.length < 1) {
          const newTemplateObject = document.createElement(output.item.templateObject)
          $(newTemplateObject).attr("class", appendClasses.join(" "))
          $(output.item.templateClass).append(newTemplateObject)
          anObjectEl = $(newTemplateObject)
        }
        else {
          // Clear object & recreate
          anObjectEl.empty()
          anObjectEl.attr("class", appendClasses.join(" "))
        }

        // Create resource list
        anObjectEl.append(this.createResourceList(instance.resources, output.item.templateTarget))

        // Don't insert resource in template twice...
        if (output.key === "resource-overlay") { return }
        
        // Also check if we need to insert a resource vs create a list
        if (template.resources && Object.keys(template.resources).filter((key) => template.resources[key].templateClass && template.resources[key].templateAction && template.resources[key].templateAction === "insert").length > 0) {

          Object.keys(template.resources).filter((key) => template.resources[key].templateAction === "insert").forEach((appendKey) => {
            const appendObjectEl = $(template.resources[appendKey].templateClass)

            // Empty first
            if (appendObjectEl.length > 0 && appendObjectEl.find("." + template.resources[appendKey].templateObjectClass).length > 0) {
              appendObjectEl.find("." + template.resources[appendKey].templateObjectClass).remove()
            }

            const resourceSrc = instance.resources && instance.resources[appendKey] && instance.resources[appendKey].resource ? instance.resources[appendKey].resource.src || instance.resources[appendKey].resource.remoteSrc : false
            const thumbSrc = instance.resources && instance.resources[appendKey] && instance.resources[appendKey].thumb ? instance.resources[appendKey].thumb.src || instance.resources[appendKey].thumb.remoteSrc : "https://nextech-booths.s3-us-west-2.amazonaws.com/_assets/file-icons/files-empty.png"

            if (resourceSrc && thumbSrc) {
              const newTemplateObject = document.createElement("a")
              $(newTemplateObject).attr("class", template.resources[appendKey].templateObjectClass)
              $(newTemplateObject).attr("id", this.createTrackingCode("resource", this.getExtension(resourceSrc), instance.resources[appendKey].resource.name))
              $(newTemplateObject).attr("href", resourceSrc)
              $(newTemplateObject).attr("target", "_blank")

              // Add thumb
              const thumb = document.createElement("img")
              $(thumb).attr("src", thumbSrc)
              $(newTemplateObject).append(thumb)

              // Insert object
              appendObjectEl.append(newTemplateObject)
            }
          })
        }
      }
      else if (output.instanceKey === "representatives") {

        let repOptionsHash = ""

        if (instance.representatives) {
          Object.keys(instance.representatives).forEach((repKey) => {
            Object.keys(this.propsThatCauseRender.representatives).forEach((propKey) => {
              let resourceVal = instance.representatives[repKey][propKey] ? JSON.stringify(instance.representatives[repKey][propKey]) : ""
              repOptionsHash += propKey + md5(resourceVal)
            })
          })

          Object.keys(this.propsThatCauseRender.representatives.external).forEach((propKey) => {
            Object.keys(this.propsThatCauseRender.representatives.external[propKey]).forEach((subpropKey) => {
              if (instance[propKey] && instance[propKey][subpropKey]) {
                repOptionsHash += propKey + subpropKey + md5(JSON.stringify(instance[propKey][subpropKey]))
              }
            })
          })
        }

        if (repOptionsHash === output.propHash && !output.item.alwaysUpdate) {
          if (log) {
            console.log('Skip: ', output.key)
          }
          return
        }

        // Set hash
        if (log) {
          console.log('Set: ', output.key)
        }


        output.propHash = repOptionsHash

        // Create list
        let anObjectEl = $(output.item.templateClass + " ." + output.item.templateObjectClasses[0])
        let appendClasses = [output.item.templateObjectClasses[0]]
        if (instance.data && instance.data.representativesDisplay && instance.data.representativesDisplay === "list") {
          appendClasses.push("list-view")
        }

        if (anObjectEl.length < 1) {
          const newTemplateObject = document.createElement(output.item.templateObject)
          $(newTemplateObject).attr("class", appendClasses.join(" "))
          $(output.item.templateClass).append(newTemplateObject)
          anObjectEl = $(newTemplateObject)
        }
        else {
          // Clear object & recreate
          anObjectEl.empty()
          anObjectEl.attr("class", appendClasses.join(" "))
        }

        // Create resource list
        // const menuColor = instance.data && instance.data.menuColor ? `#${instance.data.menuColor}` : '#000'
        // Menu color can be used to customize the coloring... for now we'll just use black
        anObjectEl.append(this.createRepresentativesList(instance.representatives, "#000"))
      }
      else if (output.instanceKey === "showcase") {

        let showcaseHash = ""

        if (instance.showcase) {
          Object.keys(instance.showcase).forEach((productKey) => {
            Object.keys(this.propsThatCauseRender.showcase).forEach((propKey) => {
              let resourceVal = instance.showcase[productKey][propKey] ? JSON.stringify(instance.showcase[productKey][propKey]) : ""
              showcaseHash += propKey + md5(resourceVal)
            })
          })
        }

        if (showcaseHash === output.propHash && !output.item.alwaysUpdate) {
          if (log) {
            console.log('Skip: ', output.key)
          }
          return
        }

        // Set hash
        if (log) {
          console.log('Set: ', output.key)
        }

        output.propHash = showcaseHash

        // Create showcase
        let anObjectEl = $(output.item.templateClass + " ." + output.item.templateObjectClass)

        if (anObjectEl.length < 1) {
          const newTemplateObject = document.createElement(output.item.templateObject)
          $(newTemplateObject).attr("class", output.item.templateObjectClass + " no-display")
          $(output.item.templateClass).append(newTemplateObject)
          anObjectEl = $(newTemplateObject)
        }
        else {
          // Clear object
          anObjectEl.empty()
        }

        // Create showcase slides + menu
        const { slides, menu } = this.createShowcaseMenuAndSlides(instance.showcase)

        // Create container
        let showcaseContainer = '<div class="showcase-experience-ar no-display">'
        showcaseContainer += '<button class="showcase-experience-ar-close-btn" onclick="closeShowcaseAr()"><img src="https://inferno-event-assets.s3.us-west-2.amazonaws.com/ar-showcase/signout.png"/></button>'
        showcaseContainer += '<div class="showcase-experience-ar-content"></div></div>'

        // Create sidebar + menu
        showcaseContainer += '<div class="showcase-sidebar"><button class="showcase-close-btn" onclick="hideShowcase()"><img src="https://inferno-event-assets.s3.us-west-2.amazonaws.com/ar-showcase/signout.png"/></button>'
        showcaseContainer += '<h3>AR Show Room</h3><p>Use the menu below or scroll through our product showcase</p>'
        showcaseContainer += '<ul class="showcase-slide-menu">' + menu + '</ul></div>'

        // Create slide content
        showcaseContainer += '<div class="showcase-experience"><div class="glide">'
        
        if (instance.showcase && Object.keys(instance.showcase).length > 1) {
          showcaseContainer += '<div data-glide-el="controls">'
          showcaseContainer += '<button class="nav-btn left-btn" data-glide-dir="<"><img src="https://inferno-event-assets.s3.us-west-2.amazonaws.com/ar-showcase/leftarrow.png" /></button>'
          showcaseContainer += '<button class="nav-btn right-btn" data-glide-dir=">"><img src="https://inferno-event-assets.s3.us-west-2.amazonaws.com/ar-showcase/leftarrow.png" /></button>'
          showcaseContainer += '</div>'
        }

        showcaseContainer += '<div class="glide__track" data-glide-el="track"><div class="showcase-slides glide__slides">'
        showcaseContainer += slides + '</div></div>'

        // Complete container
        showcaseContainer += '</div></div>'
        anObjectEl.append(showcaseContainer)


        // After run
        setTimeout(() => {
          if (window.showShowcase && window._showcase && window._showcase.enabled) {
            window.showShowcase()
          }
        }, 100)
      }
      else if (output.instanceKey === "slides") {

        let slidesHash = ""

        if (instance.slides) {
          Object.keys(instance.slides).forEach((slideKey) => {
            Object.keys(this.propsThatCauseRender.slides).forEach((propKey) => {
              let slideVal = instance.slides[slideKey][propKey] ? JSON.stringify(instance.slides[slideKey][propKey]) : ""
              slidesHash += propKey + md5(slideVal)
            })
          })
        }

        if (slidesHash === output.propHash && !output.item.alwaysUpdate) {
          if (log) {
            console.log('Skip: ', output.key)
          }

          return
        }

        // Set hash
        if (log) {
          console.log('Set: ', output.key)
        }

        output.propHash = slidesHash

        // Create list
        let slideContainer = $(output.item.templateClass)
        Object.keys(instance.slides).forEach((slideKey) => {
          let anObjectEl = slideContainer.find(".slide-" + slideKey)

          if (anObjectEl.length < 1) {
            const newTemplateObject = document.createElement(output.item.templateObject)
            $(newTemplateObject).attr("class", output.item.templateObjectClass + " slide-" + slideKey)
            slideContainer.append(newTemplateObject)
            anObjectEl = $(newTemplateObject)
          }
 
          // Set value
          anObjectEl.html(instance.slides[slideKey].value)
        })
      }
      else if (output.instanceKey === "extras") {

        // if (!instance.data) { return }
          
        // First check if the object has changed
        // let extrasOptions = {}
        let extrasOptionsHash = ""

        if (instance.data) {
          Object.keys(instance.data).forEach((srcProp) => {
            if (this.propsThatCauseRender.extras[output.key] && this.propsThatCauseRender.extras[output.key][srcProp]) {
              // extrasOptions[srcProp] = instance.data[srcProp]
              extrasOptionsHash += srcProp + md5(JSON.stringify(instance.data[srcProp]))
            }
          })
        }

        if (instance.resources) {
          Object.keys(instance.resources).forEach((resourceKey) => {
            Object.keys(this.propsThatCauseRender.extras.resources).forEach((srcProp) => {
              const val = instance.resources[resourceKey].resource && instance.resources[resourceKey].resource[srcProp] !== undefined ? instance.resources[resourceKey].resource[srcProp] : ""
              extrasOptionsHash += srcProp + md5(JSON.stringify(val))
            })
          })
        }

        if (instance.representatives) {
          Object.keys(instance.representatives).forEach((repKey) => {
            Object.keys(this.propsThatCauseRender.extras.representatives).forEach((srcProp) => {
              const val = instance.representatives[repKey] && instance.representatives[repKey][srcProp] !== undefined ? instance.representatives[repKey][srcProp] : ""
              extrasOptionsHash += srcProp + md5(JSON.stringify(val))
            })
          })
        }

        // Refresh overlays for videos only in the case of specific templates
        if (template.key === "largeCIM_Tema" && instance.videos) {
          Object.keys(instance.videos).forEach((videoKey) => {
            Object.keys(this.propsThatCauseRender.extras.videos).forEach((srcProp) => {
              const val = instance.videos[videoKey][srcProp] !== undefined ? instance.videos[videoKey][srcProp] : ""
              extrasOptionsHash += srcProp + md5(JSON.stringify(val))
            })
          })
        }

        // Check if our template has any post-render methods it needs to run
        if (template.afterRenderFunctions && template.afterRenderFunctions.length > 0) {
          if (this.debounce) {
            clearTimeout(this.debounce)
            this.debounce = null
          }

          this.debounce = setTimeout(() => this.runAfterRender(template.afterRenderFunctions), 1000)
        }

        if (extrasOptionsHash === output.propHash) {
          if (log) {
            console.log('Skip: ', output.key)
          }
          return
        }

        // Set hash
        if (log) {
          console.log('Set: ', output.key)
        }

        output.propHash = extrasOptionsHash

        // Handle booth extras, i.e menu, overlays
        if (output.item.templateAction === 'insert' && output.item.templateObject && output.item.templateObjectClasses) {
          let anObjectEl = $(output.item.templateClass + " ." + output.item.templateObjectClasses[0])

          if (anObjectEl.length < 1) {
            const newTemplateObject = document.createElement(output.item.templateObject)
            $(newTemplateObject).attr("class", output.item.templateObjectClasses.join(" "))
            $(output.item.templateClass).append(newTemplateObject)
            anObjectEl = $(newTemplateObject)
          }
          else {
            // Clear object & recreate
            anObjectEl.empty()
          }

          // Handle menu
          if (output.key === "menu") {
            
            // Create our menu based on the template
            if (template.menu) {
              Object.keys(template.menu).forEach((menuKey) => {
                const menuOption = template.menu[menuKey]
                if (menuOption.dependsOnAttr) {
                  const lookupEl = menuOption.dependsOnBooth ? exhibit : instance
                  const attr = menuOption.dependsOnAttr
                  const key = menuOption.dependsOnKey
                  const objectVal = menuOption.valueIsObject
                  let preObjectVal = false

                  if (lookupEl[attr] && ((key && lookupEl[attr][key]) || !key) && menuOption.trackingAction) {
                    let val = key ? lookupEl[attr][key] : lookupEl[attr]

                    if (objectVal) {
                      preObjectVal = val[objectVal.rootkey]
                      val = val[objectVal.rootkey]
                      objectVal.subkeys.forEach((subkey) => {
                        if (val && val[subkey]) {
                          val = val[subkey]
                        }
                      })
                    }

                    const isValid = menuOption.dependsOnValidator ? Validator.validate((preObjectVal ? preObjectVal : val), menuOption.dependsOnValidator) : true

                    if (isValid) {
                      let target = menuOption.trackingTarget
                      if (target.indexOf("{value}") > -1) {
                        target = target.replace("{value}", val)
                      }

                      if (target.indexOf("{valueExtension}") > -1) {
                        target = target.replace("{valueExtension}", this.getExtension(val))
                      }

                      let friendly = menuOption.trackingFriendly ? menuOption.trackingFriendly : ""

                      if (friendly.indexOf("{resourceName}") > -1 && preObjectVal) {
                        friendly = friendly.replace("{resourceName}", preObjectVal.name)
                      }

                      const menuOptionId = this.createTrackingCode(menuOption.trackingAction, target, friendly)
                      let menuLi = "<li id='" + menuOptionId + "'"
                      
                      if (menuOption.supplyValueInHref) {
                        menuLi += " data-href='" + val + "'"
                      }

                      if (menuOption.templateObjectClasses && menuOption.templateObjectClasses.length > 0) {
                        menuLi += " class='" + menuOption.templateObjectClasses.join(" ") + "'"
                      }

                      if (menuOption.clickAttr) {
                        menuLi += " onclick='" + menuOption.clickAttr.replace("{value}", val) + "'"
                      }

                      menuLi += ">"

                      if (menuOption.clickHref) {
                        let prefixedVal = val
                        if (val && typeof val === "string" && val.indexOf("https") < 0 && val.indexOf("blob") < 0) {
                          prefixedVal = "https://" + val
                        }

                        menuLi += "<a target='_blank' href='" + prefixedVal +"'>"
                      }

                      if (menuOption.extras && Object.keys(menuOption.extras).length > 0) {
                        Object.keys(menuOption.extras).forEach((key) => {
                          menuLi += menuOption.extras[key].templateObject
                        })
                      }

                      if (menuOption.iconHolder) {
                        menuLi += "<div class='" + (menuOption.iconHolder.templateObjectClass ? menuOption.iconHolder.templateObjectClass : "") + "'>"
                      }

                      menuLi += "<img src='" + menuOption.icon + "' alt='" + menuOption.label + "' />"

                      if (menuOption.iconHolder) {
                        menuLi += "</div>"
                      }

                      menuLi += " " + menuOption.label

                      if (menuOption.clickHref) {
                        menuLi += "</a>"
                      }

                      menuLi += "</li>"
                      anObjectEl.append(menuLi)
                    }
                  }
                }
              })
            }

            // BG Color applies to all menus
            const menuColorTargetClassArr = output.item.colorTemplateClass && Array.isArray(output.item.colorTemplateClass) ? output.item.colorTemplateClass.map((a) => $(a)) : [anObjectEl]
            menuColorTargetClassArr.forEach((itemEl) => {
              if (instance.data && instance.data.menuColor) {
                itemEl.css({backgroundColor: "#" + instance.data.menuColor})
                if (itemEl.find("li").length > 0) {
                  itemEl.find("li").css({backgroundColor: "#" + instance.data.menuColor})
                }
              }
              else {
                itemEl.css({backgroundColor: ""})
              }
            })
          }
          // Todo: Store overlay content in JSON
          // Handle overlays
          else if (output.key === "overlays") {

            // Setup overlay content
            let overlayContent = "<div class='inferno-generated-booth-overlay'><button class='inferno-generated-booth-close-btn' onclick='toggleOverlay()'><img src='https://boothgenerator.nextechar.com/_assets/icons/x.png' alt='close'></button>"

            // Desc
            if (instance.data && instance.data.information) {
              const label = template.menu && template.menu.abstract ? "Abstract" : "Information"
              overlayContent += "<div class='inferno-generated-booth-info-overlay'><h2>" + label + "</h2><div class='inferno-generated-booth-overlay-flex-container'><div class='inferno-generated-booth-overlay-flex-container-left'><h4>About</h4><p>" + instance.data.information + "</p></div>"
              
              // Social icons 
              if (instance.data.website || instance.data.facebook || instance.data.twitter || instance.data.linkedin || instance.data.instagram || instance.data.youtube || instance.data.pinterest) {
                overlayContent += "<div class='inferno-generated-booth-overlay-flex-container-right'><h4>Social Media</h4><ul class='inferno-generated-booth-overlay-social-icons'>"

                if (instance.data.website) {
                  overlayContent += "<li><a href='" + Utils.maybePrefixHTTPs(instance.data.website) + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/globe.png'/></a></li>"
                }

                if (instance.data.facebook) {
                  overlayContent += "<li><a href='" + Utils.maybePrefixHTTPs(instance.data.facebook) + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/facebook.png'/></a></li>"
                }

                if (instance.data.twitter) {
                  overlayContent += "<li><a href='" + Utils.maybePrefixHTTPs(instance.data.twitter) + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/twitter.png'/></a></li>"
                }

                if (instance.data.linkedin) {
                  overlayContent += "<li><a href='" + Utils.maybePrefixHTTPs(instance.data.linkedin) + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/linkedin.png'/></a></li>"
                }

                if (instance.data.instagram) {
                  overlayContent += "<li><a href='" + Utils.maybePrefixHTTPs(instance.data.instagram) + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/instagram.png'/></a></li>"
                }

                if (instance.data.youtube) {
                  overlayContent += "<li><a href='" + Utils.maybePrefixHTTPs(instance.data.youtube) + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/youtube.png'/></a></li>"
                }

                if (instance.data.pinterest) {
                  overlayContent += "<li><a href='" + Utils.maybePrefixHTTPs(instance.data.pinterest) + "' target='_blank'><img src='https://booth-management-tool-storage.s3.us-west-2.amazonaws.com/_assets/icons/social/pinterest.png'/></a></li>"
                }

                overlayContent += "</ul></div>"
              }

              // Close
              overlayContent += "</div></div>"
            }

            // Disclaimer
            if (instance.data && instance.data.disclaimerText) {
              overlayContent += "<div class='inferno-generated-booth-disclaimer-overlay'><h2>Disclaimer</h2><p>" + instance.data.disclaimerText + "</p></div>"
            }

            // Reps
            // if (instance.representatives && Object.keys(instance.representatives).length > 0) {
            //   overlayContent += "<div class='inferno-generated-booth-representatives-overlay'><h2>Representatives</h2><div class='inferno-generated-booth-representatives-overlay-list'>";

            //   let itemStr = "<ul>"
            //   Object.keys(instance.representatives).forEach((listItemKey) => {
            //     const listItem = instance.representatives[listItemKey]
            //     itemStr += "<li class='not-actionable'>"
            //     let i = 0

            //     Object.keys(listItem).filter((key) => key !== "_id").sort((a, b) => typeof listItem[a] === "object" && typeof listItem[b] !== "object" ? -1 : 1).forEach((itemKey) => {
            //       const isObject = typeof listItem[itemKey] === "object"
            //       if (isObject) {
            //         if (listItem[itemKey].src || listItem[itemKey].remoteSrc) {
            //           const src = listItem[itemKey].remoteSrc || listItem[itemKey].src
            //           itemStr += "<div class='rep-image-container'><img class='rep-image' src='" + src + "' /></div>"
            //         }
            //       }
            //       else {
            //         const tags = i === 0 ? ["<h3 class='no-margin'>", "</h3>"] : ["<h4 class='no-margin'>", "</h4>"]
            //         itemStr += tags[0] + listItem[itemKey] + tags[1]
            //         i++
            //       }
            //     })

            //     itemStr += "</li>"
            //   })  

            //   itemStr += "</ul>"
            //   overlayContent += itemStr + "</div></div>"
            // }

            // Representatives
            overlayContent += "<div class='inferno-generated-booth-representatives-overlay'><h2>Representatives</h2><div class='inferno-generated-booth-representatives-overlay-list'></div></div>"

            // Resources
            overlayContent += "<div class='inferno-generated-booth-resources-overlay'><h2>Resources</h2><div class='inferno-generated-booth-resources-overlay-list'></div></div>"
            
            // Video picker
            if (template.key === "largeCIM_Tema") {
              overlayContent += "<div class='inferno-generated-booth-video-picker-overlay'><h2>Select a video</h2><ul class='video-picker'>"
              if (instance.videos && Object.keys(instance.videos).length > 0) {
                Object.keys(instance.videos).sort().forEach((videoKey) => {
                  let src = instance.videos[videoKey].src || instance.videos[videoKey].remoteSrc
                  if (src) {
                    overlayContent += "<li onclick='toggleOverlay(true, \"inferno-generated-booth-video-overlay\", \"" + videoKey + "\")'><video src='" + src + "'></video></li>"
                  }
                })
              }
              overlayContent += "</ul></div>"
            }

            // Video + more
            overlayContent += "<div class='inferno-generated-booth-video-overlay'></div><div class='inferno-generated-booth-external-overlay'></div>"

            // Handle fish bowl CTA
            const fishAcceptId = this.createTrackingCode("other", 'share_info', 'fish_bowl')
            const fishDeclineId = this.createTrackingCode("other", 'decline_share_info', 'fish_bowl')
            const fishCTA = instance.data && instance.data.fishbowlCTA ? instance.data.fishbowlCTA : "Would you like to share your contact information with this exhibitor?"
            overlayContent += "<div class='inferno-generated-booth-fish-bowl-overlay'><h2>Contact share</h2>"
            overlayContent += "<h3>" + fishCTA + "</h3><button id='" + fishAcceptId + "' class='inferno-confirm-btn' onclick='toggleOverlay(true,\"inferno-generated-booth-fish-bowl-overlay-done\")'>Yes, share my contact information.</button>"
            overlayContent += "<button id='" + fishDeclineId + "' class='inferno-cancel-btn' onclick='toggleOverlay()'>Cancel</button></div>"
            overlayContent += "<div class='inferno-generated-booth-fish-bowl-overlay-done'><h2>Thank you!</h2><h3>Your contact information has been shared.</h3></div>";

            // Close content
            overlayContent += "</div>"

            // Append
            anObjectEl.append(overlayContent)
          } 
        }
      }
    })
  }
}

export default OutputHandler