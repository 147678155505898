import Header from "../header/header"
import AdminPanel from "../nav/adminPanel"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import API from "../../services/api"
import { setSelectedClient } from "../../redux/reducers/clients"
import "../dashboard/dashboard.scss"
import { setHeaderClickEvent, setHeaderLeftButton, setHeaderRightButtons, setHeaderRightItems, setHeaderTitle } from "../../redux/reducers/ui"
import Constants from "../constants"
import moment from "moment"
import * as XLSX from "xlsx"
import "./reportDetail.scss"
import ReportPlatformDetailView from "./reportPlatformDetailView"
import ReportExhibitDetailView from "./reportExhibitDetailView"

const ReportDetail = (props) => {

  const api = new API()
  const dispatch = useDispatch()
  const [report, setReport] = useState(null)
  const [user, client, headerEvent] = useSelector((state) => [state.auth.user, state.clients.selectedClient, state.ui.header.clickEvent])
  const [selectedTab, setSelectedTab] = useState("platform")

  // Fetch report
  useEffect(() => {
    let mounted = true
    props.loader.start()

    const fetchReport = async () => {
      
      try {
        const fetchedClient = await fetchClient()
        api.methods.get(`reports/${props.match.params.clientId}/${props.match.params.reportId}`).then((res) => {
          if (!mounted) { return }
          props.loader.stop()

          console.log(res.data.report)
          if (res && res.data && res.data.report) {

            // Set report
            setReport(res.data.report)

            // Set header
            dispatch(setHeaderTitle(`${res.data.report.name}`))
            dispatch(setHeaderRightButtons([{label: "Download", icon: "icon-download", className: "primary-btn", key: Constants.REPORT_DOWNLOAD_BTN}]))
            dispatch(setHeaderRightItems(null))
            dispatch(setHeaderLeftButton({label: user.isAdmin ? `${fetchedClient.firstName} ${fetchedClient.lastName}` : "Reports", key: Constants.REPORT_BACK_BTN}))
          }
          else {
            setReport(null)
          }

        }, () => {
          setReport(null)
          props.loader.stop()
        })
      }
      catch (e) {
        console.log('failed to set client')
        props.loader.stop()
      }
    }

    fetchReport()

    return () => mounted = false
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Handle header event
  useEffect(() => {

    if (!headerEvent) { return }

    // Clear
    dispatch(setHeaderClickEvent(null))

    // Handle
    if (headerEvent.key === Constants.REPORT_BACK_BTN) {
      const url = user.isAdmin ? `/clients/${client._id}` : '/reports'
      props.history.push(url)
    }
    else if (headerEvent.key === Constants.REPORT_DOWNLOAD_BTN) {
      handleDownloadReport()
    }

  }, [headerEvent]) // eslint-disable-line


  const fetchClient = async () => {
    return new Promise((resolve, reject) => { 
      if (client) {
        resolve(client)
      }

      api.methods.get(`clients/${props.match.params.clientId}`).then((res) => {
        // Set selected client
        if (res.data && res.data.client) {
          dispatch(setSelectedClient(res.data.client))
          resolve(res.data.client)
        }
        else {
          reject()
        }

      }, () => {
        reject()
      })
    })
  }

  const handleDownloadReport = () => {
    // Start loader
    const t1 = moment().valueOf()
    props.loader.start()

    // Summary page
    const summaryPage = XLSX.utils.json_to_sheet([{
      '# Registered Users': report.data.registeredUsersCount, 
      '# Active Users': report.data.turnoutUserCount, 
      '# Active Sessions': report.data.activeSessions, 
      'Average Session Duration per Active User': Math.round(report.data.averageDuration) + " minute(s)",
      'Show Rate': report.data.turnout + "%",
      'No Show Rate': (100 - report.data.turnout) + "%"
    }])

    // Weekly page
    const weeklyPage = XLSX.utils.json_to_sheet(report.data.activity.map((item) => {
      return {
        'Date': item.date,
        'Engagement': item.rate + "%",
        '# Sessions': item.sessions,
        '# Views': item.views
      }
    }))

    // Page activity
    const pageActivityPage = XLSX.utils.json_to_sheet(report.data.pageInsights.reverse().map((item) => {
      return {
        'Name': item.name,
        '# Views': item.viewItemsCount,
        '# Interactions': item.contentItemsCount,
        '# Total': item.totalCount,
        'Percentile': item.percentile + 'th'
      }
    }))

    // Page activity
    const userActivityPage = XLSX.utils.json_to_sheet(report.data.userInsights.sort((a, b) => a.totalCount > b.totalCount ? -1 : 1).map((item) => {
      return {
        'First Name': item.firstName,
        'Last Name': item.lastName,
        'Email': item.email,
        '# Views': item.viewItemsCount,
        '# Interactions': item.contentItemsCount,
        '# Total': item.totalCount
      }
    }))

    // Compile book
    const wb = {Sheets: {'Summary': summaryPage, '7 day activity': weeklyPage, 'Page activity': pageActivityPage, 'User activity': userActivityPage}, SheetNames: ['Summary', '7 day activity', 'Page activity', 'User activity']}
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const data = new Blob([excelBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"})
    var a = document.createElement("a")
    a.href = URL.createObjectURL(data)
    a.setAttribute("download", `${report.name.replace(/[\W_]+/g,"-")}_Data_Export_${ moment().format("MM/DD/YYYY-hh:mm a") }.xlsx`)
    a.click()

    // Finish
    const t2 = moment().valueOf()
    const diff = t2 - t1
    let timeout = 0
    if (diff < 500) {
      timeout = 500 - diff
    }

    setTimeout(() => {
      props.loader.stop()
    }, timeout)
  }

  return (
    report ? 
    <div className="bmt-main-view">
      <AdminPanel />
      <Header />
      <div className="reporting-meta">
        <span className="icon-clock"></span> <i>{ moment(report.startDate).format("dddd, MMMM Do, YYYY") }</i> {report.endDate !== report.startDate && <>- <i>{moment(report.endDate).format("dddd, MMMM Do, YYYY")}</i></>}
      </div>
      <div className="bmt-details">
        <ul className="bmt-details-top-bar" style={ {margin: "1vmin 0 4vmin"} }>
          <li className={selectedTab === "platform" ? "selected" : ""} onClick={() => setSelectedTab("platform")}>Platform Insights</li>
          <li className={selectedTab === "exhibits" ? "selected" : ""} onClick={() => setSelectedTab("exhibits")}>Exhibit Insights</li>
        </ul>
      </div>
      <>
        { selectedTab === "platform" ? <ReportPlatformDetailView report={report} /> : <ReportExhibitDetailView report={report.data} /> }
      </>
    </div>
    : 
    <h3 className="error">Oops, we had trouble loading this report, please try again.</h3>
  )
}

export default ReportDetail