import InputHandler from "../../input-handlers/inputHandler"

const DetailsForm = (props) => {

  if (!props.version.template || !props.version.instance || !props.version.template.meta) { return null }

  const orderedKeys = Object.keys(props.version.template.meta).sort((item) => item.order)
  
  return (
    <div className="bmt-form-page bmt-wrapper">
      <h2>Details</h2>
      {
        orderedKeys.map((key) => {
          let item = {...props.version.template.meta[key], key: key, instanceKey: 'data'}

          return (
            <InputHandler overrides={ props.version.template.overrides } readOnly={ props.readOnly } key={ key } input={ item } value={ props.version.instance && props.version.instance.data && props.version.instance.data[key] ? props.version.instance.data[key] : null } change={ props.change } />
          )
        })
      }
    </div>
  )
}

export default DetailsForm