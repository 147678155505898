const Constants = {
  CLIENTS_NEW_BTN: "clientsNewBtn",
  CLIENT_DETAIL_SAVE_BTN: "clientDetailSaveBtn",
  CLIENT_DETAIL_REVERT_BTN: "clientDetailRevertBtn",
  CLIENT_DETAIL_NEW_BTN: "clientDetailNewBtn",
  CLIENT_DETAIL_BACK_BTN: "clientDetailBackBtn",
  BLUEPRINTS_NEW_BTN: "blueprintsNewBtn",
  BLUEPRINT_DETAIL_BACK_BTN: "blueprintDetailBackBtn",
  BLUEPRINT_DETAIL_DELETE_BTN: "blueprintDetailDeleteBtn",
  BOOTH_DETAIL_BACK_BTN: "boothDetailBackBtn",
  BOOTH_DETAIL_NEW_BTN: "boothDetailNewBtn",
  BOOTH_DETAIL_DELETE_BTN: "boothDetailDeleteBtn",
  BOOTH_EDITOR_BACK_BTN: "boothEditorBackBtn",
  BOOTH_EDITOR_CANCEL_BTN: "boothEditorCancelBtn",
  BOOTH_EDITOR_SAVE_BTN: "boothEditorSaveBtn",
  BOOTH_DETAIL_EXPORT_BTN: "boothDetailExportBtn",
  REPORTS_CREATE_BTN: "reportsCreateBtn",
  REPORT_BACK_BTN: "reportBackBtn",
  REPORT_DOWNLOAD_BTN: "reportDownloadBtn",
  VIEW_SETTINGS: {
    name: {label: "Name", enabled: true, disabled: true}, 
    type: {label: "Type", enabled: true}, 
    status: {label: "Status", enabled: true}, 
    category: {label: "Category", enabled: false},
    shared: {label: "Shared", enabled: false}, 
    expo: {label: "Expo Image", enabled: false}, 
    chat: {label: "Chat", enabled: false}, 
    aroom: {label: "ARoom", enabled: false}, 
    fishbowl: {label: "Fishbowl", enabled: false}, 
    lastUpdated: {label: "Last Updated", enabled: true}, 
    delete: {label: "Delete", enabled: true}
  }
}

export default Constants