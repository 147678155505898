import { forwardRef, useImperativeHandle, useState, useRef, useEffect } from "react"

const DebouncedInput = forwardRef((props, ref) => {

  const [val, setVal] = useState("")
  const timer = useRef(null)

  useEffect(() => {

    return () => {
      // Clear timer if it exists
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = null
      }
    }

  }, [])

  // Set ref (for remote clearing)
  useImperativeHandle(ref, () => ({
    clear() {
      // Clear our input
      handleClear()
    }
  }))

  const handleChange = (newValue) => {
    // Set val
    setVal(newValue)

    // Clear timer if it exists
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }

    // Kick off timer
    timer.current = setTimeout(() => dispatchChange(newValue), 300)
  }

  const dispatchChange = (newValue) => {
    // If we have a 'ready' prop, let's call it
    if (props.ready) {
      props.ready(newValue)
    }
  }

  const handleClear = () => {
    // Clear timer if it exists
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }

    setVal("")
    dispatchChange("")
  }

  return (
    <div>
      <input type="text" placeholder={ props.placeholder ? props.placeholder : "" } value={ val } onChange={ (e) => handleChange(e.target.value) } />
      { props.includeClear && val.length > 0 && <button className="cancel-btn" onClick={ handleClear }>Clear</button> }
    </div>
  )
})

export default DebouncedInput