import InputHandler from "../../input-handlers/inputHandler"
import { useState, useEffect } from "react"
import moment from "moment"
import md5 from "md5"

const RepresentativesForm = (props) => {

  const [items, setItems] = useState(false)
  const [disableBooking, setDisableBooking] = useState(false)

  useEffect(() => {
    if (props.version.instance.representatives || props.version.template.representatives) {
      let required = {}
      let curItems = props.version.instance.representatives && Object.keys(props.version.instance.representatives).length > 0 ? props.version.instance.representatives : {}
      if (props.version.template.representatives && Object.keys(props.version.template.representatives).length > 0) {
        Object.keys(props.version.template.representatives).forEach((repKey) => {
          if (props.version.template.representatives[repKey].required) {
            required[repKey] = props.version.template.representatives[repKey]
          }
        })
      }

      let extendedItems = {...curItems}
      Object.keys(curItems).forEach((curKey) => {
        if (props.version.template.representatives && props.version.template.representatives[curKey]) {
          let curItemCopy = {...curItems[curKey]}
          curItemCopy.required = props.version.template.representatives[curKey].required
          curItemCopy.label = props.version.template.representatives[curKey].label
          extendedItems[curKey] = curItemCopy
        }
        else {
          extendedItems[curKey] = curItems[curKey]
        }
      })

      setItems({...required, ...extendedItems})
    }
    else {
      setItems({})
    }

    // Check if this booth already has booking enabled
    let disableBookingFlag = false
    if (props.version.instance.representatives && Object.keys(props.version.instance.representatives).length > 0) {
      Object.keys(props.version.instance.representatives).forEach((key) => {
        const rep = props.version.instance.representatives[key]
        if (rep.bookable && rep.bookable.resourceId) {
          disableBookingFlag = true
        }
      })
    }

    setDisableBooking(disableBookingFlag)

  }, [props.version])

  const handleAddRepresentative = () => {
    // Create a new item & add it to the list
    const newKey = md5(moment().valueOf().toString())
    setItems({...items, [newKey]: {key: newKey}})
  }

  const allowMoreRepresentatives = !props.version.template.representatives.max || props.version.template.representatives.max > Object.keys(items).length 
  const disableRepresentativesEmail = props.version.template.representatives.disableEmail

  return (  
    <div className="bmt-form-page bmt-wrapper">
      <h2>Representatives</h2>
      { Object.keys(items).length < 1 && <h5 className="no-items">You have not added any representatives yet.</h5> }
      { Object.keys(items).map((itemKey, index) => {
        const label = "Representative #" + (index+1)
        const itemBookingDisabled = disableBooking && !(items[itemKey].bookable && items[itemKey].bookable.resourceId)
        const fullItem = {...items[itemKey], label: label, key: itemKey, instanceKey: 'representatives', type: 'file', disableBooking: itemBookingDisabled, disableEmail: disableRepresentativesEmail}

        return (
          <InputHandler overrides={ props.version.template.overrides } readOnly={ props.readOnly } key={ itemKey } input={ fullItem } value={ props.version.instance && props.version.instance.representatives && props.version.instance.representatives[itemKey] ? props.version.instance.representatives[itemKey] : null } change={ props.change } />
        )
      }) }
      { allowMoreRepresentatives && <button className="text-btn" onClick={ handleAddRepresentative }>Add Representative</button> }
    </div>
  )
}

export default RepresentativesForm