import { useSelector } from "react-redux"

const AdminPanel = () => {
  const { user } = useSelector((state) => state.auth)
  
  return (
    user && user.isAdmin ? <div className="admin-panel"><span className="icon-info"></span> You are currently logged in as an admin.</div> : ""
  )
}

export default AdminPanel