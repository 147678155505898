import { createSlice } from "@reduxjs/toolkit"

const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    clients: [],
    selectedClient: false,
    ui: {
      showNewClient: false
    },
    newClient: false
  },
  reducers: {
    showNewClient(state, action) {
      state.ui.showNewClient = action.payload.show
    },
    addNewClient(state, action) {
      state.clients = [...state.clients, action.payload]
    },
    setClients(state, action) {
      state.clients = action.payload
    },
    setSelectedClient(state, action) {
      state.selectedClient = action.payload
    }
  }
})

export const { showNewClient, addNewClient, setClients, setSelectedClient } = clientsSlice.actions
export default clientsSlice.reducer