import Header from "../header/header"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import AdminPanel from "../nav/adminPanel"
import API from "../../services/api"
import { extendAuthTimer } from "../../redux/reducers/auth"
import { setSelectedClient } from "../../redux/reducers/clients"
import BlueprintForm from "../blueprints/components/blueprintForm"
import moment from "moment"
import { clearHeaderButtons, setHeaderClickEvent, setHeaderLeftButton, setHeaderRightButtons, setHeaderTitle } from "../../redux/reducers/ui"
import Constants from "../constants"

const BlueprintDetail = (props) => {

  // Set state
  const dispatch = useDispatch()
  const api = new API()
  const [user, client, boothTypes, headerEvent] = useSelector((state) => [state.auth.user, state.clients.selectedClient, state.booths.availableTypes, state.ui.header.clickEvent])
  const [error, setError] = useState(false)
  const [pristine, setPristine] = useState(false)
  const [blueprint, setBlueprint] = useState(false)
  const [pendingChanges, setPendingChanges] = useState(false)
  const [tab, setTab] = useState('details')
  const [usage, setUsage] = useState([])

  // Onload
  useEffect(() => {
    let mounted = true
    console.log('blueprint detail: mounted')

    // Go
    props.loader.start()
    dispatch(extendAuthTimer(true))
    setError(false)

    // Set selected client if it hasn't been set yet
    if (!user.isAdmin && !client) {
      
      api.methods.get(`clients/${user._id}`).then((res) => {

        if (mounted) {
          // Set client
          dispatch(setSelectedClient(res.data.client))
        }

      }, () => {
        console.log('error setting client')
        // todo: handle error
      })
    }

    // Fetch blueprint
    api.methods.get('blueprints/' + props.match.params.id).then((res) => {

      if (mounted) {
        props.loader.stop()

        if (res.data && res.data.blueprint) {
          // Set blueprint + pristine
          setBlueprint(res.data.blueprint)
          setPristine(res.data.blueprint)

          // Set header
          dispatch(setHeaderTitle(res.data.blueprint.name ? res.data.blueprint.name : "Blueprint"))

          if (!user.isShared) {
            dispatch(setHeaderLeftButton({label: "Blueprints", key: Constants.BLUEPRINT_DETAIL_BACK_BTN}))
            dispatch(setHeaderRightButtons([{label: "Delete", className: "negative-btn", key: Constants.BLUEPRINT_DETAIL_DELETE_BTN}]))
          }
          else {
            dispatch(clearHeaderButtons())
          }

          if (res.data.usage) {
            setUsage(res.data.usage)
          }
        }
        else {
          setError(true)
        }

      }

    }, () => {
      console.log('error fetching blueprint')
      if (mounted) {
        props.loader.stop()
        setError(true)
      }
    })

    return () => mounted = false

  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  // Handle header event
  useEffect(() => {
    if (headerEvent) {
      // Clear
      dispatch(setHeaderClickEvent(null))

      if (headerEvent.key === Constants.BLUEPRINT_DETAIL_BACK_BTN) {
        // Go back to list
        props.history.push('/blueprints')
      }
      else if (headerEvent.key === Constants.BLUEPRINT_DETAIL_DELETE_BTN) {
        handleDeleteBlueprint()
      }
    }

  }, [headerEvent]) // eslint-disable-line

  const cancelChanges = () => {
    setBlueprint({...pristine})
    setPendingChanges(false)
    dispatch(extendAuthTimer(true))
  }

  const saveChanges = () => {
    dispatch(extendAuthTimer(true))

    // Save
    const updated = {name: blueprint.name, description: blueprint.description, boothType: blueprint.boothType, selections: blueprint.selections}
    api.methods.put(`blueprints/${blueprint._id}`, updated).then((res) => {

      setPendingChanges(false)
      setPristine({...blueprint})

    }, () => {
      console.log('error updating blueprint')
      // todo: handle error
      window.alert("Oops, there was an issue updating your blueprint. Please try again")
    })
  }

  const handleDeleteBlueprint = () => {
    if (!window.confirm('Are you sure you would like to delete this blueprint? This action cannot be reversed')) { return }

    // Delete & then redirect to blueprints landing
    api.methods.delete('blueprints/' + blueprint._id).then((res) => {

      // Redirect
      props.history.push('/blueprints')

    }, () => {
      // todo: handle error
      console.log('error deleting blueprint')
    })
  }

  const handleBlueprintChange = (updated) => {
    setPendingChanges(true)
    setBlueprint({...updated})
  }

  const setupButtons = (
    <div className="bmt-button-group">
      <button className="cancel-btn" disabled={ !pendingChanges } onClick={ cancelChanges }>Cancel</button>
      <button className="primary-btn" disabled={ !pendingChanges } onClick={ saveChanges }>Save</button>
    </div>
  )

  const handleExhibitClick = (booth) => {
    if (booth.user_id !== user._id && user.isAdmin && (!client || client._id !== booth.user_id)) {
      api.methods.get(`/clients/${booth.user_id}`).then((clientRes) => {
        dispatch(setSelectedClient(clientRes.data.client))
        props.history.push(`/booths/${booth._id}`)
      })
    }
    else {
      props.history.push(`/booths/${booth._id}`)
    }
  }

  const view = (
    <div className="bmt-details">
      <ul className="bmt-details-top-bar">
        <li className={ tab === 'details' ? 'selected' : '' } onClick={ () => setTab('details') }>Details</li>
        <li className={ tab === 'usage' ? 'selected' : '' } onClick={ () => setTab('usage') }>Usage <span>({ usage.length })</span></li>
      </ul>
      { tab === 'details' ? 
        <div className="bmt-wrapper">
          <div className="bmt-wrapper-subheader">
            <h2>Details</h2>
            { setupButtons }
          </div>
          <div className="bmt-form-page smaller">
            <BlueprintForm pending={ pendingChanges } boothTypes={ boothTypes } limitedTypes={ !user.isAdmin && client.boothTypes ? client.boothTypes : false } modal={ false } blueprint={ blueprint } onChange={ handleBlueprintChange } />
          </div>
        </div> :
        <div className="bmt-wrapper">
          <h2>Usage - <strong>{ usage.length }</strong> { usage.length === 1 ? "exhibit" : "exhibits" }</h2>
          { usage.length > 0 && 
            <ul className="bmt-list-view">
              <li><div className="flex">Exhibit</div><div className="flex">Last Updated</div></li>
              { usage.map((b) => {
                  const last = b.lastUpdated ? b.lastUpdated : b.created
                  return (
                    <li key={ b._id}><div className="flex" onClick={ () => handleExhibitClick(b) }>{ b.name}</div><div className="flex">{ moment(last).format("MM/DD/YYYY hh:mm a") }</div></li>
                  )
                })
              }
            </ul>
          }
        </div>
      }
    </div>
  )
  
  return (
    <div className="bmt-main-view">
      { <AdminPanel /> }
      <Header />
      { (error || !blueprint) && <div className="error">Oops, there was an error fetching this blueprint. Please try again</div> }
      { !error && blueprint && view }
    </div>
  )
}

export default BlueprintDetail