import { createSlice } from "@reduxjs/toolkit"
import { session } from "../../services/session"

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: session.get() ? session.get() : null,
    sharedUi: {
      showInfernoSettings: false
    },
    categories: {
      lastFetched: null,
      items: []
    },
    timer: {
      running: false,
      extend: false
    }
  },
  reducers: {
    loginSuccess(state, action) {
      state.user = action.payload
      session.create(action.payload)
    },
    logout(state, action) {
      state.user = null
      session.destroy()
      state.timer.extend = false
      state.timer.running = false
    },
    unauthorized(state) {
      state.user = null
      session.destroy()
    },
    refreshToken(state, action) {
      session.update(action.payload)
      state.user.token = action.payload
    },
    setUserSetting(state, action) {
      if (!state.user.settings) {
        state.user.settings = {}
      }
      
      state.user.settings[action.payload.key] = action.payload.val
      session.updateSetting(action.payload.key, action.payload.val)
    },
    setSharedUi(state, action) {
      state.sharedUi[action.payload.key] = action.payload.val
    },
    setCategories(state, action) {
      state.categories = action.payload
    },
    startAuthTimer(state) {
      state.timer.running = true
    },
    stopAuthTimer(state) {
      state.timer.running = false
      state.timer.extend = false
    },
    extendAuthTimer(state, action) {
      state.timer.extend = action.payload
    }
  }
})

export const { loginSuccess, logout, unauthorized, refreshToken, setUserSetting, setSharedUi, setCategories, startAuthTimer, stopAuthTimer, extendAuthTimer } = authSlice.actions
export default authSlice.reducer