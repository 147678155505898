import InputHandler from "../../input-handlers/inputHandler"

const VideosForm = (props) => {

  if (!props.version.template || !props.version.instance) { return null }

  const orderedKeys = Object.keys(props.version.template.videos).sort((item) => item.order)
  
  return (
    <div className="bmt-form-page bmt-wrapper">
      <h2>Videos</h2>
      {
        orderedKeys.map((key) => {
          let item = {...props.version.template.videos[key], key: key, instanceKey: 'videos', type: 'file', restrictions: {filetype: ['.mov', '.mp4']}}
          return (
            <InputHandler overrides={ props.version.template.overrides } readOnly={ props.readOnly } key={ key } input={ item } value={ props.version.instance && props.version.instance.videos && props.version.instance.videos[key] ? props.version.instance.videos[key] : null } change={ props.change } />
          )
        })
      }
    </div>
  )
}

export default VideosForm