import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from "react"
import API from "../../services/api"
import AdminPanel from "../nav/adminPanel"
import Header from "../header/header"
import Modal from "../modal/modal"
import { extendAuthTimer } from "../../redux/reducers/auth"
import { setSelectedClient } from "../../redux/reducers/clients"
import BlueprintForm from "./components/blueprintForm"
import { setHeaderClickEvent, setHeaderLeftButton, setHeaderRightButtons, setHeaderTitle } from "../../redux/reducers/ui"
import Constants from "../constants"

const Blueprints = (props) => {

  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [showNewBlueprint, setShowNewBlueprint] = useState(false)
  const [newBlueprintError, setNewBlueprintError] = useState(false)
  const api = new API()
  const [user, client, boothTypes, headerEvent] = useSelector((state) => [state.auth.user, state.clients.selectedClient, state.booths.availableTypes, state.ui.header.clickEvent])

  useEffect(() => {
    let mounted = true
    props.loader.start()
    console.log('blueprints: mounted')
    
    // Set header 
    dispatch(setHeaderTitle("Blueprints"))
    dispatch(setHeaderLeftButton(null))
    dispatch(setHeaderRightButtons([{label: "Create New Blueprint", className: "outline-btn", key: Constants.BLUEPRINTS_NEW_BTN, icon: "icon-layers"}]))

    // Set selected client if it hasn't been set yet
    if (!user.isAdmin && !client) {
      
      api.methods.get(`clients/${user._id}`).then((res) => {

        if (mounted) {
          // Set client
          dispatch(setSelectedClient(res.data.client))
        }

      }, () => {
        console.log('error setting client')
        // todo: handle error
      })
    }

    // Fetch blueprints
    api.methods.get('blueprints').then((res) => {
      if (mounted) {
        setData(res.data.blueprints)
        props.loader.stop()
        dispatch(extendAuthTimer(true))
      }
    })

    return () => mounted = false
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Check for header click
  useEffect(() => {

    if (headerEvent && headerEvent.key === Constants.BLUEPRINTS_NEW_BTN) {
      setShowNewBlueprint(true)
      dispatch(setHeaderClickEvent(null))
    }

  }, [headerEvent]) // eslint-disable-line

  const handleNewBlueprintDone = (cancel, newBlueprint) => {
    if (cancel) {
      setShowNewBlueprint(false)
      return
    }

    // Reset error
    setNewBlueprintError(false)

    // Extend auth timer
    dispatch(extendAuthTimer(true))

    // Save blueprints
    api.methods.post('blueprints', newBlueprint).then((insertedBlueprintRes) => {

      // Add to list
      setData([...data, insertedBlueprintRes.data])

      // Close
      setShowNewBlueprint(false)

    }, () => {
      // Show error
      setNewBlueprintError(true)
    })
  }

  const handleDeleteBlueprint = (e, bpId) => {
    e.stopPropagation()

    if (!window.confirm("Are you sure you would like to delete this blueprint? This action cannot be reversed.")) { return }

    // Remove from blueprints
    api.methods.delete('blueprints/' + bpId).then(() => {

      // Remove from list
      setData(data.filter((item) => item._id !== bpId))

    }, () => {
      // Show error
      window.alert("Oops, there was an error deleting this blueprint. Please try again")
    })
  }

  const handleNavigateBlueprint = (bpId) => {
    props.history.push(`/blueprints/${bpId}`)
  }

  return (
    <div className="bmt-main-view">
      { <AdminPanel /> }
      { showNewBlueprint && <Modal title="Create New Blueprint" children={ <BlueprintForm modal={ true } error={ newBlueprintError } boothTypes={ boothTypes } limitedTypes={ !user.isAdmin && client.boothTypes ? client.boothTypes : false } done={ handleNewBlueprintDone } /> } close={ () => setShowNewBlueprint(false) }/> }
      <Header />
      { data.length > 0 ?
        <div className="bmt-wrapper">
          <ul className="bmt-list-view">
            <li>
              <div className="flex">Name</div>
              <div className="flex">Description</div>
              <div className="flex">Exhibit Type</div>
              <div className="flex red">Delete</div>
            </li>
            { 
              data.map((item) => {
                const typeSearch = boothTypes.find((a) => a.key === item.boothType)
                const niceType = typeSearch ? typeSearch.name : ""

                return (
                  <li key={ item._id } onClick={ () => handleNavigateBlueprint(item._id) }>
                    <div className="flex">{ item.name }</div>
                    <div className="flex">{ item.description }</div>
                    <div className="flex">{ niceType }</div>
                    <div className="flex"><button className="more-btn red" onClick={ (e) => handleDeleteBlueprint(e, item._id) }><span className="icon-trash-2"></span> Delete</button></div>
                  </li>
                )
              })
            }
          </ul>
        </div>
        : <p>You have not created any blueprints yet.</p>
      }
    </div>
  )
}

export default Blueprints